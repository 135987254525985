import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import ErrorImg from 'styles/assets/errorpage.png';

function Page404 (props) {

  return (
    <div>
      <div className="" style={{backgroundImage:"linear-gradient(to top, #ffffff, #f1f8ff)"}}>
        <Navbar />
        <div className="img-center">
          <img src={ErrorImg} className="img-404-error" alt="error"/>
        </div>
        <Footer />
      </div>

    </div>
  );
}


export default Page404;
