import React, { Component } from 'react';
import Navbar from 'components/general/Navbar';
import Footer from 'components/general/Footer';
import PaymentDetail from 'components/payment/PaymentDetail';
import InvestmentDetail from 'components/payment/InvestmentDetail';
import Mou from 'components/payment/Mou'
import PaymentInstruction from 'components/payment/PaymentInstruction';
import ConfirmPayment from 'components/general/ConfirmPayment';
import { apiHelper, dispatchMsg } from 'helpers';
import { APISTAT } from '_constant';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

class ReviewPayment extends Component {

  constructor(props){
    super(props);
    this.state = {

      data: {},
      isLoading: true,
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'payment', // use the secured one if loggedin
      urlParams: [this.props.match.params.slug_id_investment],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, data: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ data: [], isLoading: false });
      }
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render(){

    const { data, isLoading } = this.state;

    return(
      <div className="bg-lightblue">
        <Navbar/>
        <div className="container">
          <Hidden smDown>
            <Grid container spacing={32}>
              <Grid item md={7}>
                <Grid container spacing={32} direction="column">
                  <Grid item xs={12}>
                    <Mou isLoading={isLoading} {...data} />
                  </Grid>
                  <Grid item xs={12}>
                    <PaymentDetail isLoading={isLoading} {...data} />
                  </Grid>
                  <Grid item xs={12}>
                    <PaymentInstruction isLoading={isLoading} {...data} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5}>
                <Grid container spacing={32} direction="column">
                  <Grid item xs={12}>
                    <InvestmentDetail isLoading={isLoading} {...data} />
                  </Grid>
                  <Grid item xs={12}>
                    <ConfirmPayment isLoading={isLoading} {...data} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <InvestmentDetail isLoading={isLoading} {...data} />
              </Grid>
              <Grid item xs={12}>
                <PaymentDetail isLoading={isLoading} {...data} />
              </Grid>
              <Grid item xs={12}>
                <PaymentInstruction isLoading={isLoading} {...data} />
              </Grid>
              <Grid item xs={12}>
                <Mou isLoading={isLoading} {...data} />
              </Grid>
              <Grid item xs={12}>
                <ConfirmPayment isLoading={isLoading} {...data} />
              </Grid>
            </Grid>
          </Hidden>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ReviewPayment
