import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
// import Cto from 'styles/assets//about-us/CTO.png';
// import Cso from 'styles/assets//about-us/CSO.png';
// import Ceo from 'styles/assets/about-us/CEO.png';

export default class About2 extends Component {
  render(){
    return(
      <div className="about-2 container">
        <p className="demokratisasi">Demokratisasi Investasi Properti</p>
        <Grid container alignItems="flex-start">
          <Grid item lg={12} md={12} sm={12} xs={12} className="about-2-desc">
            <p style={{textIndent: 50, marginTop: 0}}>Para founder kami menginisiasi sebuah platform crowdfunding dengan tujuan jangka panjang untuk memberikan akses, demokratisasi dan membantu mendiversifikasikan investasi Kamu di sektor properti baik residensial maupun komersial. Mulai dari rumah, apartemen, ruko, perkantoran, hotel, villa, dll. Kedepannya Kamu juga bisa berinvestasi parsial pada properti tidak hanya di dekat tempat tinggal Kamu, tapi di berbagai pulau di Indonesia, baik Sumatera, Jawa, Kalimantan, Sulawesi hingga Papua. Sehingga pembangunan di Indonesia lebih merata dan pertumbuhan ekonomi semakin berkembang. Untuk permulaan, Provesty akan hanya fokus pada investasi jangka pendek Flipping, yaitu dengan skema beli-renovasi-jual.</p>

            <p style={{textIndent: 50}}>“Diharap kedepannya Provesty bisa menjadi salah satu top of mind platform di Indonesia sebagai online crowdfunding platform untuk berinvestasi pada sektor riil Properti sekaligus medium untuk masyarakat agar bisa bersumbangsih dalam peningkatan pembangunan dan ekonomi dalam negeri.”</p>
          </Grid>
          {/*<Grid item lg={4} md={4} sm={12} xs={12} container justify="space-evenly">
            <img src={Cto} alt="Adi"/>
            <img src={Ceo} alt="Afif"/>
            <img src={Cso} alt="Oki"/>
          </Grid>*/}
        </Grid>
      </div>
    )
  }
}
