import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Visual from 'styles/assets/career/visual1.png';


class Career1 extends Component {
  render(){
    return(
      <div className="career1 container">
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="career1-left">
              <p style={{fontSize: 28, fontWeight: 600}}>WE ARE HIRING</p>
              <p>Provesty is Indonesia's first technology-enabled peer to peer investment platform for real estate. Our vision is</p>
              <blockquote>to provide convenience and accessibility for everyone to invest in property with the use of technology</blockquote>
              <p>Provesty has a big dream to contribute in financial inclusion when investing in property.
                With a low entry barrier, public can also take part in domestic infrastructure development.</p>

              <p>Through crowdfunding & peer to peer financing, Provesty helps effortlessly diversified your
                investment across multiple properties by investing in residential or commercial projects
                like homes, apartments, shops, office buildings, hotels, villas, etc. You can partially
                invest in not only projects close to your location, but also projects that are spread
                across islands throughout Indonesia like Sumatra, Java, Borneo, Sulawesi, or even Papua, making
                more distributed infrastructure and economic development feasible.</p>
              <p>In the long run, by providing accessibility, more transparency and giving options to liquidity,
                Provesty aims to become the <b>Indonesia’s first digital exchange for real estate investment</b> with
                the use of our cutting-edge technology platform and data driven approach.</p>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="career1-right center-mode">
              <img src={Visual} alt="visual-career" className="img-career" style={{width:'90%', maxWidth:500, marginTop:20}} />
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Career1
