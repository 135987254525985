import React, { Component } from 'react';
import MessageContainer from 'redux/container/MessageContainer';

export default class PageExtra extends Component {

  componentDidMount() {
    // tawk to in production
    // if(process.env.NODE_ENV === 'production') {
      // var sc = document.getElementsByTagName("script")[0];
      // if(sc.src.indexOf('tawk.to') !== -1) {
      //   sc.parentElement.removeChild(sc)
      // }
    //   if(sc.src.indexOf('tawk.to') === -1) {
    //
    //     console.log('TawkTo');
    //
    //     var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); // eslint-disable-line no-unused-vars
    //     (function(){
    //       var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    //       s1.async=true;
    //       s1.src='https://embed.tawk.to/5a5a9782d7591465c706b938/default';
    //       s1.charset='UTF-8';
    //       s1.setAttribute('crossorigin','*');
    //       s0.parentNode.insertBefore(s1,s0);
    //     })();
    //   }
    // }
  }

  render() {

    return (
      <MessageContainer />
    )
  }
}
