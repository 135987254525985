import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import {Progress} from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { Link } from "react-router-dom";
import ContentLoader from 'react-content-loader'
import { price_d2r, imgPath } from 'helpers';
import DefaultPropImg from 'styles/assets/default-project-image.png';
import Ribbon from 'components/general/Ribbon';
import {PROPSTATUS,STAT} from '_constant';

const PropertiesItem = ({isLoading, ivt_name, rg_name, pr_name, percentage,
  pro_price, img_path, pro_title, pro_return, pro_aggr_funded, pro_slug, pro_duration, pro_risk, pro_aggr_status, pro_is_coming_soon,
  remaining_day, remaining_time, pro_total_slot}) =>
  {

    let time_left = '';

    let cardClass = 'proplist-container';
    // add class and create dummy to fill up space
    if(isLoading) {
      cardClass += ' loading';
      ivt_name = 'proflip';
      rg_name = 'Kuningan, Jakarta Selatan';
      pro_title = "Refinancing Unit Fountain Park Apartment, Pancoran, Jakarta";
      img_path = '//:0';
    } else {
      // if there's time left then selective string
      if (remaining_day || remaining_time !== '00:00') {
        if(remaining_day) {
          time_left = remaining_day + ' Hari ';
        } else {
          var tmp = remaining_time.split(':');
          time_left += tmp[0] + ' Jam ' + tmp[1] + ' Menit';
        }
        time_left += ' lagi';
      } else {
        time_left = 'Sudah Selesai';
      }
    }


    const isFull = (percentage === 100);

    //variable for minimum invest based on total slot
    const minimum_invest = parseInt(pro_price/pro_total_slot);

    //ribbon styling
    const ribbon_new = <div style={{position:'absolute', zIndex:'50', top:-20, left: 0, width: 250, maxWidth: '100%'}}>
      <Ribbon/>
    </div>

    //condition for product whether using ribbon or not for the new open funding
    const showRibbon = (pro_aggr_status === PROPSTATUS.PROPSTAT_OPEN && pro_is_coming_soon === STAT.STAT_NO);

    const image_ribbon = <CardMedia className="card-info-image" image={img_path ? imgPath(img_path) : DefaultPropImg}
      style={{ minHeight: 125, marginTop: '0', borderRadius: '5px 5px 0px 0px' }}
      >
      <div>
        {ribbon_new}
      </div>
    </CardMedia>

    const percentage_info =
    <div className="time-remaining">
      Terkumpul:&nbsp;
      {
        isFull ?
        <span style={{color: ' #24b47e'}}><b>{percentage}%</b></span>
        :
        <span style={{color:'#3d7dc8'}}><b>{percentage}% <span style={{float: 'right'}}>{time_left}</span></b></span>
      }
    </div>

    const image_no_ribbon = <CardMedia className="card-info-image" image={img_path ? imgPath(img_path) : DefaultPropImg}
      style={{ minHeight: 120, marginTop: '0', borderRadius: '5px 5px 0px 0px' }}
      >
    </CardMedia>

    const percentage_symbol =
    <Progress theme={{active: {
        trailColor: 'lightblue',
        color: '#3d7dc8',
        width: '100%',
      }}}
      percent={percentage}
      className={isFull ? 'progress-full':'progress-unfull'}
      symbolClassName={isFull? '': 'symbol-hide'}
      />


    const ribbon_condition = showRibbon? image_ribbon : image_no_ribbon;

    const image_loading = isLoading ?
    <ContentLoader preserveAspectRatio="xMidYMid slice" width={320} height={179} speed={1}>
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="179" />
    </ContentLoader>
    :
    <Link to={`/detail/${pro_slug}`} style={{textDecoration: 'none'}}>
      {ribbon_condition}
    </Link>


    return (
      <div className={cardClass}>
        <div className="home-card">
          <div>
            {image_loading}
          </div>
          <div className="card-title-product" style={{textAlign: 'center', marginLeft: '10px', marginRight: '10px'}}>
            {pro_title}
          </div>
          <div className="card-progress">
            <div className="progress-width" style={{fontSize: 11, height: 30}}>
              {percentage_info}
              {percentage_symbol}
            </div>
          </div>
          <div>
            <div className="card-detail">
              <Grid container>
                <Grid item xs={6}>
                  <div className="card-title-home">
                    Imbal Hasil:
                  </div>
                  <div className="card-content-home">
                    {pro_return}
                  </div>
                  <div className="card-title-home">
                    Durasi Proyek:
                  </div>
                  <div className="card-content-home">
                    {pro_duration}
                  </div>
                  <div className="card-title-home">
                    Resiko:
                  </div>
                  <div className="card-content-home">
                    {pro_risk}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="card-title-home">
                    Terkumpul:
                  </div>
                  <div className="card-content-home">
                    <span style={isFull? {color:'#24b47e'}: {}}>
                      {price_d2r(pro_aggr_funded)}
                    </span>
                  </div>
                  <div className="card-title-home">
                    Target:
                  </div>
                  <div className="card-content-home">
                    {price_d2r(pro_price)}
                  </div>
                  <div className="card-title-home">
                    Minimum Investasi:
                  </div>
                  <div className="card-content-home">
                    {price_d2r(minimum_invest)}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default PropertiesItem;
