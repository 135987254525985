import React, { Component } from 'react';
import Loan1 from 'components/how-loan/Loan1'
import Loan2 from 'components/how-loan/Loan2'
//import Loan3 from 'components/how-loan/Loan3'
//import Desktop from 'components/how-loan/Desktop'
//import Tab from 'components/how-loan/Tab'
//import Mobile from 'components/how-loan/Mobile'
//import Loan5 from 'components/how-loan/Loan5'
import Loan3New from 'components/how-loan/Loan3New'
import Loan4New from 'components/how-loan/Loan4New'
import Loan5New from 'components/how-loan/Loan5New'
//import Loan6 from 'components/how-loan/Loan6'
import Navbar from 'components/general/Navbar';
import Footer from 'components/general/Footer';
//import { Element } from 'react-scroll'
//import Hidden from '@material-ui/core/Hidden'
import ScrollAnimation from 'react-animate-on-scroll'


class HowtoLoan extends Component {

  render(){


    return(
      <div className="how-to-loan">
        <div className="loan-header">
          <Navbar/>
          <Loan1 to="loan-3"/>
          <Loan2 />
        </div>
        <div className="wavy">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Loan3New />
          </ScrollAnimation>
          {/*<Element name="loan-3">
              <Hidden smDown>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <Desktop />
                </ScrollAnimation>
              </Hidden>
              <Hidden only={['xs','md','lg', 'xl']}>
                <div style={{width: '100%', textAlign:'center', marginTop: 30, marginBottom: 30}}>
                  <Tab />
                </div>
              </Hidden>
              <Hidden smUp>
                <div style={{width: '100%', textAlign:'center', marginTop: 50, marginBottom: 30}}>
                  <Mobile />
                </div>
              </Hidden>
          </Element>*/}
          <div className="wavy">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <Loan4New />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <Loan5New />
            </ScrollAnimation>
            {/*<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <Loan6 />
            </ScrollAnimation>*/}
            <div style={{height: 100}} />
            <div className="wavy">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HowtoLoan
