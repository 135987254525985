import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid'
import Navbar from 'components/general/Navbar'
import Footer from 'components/general/Footer'
import BlogContent from 'components/blog/BlogContent'
import BlogRecentPost from 'components/blog/BlogRecentPost'
import BlogPrevNext from 'components/blog/BlogPrevNext'
import { apiHelper, dispatchMsg } from 'helpers';
import { APISTAT } from '_constant';
import {Helmet} from 'react-helmet';
import { escapeHTML, imgPath } from 'helpers';

export default class BlogDetail extends Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading: true,
      data: {}
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {

    this.setState({ isLoading:true });

    // cancel previous if any
    apiHelper.cancelAllRequest(this);

    apiHelper.request(this, {
      resource: 'blog_detail',
      urlParams: [ this.props.match.params.ps_slug],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({
            isLoading: false,
            data: resp.data
          });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ data: {}, isLoading: false });
      }
    });
  }

  render(){

    const {isLoading, data} = this.state;
    const list = isLoading? [0,0,0,0,0] : data.populars;

    return(
      <div className="career-page career-top">
        { !isLoading && data.ps_title?
          <Helmet>
            <title>{data.ps_title} | Provesty</title>
            <meta property="og:title" content={data.ps_title} />
            <meta name="description" property="og:description" content={escapeHTML(data.ps_content_short)} />
            <meta name="keywords" content={data.tg_texts.split(',').join(', ')}  />
            <meta property="og:image" content={imgPath(data.ps_image)} />
            <meta name="author" content={data.u_name} />
          </Helmet>
          :
          ''
        }
        <Navbar />
        <div className="container" style={{marginTop: 20}}>
          <Grid container spacing={24}>
            <Grid item xs={12} md={8}>
              <BlogContent isLoading={isLoading} data={data} />
              <div>
                <BlogPrevNext isLoading={isLoading} data={data} />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <BlogRecentPost isLoading={isLoading} list={list} />
            </Grid>
            <Grid item xs={12} md={8}>
              {/*<BlogComment />*/}
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    )
  }
}
