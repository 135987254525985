import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Button from '@material-ui/core/Button';
import DefaultCompanyLogo from "styles/assets/default-company-logo.png";
import DefaultFlipper from 'styles/assets/engineer.png';
import { imgPath, userHelper } from 'helpers'
import SwipeableViews from 'react-swipeable-views';
import UserContainer from 'redux/container/UserContainer'
import { DATAVAR } from '_constant'
import { dataHelper } from 'helpers'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function process_dom (dom_string) {
  var doc = new DOMParser().parseFromString(dom_string, "text/html");

  // make table horizontally scrollable
  var els = doc.querySelectorAll('table');
  if(els.length) {
    for(var i=0; i < els.length; i++) {
      var el = els[i];
      var wrapper = doc.createElement('div')
      wrapper.className = "table-container";
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);
    }
    return doc.body.innerHTML
  } else
  return dom_string;
}

export default class PropDetail extends Component {

  constructor(props){
    super(props);
    this.state = {
      tabIndex: 0
    };
  }

  redirectTo = targetUrl => ev => {
    dataHelper.set(DATAVAR.REDIRECT_LOGIN, this.props.history.location.pathname);
    this.props.history.push(targetUrl);
  }

  handleChangeTab = (event, tabIndex) => {
    this.setState({ tabIndex });
  };
  handleChangeSwipe = tabIndex => {
    this.setState({ tabIndex });
  };

  render() {

    const { isLoading, pro_overview_html, pro_desc_html, pro_invest_html, vd_name, vd_desc, vd_image, vendor_address, id_business_form, vd_web, docs = [], updates = [], update_img, action_req, vd_type } = this.props;

    if(isLoading)
    return ''

    // by right this should be the same with action_req = loginOrRegister
    const isLogin = userHelper.get();
    const isEmailNotValidated = action_req === 'validateEmail';


    const validateEmail =
    <div>
      Validasi email kamu dengan mengklik link yang dikirim ke <u><UserContainer part="email" /></u> untuk melihat info yang lebih lengkap dan juga berinvestasi.
      <div style={{marginTop: 10}}>
        Belum menerima email? <Button onClick={this.redirectTo('/send-email-verify?action=resend')} className="button-blue" style={{padding: "1px 8px", marginLeft: 9}}>Kirim Lagi</Button>
    </div>
  </div>;

  var doc_list =
  docs.length ?
  <ul className="list-document">{
      docs.map((doc) => { return (
        <li key={doc.id_doc}>
          <a href={imgPath(doc.doc_path)} target="_blank" rel="noopener noreferrer" className="casual-link"><i className="material-icons button-icon">cloud_download</i> &nbsp;&nbsp;{doc.doc_title}</a>
        </li>
      )})
    }
  </ul> :
  <div style={{fontStyle: 'italic'}}>Tidak ada dokumen pendukung
  </div>;

  // const pleaseLogin =


  var tabs = [
    { title: 'Overview', icon: 'info-circle', contentDiv:
      <div className="html-content" dangerouslySetInnerHTML={{__html: process_dom(pro_overview_html)}}
        />,
      is_login:''
    },
    { title: 'Properti', icon: 'home', contentDiv:
      <div className="html-content" dangerouslySetInnerHTML={{__html: process_dom(pro_desc_html)}}
        />,
      is_login:<span><b>Daftar</b> atau <b>Masuk</b> dengan mudah menggunakan akun Facebook/Gmail/Email kamu untuk melihat info yang lebih lengkap dan juga berinvestasi.</span>,
      emoticon:``
    },
    { title: 'Investasi', icon: 'area-chart', contentDiv:
      <div className="html-content" dangerouslySetInnerHTML={{__html: process_dom(pro_invest_html)}}
        />,
      is_login: <span>Masih belum bisa melihat detail investasi? <b>Daftar</b> atau <b>Masuk</b> dulu yuk untuk mengetahui lebih lanjut tentang detail investasi di Provesty!</span>,
      emoticon:``
    },
    { title: 'Dokumen', icon: 'file-o', contentDiv: doc_list, is_login: <span>Eits.. Mau tahu lebih lanjut tentang dokumen terkait investasi di proyek ini? <b>Daftar</b> atau <b>Masuk</b> dulu yuk untuk mengetahui lebih lanjut tentang detail investasi di Provesty</span>,
      emoticon:`&#128516`
    },
    { title: 'Manajemen', icon: 'users', contentDiv:
      <Grid container direction="column">
        <Grid item container spacing={16} alignItems="flex-start">
          <Grid item><img src={vd_image ? imgPath(vd_image) : ((vd_type === 'Individual' || id_business_form === '6') ? DefaultFlipper: DefaultCompanyLogo)} style={{width: 100}} alt="vendor" /></Grid>
          <Grid item style={{paddingLeft: 20}}>
            <b>{ vd_name }</b><br />
            {
              vendor_address ?
              <div>
                {vendor_address.vl_name}, {vendor_address.dt_name}<br />{vendor_address.rg_name}, {vendor_address.pr_name}<br />
              <a href={vd_web.startsWith('http') ? vd_web: 'http://' + vd_web} target="_blank" rel="noopener noreferrer">{vd_web}</a>
            </div>
            : ''
          }
        </Grid>
      </Grid>
      <Grid item style={{marginTop: 20}}>
        <div dangerouslySetInnerHTML={{ __html: vd_desc }} />
      </Grid>
    </Grid>,
    is_login:<span>Belum bisa lihat detail manajemen investasi? <b>Daftar</b> atau <b>masuk</b> dulu yuk</span>,
    emoticon:`&#128521`
  },
  { title: 'Update', icon: 'clock-o', contentDiv:
    updates.length ?
    <VerticalTimeline>
      {
        updates.slice(0).reverse().map(ud => { return (
          <VerticalTimelineElement
            key={ud.id_update}
            className="vertical-timeline-element--work"
            date={ud.up_date}
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff'}}
            icon={<img className="img-update-timeline img-circle" src={imgPath(update_img)} alt="point"/>}
            >
            <div className="html-content timeline-content" dangerouslySetInnerHTML={{
                __html: ud.up_content
              }}>
            </div>
          </VerticalTimelineElement>
        )})
      }
    </VerticalTimeline>
    :
    <span style={{fontStyle: 'italic'}}>Belum ada update untuk properti ini</span>,
      is_login:<span>Ingin mengetahui update investasi ini? <b>Daftar</b> atau <b>Masuk</b> dulu yuk untuk tahu lebih lanjut</span>,
      emoticon:`&#128512`,
    },
  ]

  return (
    <div className="prop-detail">
      <Tabs
        value={this.state.tabIndex}
        onChange={this.handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        action={(actions) => setTimeout(actions.updateIndicator.bind(actions), 0)}
        >
        {
          tabs.map(t => <Tab classes={{root: 'tab-root', selected: 'tab-selected'}} key={t.title} label={t.title} />)
        }
      </Tabs>
      <SwipeableViews
        axis='x'
        index={this.state.tabIndex}
        onChangeIndex={this.handleChangeSwipe}
        animateHeight={true}
        className="prop-swipe-tab soft-paper"
        >
        {
          tabs.map(t => <div
            key={t.title}
            className={"prop-detail-tab " + (t.title === 'Update' && updates.length ? 'bg-lightblue' : '')}
            >{
              t.title === 'Overview' ?  // overview is the only tab which can be shown without login or email verify
              t.contentDiv :
              (isLogin ?
                (isEmailNotValidated? validateEmail : t.contentDiv)
                :
                <div className="please-login">
                  <div className="login-detail-content">
                    {t.is_login} <span dangerouslySetInnerHTML={{__html:t.emoticon}} />.
                  </div>
                  <div className="login-button-detail">
                    <Button onClick={this.redirectTo('/login')} className="button-login button-login-other button-login-detail button-white">Masuk</Button>
                    <Button onClick={this.redirectTo('/register')} className="button-register button-register-other button-register-detail button-blue">Daftar</Button>
                  </div>
                </div>
              )
            }</div> )
          }
        </SwipeableViews>
      </div>
    );
  }
}
