import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import { apiHelper } from 'helpers';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

class Mou extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mouVisible: false,
      mouContent: null,
      mouLoading: false,
    };
  }

  load_mou = (event) => {

    event.preventDefault();

    this.setState({mouLoading: true});

    apiHelper.request(this, {
      resource: 'mou',
      urlParams: [this.props.id_investment],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ mouLoading: false, mouVisible: true, mouContent: resp.data });
        }  else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        this.setState({mouLoading: false});
      },
    });
  }

  closeMou = () => {
    this.setState({mouVisible: false});
  }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var mywindow = window.open('', 'mydiv');
    mywindow.document.write(printContents);
    mywindow.print();
    mywindow.close();
  }

  render() {

    const {mouLoading, mouContent, mouVisible} = this.state;

    return (
      <Paper className="soft-paper">
        <div className="paper-title">
          MOU Investasi
        </div>
        <div className="paper-body">
          <Grid container alignItems="center" spacing={16}>
            <Grid item lg={8} md={8} sm={8} xs={12}>
              Dengan melakukan pembayaran saya mengerti dan menyetujui ketentuan MOU yang berlaku.
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <div className="button-progress-wrapper-full">
                <Button disabled={mouLoading} onClick={this.load_mou} className="button-blue" style={{}} fullWidth>Lihat MOU
              </Button>
              {mouLoading && <CircularProgress size={24} style={{marginTop: -12}} className="button-progress" />}
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        classes={{paper: 'mou-doc', scrollPaper: 'paper'}}
        open={mouVisible}
        onClose={this.closeMou}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        >
        {/*<DialogTitle id="scroll-dialog-title">MOU</DialogTitle>*/}
        <DialogContent>

          <div className="html-content" id="printableArea" dangerouslySetInnerHTML={{
              __html: mouContent
            }} />
          </DialogContent>
          <DialogActions>
            <Button className="button-white button-shadow" onClick={this.closeMou} color="primary">
              Tutup
            </Button>
            <Button className="button-blue button-shadow" onClick={() => this.printDiv('printableArea')} color="primary">
              Print
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  }
}

export default Mou;
