import React from 'react';
import Rodal from 'rodal';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

export default class ModalTransact extends React.Component {

  static defaultProps = {
    history: null,
    onClose: null
  };

  constructor(props) {
    super(props);
    this.state = {
      modalHeight: 370,
    };
  }

  componentDidUpdate() {
    // responsive height
    const height = this.divElement.clientHeight; // 378
    const { modalHeight } = this.state; // 370
    var diff = 1;
    if(height > diff && modalHeight !== height - diff)
    this.setState({modalHeight: (height - diff)});
  }

  render() {

    const MyToolTip = (props) =>
    <Tooltip disableTouchListener classes={{popper:"tooltip-content"}} title={
        <div>{props.text}</div>
      } >
      <i className="material-icons tiny info-pop-link" style={props.fontSize? {fontSize: props.fontSize} : {}}>info</i>
    </Tooltip>;

    const { data } = this.props;

    const table =
    <div style={{maxHeight: 360, overflowY: 'auto'}}>
      <Table className="table table-transact">
        <TableHead style={{height: 36}}>
          <TableRow>
            <Hidden xsDown>
              <TableCell>No</TableCell>
            </Hidden>
            <TableCell>Tanggal</TableCell>
            <TableCell>Tipe</TableCell>
            <TableCell>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data ?
            data.map((x, index) => {
              return(
                <TableRow key={index} style={{backgroundColor: (index%2? 'white' : '#F5F5F5')}}>
                  <Hidden xsDown>
                    <TableCell align="center">
                      <span className="loading-effect">
                        {x.index + 1}
                      </span>
                    </TableCell>
                  </Hidden>
                  <TableCell>{x.tstamp}</TableCell>
                  <TableCell>{x.type}</TableCell>
                  <TableCell>
                    <span className="loading-effect">
                      <span style={
                          x.amount.startsWith('+') && x.type !== 'Refund'? {color: 'green'} : null
                        }>{x.amount}
                      </span>
                    </span>
                  </TableCell>
                </TableRow>
              )
            }) :
            <TableRow>
              <TableCell>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </div>;

    const { visible, total_return, total_expected_return, return_percentage } = this.props;

    return (
      <Rodal className="sms-modal" visible={visible} onClose={() => this.props.onClose()}
        showCloseButton={false} width={450} height={this.state.modalHeight}
        >
        <div className="sms-modal-content" style={{paddingLeft: 0, paddingRight: 0}} ref={ (divElement) => this.divElement = divElement}>
          <div>
            <p style={{textAlign: "center"}}>
              Total Pengembalian: <b>{total_return}</b> ({return_percentage}%)
              <MyToolTip text={<div>Pengembalian pokok + margin, dari total potensi pengembalian <b>{total_expected_return}</b>. Angka ini bisa berbeda jika waktu pengembalian atau harga jual berbeda dari perkiraan.</div>} fontSize={13} />
            </p>
          </div>
          {table}
        </div>
      </Rodal>
    );
  }
}
