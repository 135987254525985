import React, { Component } from 'react';
import Navbar from 'components/general/Navbar';
import Footer from 'components/general/Footer';
import Prohouse1 from 'components/prohouse/Prohouse1'
import Prohouse2 from 'components/prohouse/Prohouse2'
import ProhouseForm from 'components/prohouse/ProhouseForm'
import { Element } from 'react-scroll';


  class Prohouse extends Component {


    render()
    {
      return(
        <div className="mobile-justify bg-lightgreen">
          <Navbar />
          <div className="container">
            <Prohouse1 buttonLink="pro-form"/>
          </div>
          <Prohouse2 />
          <Element name="pro-form">
            <ProhouseForm />
          </Element>
          <Footer />
        </div>
      )
    }
  }

  export default Prohouse
