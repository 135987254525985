import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropListItem from './PropListItem';
import ReactPaginate from 'react-paginate';
import {withRouter} from 'react-router-dom'
import MyTextField from "components/general/MyTextField";
import { apiHelper, debounceFn, dispatchMsg, formData, userHelper } from 'helpers';
import { APISTAT } from '_constant';
import { scroller } from 'react-scroll';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Search from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import QueryNotFound from 'components/general/QueryNotFound';

class PropList extends Component {

  constructor(props){
    super(props);

    var params = new URLSearchParams(this.props.history.location.search);
    var status = params.get('status')
    this.state = {
      isLoading: true,
      data: [],

      currentPage: 0, // 0 based page number
      pageCount: 1,

      keyword: '',
      status: status === 'active'? 'active' : 'all',

      validErrors: {} // just for the sake of using MyTextField
    };
  }

  perPageCount =  6; // sync with server

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  componentDidMount() {
    // debouncing used by search field
    this.debounceLoadData = debounceFn(this.loadData, 350);
    this.loadData();
  }

  loadData = (pageNum) => {

    // if undefined then it's calledfrom keyword
    var currentPage = typeof pageNum === 'undefined'? 0: pageNum;

    var data = { start : currentPage * this.perPageCount };
    if(this.state.keyword !== '')
    data['keyword'] = this.state.keyword;
    if(this.state.status === 'active')
    data['active'] = true;

    this.setState({ isLoading:true });

    // cancel previous if any
    apiHelper.cancelAllRequest(this);

    apiHelper.request(this, {
      resource: userHelper.isLogin()?'slisting':'listing',
      data: formData(data),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({
            pageCount: Math.ceil(resp.data.recordsFiltered/this.perPageCount) ,
            data: resp.data.data,
            isLoading: false,
            isRibbon: false,
            currentPage: currentPage
          });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ data: [], isLoading: false });
      }
    });
  }

  handleInputChange = event => {
    var name = event.target.name;
    var val = event.target.value;

    if(name === 'keyword') {
      this.setState({ [name]: val }, () => {
        this.debounceLoadData();
      });
    } else {
      this.setState({ [name]: val }, () => {
        this.loadData();
      });
    }
  };

  handlePageClick = (e) => {
    // pagination use 0-based page
    this.loadData(e.selected);
    scroller.scrollTo('myScrollToElement', {
      duration: 300,
      delay: 100,
      smooth: 'easeInOutCubic'}
    );
  }

  render(){

    const {isLoading, data} = this.state;
    const lists = isLoading? [0,0,0,0,0,0] : data;
    return(
      <div className="container">
        <div id="listing-bar" name="myScrollToElement">
          <Grid container>
            <Grid item xs={false} md={2} />
            <Grid item xs={12} md={4} container>
              <div style={{ paddingTop: 18, fontSize: 16, fontWeight: 700, paddingRight: 23 }}>Status</div>
              <RadioGroup
                aria-label="gender"
                name="status"
                value={this.state.status}
                onChange={this.handleInputChange}
                style={{ flexDirection: 'row' }}
                >
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label="Semua"
                  classes={{label: "myTheme"}}
                  />
                <FormControlLabel
                  value="active"
                  control={<Radio color="primary" />}
                  label="Aktif"
                  classes={{label: "myTheme"}}
                  />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={4} >
              <MyTextField name="keyword" obj={this} placeholder="Cari Judul/Deskripsi" variant="outlined" fullWidth={true} InputLabelProps={{ style: {fontSize: 15}}}
                InputProps={{
                  classes: { input: 'search-field', adornedStart: 'search-field adorned-start'},
                  inputProps: {style: {fontSize: 15}},
                  startAdornment: (
                    <InputAdornment>
                      {this.state.isLoading && this.state.keyword ?  <CircularProgress size={24} style={{margin: 12}} /> :
                      <IconButton aria-label="Search">
                        <Search/>
                      </IconButton>}
                    </InputAdornment>
                  )
                }}
                />
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={24} style={{marginTop: 50}}>
          {lists.map((item, index) => {
            return(
              <Grid item xs={12} md={4} sm={6} key={index}>
                <PropListItem isLoading={isLoading} {...item}/>
              </Grid>
            )
          })}
        </Grid>
        {lists.length === 0 ?
          <QueryNotFound />
          :
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              forcePage={this.state.currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>
        }
      </div>
    )
  }
}

export default withRouter(PropList);
