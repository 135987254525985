import React, {Component} from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { withStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: '#6EB1C6',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
          >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
      />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class MyMessage extends Component {

  static defaultProps = {
    msgType: '',
    msg: '', // set the msg to empty string will close the popup
    msgShow: false,
    onClose: null
  }

  render() {
    const { msgType, msg, msgShow, onClose } = this.props;

    var validTypes = ["success", "warning", "info", "error"];

    if(validTypes.indexOf(msgType) !== -1) {
      return (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={msgShow}
          autoHideDuration={5000}
          onClose={onClose}
          >
          <MySnackbarContentWrapper
            onClose={onClose}
            variant={msgType}
            message={msg}
            />
        </Snackbar>
      );
    } else {
      return (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={msgShow}
          onClose={onClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          autoHideDuration={5000}
          message={<span id="message-id">{msg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
              >
              <CloseIcon />
            </IconButton>,
          ]}
          />
      );
    }

  }
}

export default withStyles(styles2)(MyMessage);
