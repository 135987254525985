import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import SocialMedia from './SocialMedia';
import Attention from './Attention'
import PlayStore from 'styles/assets/playstore.png'
import AppStore from 'styles/assets/app_store.svg'
import SslSecure from 'styles/assets/ssl-secure.png'
import { NavLink } from "react-router-dom";
import Fintech from 'styles/assets/fintech.png'

class Footer extends Component {
  render(){

    return(
      <div className="footer">
        <div className="container footer-content">
          <SocialMedia />
          <Grid container>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <div className="footer-left">
                <p>
                  Provesty adalah platform crowdfunding pertama di Indonesia yang berfokus pada investasi di sektor properti.
                </p>
                <p>
                  Visi utama Provesty adalah memberikan kemudahan dan aksesibilitas bagi setiap orang untuk bisa berinvestasi pada properti dengan menggunakan teknologi. Dengan berinvestasi di Provesty, investor juga bisa turut berkontribusi dalam pemerataan pembangunan dalam negeri. Kunjungi Tentang Kami untuk melihat lebih detail.
                </p>
              </div>
            </Grid>
            <Grid item md={1} lg={1} />
            <Grid item xs={6} sm={4} md={2} lg={2} className="important-links">
              <p>
                <b>IMPORTANT LINKS</b>
              </p>
              <p>
                <NavLink to="/about-us">
                  Tentang Kami
                </NavLink>
              </p>
              <p>
                <NavLink to="/invest">
                  Investasi
                </NavLink>
              </p>
              <p>
                <NavLink to="/how-it-works">
                  Cara Kerja
                </NavLink>
              </p>
              {/*<p>
                <NavLink to="/how-to-loan">
                  Ajukan Pinjaman
                </NavLink>
              </p>*/}
              <p>
                <NavLink to="/careers">
                  Karir
                </NavLink>
              </p>
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={2}>
              <p>
                <b>CONTACT INFO</b>
              </p>
              <p>
                Ariobimo Sentral lt.8
                <br />
                Kuningan Timur, Setiabudi
                <br />
                DKI Jakarta 12950
              </p>
              <p>
                +62 811-199-8000
              </p>
              <p>
                info@provesty.com
              </p>
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Hidden xsDown>
                <div>
                  <div>
                    <p>
                      <b>MEMBER DARI</b>
                    </p>
                    <a href="https://fintech.id" target="_blank"  rel="noopener noreferrer">
                      <img src={Fintech} alt="Smiley face" height="50" width="100"/>
                    </a>
                  </div>
                  <div>
                    <p>
                      <b>DILINDUNGI OLEH</b>
                    </p>
                    <img src={SslSecure} alt="Smiley face" height="50" width="120"/>
                  </div>
                </div>
              </Hidden>
              <Hidden smUp>
                <Grid container>
                  <Grid item xs={6}>
                    <div>
                      <p>
                        <b>MEMBER DARI</b>
                      </p>
                      <a href="https://fintech.id" target="_blank"  rel="noopener noreferrer">
                        <img src={Fintech} alt="Smiley face" height="50" width="120"/>
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <p>
                        <b>DILINDUNGI OLEH</b>
                      </p>
                      <a href="https://fintech.id" target="_blank"  rel="noopener noreferrer">
                        <img src={SslSecure} alt="Smiley face" height="50" width="120"/>
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
            <Grid item lg={3} md={4} sm={10} xs={12}>
              <div>
                <p><b>UNDUH APLIKASI KAMI</b></p>
                <Grid container>
                  <Grid item md={6} sm={4} xs={6}>
                    <div>
                      <a href="https://play.google.com/store/apps/details?id=com.provesty.provesty" target="_blank"  rel="noopener noreferrer">
                        <img src={PlayStore} alt="Smiley face" height="60" width="150"/>
                      </a>
                    </div>
                  </Grid>
                  <Grid item md={6} sm={4} xs={6}>
                    <div style={{marginTop:10}}>
                      <a href="https://apps.apple.com/us/developer/pt-provesty-global-nusantara/id1481804971" target="_blank" rel="noopener noreferrer">
                        <img src={AppStore} alt="Smiley face"/>
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <Attention />
        <div style={{width: '100%', textAlign: 'center', marginTop: 50, marginBottom: 10}}>
          <hr/>
          <div className="container">
            <p>Copyright © 2019 PT. Provesty Global Nusantara</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;
