import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MyTextField from "components/general/MyTextField";
import MyCheckboxes from "components/general/MyCheckboxes";
import MyRadioGroup from "components/general/MyRadioGroup";
import Grid from '@material-ui/core/Grid';
import { formData, apiHelper, dispatchMsg, formScrollToError, formDef, formValid, price_d2r, price_r2d } from 'helpers';
import { APISTAT } from '_constant';
import {Helmet} from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-spinkit';
import ReactSelect from "components/general/ReactSelect";

function getSteps() {
  return ['Lengkapi Informasi Proyek', 'Detail Proyek', 'Informasi Dokumen'];
}

export default class ProjectInfo extends React.Component {

  static defaultProps = {
    history: null
  }

  constructor(props) {
    super(props);
    var fields = formDef(this, [
      {name: 'pj_title', label: 'Nama Proyek'},
      {name: 'pj_amount', label: 'Kebutuhan Dana', valid: 'requiredPrice', default: price_d2r('')},
      {name: 'pj_duration_month', label: 'Durasi', valid: 'requiredNumber'},
      {name: 'pj_req_date', label: 'Tanggal Kebutuhan', valid: 'requiredDate'},
      {name: 'id_province', label: 'Provinsi'},
      {name: 'id_regency', label: 'Kota/Kabupaten'},
      {name: 'id_district', label: 'Kecamatan'},
      {name: 'id_village', label: 'Kelurahan'},
      {name: 'ad_line', label: 'Alamat'},
      {name: 'ad_postal', label: 'Kode Pos'},
      {name: 'pj_prop_type', label: 'Tipe Properti'},
      {name: 'pj_prop_cert', label: 'Bukti Kepemilikan'},
    ]);

    this.state = {
      activeStep: 0,

      ...fields,

      provinces: {},
      regencies: {},
      districts: {},
      villages: {},

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;

    if (name === 'id_province') {
      this.setState({ [name]: val }, this.loadRegency);
    } else if (name === 'id_regency') {
      this.setState({ [name]: val }, this.loadDistrict);
    } else if (name === 'id_district') {
      this.setState({ [name]: val }, this.loadVillage);
    } else {
      if (name === 'pj_amount')
      val = price_d2r(price_r2d(val));

      this.setState({ [name]: val });
    }

  };


  loadVillage = () => {
    apiHelper.request(this, {
      resource: 'villages_select',
      urlParams: [this.state.id_district],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_village: '', villages: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadDistrict = () => {
    apiHelper.request(this, {
      resource: 'districts_select',
      urlParams: [this.state.id_regency],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_district: '', districts: resp.data, id_village: '', villages: [] });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadRegency = () => {
    apiHelper.request(this, {
      resource: 'regencies_select',
      urlParams: [this.state.id_province],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_regency: '', regencies: resp.data, id_district: '', districts: [], id_village: '', villages: [] });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  validate = () => {

    this.errors = {};

    formValid(this);

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;
    // clear the errors
    this.setState({ buttonLoading: true });

    apiHelper.request(this, {
      resource: 'project_info_post',
      urlParams: [ this.props.match.params.id_project ? this.props.match.params.id_project :''],
      data: formData('form-data'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ buttonLoading: false });
          dispatchMsg('success', 'Info Proyek berhasil disimpan.')
          this.props.history.push( "/project/detail/" + resp.data.id_project);
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false });
      },
    });
  }


  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'project_info_get', // use the secured one if loggedin
      urlParams: [ this.props.match.params.id_project ? this.props.match.params.id_project :''],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }


  render() {

    const steps = getSteps();
    const { activeStep } = this.state;
    const stepButtons = steps.map((label, index) => {
      return (
        <Step key={label} name="stepper" >
          <StepLabel classes={{label: 'step-label'}} StepIconProps={{classes: {root: 'step-icon', active: 'step-icon-active', completed: 'step-icon-completed'}}}>
            {label}
          </StepLabel>
        </Step>
      );
    });

    const formContent =
    <Grid container spacing={16}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid item xs={12}>
          <div className="form-legend">Info Proyek</div>
          <div style={{textAlign: 'left', fontSize: 13, marginTop: 7 }}>Isi dengan informasi dasar proyek</div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="pj_title" obj={this} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="pj_amount" obj={this} type="tel" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="pj_duration_month" obj={this} label="Durasi Proyek (dalam bulan, max 12)" type="number" inputProps={{max: 12}} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="pj_req_date" obj={this} type="date" shrink />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyCheckboxes name="pj_prop_type" obj={this}
          options={[
            {k: 'Residensial', v:'Residensial'},
            {k: 'Komersial', v:'Komersial'},
          ]}
          />
      </Grid>

      <Grid item xs={12} sm={6}>
        <MyRadioGroup name="pj_prop_cert" obj={this}
          options={[
            {k: '1', v:'SHM'},
            {k: '2', v:'SHGB'},
            {k: '3', v:'Lainnya'},
          ]}
          />
      </Grid>

      <Grid item xs={12}>
        <div className="form-legend">Alamat</div>
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect name="id_province" obj={this}
          options={Object.keys(this.state.provinces).map(k => ({k: k, v:this.state.provinces[k]}))} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect
          name="id_regency" obj={this} options={Object.keys(this.state.regencies).map(k => ({k: k, v:this.state.regencies[k]}))} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect name="id_district" obj={this}
          options={Object.keys(this.state.districts).map(k => ({k: k, v:this.state.districts[k]}))} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect
          name="id_village" obj={this} options={Object.keys(this.state.villages).map(k => ({k: k, v:this.state.villages[k]}))} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <MyTextField name="ad_line" obj={this} multiline rows={3} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField name="ad_postal" obj={this} />
      </Grid>


    </Grid>;

    const stepperContent = this.state.isLoading ?
    <div style={{height: 500}}>
      <Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" />
    </div>
    :
    <form onSubmit={this.handleSubmit} id="form-data" autoComplete="off" name="form">
      {formContent}
      <div className="sms-modal-buttons" style={{marginTop: 55, marginBottom: 30, textAlign: 'center'}}>
        <div className="button-progress-wrapper">
          <Button disabled={this.state.buttonLoading} className="btn-stepper-next button-blue" type="submit" style={{width: 120}}>Simpan</Button>
          {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
        </div>
      </div>
    </form>;

    return (
      <div>
        <Helmet>
          <title>Credential Profile</title>
        </Helmet>
        <div className="bg-lightblue">
          <Navbar />
          <div className="container credential-page">
            <Paper >
              <Stepper activeStep={activeStep} alternativeLabel className="stepper-label stepper-loan">
                {stepButtons}
              </Stepper>
            </Paper>
            <Paper className="cred-area">
              <div className="stepper-content">
                {stepperContent}
              </div>
            </Paper>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
