import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MyTextField from "components/general/MyTextField";
import ReactSelect from "components/general/ReactSelect";
import MyUploadImage from "components/general/MyUploadImage";
import Grid from '@material-ui/core/Grid';
import { formData, apiHelper, dispatchMsg, formScrollToError, formDef, formValid } from 'helpers';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-spinkit';
import NPWP from "styles/assets/npwp.png";
// import DefaultCompanyLogo from "styles/assets/default-company-logo.png";

function getSteps() {
  return ['Lengkapi Informasi Personal', 'Informasi Pengalaman', 'Informasi Dokumen'];
}

export default class CompanyProfile extends React.Component {

  static defaultProps = {
    history: null
  }

  // {name: 'fieldName', type: 'text' (number,date, default:text), valid: 'required' (<empty>, requiredImg default: required, can be array), default: '' (default:'') }

  constructor(props) {
    super(props);
    var fields = formDef(this, [
      {name: 'vd_name', label: 'Nama Perusahaan'},
      {name: 'vd_company_name', label: 'Nama PT', valid: ''},
      {name: 'vd_estbl_date', label: 'Tanggal Berdiri', 'valid': 'requiredDate'},
      // {name: 'vd_image', label: 'Logo', valid: 'requiredImg', validParam: 'vd_image_init'},
      {name: 'vd_estbl_deed_no', label: 'No Akta'},
      {name: 'vd_tax_no', label: 'No NPWP Perusahaan'},
      {name: 'vd_tax_img', label: 'Scan NPWP', valid: 'requiredImg', validParam: 'vd_tax_img_init'},
      {name: 'vd_emp_num', label: 'Jumlah Karyawan', 'valid': 'requiredNumber'},
      {name: 'vd_cp_name', label: 'Nama CP'},
      {name: 'vd_cp_phone', label: 'Telepon CP'},
      {name: 'vd_cp_email', label: 'Email CP', valid: ['required', 'validEmail']},
      {name: 'id_province', label: 'Provinsi'},
      {name: 'id_regency', label: 'Kota/Kabupaten'},
      {name: 'id_district', label: 'Kecamatan'},
      {name: 'id_village', label: 'Kelurahan'},
      {name: 'ad_line', label: 'Alamat'},
      {name: 'ad_postal', label: 'Kode Pos'},
      {name: 'id_bank', label: 'Bank'},
      {name: 'bac_branch', label: 'Cabang'},
      {name: 'bac_owner_name', label: 'Atas Nama'},
      {name: 'bac_no', label: 'No Akun'}
    ]);

    this.state = {
      activeStep: 0,

      ...fields,

      vd_tax_img_init: '',
      vd_image_init: '',

      provinces: {},
      regencies: {},
      districts: {},
      villages: {},
      banks: {},

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = event => {

    var name = event.target.name;
    var val = event.target.value;

    if (name === 'id_province') {
      this.setState({ [name]: val }, this.loadRegency);
    } else if (name === 'id_regency') {
      this.setState({ [name]: val }, this.loadDistrict);
    } else if (name === 'id_district') {
      this.setState({ [name]: val }, this.loadVillage);
    } else {

      if (name === 'vd_tax_no') {
        // format tax to xx.xxx.xxx.x-xxx.xxx
        let nums = val.replace(/\D/g,'');
        val = nums.substring(0,2) + (nums.length > 2? '.' : '' ) + nums.substring(2,5) + (nums.length > 5? '.' : '' ) + nums.substring(5,8) + (nums.length > 8? '.' : '' ) + nums.substring(8,9) + (nums.length > 9? '-' : '' ) + nums.substring(9,12) + (nums.length > 12? '.' : '' ) + nums.substring(12,15);
      }
      if (name === 'vd_cp_phone') {
        // filter phone and decide if we need to verify
        val = val.replace(/[^\d\s()+.-]/g, '');  // filter non number and (,),+,-
      }

      this.setState({ [name]: val });
    }
  };

  loadVillage = () => {
    apiHelper.request(this, {
      resource: 'villages_select',
      urlParams: [this.state.id_district],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_village: '', villages: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadDistrict = () => {
    apiHelper.request(this, {
      resource: 'districts_select',
      urlParams: [this.state.id_regency],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_district: '', districts: resp.data, id_village: '', villages: [] });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadRegency = () => {
    apiHelper.request(this, {
      resource: 'regencies_select',
      urlParams: [this.state.id_province],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_regency: '', regencies: resp.data, id_district: '', districts: [], id_village: '', villages: [] });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  validate = () => {

    this.errors = {};

    formValid(this);

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;
    // clear the errors
    this.setState({ buttonLoading: true });

    apiHelper.request(this, {
      resource: 'company_profile_post',
      data: formData('form-data'),
      urlParams: [ this.props.match.params.id_vendor ?
        this.props.match.params.id_vendor : ''],
        fnSuccess: resp => {
          if (resp.status === APISTAT.SUCCESS) { // success

            this.setState({ buttonLoading: false });
            dispatchMsg('success', 'Data berhasil disimpan.')
            this.props.history.push( "/sponsor-company/experience/" + resp.data.id_vendor);
          } else if (resp.status === APISTAT.ERR_USER_INPUT) {
            this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
          } else {
            throw new Error(resp.msg);
          }
        },
        fnError: err => {
          dispatchMsg('error', err.message);
          this.setState({ buttonLoading: false });
        },
      }
    );
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'company_profile_get', // use the secured one if loggedin
      urlParams: [ this.props.match.params.id_vendor ?
        this.props.match.params.id_vendor : ''],
        fnSuccess: resp => {
          if (resp.status === APISTAT.SUCCESS) {
            this.setState({ isLoading:false, ...resp.data});
          } else {
            throw new Error(resp.msg);
          }
        },
        fnError: err => {
          dispatchMsg('error', err.message);
          this.setState({ isLoading: false });
        }
      });
    }


    render() {

      const steps = getSteps();
      const { activeStep } = this.state;
      const stepButtons = steps.map((label, index) => {
        return (
          <Step key={label} name="stepper" >
            <StepLabel classes={{label: 'step-label'}} StepIconProps={{classes: {root: 'step-icon', active: 'step-icon-active', completed: 'step-icon-completed'}}}>
              {label}
            </StepLabel>
          </Step>
        );
      });

      const formContent =
      <Grid container spacing={16}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid item xs={12}>
            <div className="form-legend">Data Perusahaan</div>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          <MyTextField name="vd_name" obj={this} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MyTextField name="vd_estbl_date" obj={this} type="date" shrink />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MyTextField name="vd_estbl_deed_no" obj={this} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MyTextField name="vd_emp_num" obj={this} type="number" />
        </Grid>
        <Grid item sm={12} container alignItems="flex-end">
          <Grid item sm >
            <MyTextField name="vd_tax_no" obj={this} type="tel" />
          </Grid>
          <Grid item>
            <MyUploadImage name="vd_tax_img" obj={this} ButtonProps={{className:"button-upload-doc button-blue"}} ImgProps={{style: {maxWidth: 200}}} buttonContent={<span>Upload Scan NPWP &nbsp;<i className="material-icons button-icon">cloud_upload</i></span>} defaultImg={NPWP} />
          </Grid>
        </Grid>


        <Grid item xs={12}>
          <div className="form-legend">Alamat</div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <ReactSelect name="id_province" obj={this}
            options={Object.keys(this.state.provinces).map(k => ({k: k, v:this.state.provinces[k]}))} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ReactSelect
            name="id_regency" obj={this} options={Object.keys(this.state.regencies).map(k => ({k: k, v:this.state.regencies[k]}))} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ReactSelect name="id_district" obj={this}
            options={Object.keys(this.state.districts).map(k => ({k: k, v:this.state.districts[k]}))} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ReactSelect
            name="id_village" obj={this} options={Object.keys(this.state.villages).map(k => ({k: k, v:this.state.villages[k]}))} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MyTextField name="ad_line" obj={this} multiline rows={3} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MyTextField name="ad_postal" obj={this} />
        </Grid>

        <Grid item xs={12}>
          <div className="form-legend">Contact Person</div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <MyTextField name="vd_cp_name" obj={this} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MyTextField name="vd_cp_phone" obj={this} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MyTextField name="vd_cp_email" obj={this} />
        </Grid>


        <Grid item xs={12}>
          <div className="form-legend">Data Bank</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReactSelect name="id_bank" obj={this} options={Object.keys(this.state.banks).map(k => ({k: k, v:this.state.banks[k]}))} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextField name="bac_branch" obj={this} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextField name="bac_owner_name" obj={this} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MyTextField name="bac_no" obj={this} />
        </Grid>

      </Grid>;

      const stepperContent = this.state.isLoading ?
      <div style={{height: 500}}>
        <Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" />
      </div>
      :
      <form onSubmit={this.handleSubmit} id="form-data" autoComplete="off" name="form">
        {formContent}
        <div className="sms-modal-buttons" style={{marginTop: 55, marginBottom: 30, textAlign: 'center'}}>
          <div className="button-progress-wrapper">
            <Button disabled={this.state.buttonLoading} className="btn-stepper-next button-blue" type="submit" style={{width: 120}}>Simpan</Button>
            {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
          </div>
        </div>
      </form>;

      return (
        <div>
          <div className="bg-lightblue">
            <Navbar />
            <div className="container credential-page">
              <Paper >
                <Stepper activeStep={activeStep} alternativeLabel className="stepper-label stepper-loan">
                  {stepButtons}
                </Stepper>
              </Paper>
              <Paper className="cred-area">
                <div className="stepper-content">
                  {stepperContent}
                </div>
              </Paper>
            </div>
            <Footer />
          </div>
        </div>
      );
    }
  }
