import React, {Component} from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import How1 from 'components/how/How1';
import How2 from 'components/how/How2';
import How3 from 'components/how/How3';
import How4 from 'components/how/How4';
import How5 from 'components/how/How5';
import How6 from 'components/how/How6';
import { Element } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';

class HowWorks extends Component {

  render() {
    return (<div className="">
      <div className="">
        <div className="bg-lightgreen">
          <Navbar/>
          <div className="container">
            <How1 to="how-2"/>
          </div>
          <div className="">
            <Element name="how-2">
              <How2/>
            </Element>
          </div>
          <div className="">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <How3 />
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="wavy">
        <ScrollAnimation  animateIn="fadeInUp" animateOnce={true}>
          <How4 />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <How5 />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <How6 />
        </ScrollAnimation>
      </div>
      <div className="bg-lightgreen-reverse">
        <Footer/>
      </div>
    </div>)
  }
}

export default HowWorks;
