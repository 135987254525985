import React, {Component} from "react"
import {Switch, Route, withRouter} from 'react-router-dom'
import MyRoute from 'components/auth/MyRoute'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Listing from 'pages/Listing'
import HowWorks from "pages/HowWorks";
import AboutUs from 'pages/AboutUs';
import PropDetailPage from 'pages/PropDetailPage';
import Login from './pages/Login'
import Logout from './pages/Logout'
import Home from './pages/Home'
import Register from './pages/Register'
import Faq from './pages/Faq'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Dashboard from './pages/Dashboard'
import DashboardProfile from './pages/DashboardProfile'
import DashboardTransaction from './pages/DashboardTransaction'
import DashboardMessage from './pages/DashboardMessage'
import DashboardPortfolio from './pages/DashboardPortfolio'
//import DashboardLoan from './pages/DashboardLoan'
import DashboardAccount from './pages/DashboardAccount'
import CredentialProfile from './pages/CredentialProfile'
import CredentialDocument from './pages/CredentialDocument'
import IndividualProfile from './pages/IndividualProfile'
import IndividualExperience from './pages/IndividualExperience'
import IndividualDocument from './pages/IndividualDocument'
import CompanyProfile from './pages/CompanyProfile'
import CompanyExperience from './pages/CompanyExperience'
import CompanyDocument from './pages/CompanyDocument'
import SendEmailVerify from 'pages/SendEmailVerify'
import VerifyEmail from 'pages/VerifyEmail'
// import DummyPage from 'pages/DummyPage';
import ReviewPayment from 'pages/ReviewPayment';
import ReviewTopup from 'pages/ReviewTopup';
import HowtoLoan from 'pages/HowtoLoan';
import Risk from 'pages/Risk';
import Privacy from 'pages/Privacy';
import DataDeletion from 'pages/DataDeletion';
import Career from 'pages/Career';
import Page404 from 'pages/Page404'
import Blog from 'pages/Blog';
import BlogDetail from 'pages/BlogDetail';
import ProjectInfo from 'pages/ProjectInfo';
import ProjectDetail from 'pages/ProjectDetail';
import ProjectDocument from 'pages/ProjectDocument';
import Prohouse from 'pages/Prohouse';
import PageExtra from 'components/general/PageExtra';
import NavbarActual from 'components/general/NavbarActual';
import {Helmet} from 'react-helmet';
import Logo from "styles/assets/logo-04.png";
import BuildBricks from 'styles/assets/how-works/VisualCarousel.png';
import AboutLogo from 'styles/assets/about-us/about-top.png';
import Visual from 'styles/assets/career/visual1.png';
import Funding from 'styles/assets/home/permodalan.png';
import AppraiseSimple from 'styles/assets/appraisal/appraise_simple.png';
import ProhouseBig from 'styles/assets/prohouse/prohouse-big.png'
import UserArtboard from 'pages/UserArtboard';
import Appraisal from 'pages/Appraisal';

class Routing extends Component {

  componentDidMount() {
    setTimeout(() => {
      var preloader = document.querySelector('#preloader-wrapper');
      preloader.remove();
    }, 800);
  }

  render() {
    // console.log(this.props.location);
    // return '';

    var allPages = [...Routing.publicPages, ...Routing.noMenuPages, ...Routing.dashboardPages];

    var currentPath = this.props.location.pathname;
    var currentPage = Routing.getPage(currentPath, allPages) || { path: '404-not-found', title: 'Halaman Tidak Ditemukan', exact: false, component: Home, public: true, desc: '', tag: '', image: Logo};


    var t_api = typeof Tawk_API !== 'undefined'? Tawk_API: null; // eslint-disable-line no-undef
    if(t_api && typeof t_api.setAttributes !== 'undefined') {
      var val = {location: currentPage.title};
      t_api.setAttributes(val);
    }

    // console.log(currentPage);
    // for detail desc of blog/property in public area will be customed by the page
    var header =
    currentPage.path.includes(':') && currentPage.public ?
    <Helmet>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="356109988164669" />
    </Helmet>
    :
    <Helmet>
      <title>{currentPage.title.includes('Provesty') ? currentPage.title : 'Provesty | ' + currentPage.title}</title>
      <meta property="og:title" content={currentPage.title} />
      <meta name="description" property="og:description" content={currentPage.desc} />
      <meta name="keywords" content={currentPage.tag} />
      <meta property="og:image" content={currentPage.image} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="356109988164669" />
      <meta name="author" content="Provesty" />
    </Helmet>

    return (
      <React.Fragment>
        {
          Routing.getPage(currentPath, Routing.noMenuPages) === null ?
          <NavbarActual history={this.props.history} />
          : ''
        }
        { header }
        <TransitionGroup>
          <CSSTransition
            key={currentPath}
            location={currentPath}
            timeout={{ enter: 300, exit: 300 }}
            classNames={'fade'}
            onExit = {function () {
              // this will create smooth transition even when current page is scrolled
              document.getElementsByClassName("fade-exit")[0].style.marginTop = '-' + window.scrollY + 'px';
              window.scrollTo(0, 0);
            }}
            >
            <section className="route-section">
              <Switch location={this.props.location}>
                {
                  allPages.map((p, index) => {
                    return <MyRoute key={index} publicArea={p.public} path={p.path} exact={p.exact} component={p.component} />
                  })
                }
                {/*Page not found at the end*/}
                <Route component={Page404}  />
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
        <PageExtra />
      </React.Fragment>
    )
  }
}

Routing.publicPages = [
  { path: '/', title: 'Platform Fintech Investasi Properti Pertama di Indonesia  ', exact: true, component: Home, public: true, desc: 'Dengan skema Peer-to-Peer Crowdfunding & teknologi, semua bisa berinvestasi Properti', tag: 'Investasi, peer to peer, peer to peer lending, investasi properti, pinjaman online, investasi online, investasi peer to peer lending, peer to peer lending indonesia, pinjaman online indonesia, pinjaman aman', image: Logo},
  { path: '/prohouse', title: 'Beli Rumah Saya', exact: false, component: Prohouse, public: true, desc: 'Jual rumah kamu secara cepat ke Provesty. Dapatkan dana secara tunai dalam hitungan hari sejak disetujui.', tag: 'ibuying, jual, beli, rumah, cepat, tunai', image: ProhouseBig},
  { path: '/how-it-works', title: 'Cara Kerja Investasi', exact: false, component: HowWorks, public: true, desc: 'Cara Kerja berinvestasi Peer-to-Peer Lending Marketplace di Provesty untuk Investor. Investasi secara kolektif (crowdfunding) untuk pembiayaan pinjaman flipping rumah dengan proses jelas & transparan.', tag: 'Cara kerja investasi properti, cara investasi properti, investasi crowdfunding, investasi peer to peer lending, investasi aman, investasi menguntungkan, crowdfunding, peer to peer lending marketplace, cara kerja transparan, return menarik,return tinggi,alternatif,jangka pendek', image: BuildBricks},
  { path: '/how-to-sell', title: 'Cara Penjualan', exact: false, component: HowtoLoan, public: true, desc: 'Cara kerja pengajuan pendanaan Peer-to-Peer Lending Marketplace di Provesty untuk Flipper. Pembiayaan pinjaman flipping rumah dengan proses jelas & transparan.', tag: 'cara kerja investasi, cara kerja investasi properti, cara pengajuan dana online, investasi online, pinjaman online, legal properti, ', image: BuildBricks},
  { path: '/about-us', title: 'Tentang Kami', exact: false, component: AboutUs, public: true, desc: 'Provesty, crowdfunding peer to peer lending pertama di Indonesia bergerak di bidang properti. Saat kenal lebih dekat tentang tim kami.', tag: 'provesty, invetasi provesty, investasi properti, ceo provesty, tim provesty, kredit pro, pinjaman online, investasi peer to peer, peer to peer lending', image: AboutLogo},
  { path: '/privacy', title: 'Kebijakan Privasi', exact: false, component: Privacy, public: true, desc: 'Kebijakan privasi untuk penggunaan website Provesty', tag: 'privacy, policy', image: ''},
  { path: '/faq', title: 'Pertanyaan Umum', exact: false, component: Faq, public: true, desc: 'Temukan penjelasan seputar pertanyaan investasi dan pendanaa properti di Peer to peer lending Provesty.', tag: 'cara kerja investasi, cara kerja investasi properti, cara pengajuan dana online, investasi online, pinjaman online, investasi, peer ti peer lending, investasi properti, pinjaman aman', image: Visual},
  { path: '/invest', title: 'Daftar Properti', exact: false, component: Listing, public: true, desc: 'Temukan kesempatan berinvestasi di peer to peer lending bidang properti dengan return besaing', tag: 'Properti, daftar investasi, investasi online, investasi peer to peer lending, flipping rumah, danai pinjaman, invetasi properti, pendanaan properti, cara kerja transparan, return menarik,return tinggi,alternatif,jangka pendek', image: Funding},
  { path: '/appraisal', title: 'ProEngine', exact: false, component: Appraisal, public: true, desc: 'Cek harga pasaran properti di semua area populer di Indonesia', tag: 'cek, harga rumah, harga tanah, harga ruko, perkiraan, pasaran, sekitar, luas tanah, luas bangunan', image: AppraiseSimple},
  { path: '/detail/:pro_slug', title: 'Detail Properti', exact: false, component: PropDetailPage, public: true, desc: '', tag: '', image: ''},
  { path: '/risk', title: 'Resiko Investasi', exact: false, component: Risk, public: true, desc: 'Ketahui resiko berinvestasi di Provesty, investasi peer to peer lending marketplace di bidang properti.', tag: 'Resiko investasi Properti, investasi online, investasi peer to peer lending, flipping rumah, danai pinjaman, invetasi properti, pendanaan properti, return menarik,return tinggi,alternatif,jangka pendek, investasi aman, resiko rendah', image: Funding},
  { path: '/careers', title: 'Karir di Provesty', exact: false, component: Career, public: true, desc: 'Bergabung menjadi tim terbaik di perusahaan fintech startup bidang properti di Indonesia', tag: 'Karir, lowongan kerja, front end engineer, peer to peer lending, pinjaman online, Investasi,peer to peer, peer to peer lending, investasi properti, pinjaman online, investasi online, investasi peer to peer lending', image: Visual},
  { path: '/blog', title: 'Blog', exact: false, component: Blog, public: true, desc: 'Provesty, investasi peer to peer lending marketplace di bidang properti. Ketahui berita dan tips investasi properti.', tag: 'Blog investasi, tips investasi, atur uang, peer to peer lending, pinjaman online, Investasi,peer to peer, peer to peer lending, investasi properti, pinjaman online, investasi online, investasi peer to peer lending', image: Visual},
  { path: '/blog-detail/:ps_slug', title: 'Blog Detail', exact: false, component: BlogDetail, public: true, desc: '', tag: '', image: ''},
  { path: '/credential/profile', title: 'Data Profile', exact: false, component: CredentialProfile, public: false, desc: '', tag: '', image: ''},
  { path: '/credential/document', title: 'Data Dokumen', exact: false, component: CredentialDocument, public: false, desc: '', tag: '', image: ''},
  { path: '/payment/:slug_id_investment', title: 'Detail Pembayaran Investasi', exact: false, component: ReviewPayment, public: false, desc: '', tag: '', image: ''},
  { path: '/payment_topup/:slug_id_payment', title: 'Detail Pembayaran TopUp', exact: false, component: ReviewTopup, public: false, desc: '', tag: '', image: ''}
];

Routing.noMenuPages = [
  { path: '/login', title: 'Masuk', exact: false, component: Login, public: true, desc: 'Provesty, investasi peer to peer lending marketplace di bidang properti dengan sistem crowdfunding, dan return bersaing.', tag: 'Login, masuk, investasi, tips investasi, atur uang, peer to peer lending, pinjaman online, Investasi,peer to peer, peer to peer lending, investasi properti, pinjaman online, investasi online, investasi peer to peer lending', image: Logo},
  { path: '/logout', title: 'Keluar', exact: false, component: Logout, public: true, desc: 'Provesty, investasi peer to peer lending marketplace di bidang properti dengan sistem crowdfunding, dan return bersaing.', tag: 'Logout, keluar, investasi, tips investasi, atur uang, peer to peer lending, pinjaman online, Investasi,peer to peer, peer to peer lending, investasi properti, pinjaman online, investasi online, investasi peer to peer lending', image: Logo},
  { path: '/register', title: 'Daftar', exact: false, component: Register, public: true, desc: 'Provesty, investasi peer to peer lending marketplace di bidang properti dengan sistem crowdfunding, dan return bersaing. ', tag: 'Daftar, daftar investasi, investasi, tips investasi, atur uang, peer to peer lending, pinjaman online, Investasi,peer to peer, peer to peer lending, investasi properti, pinjaman online, investasi online, investasi peer to peer lending', image: Logo},
  { path: '/forgot-password', title: 'Lupa Password', exact: false, component: ForgotPassword, public: true, desc: 'Provesty, investasi peer to peer lending marketplace di bidang properti dengan sistem crowdfunding, dan return bersaing. ', tag: 'password, daftar investasi, investasi, tips investasi, atur uang, peer to peer lending, pinjaman online, Investasi,peer to peer, peer to peer lending, investasi properti, pinjaman online, investasi online, investasi peer to peer lending', image: Logo},
  { path: '/reset-password', title: 'Reset Password', exact: false, component: ResetPassword, public: true, desc: 'Provesty, investasi peer to peer lending marketplace di bidang properti dengan sistem crowdfunding, dan return bersaing.', tag: 'Reset Password, daftar investasi, investasi, tips investasi, atur uang, peer to peer lending, pinjaman online, Investasi,peer to peer, peer to peer lending, investasi properti, pinjaman online, investasi online, investasi peer to peer lending', image: Logo},
  { path: '/verify-email', title: 'Verifikasi Email', exact: false, component: VerifyEmail, public: true, desc: 'Verifikasi email kamu sekarang untuk bisa mulai berinvestasi', tag: 'verifikasi email, investasi', image: ''},
  { path: '/send-email-verify', title: 'Kirim Email Verifikasi', exact: false, component: SendEmailVerify, public: false, desc: '', tag: '', image: ''},
  { path: '/rewind', title: 'Catatan Akhir Tahun', exact: false, component: UserArtboard, public: false, desc: 'Lihat perjalanan investasi kamu di tahun ini', tag: 'resolution, rewind, 2019', image: ''},

]

Routing.dashboardPages = [
  { path: '/dashboard', title: 'Dashboard Investor', exact: true, component: Dashboard, public: false, desc: '', tag: '', image: ''},
  { path: '/dashboard/portfolio', title: 'Portfolio', exact: false, component: DashboardPortfolio, public: false, desc: '', tag: '', image: ''},
  { path: '/dashboard/transaction', title: 'Transaction', exact: false, component: DashboardTransaction, public: false, desc: '', tag: '', image: ''},
  { path: '/dashboard/message', title: 'Pesan', exact: false, component: DashboardMessage, public: false, desc: '', tag: '', image: ''},
  { path: '/dashboard/profile', title: 'Profil', exact: false, component: DashboardProfile, public: false, desc: '', tag: '', image: ''},
  /*{ path: '/dashboard/loan', title: 'Dashboard Peminjam', exact: false, component: DashboardLoan, public: false, desc: '', tag: '', image: ''},*/
  { path: '/dashboard/account', title: 'Dashboard Akun', exact: false, component: DashboardAccount, public: false, desc: '', tag: '', image: ''},
  { path: '/sponsor-individual/profile', title: 'Profil Peminjam Individu', exact: false, component: IndividualProfile, public: false, desc: '', tag: '', image: ''},
  { path: '/sponsor-individual/experience', title: 'Pengalaman Peminjam Individu', exact: false, component: IndividualExperience, public: false, desc: '', tag: '', image: ''},
  { path: '/sponsor-individual/document', title: 'Dokumen Peminjam Individu', exact: false, component: IndividualDocument, public: false, desc: '', tag: '', image: ''},
  { path: '/sponsor-company/profile', title: 'Profil Peminjam Perusahaan', exact: false, component: CompanyProfile, public: false, desc: '', tag: '', image: ''},
  { path: '/sponsor-company/profile/:id_vendor', title: 'Profil Peminjam Perusahaan', exact: false, component: CompanyProfile, public: false, desc: '', tag: '', image: ''},
  { path: '/sponsor-company/experience/:id_vendor', title: 'Profil Pengalaman Perusahaan', exact: false, component: CompanyExperience, public: false, desc: '', tag: '', image: ''},
  { path: '/sponsor-company/document/:id_vendor', title: 'Profil Dokumen Perusahaan', exact: false, component: CompanyDocument, public: false, desc: '', tag: '', image: ''},
  { path: '/project/info/:id_project', title: 'Info Proyek', exact: false, component: ProjectInfo, public: false, desc: '', tag: '', image: ''},
  { path: '/project/info', title: 'Info Proyek', exact: false, component: ProjectInfo, public: false, desc: '', tag: '', image: ''},
  { path: '/project/detail/:id_project', title: 'Detail Proyek', exact: false, component: ProjectDetail, public: false, desc: '', tag: '', image: ''},
  { path: '/project/document/:id_project', title: 'Dokumen Proyek', exact: false, component: ProjectDocument, public: false, desc: '', tag: '', image: ''},
  { path: '/privacy', title: 'Privacy Policy', exact: false, component: Privacy, public: true, desc: 'Ketentuan penggunaan data di Provesty', tag: 'privacy policy, data, good governance', image: ''},
  { path: '/data-deletion', title: 'Kebijakan Penghapusan Data', exact: false, component: DataDeletion, public: true, desc: 'Tata cara penghapusan data user di aplikasi Provesty', tag: 'data deletion, policy', image: ''},
]

Routing.getPage = (path, pages) => {
  var ret = null;
  for(var i=0; i<pages.length; i++) {
    var page = pages[i];
    if(page.exact) {
      if(path === page.path) {
        ret = page;
        break;
      }
    } else {
      var pathArr = path.split('/');
      var pagePathArr = page.path.split('/');
      var match = true;
      for(var j=0; j<pagePathArr.length; j++) { // make sure all section with no widlcard are same
        if(pagePathArr[j].includes(':'))
        continue;
        else if(pagePathArr[j] !== pathArr[j]) {
          match = false;
          break;
        }
      }
      if (match) {
        ret = page;
        break;
      }
    }
  }
  return ret;
}

export default withRouter(Routing);
