import { SET_USER, UPDATE_USER, CLEAR_USER } from '_constant';

export const setUser = (data) => {
  return {
    type: SET_USER,
    payload: data,
  }
}
export const updateUser = (data) => {
  return {
    type: UPDATE_USER,
    payload: data,
  }
}
export const clearUser = () => {
  return {
    type: CLEAR_USER
  }
}
