import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import BigLogo from 'styles/assets/about-us/about-top.png';
import Button from '@material-ui/core/Button';
import {
  Link
} from 'react-scroll'

export default class About1 extends Component {
  render(){
    return(
      <div className="about-1 container">
        <Grid container alignItems="center">
          <Grid item lg={1} md={1} />
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div style={{textAlign: "center"}}>
              <img className="img-big" src={BigLogo} alt="provesty big" />
            </div>
          </Grid>
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <div className="right-about">
              <h1 className="about-title">Tentang Kami</h1>

              <p><b>Provesty</b> adalah platform crowdfunding pertama di Indonesia yang fokus terhadap investasi pada sektor properti. Visi utama Provesty adalah:</p>

              <p>“Memberikan kemudahan dan aksesibilitas bagi setiap orang untuk bisa berinvestasi pada properti dengan menggunakan teknologi.”</p>

              <p>Untuk saat ini Provesty hanya fokus pada investasi jangka pendek dengan skema Flipping (Beli- Renovasi-Jual). </p>

              <Grid container>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <Link activeClass="active" value="about-2" to={this.props.to} spy={true} smooth={true} duration={2000}>
                    <Button className="button-mulai-investasi">
                      Hubungi Kami
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}
