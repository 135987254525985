import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Gembok from 'styles/assets/Icon.png';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    maxWidth: 500,
    margin: '0 auto',
    marginTop: 30,
  },
  container:{
    margin: '10'
  },
  expansion:{
    boxShadow:'none',
    border: '1px solid #1d5d91',
    color: '#1d5d91',
    backgroundColor:'white',
    marginLeft: 10,
    marginRight: 10,
  },
});


class Loan5New extends Component {
  render() {

    const { classes } = this.props;

    return (<div className="container loan6">
      <div className="loan6-title">Pertanyaan Yang Sering Ditanyakan</div>
      <div className="loan6-content">Silahkan hubungi kontak kami yang terletak di bagian Footer jika masih terdapat pertanyaan</div>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Apakah Prohouse adalah perusahaan yang berbeda dengan Provesty ?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Prohouse adalah perusahaan yang berada dibawah naungan Provesty yang memiliki agent properti yang sudah mendapatkan sertifikat Tenaga Ahli Broker Properti dari AREBI (Asosiasi Real Estate Broker Indonesia).
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Berapa fee yang dibayarkan jika rumah terjual?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Jika terjadi penjualan properti, fee akan dibayarkan setelah rumah terjual. Fee yang perlu dibayarkan sebesar 2,5%-3% dari harga transaksi dikurangi pajak.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Wilayah mana saja yang menjadi daerah penjualan Prohouse by Provesty?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Cakupan wilayah Prohouse by Provesty meliputi JABODETABEK (Jakarta, Bogor, Depok, Tangerang, dan Bekasi) dan Bandung.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Siapa yang dapat menjual rumah di Prohouse by Provesty?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Siapa saja dapat menjual rumah melalui Prohouse, selama kamu memiliki hak kepimilikan sah atas rumah atau properti yang akan dijual.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Apakah boleh saya menjual properti berbentuk tanah?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Boleh, tetapi hanya untuk opsi titip jual. Namun, untuk opsi penjualan untuk dana talangan dan beli putus untuk sekarang kami belum bisa menerima.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Grid>
      </Grid>
    </div>)
  }
}

Loan5New.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Loan5New);
