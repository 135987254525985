import React, { Component } from 'react';
import Logo from 'styles/assets/logo.png'
import { Link, NavLink } from "react-router-dom";
import { APISTAT, DATAVAR } from '_constant';
import { apiHelper, dispatchMsg, dispatchCloseMsg, formData, dataHelper, userHelper } from 'helpers';
import Grid from '@material-ui/core/Grid';
import Spinner from 'react-spinkit';

export default class VerifyEmail extends Component {

  state = {
    isLoading: true,
    isSuccess: false,
  };

  componentDidMount() {
    this.loadData();
  }
  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  loadData = () => {

    var params = new URLSearchParams(this.props.history.location.search);

    this.setState({ isLoading: true});

    apiHelper.request(this, {
      resource: 'verify_email',
      data: formData({
        e: params.get('e'),
        c: params.get('c')
      }),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success

          // if not login, prompt login. If login, check if need to go to saved page
          var isLogin = userHelper.isLogin();
          var msg = isLogin ? 'Verifikasi berhasil. Redirecting..' : 'Verifikasi berhasil. Silakan login di halaman berikutnya..';
          var redirectTgt = isLogin ? (dataHelper.get(DATAVAR.REDIRECT_LOGIN, true) || '/dashboard') : '/login' ;

          dispatchMsg('success', msg);
          this.setState({ isLoading: false, isSuccess: true});

          setTimeout(() => { dispatchCloseMsg(); this.props.history.push(redirectTgt)}, 2000);
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false});
      }
    });

  }

  render(){
    return(
      <div className="login-page">
        <div className="container container-login">
          <NavLink to='/'>
            <img src={Logo} alt="logo-white" style={{zIndex: 1000, width: 150, marginTop: 26, cursor: 'pointer'}} />
          </NavLink>
          <div id="auth-root">
            <Grid container justify="center" alignItems="center" className="faux-tab auth-box" >
              Verifikasi Email
            </Grid>
            <div className="auth-box">
              <div className="tab-container">
                <div className="auth-form">
                  <Grid container alignItems="center" justify="center" style={{height: 60}}>
                    {
                      this.state.isLoading ?
                      <div className="my-spinner-container" style={{    height: 'auto', paddingTop: 0}}>
                        <Spinner fadeIn='quarter' name="chasing-dots" />
                      </div>
                      :
                      (
                        this.state.isSuccess ?
                        'Email Berhasil diverifikasi' :
                        'Email gagal diverifikasi'
                      )
                    }
                  </Grid>
                </div>
              </div>

            </div>
            <div className="sign-up-now">
              <p>Kembali ke halaman<span className="sign-up-button"> <Link to="/login"><u>Login</u></Link></span></p>
            </div>

          </div>
        </div>

      </div>
    )
  }
}
