import React from 'react';
import FaqItem from './FaqItem';

class FaqSection extends React.Component {

  render() {
    const { items, sectionTitle } = this.props;
    const faqItems = items.map(x => <FaqItem key={x.id_faq} fq_question={x.fq_question} fq_answer={x.fq_answer} id={x.id_faq} /> );

    return (
      <div className="faq-section">
        <div className="faq-title" dangerouslySetInnerHTML={{
            __html: sectionTitle
          }} />
          {faqItems}
        </div>
      );
    }
  }

  export default FaqSection;
