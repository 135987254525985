import 'react-app-polyfill/ie9';
import './styles/normalize.css';
import "react-sweet-progress/lib/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "animate.css/animate.min.css";
import './styles/index.css';
import 'react-switchable/dist/main.css'
import React from 'react';
import ReactDOM from 'react-dom';
// import {Switch, Route, BrowserRouter as Router} from 'react-router-dom'
// import Navbar from 'components/general/Navbar'
import Routing from "./Routing"
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import Store from './store';
import { LocalizeProvider } from "react-localize-redux";

import { unregister } from './registerServiceWorker';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-PJZ3SSM'
}

TagManager.initialize(tagManagerArgs)

window.store = Store;
ReactDOM.render(

  <Provider store={Store}>
    <LocalizeProvider store={Store}>
      <Router>
        <Routing />
      </Router>
    </LocalizeProvider>
  </Provider>

  , document.getElementById('root'));
  unregister();
