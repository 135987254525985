import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import VisualCarousel from 'styles/assets/home/VisualCarousel.png'
import Hidden from '@material-ui/core/Hidden';
import { withLocalize } from 'react-localize-redux';
import {Link} from 'react-router-dom';


class CarouInvest extends Component {

  constructor(props) {
    super(props);
    this.addTranslationsForActiveLanguage();
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.addTranslationsForActiveLanguage();
    }
  }

  addTranslationsForActiveLanguage() {
    const {activeLanguage} = this.props;

    if (!activeLanguage) {
      return;
    }

    import(`../../translations/home/${activeLanguage.code}.home.json`)
    .then(translations => {
      this.props.addTranslationForLanguage(translations, activeLanguage.code)
    });
  }

  render(){
    return(
      <div className="container" style={{maxWidth:'100%'}}>
        <Grid container alignItems="center" style={{marginTop: 15}}>
          <Hidden smDown>
            <Grid item lg={1} md={1} sm={false} xs={false} />
          </Hidden>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div className="home1-left">
              <div className="home1-title">
                {/*<Translate id={`home1.header`}/>*/}
                Sekarang Semua Orang Bisa
              </div>
              {/*<h3><Translate id={`movie1.title`} /></h3>
            <p><Translate id={`movie1.description`} /></p>*/}
            <div className="loan1-subtitle">{/*<Translate id={`home1.subheader`} />*/}Berinvestasi di Properti</div>
            <div className="home1-content">
              Dengan teknologi, data dan skema crowdfunding, investasikan dana kamu di berbagai aset properti mulai dari 1 juta rupiah. Dapatkan imbal hasil jangka pendek dengan skema flipping (Beli-Renovasi-jual) dari Provesty
            </div>
            <div className="home1-buttons">
              <Grid container spacing={16}>
                <Grid item lg={5} md={6} sm={6} xs={12}>
                  <Link to="/how-it-works" style={{textDecoration:'none'}}>
                    <Button className="button-mulai-investasi" style={{}}>
                      Pelajari Investasi
                    </Button>
                  </Link>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Link to="/invest" style={{textDecoration: 'none'}}>
                    <Button variant="contained" className="btn-dark-blue">
                      Mulai Investasi
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <div className="home1-right img-center">
            <Hidden smDown><img className="img-width" src={VisualCarousel} alt="" /></Hidden>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
}

export default withLocalize(CarouInvest);
