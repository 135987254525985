import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
// import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Likuiditas from 'styles/assets/risk/likuiditas.png';
import Resiko from 'styles/assets/risk/resiko.png';
import Fraud from 'styles/assets/risk/fraud.png';
import Resesi from 'styles/assets/risk/resesi.png';
import Pailit from 'styles/assets/risk/pailit.png';
import Capital from 'styles/assets/risk/capital_risk.png';


function TabContainer(props) {
  return (
    <Typography key={props.tabName} component="div" className="fade-in">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: 20,
  },
  grid: {
    padding: '32px',
    [theme.breakpoints.down('sm')]:{
      padding: '32px 10px'
    }
  },
  tabsRoot: {
    borderBottom: '1px solid rgba(61, 125, 200, 0.61);',
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabRoot: {
    minWidth: 30,
    border: '3px solid transparent',
    color: 'gray',
    marginRight: theme.spacing.unit * 2,
    fontFamily: [
    ].join(','),
    '&:hover': {
      color: 'rgba(61, 125, 200, 0.61);',
      border: 'solid 2px rgba(61, 125, 200, 0.61)',
      borderRadius: 26.5,
      fontWeight: 600,
    },
    '&$tabSelected': {
      color: 'rgba(61, 125, 200, 0.61);',
      border: '2px solid rgba(61, 125, 200, 0.61);',
      borderRadius: 26.5,
      fontWeight: 600,
    },
  },
  tabSelected: {},
});



class RiskTop extends Component {

  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render(){

    const { classes } = this.props;
    const { value } = this.state;

    var risks =[
      {
        "tab_value": 0,
        "tab_title":"Likuiditas",
        "tab_image":Likuiditas,
        "tab_content":`
            Likuiditas sudah menjadi Natural Risk ketika berinvestasi pada sektor properti. Meskipun harga properti terutama di Indonesia relatif stabil dan terus mengalami kenaikan, namun pencairan atau penjualan properti tidak dapat dilakukan dalam waktu singkat. Meskipun ditargetkan terjual dalam jangka waktu tertentu, tetap masih ada resiko perpanjangan yang bergantung kepada proses dan kondisi market saat penjualan. Oleh karena itu Investor sebaiknya mempertimbangkan resiko ini sebelum berinvestasi pada Provesty.<br/><br/>
            Provesty tentunya akan melakukan analisis dan due diligence secara menyeluruh dan akurat, sehingga proyek properti bisa memiliki likuiditas yang cukup ketika akan dijual, mulai dari market strength di sekitar lokasi properti, harga properti, prospek kedepannya, dan legalitas yang mumpuni dari setiap asset yang akan diinvest melalui Provesty. Kami pun akan transparan terhadap setiap proses yang dilakukan, dan Investor bisa dengan mudah mengakses setiap update yang kami berikan melalui platform kami secara online.`
      },
      {
        "tab_value": 1,
        "tab_title":"Gagal Bayar",
        "tab_image":Resiko,
        "tab_content":`
        Untuk proyek properti yang menggunakan system pinjaman/loan, misal dalam skema Murabahah atau jual beli, ada resiko developer atau sponsor mengalami default atau gagal bayar ketika akan melunasi kredit baik dikarenakan kegagalan usaha, fraud, legalitas ataupun karena alasan lainnya.<br/><br/>
        Untuk memitigasi resiko ini dari pihak Provesty akan melalukan Analisa kelayakan dan credit scoring untuk setiap developer yang mengajukan pembiayaan sehingga meminimalisir resiko gagal bayar. Selain itu asset properti yang diajukan dapat juga menjadi jaminan, meskipun kembali lagi tidak dapat dilikuidasi dalam waktu yang singkat sehingga tetap terjadi penundaan. Dari pihak investor, mitigasi yang bisa dilakukan adalah dengan cara mendiversifikasi investasi di berbagai proyek properti yang kami sediakan.`
      },
      {
        "tab_value": 2,
        "tab_title":"Fraud",
        "tab_image":Fraud,
        "tab_content":`
        Resiko fraud dapat terjadi ketika ada perselisihan contohnya pada legalitas asset, sengketa tanah, pemilik ganda, dan lain sebagainya. Untuk memitigasi ini Provesty akan melakukan due diligence yang kuat, bekerja sama dengan konsultan hukum berpengalaman, dan bekerja sama dengan BPN atau lembaga berwenang lainnya. Dokumen pun dapat diakses oleh investor di detail proyek dan properti seperti surat tanah, MoU, dokumen perjanjian, dll.`
      },
      {
        "tab_value": 3,
        "tab_title":"Resesi",
        "tab_image":Resesi,
        "tab_content":`
        Indonesia saat ini Indonesia memiliki kestabilan ekonomi yang baik bahkan di negara-negara G20, namun resiko terjadinya resesi dan krisis ekonomi masih ada yang mengakibatkan kerugian pada investor. Meskipun sektor properti pasti akan menjadi prioritas utama oleh pemerintah karena dampaknya yang sangat besar kepada masyarakat.`
      },
      {
        "tab_value": 4,
        "tab_title":"Pailit",
        "tab_image":Pailit,
        "tab_content":`Resiko pailit dikarenakan satu dan lain hal selalu ada di setiap perusahaan. Namun Provesty akan terus berintegritas dalam memajukan perusahaan dengan tim yang solid, transparansi, kontinuitas evaluasi, inovasi dan analisis perusahaan yang mumpuni. Ketika Provesty pailit, semua investasi tetap akan berjalan dan diselesaikan hingga tuntas dikarenakan perjanjian yang dibuat juga melibatkan pihak developer.`
      }
    ]

    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   arrows: false,
    //   autoplay: true,
    //   autoplaySpeed: 5000,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1
    // };
    var risk = risks[value];

    return(
      <div className="risk-top">
        <Grid container>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <div className="img-center  ">
              <img src={Capital} alt="capital-risk" style={{width: 165, margin: '0 auto'}}/>
            </div>
          </Grid>
          <Grid item ld={8} md={8} sm={12} xs={12}>
            <p className="capital-risk">Capital Risk</p>
              <div style={{height: '100%', width:'90%', margin: '0 auto'}}>
                <div className="risk-slider-content">
                  Provesty akan melakukan analisis dan due diligence secara teliti, ketat dan seakurat mungkin dalam menyeleksi setiap proyek dan properti yang masuk. Dengan tim kami yang sudah berpengalaman puluhan tahun di sektor properti, serta sistem credit-scoring yang akurat, Provesty mencoba memaksimalkan return sekaligus meminimalisir resiko untuk para investor. Namun resiko tetap tidak bisa sepenuhnya dihindari, sehingga para investor diharapkan dapat mempelajari resiko-resiko di bawah ini sehingga dapat lebih cermat sebelum melakukan investasi.
                </div>
              </div>
          </Grid>
        </Grid>
        <div>
            <div className="tab-risk">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons="on"
                classes={{indicator:classes.tabsIndicator}}
                >
                {
                  risks.map((risk, index) =>
                  <Tab
                    disableRipple
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }} key={index} label={risk.tab_title}
                  />
                  )
                }
              </Tabs>
            </div>
            <Paper className={classes.root}>
              {
                    risk &&
                    <TabContainer tabName={risk.tab_title} >
                      <Grid container alignItems="center" className={classes.grid}>
                        <Grid item lg={3} md={3} sm={4} xs={12}>
                          <div className="">
                            <img src={risk.tab_image} alt="risk-icon" style={{width: 146, maxWidth: '100%', display:'block', margin:"auto"}}/>
                          </div>
                        </Grid>
                        <Grid item lg={9} md={9} sm={8} xs={12}>
                          <p className="risk-content" dangerouslySetInnerHTML={{__html:risk.tab_content}}/>
                        </Grid>
                      </Grid>
                    </TabContainer>
              }
            </Paper>
        </div>
      </div>
    )
  }
}

RiskTop.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(RiskTop);
