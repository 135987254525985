import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ProsesBangunan from 'styles/assets/how-works/ProsesBangunan.png';
import Buy from 'styles/assets/how-works/buy.png';
import Fix from 'styles/assets/how-works/fix.png';
import Sell from 'styles/assets/how-works/sell.png';

export default class How1 extends Component {
  render(){
    return(
      <div className="how-2 ta-center">
        <div className="container">
          <div className="loan2-title">
            Apa itu Flipping ?
          </div>
          <div className="how2-content">
            Flipping adalah jenis investasi jangka pendek di sektor Properti dengan 3 pendekatan: Beli, Renovasi, Jual
          </div>
        </div>
        <div className="bg-build">
          <img src={ProsesBangunan} alt="ProsesBangunan" style={{width: '100%'}} className="proses-bangunan" />
          <div className="container ta-center">
            <Grid container>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className="how2-product">
                  <img src={Buy} alt="beli"/>
                    <div className="loan2-title">
                      Beli
                    </div>
                  <div>
                    Partner Flipper Provesty mencari properti di daerah sekitar mereka, dengan lokasi yang bagus dan market yang tumbuh, diutamakan dengan harga di bawah pasar
                  </div>
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className="how2-product">
                  <img src={Fix} alt="perbaikan"/>
                    <div className="loan2-title">
                      Renovasi
                    </div>
                  <div>
                    Untuk meningkatkan harga jual, Flipper merenovasi bangunan tersebut, seperti mengecat ulang, memperbaiki atap atau menaikan bangunan menjadi 2 lantai
                  </div>
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className="how2-product">
                  <img src={Sell} alt="jual"/>
                    <div className="loan2-title">
                      Jual
                    </div>
                  <div>
                    Setelah direnovasi, Flipper langsung menjual properti dengan harga lebih tinggi kepada pembeli baru (end user) dalam waktu singkat dan mengembalikan dana investor
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}
