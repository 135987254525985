import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
// import SwipeableViews from 'react-swipeable-views';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
import LoginComponent from './LoginComponent'
import RegisterComponent from './RegisterComponent'

function TabContainer({ children, dir }) {
  return (
    <div className="tab-container">
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
  },
});

class Auth extends React.Component {
  state = {
    tabIndex: this.props.source === 'login'? 0: 1,
  };

  // trigger by tab
  handleTabChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };
  // trigger by slide
  handleChangeIndex = index => {
    this.setState({ tabIndex: index });
  };

  render() {
    const { theme } = this.props;

    return (
      <React.Fragment>
        <Tabs className="auth-box"
          classes={{
            root: 'mytabs',
            flexContainer: 'flex-container'
          }}
          value={this.state.tabIndex}
          onChange={this.handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#3d7dc8"
            }
          }}
          action={(actions) => setTimeout(actions.updateIndicator.bind(actions), 0)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          centered
          >
          <Tab label="Masuk" classes={{root: 'tab-root', selected: 'tab-selected'}}/>
          <Tab label="Daftar" classes={{root: 'tab-root', selected: 'tab-selected'}}/>
        </Tabs>

        <div className="auth-box">
          {/*<SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={this.state.tabIndex}
            onChangeIndex={this.handleChangeIndex}
            >*/
          }
          {this.state.tabIndex === 0 ?
            <TabContainer dir={theme.direction}>
              <LoginComponent history={this.props.history} />
            </TabContainer> :
            <TabContainer dir={theme.direction}>
              <RegisterComponent history={this.props.history} />
            </TabContainer>
          }
          {/*</SwipeableViews>*/}
        </div>
        {
          this.state.tabIndex === 0 ?
          <div className="sign-up-now">
            <p>Apakah kamu</p>
            <p style={{marginLeft: 10}}>
              <span className="sign-up-button">
                <Link to="/forgot-password">Lupa Kata Sandi</Link>
              </span> ?
            </p>
          </div>
          :  ''
        }
      </React.Fragment>
    );
  }
}

Auth.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Auth);
