import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const FaqItem = (props) =>  {

  return (
    <div className="faq-item">
      <ExpansionPanel classes={{root: 'faq-panel', expanded: 'faq-panel-expanded'}} >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className="faq-q" dangerouslySetInnerHTML={{__html: props.fq_question}} />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="faq-ans" dangerouslySetInnerHTML={{__html: props.fq_answer}} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )};

    export default FaqItem;
