  export const userHelper = {
  isValidData: function (data) {
    // data is a json object with token, name, and photo
    if(
      typeof data === 'object' &&
      typeof data.token !== 'undefined' &&
      typeof data.u_name !== 'undefined' &&
      typeof data.u_email !== 'undefined' &&
      typeof data.u_photo !== 'undefined'
    )
    return true;
    else
    return false;
  },
  get: function ()  {
    // can be used to check if valid data is stored
    var data = localStorage.getItem('user-provesty');
    if(data && this.isValidData(JSON.parse(data)))
    return data;
    else
    return null;
  },
  getData: function(item) {
    if(!this.get())
    return null;
    var data = JSON.parse(this.get());
    if(data && typeof(data[item]) !== 'undefined')
    return data[item];
  },
  isLogin: function () {
    return this.getData('token') ? true: false;
  },
  getToken: function () {
    return this.getData('token');
  }
}

export default userHelper;
