import React from 'react';
import Rodal from 'rodal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MyTextField from "components/general/MyTextField";
// import MySelect from "components/general/MySelect";
import NormalSelect from "components/general/NormalSelect";
import { price_d2r, price_r2d } from 'helpers';
import { apiHelper, dispatchMsg, formVal, formData } from 'helpers';
import { APISTAT, NUMBERS } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ModalTopup extends React.Component {

  static defaultProps = {
    history: null,
    bank_list: [],
    u_aggr_liquid_bal: 0,
    onClose: null
  };

  constructor(props) {
    super(props);
    this.state = {
      py_amount: price_d2r(0),
      id_bank_account_dst: 0,
      validErrors: {},
      buttonLoading: false,

      modalHeight: 370,
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;
    if (name === 'py_amount') {
      val = price_d2r(val);
    }
    this.setState({ [name]: val });
  };

  validate = () => {

    this.errors = {};

    if(price_r2d(this.state.py_amount) < NUMBERS.MIN_TRANSFER_AMOUNT) {
      this.errors['py_amount'] = 'Jumlah topup harus melebihi minimum yaitu ' + price_d2r(NUMBERS.MIN_TRANSFER_AMOUNT);
    }

    formVal(this, 'id_bank_account_dst', 'Bank', 'required');

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    // clear the errors
    this.setState({ buttonLoading: true});

    apiHelper.request(this, {
      resource: 'topup_post',
      data: formData('form-topup'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          dispatchMsg('success', 'Topup berhasil');
          this.setState({ buttonLoading: false }, () => this.props.history.push("/payment_topup/" + resp.data.id_payment));

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({  buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        this.setState({ buttonLoading: false});
      },
    });
  }

  componentDidUpdate() {
    // responsive height
    const height = this.divElement.clientHeight; // 378
    const { modalHeight } = this.state; // 370
    var diff = 1;
    if(height > diff && modalHeight !== height - diff)
    this.setState({modalHeight: (height - diff)});
  }

  render() {

    const { visible, bank_list, u_aggr_liquid_bal} = this.props;

    return (
      <Rodal className="sms-modal" visible={visible} onClose={() => this.props.onClose()}
        showCloseButton={false} width={420} height={this.state.modalHeight}
        >
        <div className="sms-modal-content" ref={ (divElement) => this.divElement = divElement}>
          <form onSubmit={this.handleSubmit} id="form-topup">
            <h3 className="sms-modal-title">Top Up Wallet</h3>

            <Grid container className="form-group">
              <Grid item xs={6} className="form-label">Saldo Kamu</Grid>
              <Grid item xs={6} className="form-content"><div className="form-content-info">{price_d2r(u_aggr_liquid_bal)}</div></Grid>
            </Grid>
            <Grid container className="form-group">
              <Grid item xs={6} className="form-label">Jumlah Topup*</Grid>
              <Grid item xs={6} className="form-content">
                <MyTextField name="py_amount" label="" obj={this}
                  variant="outlined" type="tel"
                  />
              </Grid>
            </Grid>
            <Grid container className="form-group">
              <Grid item xs={6} className="form-label">Tujuan Transfer*</Grid>
              <Grid item xs={6} className="form-content">
                <NormalSelect
                  name="id_bank_account_dst" label="" obj={this}
                  options={bank_list.map(x => ({k:x.id_bank_account, v:x.ba_name}))}
                  variant="outlined"
                  />
              </Grid>
            </Grid>
            <div className="sms-modal-buttons" style={{marginTop: 30, marginBottom: 30}}>
              <div className="button-progress-wrapper">
                <Button disabled={this.state.buttonLoading} className="button-blue" type="submit" style={{width: 120}}>Top Up</Button>
                {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
              </div>
              <Button style={{marginLeft: 16}} className="button-white" onClick={() => this.props.onClose()}>Batal</Button>
            </div>
          </form>
          <div><p style={{textAlign: "center"}}>
            <b>Note!</b> Saldo yang cukup di wallet memungkinkan kamu untuk berinvestasi secara instan
            </p></div>
          </div>
        </Rodal>
      );
    }
  }
