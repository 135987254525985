import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MyTextField from "components/general/MyTextField";
import MyCheckboxes from "components/general/MyCheckboxes";
import Grid from '@material-ui/core/Grid';
import { formData, apiHelper, dispatchMsg, formScrollToError, formDef, formValid } from 'helpers';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-spinkit';

function getSteps() {
  return ['Informasi Personal', 'Lengkapi Informasi Pengalaman', 'Informasi Dokumen'];
}

export default class CompanyExperience extends React.Component {

  static defaultProps = {
    history: null
  }

  constructor(props) {
    super(props);
    var fields = formDef(this, [
      {name: 'vd_experience_year', label: 'Pengalaman Properti'},
      {name: 'vd_experience_unit', label: 'Jumlah Unit'},
      {name: 'vd_experience_type', label: 'Tipe Properti'},
      {name: 'vd_experience_location', label: 'Lokasi Properti'},
    ]);

    this.state = {
      activeStep: 1,

      ...fields,

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;

    this.setState({ [name]: val });
  };


  validate = () => {

    this.errors = {};

    formValid(this);

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;
    // clear the errors
    this.setState({ buttonLoading: true });

    apiHelper.request(this, {
      resource: 'company_experience_post',
      urlParams: [ this.props.match.params.id_vendor],
      data: formData('form-data'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ buttonLoading: false });
          dispatchMsg('success', 'Data Pengalaman berhasil disimpan.')
          this.props.history.push( "/sponsor-company/document/" + this.props.match.params.id_vendor);
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false });
      },
    });
  }


  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'company_experience_get', // use the secured one if loggedin
      urlParams: [ this.props.match.params.id_vendor],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }


  render() {

    const steps = getSteps();
    const { activeStep } = this.state;
    const stepButtons = steps.map((label, index) => {
      return (
        <Step key={label} name="stepper" >
          <StepLabel classes={{label: 'step-label'}} StepIconProps={{classes: {root: 'step-icon', active: 'step-icon-active', completed: 'step-icon-completed'}}}>
            {label}
          </StepLabel>
        </Step>
      );
    });

    const formContent =
    <Grid container spacing={16}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid item xs={12}>
          <div className="form-legend">Deskripsi Pengalaman</div>
          <div style={{textAlign: 'left', fontSize: 13, marginTop: 7 }}>Deskripsi tentang pengalaman yang menggambarkan proyek yang telah dilaksanakan sebelumnya. Info diharapkan akurat dan tidak menyesatkan, karena akan menjadi pertimbangan kami dalam memutuskan untuk menggalang pinjaman</div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="vd_experience_year" label="Pengalaman Properti (dalam Tahun)" obj={this} type="number"
          />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="vd_experience_unit" obj={this} type="number"
          />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyCheckboxes type="hidden" name="vd_experience_type" obj={this}
          options={[
            {k: '1', v:'Residensial'},
            {k: '2', v:'Komersial'},
          ]}
          />
      </Grid>
      <Grid item xs={12}>
        <MyTextField name="vd_experience_location" placeholder="Contoh: Jabodetabek, Palembang" obj={this}
          />
      </Grid>
    </Grid>;

    const stepperContent = this.state.isLoading ?
    <div style={{height: 500}}>
      <Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" />
    </div>
    :
    <form onSubmit={this.handleSubmit} id="form-data" autoComplete="off" name="form">
      {formContent}
      <div className="sms-modal-buttons" style={{marginTop: 55, marginBottom: 30, textAlign: 'center'}}>
        <div className="button-progress-wrapper">
          <Button disabled={this.state.buttonLoading} className="btn-stepper-next button-blue" type="submit" style={{width: 120}}>Simpan</Button>
          {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
        </div>
      </div>
    </form>;

    return (
      <div>
        <div className="bg-lightblue">
          <Navbar />
          <div className="container credential-page">
            <Paper >
              <Stepper activeStep={activeStep} alternativeLabel className="stepper-label stepper-loan">
                {stepButtons}
              </Stepper>
            </Paper>
            <Paper className="cred-area">
              <div className="stepper-content">
                {stepperContent}
              </div>
            </Paper>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
