import React, {Component} from "react";
import Select, { components as rscomponents } from 'react-select';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { formGetLabel, formIsRequired } from 'helpers';

const styles = theme => (
  {
    root: {
      flexGrow: 1
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      class: 'oflelee'
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 14,
      color: '#6b7c93'
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 14,
    },
    paper: {
      position: 'absolute',
      zIndex: 2,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
    indicatorsContainer: {
      padding: 30
    }
  }
);

const stylesFilter = theme => (
  {
    root: {
      flexGrow: 1
    },
    input: {
      display: 'flex',
      padding: 0,
      backgroundColor: '#F0F0F0',
      borderRadius: 4
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      class: 'oflelee',
      color: '#fff',
      paddingLeft: 8
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 12,
      backgroundColor: '#F0F0F0',
      paddingLeft: 8
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 14,
    },
    paper: {
      position: 'absolute',
      zIndex: 2,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
    indicatorsContainer: {
      padding: 30
    }
  }
);


function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
      >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {

  var p = props.selectProps.textFieldProps;
  var classes = {
    focused: "input-focused",
    disabled: 'input-disabled',
    error: 'input-error'
  };
  // this is to avoid console error
  if(typeof p.variant === 'undefined' || p.variant === 'standard') {
    classes.underline = 'input-underline';
  }
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
        classes: classes,
        className: 'my-form-select',
        fullWidth: true
      }}
      {...props.selectProps.textFieldProps}
      />
  );
}

function Option(props) {
  return (
    <MenuItem
      className="select-item"
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
      >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder + ' cimol'}
      {...props.innerProps}
      >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props)
{
  return <div className={props.selectProps.classes.valueContainer}>{props.children}
  </div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}
const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  // backgroundColor: colourOptions[2].color,
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};
const IndicatorSeparator = ({ innerProps }) => {
  return (
    <span style={indicatorSeparatorStyle} {...innerProps}/>
  );
};
const IndicatorsContainer = (props) => {
  return (
    <rscomponents.IndicatorsContainer className="react-select-ic" {...props}/>
  );
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator,
  IndicatorsContainer
};

class ReactSelect extends Component {

  static defaultProps = {
    // default props
    fullWidth: true,
    required: false,

    // props that needs to be taken out
    obj: null,  // the component who called this. It must have state: <name>, valid_errors, and functions handleInputChange
    shrink: false,
    options: []
  }

  render() {

    const p = this.props;

    const {obj, shrink, required, options, classes, theme, noOption, ...ptf} = p;

    const helperProps = { classes: {root:"my-form-helpertxt"}};
    const labelProps = { classes: {
      root: "my-form-label",
      focused: "label-focused",
      shrink: "label-shrink"}
    };

    classes.placeholder="my-select-placeholder";

    var sOptions = options.map(x => ({ value: x.k, label: x.v}));
    var sVal = {value: '', label: ''};
    if (obj) {
      if(!(p.name in obj.state))
      throw new Error('state ' + p.name  + ' is not declared!');

      // check value against options
      var _value = '';

      for(var i=0; i<sOptions.length; i++) {
        if(sOptions[i].value.toString() === obj.state[p.name].toString()) {
          _value = obj.state[p.name];
          sVal = sOptions[i];
          break;
        }
      }

      ptf.value = _value;

      if(typeof obj.state.validErrors[p.name] !== 'undefined') {
        ptf.helperText = obj.state.validErrors[p.name];
        ptf.error = true;
      }
    }
    ptf.label = ptf.label ? ptf.label : formGetLabel(obj, p.name);
    if (required || formIsRequired(obj, p.name)) {
      ptf.label = ptf.label + '*';
    }
    if (shrink) {
      labelProps.shrink = true;
    }

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            name={p.name}
            value={sVal}
            className="my-form-control"
            classes={classes}
            options={sOptions}
            components={components}
            textFieldProps={{
              ...ptf,
              InputLabelProps: labelProps,
              FormHelperTextProps: helperProps
            }}
            isSearchable={true}
            noOptionsMessage={({ inputValue }) => noOption ? noOption : 'Tidak ada pilihan'}
            onChange={obj => this.props.obj.handleInputChange({target: {name:this.props.name, value:obj.value}})}
            />
        </NoSsr>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ReactSelect);

const ReactSelectFilter = withStyles(stylesFilter, { withTheme: true })(ReactSelect);
export {
  ReactSelectFilter
};
