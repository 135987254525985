import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Logo_home from "styles/assets/logo-17.png";
import Logo from "styles/assets/logo-07.png";
import Sticky from 'react-sticky-el';
import { Link, NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import userHelper from 'helpers/userHelper';
import UserContainer from 'redux/container/UserContainer'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/Inbox';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import {debounceFn} from 'helpers';
// import LangToggle from './LanguageToggle';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize} from 'react-localize-redux';
import Routing from "Routing"
import DashboadMobileNav from "./DashboardMobileNav";
import { GP } from '_constant';

// Exposes the injected properties. This is to wrap hidden and NavLink wrapped menu
// const Wire = ({ children, ...props }) => children(props);

function isInPath(path, pages) {
  return pages.map(x => x.path).indexOf(path) !== -1;
}

class NavbarActual extends Component {
  constructor(props){
    super(props);
    this.state = {
      isTopWindow: true,

      // popper
      extOtherOpen: false,
      extHiwOpen: false,
      extUserOpen: false,

      // drawer
      isDrawerOpen: false
    };
    this.props.initialize({
      languages: [
        { name: 'Id', code: 'id' },
        { name: 'En', code: 'en'}
      ],
      options: { renderToStaticMarkup }
    });
  }

  handlePopperToggle = section => () => {
    var name = 'ext' + section + 'Open';
    this.setState({ [name]: !this.state[name] });
  };

  handlePopperClose = section => event => {
    var name = 'ext' + section + 'Open';
    this.setState({ [name]: false });
  };

  handleScroll = () => {

    // const isTopWindow = window.scrollY <= 600;
    const isTopWindow = (this.state.isTopWindow && window.scrollY <= 150) || (!this.state.isTopWindow && window.scrollY <= 100) ;

    // don't switch if we are in transition
    if (isTopWindow !== this.state.isTopWindow) {
      this.handlePopperClose('Other')();
      this.handlePopperClose('Hiw')();
      this.handlePopperClose('User')();
      this.setState({ isTopWindow });
    }
  }

  openDrawer = (open) => () => {
    this.setState({isDrawerOpen: open});
  }

  componentDidMount(){
    this.debounceCallScroll = debounceFn(this.handleScroll, 350);
    document.addEventListener('scroll', this.handleScroll);
    window.onresize = this.toggleTawkTo;
  }

  componentDidUpdate(prevProps, prevState) {
    this.toggleTawkTo();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  // use this to toggle tawkTo, consider it is shown first
  tawkToShown = true;
  toggleTawkTo = () => {
    var _showTalkTo = true;
    // only matters in mobile AND when TawkTo is present
    if(window.screen.width < 600) {
      var currentPath = this.props.history.location.pathname;
      var isDashboardPage = Routing.getPage(currentPath, Routing.dashboardPages);
      _showTalkTo = !isDashboardPage;
    }

    if((this.tawkToShown && !_showTalkTo) ||  (!this.tawkToShown && _showTalkTo)) {
      var t_api = typeof Tawk_API !== 'undefined'? Tawk_API: null; // eslint-disable-line no-undef
      if(t_api && typeof t_api.showWidget !== 'undefined' && typeof t_api.hideWidget !== 'undefined') {
        if(_showTalkTo) {
          t_api.showWidget();
        } else {
          t_api.hideWidget();
        }
        // only change when it is confirmed shown/hidden
        if((_showTalkTo && !t_api.isChatHidden()) || (!_showTalkTo && t_api.isChatHidden()))
        this.tawkToShown = _showTalkTo;
      }
    }
  }


  render() {
    // return (<div className="navbar"><div className="sub-navbar"></div></div>);
    const {isDrawerOpen, isTopWindow, extOtherOpen, extHiwOpen, extUserOpen} = this.state;

    const navbarClass = 'navbar ' + (isTopWindow? "" : "navbar-fixed scale-in-ver-top");
    const LogoChange = isTopWindow ? Logo : Logo_home;
    const subNavbar = "sub-navbar container";
    const btnNavbar = "button-navbar"
    // const btnNavbarBottom = "button-navbar-bottom"
    const btnLogin = 'button-login ' + (isTopWindow? "button-login-other" : "button-login-plain");
    const btnRegister = 'button-register ' + (isTopWindow? "button-register-other" : "button-register-plain");
    const btnMobileMenu = "button-hamburger " + (isTopWindow ? "button-menu":"button-menu-bottom");
    const drawerLogoClass = isDrawerOpen? "close" : "menu";

    var othersPaths = [
      {path: "/faq", label: 'FAQ'},
      {path: "/risk", label: 'Resiko'},
      {path: "/blog", label: 'Blog'},
      {path: "/appraisal", label: 'Cek Harga Rumah'},
      // {path: "/careers", label: 'Karir'},
      {path: "/about-us", label: 'Tentang Kami'}
    ]
    var hiwPaths = [
      {path: "/how-it-works", label: 'Investor'},
      {path: "/how-to-sell", label: 'House Owner'},
    ]
    var currentPath = this.props.history.location.pathname;
    var isDashboardPage = Routing.getPage(currentPath, Routing.dashboardPages);

    const loginBtnOrUserArea = userHelper.isLogin() ?
    <Grid container justify="flex-end" wrap='nowrap' className="nav-user-area">
      <Grid item xs={12} sm>
        <NavLink to="/dashboard">
          <UserContainer part="avatar" />
        </NavLink>
      </Grid>
      <Grid item>
        <Button className={btnNavbar + ' nav-user-button'}
          style={{maxWidth: 148, marginRight: 10}}
          buttonRef={node => {
            this.anchorUserEl = node;
          }}
          aria-owns={extUserOpen ? 'menu-list-grow-user' : undefined}
          aria-haspopup="true"
          onClick={this.handlePopperToggle('User')} >
          <div className="truncate"><UserContainer part="name" /></div>
          <i className="material-icons button-icon">keyboard_arrow_down</i>
        </Button>
        <Popper open={extUserOpen} anchorEl={this.anchorUserEl} transition placement="bottom-end" disablePortal={true}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow-user"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
              <Paper>
                <ClickAwayListener onClickAway={this.handlePopperClose('User')}>
                  <MenuList className="menu-other">
                    {
                      [
                        {path: "/dashboard", label: 'Dashboard', exact: true},
                        {path: "/dashboard/portfolio", label: 'Portfolio'},
                        {path: "/dashboard/transaction", label: 'Transaksi'},
                        //{path: "/dashboard/loan", label: 'Ajukan Pembiayaan', icon:<Icon>border_color</Icon>},
                        {path: "/dashboard/profile", label: 'Profil'},
                        {path: "/logout", label: 'Logout'},
                      ].map(x =>
                        <NavLink key={x.path} to={x.path} exact={x.exact}><MenuItem onClick={this.handlePopperClose('User')}>{x.label}</MenuItem></NavLink>
                      )
                    }
                  </MenuList>

                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
    :
    <Grid container>
      {/*<LangToggle />*/}
      <div className="login-position">

        <NavLink to="/login">
          <Button className={btnLogin}>Masuk</Button>
        </NavLink>
        <NavLink to="/register">
          <Button style={{marginLeft: 15}} className={btnRegister}>Daftar</Button>
        </NavLink>

      </div>
    </Grid>
    ;

    const loginBtnOrUserAreaMobile = userHelper.isLogin() ?
    <div>
      <div className="close-drawer">
        <Button onClick={() => this.openDrawer(false)}>
          <Icon>close</Icon>
        </Button>
        {/*<LangToggle />*/}
      </div>
      <NavLink to="/dashboard">
        <UserContainer part="avatar" />
      </NavLink>
      <div id="mobile-u-name">
        <UserContainer part="name" />
      </div>
      <div id="mobile-u-email">
        <UserContainer part="email" />
      </div>
    </div>
    :
    <div>
      <div className="close-drawer">
        <Button onClick={() => this.openDrawer(false)}>
          <Icon>close</Icon>
        </Button>
      </div>
      <div style={{fontSize: 13, padding: '7px 34px 13px 0'}}>
        Buka semua akses di website Provesty dengan Mendaftar/Masuk
      </div>
      <Grid container>

        <Grid item xs={12}>
          {/*<LangToggle />*/}
          <div className="login-position">
            <NavLink to="/login">
              <Button className={btnLogin}>Masuk</Button>
            </NavLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="login-position">
            <NavLink to="/register">
              <Button style={{marginLeft: 15}} className={btnRegister}>Daftar</Button>
            </NavLink>
          </div>
        </Grid>
      </Grid>
    </div>;

    const mobileDashboardMenu = userHelper.isLogin() ?
    <React.Fragment>
      <div className="sub-menu-mobile">Area User</div>
      <List>
        {
          [
            {path: "/dashboard", label: 'Dashboard', icon:<Icon>dashboard</Icon>, exact: true},
            {path: "/dashboard/portfolio", label: 'Portfolio', icon:<Icon>art_track</Icon>},
            {path: "/dashboard/transaction", label: 'Transaksi', icon:<Icon>list_alt</Icon>},
            //{path: "/dashboard/loan", label: 'Ajukan Pembiayaan', icon:<Icon>border_color</Icon>},
            {path: "/dashboard/profile", label: 'Profil', icon:<Icon>person</Icon>},
            {path: "/logout", label: 'Logout', icon:<Icon>exit_to_app</Icon>},
          ].map(x =>
            <NavLink key={x.path} to={x.path} exact={x.exact}>
              <ListItem button classes={{root: 'list-root'}}>
                <ListItemIcon>{x.icon}</ListItemIcon>
                <ListItemText primary={x.label} classes={{primary: 'list', root:'list-item-root'}} />
              </ListItem>
            </NavLink>
          )
        }
      </List>
      <Divider />
    </React.Fragment>
    :
    '';

    var mobileDrawer =
    <Drawer
      anchor="right"
      open={this.state.isDrawerOpen}
      onClose={this.openDrawer(false)}
      >
      <div
        tabIndex={0}
        role="button"
        onClick={this.openDrawer(false)}
        onKeyDown={this.openDrawer(false)}
        >
        <div id="mobile-drawer">
          <div id="user-login-mobile">
            {loginBtnOrUserAreaMobile}
          </div>
          <div id="mobile-menu">
            {
              // include dashboard menu if NOT in dashboard
              isDashboardPage ? '' : mobileDashboardMenu
            }
            <div className="sub-menu-mobile">Menu</div>
            <List>
              {
                [
                  {path: "/invest", label: 'Proyek Pendanaan', icon: <Icon>home</Icon>},
                  ...(GP.SHOW_PROHOUSE? [{path: "/prohouse", label: 'Beli Rumah Saya', icon: <Icon>store</Icon>}] : []),
                  {path: "/how-it-works", label: 'Cara Kerja', icon: <Icon>perm_data_setting</Icon>},
                  ...(GP.SHOW_PROHOUSE? [{path: "/how-to-sell", label: 'Cara Ajukan Penjualan', icon: <Icon>shop_two</Icon>}] : []),
                  {path: "/appraisal", label: 'Cek Harga Rumah', icon: <Icon>touch_app</Icon>},
                  {path: "/about-us", label: 'Tentang Kami', icon: <Icon>group</Icon>},
                  {path: "/faq", label: 'FAQ', icon: <Icon>help_outline</Icon>},
                  {path: "/risk", label: 'Risk', icon: <Icon>warning</Icon>},
                  {path: "/blog", label: 'Blog', icon: <Icon>speaker_notes</Icon>},
                  // {path: "/careers", label: 'Karir', icon: <Icon>computer</Icon>}
                  // {path: "/dummy", label: 'Lainnya', icon: <InboxIcon />},
                ].map(x => {
                  return (
                    <NavLink key={x.path} to={x.path}>
                      <ListItem button classes={{root: 'list-root'}}>
                        <ListItemIcon>{x.icon}</ListItemIcon>
                        <ListItemText primary={x.label} classes={{primary: 'list', root:'list-item-root'}} />
                      </ListItem>
                    </NavLink>
                  )}
                )
              }
            </List>
          </div>
        </div>
      </div>
    </Drawer>;

    const navbar =
    <div className={navbarClass}>
      <div className={subNavbar}>
        <Grid container alignItems="center">
          <Grid item xs="auto">
            <Link id="link-logo" to="/"><img className="img-logo" src={LogoChange} alt="provesty_logo" /></Link>
          </Grid>
          <Hidden smDown>
            <Grid item md>
              <Grid container justify="center">
                {
                  [
                    {path: "/invest", label: 'Proyek Pendanaan'},
                    ...(GP.SHOW_PROHOUSE ? [{path: "/prohouse", label: 'Beli Rumah Saya'}] : []),
                    {path: "/how-it-works", label: 'Cara Kerja'},
                    {path: "/dummy", label: 'Lainnya'},
                  ].map(x => {
                    if (x.label === 'Lainnya') {
                      return (
                        <React.Fragment key={x.path}>
                          <Button className={btnNavbar + (isInPath(currentPath, othersPaths)? ' contain-active' : '')}
                            buttonRef={node => {
                              this.anchorEl = node;
                            }}
                            aria-owns={extOtherOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handlePopperToggle('Other')}>
                            {x.label} <i className="material-icons button-icon">keyboard_arrow_down</i></Button>
                          <Popper open={extOtherOpen} anchorEl={this.anchorEl} transition disablePortal placement="bottom-end">
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                id="menu-list-grow"
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                <Paper>
                                  <ClickAwayListener onClickAway={this.handlePopperClose('Other')}>
                                    <MenuList className="menu-other">
                                      {
                                        othersPaths.map(x => {
                                          return (
                                            <NavLink key={x.path} to={x.path}>
                                              <MenuItem onClick={this.handlePopperClose('Other')}>{x.label}</MenuItem>
                                            </NavLink>
                                          )
                                        })
                                      }
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </React.Fragment>
                      )
                    } else if (x.label === 'Cara Kerja' && GP.SHOW_PROHOUSE) {
                      return (
                        <React.Fragment key={x.path}>
                          <Button className={btnNavbar + (isInPath(currentPath, hiwPaths)? ' contain-active' : '')}
                            buttonRef={node => {
                              this.anchorHiwEl = node;
                            }}
                            aria-owns={extHiwOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handlePopperToggle('Hiw')}>
                            {x.label} <i className="material-icons button-icon">keyboard_arrow_down</i></Button>
                          <Popper open={extHiwOpen} anchorEl={this.anchorHiwEl} transition disablePortal placement="bottom-end">
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                id="menu-list-grow"
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                <Paper>
                                  <ClickAwayListener onClickAway={this.handlePopperClose('Hiw')}>
                                    <MenuList className="menu-other">
                                      {
                                        hiwPaths.map(x => {
                                          return (
                                            <NavLink key={x.path} to={x.path}>
                                              <MenuItem onClick={this.handlePopperClose('Hiw')}>{x.label}</MenuItem>
                                            </NavLink>
                                          )
                                        })
                                      }
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <NavLink key={x.path} to={x.path}>
                          <Button className={btnNavbar}>{x.label}</Button>
                        </NavLink>
                      )
                    }
                  })
                }
              </Grid>
            </Grid>
            {/*user/login area on desktop */}
            <Grid item md='auto'>
              {loginBtnOrUserArea}
            </Grid>
          </Hidden>
          {/*mobile toggle*/}
          <Hidden mdUp>
            <Grid item xs={true}>
              <div className="menu-position">
                <button className={btnMobileMenu} onClick={this.openDrawer(true)}>
                  <Icon className="md-32">{drawerLogoClass}</Icon>
                  <div >MENU</div>
                </button>
              </div>
            </Grid>
            {mobileDrawer}
          </Hidden>
        </Grid>
      </div>
    </div>;

    const Sticked = isTopWindow ? <div>{navbar}</div> : <Sticky>{navbar}</Sticky>;

    return (
      <React.Fragment>
        <div style={{zIndex: 101, position:'relative'}}>
          {Sticked}
        </div>
        <Hidden smUp>
          {
            isDashboardPage? <DashboadMobileNav history={this.props.history} /> : ''
          }
        </Hidden>
      </React.Fragment>
    );
  }

}

export default withLocalize(NavbarActual);
