import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Digi from 'styles/assets/about-us/digiasia.png';
import KreditPro from 'styles/assets/about-us/kreditpro.png';


export default class OurPartner extends Component {
  render(){
    return(
      <div className="our-partner container">
        <div className="partner-title">Partner Kami</div>
        <div className="partner-content">Beberapa partner yang bekerja sama dengan Kami</div>
        <div className="ta-center">
        <Grid container>
          <Grid item lg={3} md={3} />
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <img src={KreditPro} alt="partner" style={{marginTop: 20}}/>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <img src={Digi} alt="partner" style={{marginTop: 20}}/>
          </Grid>
        </Grid>
        </div>
      </div>
    )
  }
}
