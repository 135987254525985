import React, {Component} from "react";
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { formGetLabel, formIsRequired } from 'helpers';


export default class MyCheckboxes extends Component {

  static propTypes = {
    obj: PropTypes.shape({
      state: PropTypes.shape({
        validErrors:  PropTypes.object.isRequired
      }).isRequired,
      handleInputChange: PropTypes.func.isRequired,
    }).isRequired
  }

  static defaultProps = {
    obj: null,
    options: [] // format [{k: a, v: b}, ...]
  }

  processEvent = (e, checked, index, name, obj, optionsMap) => {

    optionsMap[index].checked = checked;
    var value = optionsMap.filter(x => x.checked).map(x => x.k).join(',');
    obj.handleInputChange({target: {name, value}});
  }

  render() {

    const p = this.props;

    const fc_class = {
      root:"my-form-control"
    };
    const labelProps = { classes: {
      root: "my-form-label",
      focused: "label-focused",
      shrink: "label-shrink"
    }};

    const {obj, options, name, label} = p;

    if(!(name in obj.state))
    throw new Error('state ' + name  + ' is not declared!');

    if(typeof obj.state[name] !== 'string')
    throw new Error('state ' + name  + ' must be string!');

    // check value against options
    var currentValArr = obj.state[name].split(',').filter(x => x !== '');
    var actualValArr = [];
    var optionsMap = options.map(x => Object.assign(x, {checked: false}));
    for(var i=0; i<options.length; i++) {
      if(currentValArr.indexOf(options[i].k) !== -1) {
        actualValArr.push(options[i].k);
        optionsMap[i].checked = true;
      }
    }
    var actualVal = actualValArr.join(',');
    var checkboxes = options.map((x, index) =>
    {
      return(
        <FormControlLabel
          key={x.k}
          label={x.v}
          control={
            <Checkbox
              value={x.k}
              checked={actualValArr.indexOf(x.k) !== -1}
              onChange={(e, checked) => this.processEvent(e, checked, index, name, obj, optionsMap)}
              classes={{root: 'my-radio', checked: 'radio-checked'}} />
          }
          classes={{root: 'cb-root', label: "myTheme"}}
          />
      )
    }
  );

  var helperText = '';
  var formGrpClass = '';
  if(typeof obj.state.validErrors[name] !== 'undefined') {
    helperText = <FormHelperText className="my-form-helpertxt error">{obj.state.validErrors[name]}
    </FormHelperText>
    formGrpClass += ' input-error'
  }

  var _label = label ? label : formGetLabel(obj, name);
  if(_label && (p.required || formIsRequired(obj, name)))
  _label += '*';

  return (
    <FormControl fullWidth classes={fc_class}>
      <input type="hidden" name={name} value={actualVal} />
      <InputLabel shrink {...labelProps}>{_label}</InputLabel>
      <FormGroup row className={formGrpClass}>
        {checkboxes}
      </FormGroup>
      {helperText}
    </FormControl>
  );
}
}
