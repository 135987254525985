// actions types
export const SET_LISTING = `SET_LISTING`
export const LOAD_LISTING = `LOAD_LISTING`
export const SET_LOGIN_MSG = `SET_LOGIN_MSG`
export const SET_MESSAGE = `SET_MESSAGE`
export const CLOSE_MESSAGE = `CLOSE_MESSAGE`
export const SET_USER = 'SET_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const SET_UNREAD = 'SET_UNREAD'

// OBSOLETE, use the imgPath function in general helper to derive the backend location
// export const HEADERS = {
//   URL: process.env.NODE_ENV === 'production' ? "https://api.provesty.com/" : "https://provesty.test/"
// URL: "https://test.provesty.com/"
// }

export const GP = { // general param for the application
  SHOW_PROHOUSE: false
}

export const APISTAT = {
  SUCCESS: 0,
  ERR_GENERIC: 1,
  ERR_USER_INPUT: 7,
  ERR_INVALID_REST_METHOD: 126,
  ERR_EXPIRED_LOGIN: 127, // used only in SEPI
  ERR_USER_CREDENTIAL: 128,
  ERR_UNAUTHORIZED: 129,
  ERR_ACCESS_NOT_RECOGNIZED: 130 // used only in SEPI
}

export const PROPSTATUS = {
  PROPSTAT_OPEN: 'Open',
  PROPSTAT_FULL: 'Full',
  PROPSTAT_ACTIVE: 'Active',
  PROPSTAT_ACTIVE_PROCESSING: 'Processing', // derivation from active.. imply that all payments are verified but project has not started
  PROPSTAT_COMPLETED: 'Completed',
  PROPSTAT_EXPIRED: 'Expired'
}


export const SEX = {
  MALE: 'Male',
  FEMALE: 'Female'
}

export const IVSTAT = {
  PENDING: 'Pending',
  REGISTERED: 'Registered',
  ACTIVE: 'Active',
  PROCESSING: 'Processing', // derivation from active.. imply that all payments are verified but project has not started
  COMPLETED: 'Completed',
  EXPIRED: 'Expired',
  REFUNDED: 'Refunded',
  TRANSFERRED: 'Transferred'
}

export const STAT = {
  STAT_YES: 'Yes',
  STAT_NO: 'No',
  STAT_PENDING: 'Pending',
  STAT_APPROVED: 'Approved',
  STAT_REJECTED: 'Rejected',
  STAT_PAID: 'Paid',
  STAT_SETTLED: 'Settled',
  STAT_CHECK: 'Need Check'
}

export const VDTYPE = {
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company'
}

export const PAYDIR = {
  PAY_IN: 'In',
  PAY_OUT: 'Out',
  PAY_REFUND: 'Refund',
  PAY_REINVEST: 'Reinvest',
  PAY_INVEST: 'Invest',
  PAY_TOPUP: 'TopUp',
  PAY_TRANSFER: 'Transfer'
}

export const PAYSTAT = {
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  EXPIRED: 'Expired'
}

export const PJSTAT = {
  REVIEW: 'Review',
  SURVEY: 'Survey',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
}

export const NUMBERS = {
  // the minimum of transfer from user. This is set because we might need to pay for user transfer (VA)
  MIN_TRANSFER_AMOUNT: 50000
}
// for dataHelper
export const DATAVAR = {
  INVEST: 'invest', // {iv_slot, py_amount_reinvest, id_bank_account_dst, pro_slug}
  PAGEOUT: 'pageout',
  REDIRECT_LOGIN: 'redirect_login'
}

export const UNSUB = {
  UNSUB_NEW_LISTING: 0
}

export const PTYPE = {
  RUMAH: 'Rumah',
  APT: 'Apartemen',
  TANAH: 'Tanah',
  RUKO: 'Ruko'
}

export const COLORS = {
  TEXT_DEF: '#6b7c93',
  TEXT_STRONG: '#1f5fae',
  BTN_BLUE: '#3d7dc8',
  BG_LIGHTBLUE: '#F1F8FF',
  BG_DARKBLUE: '#0E57A0',
  BG_GRAB_ATTN: '#FFF98C',
  SUCCESS: '#049E51'
}
