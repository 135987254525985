import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { price_d2r } from 'helpers';

function PaymentTopupDetail({isLoading, pt = {}}) {

  return(
    <Paper className="soft-paper">
      <div className="paper-title">
        Detail Pembayaran
      </div>
      <div className="paper-body">
        <Grid container spacing={24}>
          <Grid item xs={6} sm={8}>
            Jumlah TopUp
          </Grid>
          <Grid item xs={6} sm={4} className="paper-value">
            { price_d2r(pt.py_amount) },-
          </Grid>
          <Grid item xs={6} sm={8}>
            Biaya Admin
          </Grid>
          <Grid item xs={6} sm={4} className="paper-value">
            { price_d2r('') },-
          </Grid>
        </Grid>
      </div>

      <div className="paper-body" style={{ backgroundColor: '#fbfbfb'}}>
        <Grid container spacing={24} alignItems="center" >
          <Grid item xs={6} sm={8}>
            Total
          </Grid>
          <Grid item xs={6} sm={4} className="paper-value">
            { price_d2r(pt.py_amount) },-
          </Grid>
        </Grid>
      </div>

    </Paper>
  )
}


export default PaymentTopupDetail;
