import React from "react";
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Default from "styles/assets/default-image.png";
import { imgPath } from 'helpers';

export default class MyUploadImage extends React.Component {

  static defaultProps = {
    // default props
    name: '',
    label: '',
    required: false,
    defaultImg: '',
    ImgProps: {},
    ButtonProps: {},
    buttonContent: '',

    // props that needs to be taken out
    obj: null,  // the component who called this. It must have state: <name>, valid_errors, and functions handleInputChange

  }

  constructor(props){
    super(props)
    this.state = {
      file: null,
    }
  }

  initImg = '';

  handleReadingImg = (upload) => {
    this.props.obj.handleInputChange({target: {name: this.props.name, value: upload.target.result }});
  }
  handleFileChange = (event) => {
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = this.handleReadingImg;
    reader.readAsDataURL(file);
  }

  componentDidUpdate() {

    var currentVal = this.props.obj.state[this.props.name];
    if(currentVal && !currentVal.startsWith('data')) {
      // still image path, reset after backup
      this.initImg = currentVal;
      this.props.obj.handleInputChange({target: {name: this.props.name, value: ''}});

      // check if init state of this field is stated
      if(typeof this.props.obj.state[this.props.name + '_init'] !== 'undefined')
      this.props.obj.handleInputChange({target: {name: this.props.name + '_init', value: currentVal}});
    }
  }


  render() {

    var p = this.props;
    const {obj, name} = p; // separate extra props

    if(typeof obj.state[name] === 'undefined')
    throw new Error('state ' + name + ' is not declared!');

    var value = obj.state[name];
    var imgSrc = '';
    if (value === '') {
      // empty value
      if (this.initImg !== '') {
        imgSrc = imgPath(this.initImg);
      } else if (this.props.defaultImg !== '') {
        imgSrc = this.props.defaultImg;
      } else {
        imgSrc = Default;
      }
    } else {
      if(value.startsWith('data')) {
        imgSrc = value;
      } else {
        // still in the form of image path
        imgSrc = imgPath(value);
        value = '';
      }
    }

    var helperText = '';
    var imgClass = '';
    if(typeof obj.state.validErrors[name] !== 'undefined') {
      helperText = <FormHelperText className="my-form-helpertxt error">{obj.state.validErrors[name]}
      </FormHelperText>
      imgClass = ' img-error input-error';
    }

    return (
      <div className="my-form-image">
        <div>
          <img src={imgSrc} {...this.props.ImgProps} className={'img-responsive' + imgClass} alt="Upload" />
        </div>
        <input type="hidden" name={p.name} value={value} />

        <Button variant="contained" component="label" {...this.props.ButtonProps}>
          {this.props.buttonContent? this.props.buttonContent: 'Upload File'}
          <input type="file" style={{display:'none'}} onChange={ this.handleFileChange } accept="image/*" />
        </Button>
        {helperText}
      </div>
    );
  }
}
