import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import { apiHelper, dispatchMsg, formVal, formScrollToError, userHelper, dataHelper, price_d2r } from 'helpers';
import { APISTAT, DATAVAR, PTYPE } from '_constant';
import MyTextField from "components/general/MyTextField";
import ReactSelect from "components/general/ReactSelectOutline";
import Grid from '@material-ui/core/Grid';
import AptTarget from 'styles/assets/appraisal/apt_target.png';
import AptIcon from 'styles/assets/appraisal/apt_icon.png';
import AptComp from 'styles/assets/appraisal/apt_comp.png';
import HouseIcon from 'styles/assets/appraisal/house_icon.png';
import HouseTarget from 'styles/assets/appraisal/house_target.png';
import HouseComp from 'styles/assets/appraisal/house_comp.png';
import LandTarget from 'styles/assets/appraisal/land_target.png';
import LandIcon from 'styles/assets/appraisal/land_icon.png';
import LandComp from 'styles/assets/appraisal/land_comp.png';
import RukoTarget from 'styles/assets/appraisal/ruko_target.png';
import RukoIcon from 'styles/assets/appraisal/ruko_icon.png';
import RukoComp from 'styles/assets/appraisal/ruko_comp.png';
import PreloadingIcon from 'styles/assets/preloader.gif'
import SearchSample from 'styles/assets/appraisal/search_sample.png';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

function floatComp(str) {
  return parseFloat(parseFloat(str).toFixed(9));
}

function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}

class AppraisalEngine extends Component {

  static defaultProps = { // default map
    center: { // provesty office
      lat: -6.227343,
      lng: 106.833375
    },
    zoom: 16
  };

  constructor(props) {
    super(props);

    this.state = {
      targetLat: -6.227343,
      targetLng: 106.833375,
      land_area: '',
      build_area: '',
      property_type: PTYPE.RUMAH,

      land_area_disabled: false,
      build_area_disabled: false,

      isLoading: false,
      targetPrice: 'Klik/Cari lokasi untuk memulai',

      validErrors: {}
    }

    this.markers = [];
    this.markerListeners = [];
    this.all_overlays = [];
  }

  componentDidMount() {

  }

  componentWillUnmount() {

    apiHelper.cancelAllRequest(this);

    if(typeof this.maps != 'undefined') {
      this.maps.event.clearInstanceListeners(this.searchBox); // eslint-disable-line no-undef
      this.maps.event.removeListener(this.listenerPlaceChange);
      this.maps.event.removeListener(this.listenerClick);
      this.maps.event.removeListener(this.listenerOverlay);
    }
  }

  handleInputChange = event => {

    var name = event.target.name;
    var val = event.target.value;

    let newState = { [name]: val };

    // disabled the unnecessary field
    if(name === 'property_type') {
      newState['land_area_disabled'] = (val === PTYPE.APT);
      newState['build_area_disabled'] = (val === PTYPE.TANAH);
      newState['targetPrice'] = ''; // empty the price as well
    }

    this.setState(newState);

  };

  validate = () => {

    this.errors = {};

    let type = this.state.property_type;

    formVal(this, 'property_type', 'Tipe Properti', 'required');
    if(type === PTYPE.RUMAH || type === PTYPE.APT || type === PTYPE.RUKO)
    formVal(this, 'build_area', 'Luas Bangunan', 'requiredNumber');

    if(type === PTYPE.RUMAH || type === PTYPE.TANAH || type === PTYPE.RUKO)
    formVal(this, 'land_area', 'Luas Tanah', 'requiredNumber');

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }


  loadData = () => {

    if(!this.validate()) {
      this.setState({ isLoading: false, targetPrice: 'Isi dulu luas tanah/bangunan' });
      return;
    }

    this.setState({ isLoading:true });

    const { targetLat, targetLng, property_type, land_area, build_area} = this.state;

    // change the url so it can be shared
    let newUrl = window.location.href;
    let params = {
      coord: targetLat + ',' + targetLng,
      lt: land_area,
      lb: build_area,
      tipe: property_type
    };
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        newUrl = updateQueryStringParameter(newUrl, key, params[key]);
      }
    }
    window.history.replaceState(null, document.getElementsByTagName("title")[0].innerHTML, newUrl);

    // cancel previous request if necessary
    apiHelper.cancelAllRequest(this);

    // since poly require sending in 2d array, this needs to be done manually
    var data = new FormData();
    data.append('coord', targetLat + ', ' + targetLng);
    data.append('land_area', land_area);
    data.append('build_area', build_area);
    data.append('property_type', property_type);

    if (this.all_overlays.length)
    this.all_overlays[0].overlay.getPath().getArray().forEach(function (item, index) {
      data.append('poly[' + index + '][]', item.lat());
      data.append('poly[' + index + '][]', item.lng());
    });

    // fire event for proengine search here to record conversion
    if(window.dataLayer) {
      window.dataLayer.push({'event':'ProEngine Search'});
      console.log('ProEngine Search');
    }
    else {
      console.log('window.dataLayer Not Detected');
    }

    apiHelper.request(this, {
      resource: 'appraise', // use the secured one if loggedin
      data: data,
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {

          let data = resp.data;

          this.clearAllMarkers(); // clear first before adding

          // consolidate markers with same coordinates
          var _coords = {};
          for (let i = 0; i < data.comps.length; i++) {
            var item = data.comps[i];
            var _c = item.lat + ',' + item.lng;
            if (_coords.hasOwnProperty(_c))
            _coords[_c].push(item);
            else
            _coords[_c] = [item];
          }

          // set bound for better viewing
          var center = {lat: data.lat, lng: data.lng};
          let bounds =  new google.maps.LatLngBounds(); // eslint-disable-line no-undef
          bounds.extend(center);
          for (let i = 0; i < resp.data.comps.length; i++) {
            let item = data.comps[i];
            bounds.extend(new google.maps.LatLng(item.lat, item.lng)); // eslint-disable-line no-undef
          }
          this.map.fitBounds(bounds);

          // finally add the markers
          this.addMarkers(_coords);

          let targetPrice = price_d2r(parseInt(resp.data.result), true);

          if(property_type === PTYPE.TANAH) {
            targetPrice += ' (' + price_d2r(parseInt(resp.data.result/parseInt(land_area)), true) +'/m2)';
          }

          this.setState({ isLoading: false, targetPrice: targetPrice });
        } else if (resp.status === APISTAT.ERR_GENERIC) {
          this.clearAllMarkers(); // clear all marker
          this.setState({ isLoading: false,
            targetPrice:
            <Grid container direction="row" alignItems="center" wrap="nowrap">
              <Grid item><Icon style={{ fontSize: 15, display: 'block', color: 'red', marginRight: 4 }}>error_outlined</Icon></Grid>
              <Grid item style={{ color: 'grey'}}>{resp.msg}</Grid>
            </Grid>});
          } else {
            throw new Error(resp.msg);
          }
        },
        fnError: err => {
          dispatchMsg('error', err.message);
          this.clearAllMarkers();
          this.setState({ isLoading: false, targetPrice: '' });
        }
      });
    }

    // coords is an object
    // for dramatic effect we're going to put the marker one by one
    addMarkers = coords => {
      // now add in the markers

      let { property_type } = this.state;
      let i=0;
      for (var key in coords) {
        if (coords.hasOwnProperty(key)) {
          var coord = coords[key];
          (function(obj, j, k, _coord) { // eslint-disable-line no-loop-func
            window.setTimeout(
              () => {  // eslint-disable-line no-loop-func
                var c = k.split(',');

                // make 2 versions for title and info
                let _strInfo = '';
                let _strTitle = '';
                if(_coord.length === 1) {
                  if(property_type === PTYPE.APT) {
                    _strInfo += 'LB ' + _coord[0].build_area + ': ';
                  } else if(property_type === PTYPE.TANAH) {
                    _strInfo += 'LT ' + _coord[0].land_area + ': ';
                  } else {
                    _strInfo += 'LT/LB ' + _coord[0].land_area + '/' +  _coord[0].build_area + ': ';
                  }

                  let _strPrice = '<span style="font-weight:bold">' +  price_d2r(_coord[0].price, true) + '</span>';

                  if(property_type === PTYPE.TANAH) { // show per m2 for tanah
                    _strPrice += ' <span style="font-size:12px">(' + price_d2r(parseInt(_coord[0].price/_coord[0].land_area), true) + ' /m2)</span>';
                  }

                  if (typeof _coord[0].url != 'undefined') {
                    _strPrice = '<a href="'+ _coord[0].url + '" target="_blank">' +
                    _strPrice + '</a>';
                  }
                  _strInfo += _strPrice;

                  if(property_type === PTYPE.APT) {
                    _strTitle += 'LB ' +  _coord[0].build_area + ': ' +  price_d2r(_coord[0].price, true);
                  } else if(property_type === PTYPE.TANAH) {
                    _strTitle += 'LT ' + _coord[0].land_area + ': ' +  price_d2r(_coord[0].price, true);
                  } else {
                    _strTitle += 'LT/LB ' + _coord[0].land_area + '/' +  _coord[0].build_area + ': ' +  price_d2r(_coord[0].price, true);
                  }
                } else {
                  _strInfo += _coord.length + ' ' + property_type + ':';
                  _strInfo += '<ul style="padding: 5px 15px 0; margin: 0px 0;">';
                  let _strTitleArr = [];
                  for(let k=0; k < _coord.length; k++) {

                    if(property_type === PTYPE.APT) {
                      _strInfo += '<li>LB ' + _coord[k].build_area + ': ';
                    } else if(property_type === PTYPE.TANAH) {
                      _strInfo += '<li>LT ' + _coord[k].land_area + ': ';
                    } else {
                      _strInfo += '<li>LT/LB ' + _coord[k].land_area + '/' +  _coord[k].build_area + ': ';
                    }

                    let _strPrice = '<span style="font-weight:bold">' +  price_d2r(_coord[k].price, true) + '</span>';

                    if(property_type === PTYPE.TANAH) { // show per m2 for tanah
                      _strPrice += ' <span style="font-size:12px">(' + price_d2r(parseInt(_coord[k].price/_coord[k].land_area), true) + ' /m2)</span>';
                    }

                    _strPrice += '</li>';
                    if (typeof _coord[k].url != 'undefined') {
                      _strPrice = '<a href="'+ _coord[k].url + '" target="_blank">' +
                      _strPrice + '</a>';
                    }
                    _strInfo += _strPrice;

                    if(property_type === PTYPE.APT) {
                      _strTitleArr.push('LB ' +  _coord[k].build_area + ': ' +  price_d2r(_coord[k].price, true));
                    } else if(property_type === PTYPE.TANAH) {
                      _strTitleArr.push('LT ' + _coord[k].land_area + ': ' +  price_d2r(_coord[k].price, true));
                    } else {
                      _strTitleArr.push('LT/LB ' + _coord[k].land_area + '/' +  _coord[k].build_area + ': ' +  price_d2r(_coord[k].price, true));
                    }
                  }
                  _strInfo += '</ul>';
                  _strTitle = _strTitleArr.join(', ');
                }


                let compMarker = '';
                switch(property_type) {
                  case PTYPE.APT:
                  compMarker = AptComp;
                  break;
                  case PTYPE.RUKO:
                  compMarker = RukoComp;
                  break;
                  case PTYPE.TANAH:
                  compMarker = LandComp;
                  break;
                  default: // PTYPE.RUMAH
                  compMarker = HouseComp;
                  break;
                }


                let _m = new google.maps.Marker({ // eslint-disable-line no-undef
                  position: {lat: parseFloat(c[0]), lng: parseFloat(c[1])},
                  map: obj.map,
                  icon: {
                    url: compMarker,
                    scaledSize: new google.maps.Size(35, 40), // eslint-disable-line no-undef
                    // anchor: new google.maps.Point(30, 30), // eslint-disable-line no-undef
                  },

                  label: _coord.length === 1 ? null : '' + _coord.length,
                  animation: google.maps.Animation.DROP, // eslint-disable-line no-undef
                  title: _strTitle
                });


                let infowindow = new google.maps.InfoWindow({// eslint-disable-line no-undef
                  content:'<div style="font-size: 13px; color: black;">' + _strInfo + '</div>'
                });

                let _listener = google.maps.event.addListener(_m, 'click', // eslint-disable-line no-undef
                function() {
                  // close all info windows before opening this one
                  infowindow.open(obj.map, _m);
                });

                obj.markers.push(_m);
                obj.markerListeners.push(_listener);
              }, j * 100);
            }
          )(this, i++, key, coord);
        }
      }
    }

    redirectTo = targetUrl => ev => {
      dataHelper.set(DATAVAR.REDIRECT_LOGIN, this.props.history.location.pathname);
      this.props.history.push(targetUrl);
    }

    clearAllShape = () => {
      for (let i = 0; i < this.all_overlays.length; i++)
      {
        this.all_overlays[i].overlay.setMap(null);
      }
      this.all_overlays = [];
    }

    clearAllMarkers = () => {
      // remove all previous comps marker
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
        google.maps.event.removeListener(this.markerListeners[i]); // eslint-disable-line no-undef
      }
      this.markers.length = 0;
      this.markerListeners.length = 0;
    }

    _onPlacesChanged = () => {

      let searchBox = this.searchBox;
      let map = this.map;

      // delete previous polygon if exist
      this.clearAllShape();

      searchBox.set('map', null);

      var places = searchBox.getPlaces();

      if(places.length > 1) // user don't search specific place, just return
      return;

      var bounds = new google.maps.LatLngBounds(); // eslint-disable-line no-undef
      var place = places[0];

      let _lat = floatComp(place.geometry.location.lat());
      let _lng = floatComp(place.geometry.location.lng());

      // $('[name="coord"]').val(_lat + ', ' + _lng);
      // targetMarker.setPosition(place.geometry.location);
      bounds.extend(place.geometry.location);

      // adjust target position
      this.setState({
        targetLat: _lat,
        targetLng: _lng,
      }, this.loadData);

      map.fitBounds(bounds);
      searchBox.set('map', map);
      map.setZoom(Math.min(map.getZoom(), 17));

    }

    _onMapClick = (event) => {

      // delete previous polygon if exist
      this.clearAllShape();

      // move target position, and calculate
      this.setState({
        targetLat: floatComp(event.latLng.lat()),
        targetLng: floatComp(event.latLng.lng()),
        isLoading: true
      }, this.loadData);

      // pan to the new location
      this.map.panTo(event.latLng);
    }

    _onOverlayComplete = (event) => {
      // delete previous polygon if exist
      this.clearAllShape();

      // add the new one
      this.all_overlays.push(event);

      // now calculate
      this.loadData();
    }

    handleApiLoaded = (map, maps) => {
      // connect search box
      var input = ReactDOM.findDOMNode(this.refs.input);
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(input); // eslint-disable-line no-undef
      this.searchBox = new google.maps.places.SearchBox(input); // eslint-disable-line no-undef

      // add drawing manager for drawing boundaries
      var drawingManager = new google.maps.drawing.DrawingManager({ // eslint-disable-line no-undef
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT, // eslint-disable-line no-undef
          drawingModes: ['polygon']
        },
      });
      drawingManager.setMap(map);

      // Add listener
      this.listenerPlaceChange = maps.event.addListener(this.searchBox, 'places_changed', this._onPlacesChanged);
      this.listenerClick = maps.event.addListener(map, 'click', this._onMapClick);
      // subscribe where all been drawn
      this.listenerOverlay = maps.event.addListener(drawingManager, 'overlaycomplete', this._onOverlayComplete);

      // assign
      this.map = map;
      this.maps = maps;

      // check if the url has already the parameters
      let url = new URL(window.location.href);
      let coord = url.searchParams.get("coord");
      let lt = url.searchParams.get("lt");
      let lb = url.searchParams.get("lb");
      let tipe = url.searchParams.get("tipe");

      if(coord && tipe && (lt || tipe === PTYPE.APT)  && (lb || tipe === PTYPE.TANAH) ) {
        let cs = coord.split(',');
        if(cs.length === 2) {
          let lat = floatComp(cs[0]);
          let lng = floatComp(cs[1]);
          // pan to the new location
          this.map.panTo(new google.maps.LatLng(lat, lng)); // eslint-disable-line no-undef
          this.setState({
            property_type: tipe,
            land_area: lt,
            build_area: lb,
            targetLat: lat,
            targetLng: lng,
            land_area_disabled: (tipe === PTYPE.APT),
            build_area_disabled: (tipe === PTYPE.TANAH)
          }, this.loadData);
        }
      }

    };

    render(){

      const { classes } = this.props;
      const { targetLat, targetLng, isLoading, targetPrice, land_area_disabled, build_area_disabled, property_type} = this.state;


      if(!userHelper.isLogin()) {
        return (
          <React.Fragment>
            <div className="container form-content ta-center" style={{marginBottom: 10}}>
              <h3>Coba ProEngine</h3>
              <div className="container">
                Masuk/Daftar terlebih dahulu ya untuk menggunakan ProEngine :)
                <div className="please-login">

                  <div className="login-button-detail">
                    <Button onClick={this.redirectTo('/login')} className="button-login button-login-other button-login-detail button-white">Masuk</Button>
                    <Button onClick={this.redirectTo('/register')} className="button-register button-register-other button-register-detail button-blue">Daftar</Button>

                  </div>

                  <img src={SearchSample} alt="contoh pencarian" className="img-responsive" style={{marginTop: 25, border: '1px solid black'}}/>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }

      let targetMarker = '';
      switch(property_type) {
        case PTYPE.APT:
        targetMarker = AptTarget;
        break;
        case PTYPE.RUKO:
        targetMarker = RukoTarget;
        break;
        case PTYPE.TANAH:
        targetMarker = LandTarget;
        break;
        default: // PTYPE.RUMAH
        targetMarker = HouseTarget;
        break;
      }

      const TargetMarker = props => {
        return (
          <div>
            {
              isLoading ?
              <img className={classes.loadingIcon} src={PreloadingIcon} alt="loading" />
              : (
                targetPrice === ''? '' :
                <div className={classes.bubble}>{targetPrice}</div>

              )
            }
            <img
              src={targetMarker} alt="target"
              className={classes.marker}
              width={42}
              text="Appraised Property"
              />
          </div>
        );
      }

      return(
        <React.Fragment>

          <div className="container form-content ta-center" style={{marginBottom: 10}}>
            <h3>Coba ProEngine</h3>
            <Grid container spacing={16} className="" justify="center">
              <Grid item xs={12} sm="auto">
                <div className="auto-field-1">
                  <ReactSelect name="property_type" label="Tipe Properti" obj={this}  variant="outlined"
                    options={
                      [
                        { k: 'Rumah', i: HouseIcon},
                        { k: 'Apartemen', i: AptIcon},
                        { k: 'Ruko', i: RukoIcon},
                        { k: 'Tanah', i: LandIcon}
                      ].map(x => ({
                        k: x.k,
                        v: <React.Fragment>
                        <img src={x.i} alt={x.k} style={{width: 21,
                            position: 'absolute', top: 6}} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{x.k}
                          </React.Fragment>
                        })
                      )}
                      />
                  </div>
                </Grid>
                <Grid item xs={6} sm="auto">
                  <div className="auto-field-1">
                    <MyTextField name="land_area" obj={this} variant="outlined" type="tel" label="Luas Tanah" disabled={land_area_disabled} />
                  </div>
                </Grid>
                <Grid item xs={6} sm="auto">
                  <div className="auto-field-1">
                    <MyTextField name="build_area" obj={this} variant="outlined" type="tel" label="Luas Bangunan" disabled={build_area_disabled}/>
                  </div>
                </Grid>
              </Grid>
            </div>

            {/*map canvas*/}
            <div style={{ height: '70vh', width: '95%', margin:'auto', position: 'relative'}}>
              <div style={{display: 'none'}}>
                <input type="text" ref="input" name="search" id="pac-input" className={classes.inputSearch} placeholder="Cari Lokasi" />
              </div>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyBFx0Kkpse6IV8SMw7CrzP4RfEAOAC9zKQ',
                  language: 'id',
                  region: 'id',
                  libraries:['places', 'drawing']
                }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                options={ (maps) => {
                  return {
                    scaleControl: true,
                  }
                }}
                >
                <TargetMarker
                  lat={targetLat}
                  lng={targetLng}
                  />
              </GoogleMapReact>
            </div>
          </React.Fragment>
        )
      }
    }

    const styles = theme => ({
      inputSearch: {
        width: '55%',
        maxWidth: 270,
        backgroundColor: '#f5f591dd',
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        zIndex: 10,
        fontSize: 13,
        padding: '7px 10px',
        margin: 5,
        fontFamily: '"Montserrat", sans-serif'
      },
      marker: {
        position: 'absolute',
        transform: 'translate(-50%, -100%)'
      },
      bubble: {
        marginBottom: -28,
        position: 'absolute',
        top: -75,
        left: -18,
        padding: '3px 5px',
        borderRadius: 5,
        border: 'solid 2px #1D5D91',
        fontSize: 13,
        color: '#222',
        backgroundColor: 'white',
        whiteSpace: 'nowrap',
        animationName: 'palio',
        animationDuration: 4
      },
      loadingIcon: {
        width: 66,
        position: 'absolute',
        transform: 'translate(-50%, -91%)'
      }
    });

    export default withRouter(withStyles(styles)(AppraisalEngine));
