import { SET_USER, UPDATE_USER, CLEAR_USER } from '_constant'

const isValidData = function (data) {
  // data is a json object with token, name, and photo
  if(
    typeof data === 'object' &&
    typeof data.token !== 'undefined' && data.token !== '' &&
    typeof data.u_name !== 'undefined' &&
    typeof data.u_email !== 'undefined' &&
    typeof data.u_photo !== 'undefined'
  )
  return true;
  else
  return false;
}

const isValidUpdateData = function (data) {
  // data is a json object with either token, name, and photo
  if(
    typeof data === 'object' && (
      (typeof data.token !== 'undefined' && data.token !== '' ) ||
      typeof data.u_name !== 'undefined' ||
      typeof data.u_email !== 'undefined' ||
      typeof data.u_photo !== 'undefined'
    )
  )
  return true;
  else
  return false;
}

const filterData = function (data) {

  var fields = ['token', 'u_name', 'u_email', 'u_photo'];
  var userObj = {};
  fields.forEach((item, index) => {
    if(typeof data[item] !== 'undefined') {
      userObj[item] = data[item];
    }
  });

  return userObj;
}


export function user(state = {}, action) {
  // first time
  if (typeof(state.token) === 'undefined') {
    var data = localStorage.getItem('user-provesty');
    if(data && isValidData(JSON.parse(data)))
    state = JSON.parse(data)
    else
    state = {token : '', u_name: '', u_email: '', u_photo: ''};
  }

  // this is a bit hacky, but this is the only place where it make sense due to its centralize location
  var t_api = typeof Tawk_API !== 'undefined'? Tawk_API: null; // eslint-disable-line no-undef
  if(t_api && typeof t_api.setAttributes !== 'undefined' && state.u_name && (!t_api.visitor || t_api.visitor.name !== state.u_name)) {
    var val = {name: state.u_name, email: state.u_email};
    t_api.visitor = val;
    t_api.setAttributes(val);
  }

  switch (action.type) {
    case SET_USER:
    if(isValidData(action.payload)) {
      let newData = Object.assign({}, state, filterData(action.payload));
      localStorage.setItem('user-provesty', JSON.stringify(newData));
      return newData;
    } else {
      return state;
    }
    case UPDATE_USER:
    if(isValidUpdateData(action.payload)) {
      let newData = Object.assign({}, state, filterData(action.payload));
      localStorage.setItem('user-provesty', JSON.stringify(newData));
      return newData;
    } else {
      return state;
    }
    case CLEAR_USER:
    localStorage.removeItem('user-provesty');
    return {token : '', u_name: '', u_email: '',  u_photo: ''};
    default:
    return state
  }
}
