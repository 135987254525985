import React, {Component} from 'react';
import Navbar from 'components/general/Navbar'
import Footer from 'components/general/Footer'
import InfoAppraisal from 'components/appraisal/InfoAppraisal'
import FAQAppraisal from 'components/appraisal/FAQAppraisal'
import HowAppraisal from 'components/appraisal/HowAppraisal'
import AppraisalEngine from 'components/appraisal/AppraisalEngine'

export default class Appraisal extends Component {

  render(){


    return(
      <div className="career-page">
        <div className="">
          <Navbar />
          <div className="container" style={{paddingTop: 50}}>
            <InfoAppraisal />
          </div>
          <div className="wavy">
            <div className="container" style={{marginTop: 50}}>
              <HowAppraisal />
            </div>
          </div>
          <div className="bg-lightgreen" style={{paddingTop: 50}}>
            <div className="">
              <AppraisalEngine />
            </div>
          </div>
          <div className="bg-lightgreen-reverse" style={{paddingTop: 50}}>
            <div className="container">
              <FAQAppraisal />
            </div>
          </div>
          <div className="bg-lightgreen-static" style={{paddingTop: 50, paddingBottom: 10}}>
            <div className="container">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
