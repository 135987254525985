import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
// import Dollar from 'styles/assets/dollar.png';
// import Round from 'styles/assets/round.png';
// import Time from 'styles/assets/time.png';
// import Question from 'styles/assets/question.png';
// import ScrollAnimation from 'react-animate-on-scroll';
import Icon1 from 'styles/assets/home/mudah.png'
import Icon2 from 'styles/assets/home/transparan.png'
import Icon3 from 'styles/assets/home/disverivikasi.png'
import Icon4 from 'styles/assets/home/kontribusi.png'


export default class Home2 extends Component {
  render(){
    return(
      <div className="home-2 bg-lightgreen-reverse">
        <div className="home-2-float container">
          <Grid container justify="center" className="home2-grid">
            {
              [
                { icon: Icon1, title: 'Mudah', content: 'Daftar dalam 5 menit & Invest di properti mulai dari Rp 1 juta'},
                { icon: Icon2, title: 'Transparan', content: 'Dapatkan update <i>real time</i> tanpa adanya <i>Hidden fee</i>'},
                { icon: Icon3, title: 'Terjamin', content: 'Pendanaan ke aset rumah yang sekaligus menjadi jaminan'},
                { icon: Icon4, title: 'Diversifikasi', content: 'Minimalisasi resiko dengan investasi di berbagai Properti'},

              ].map(x =>
                <Grid key={x.title} item lg={3} md={3} sm={6} xs={12}>
                  <div className="home2-card">
                    <Grid container spacing={8} alignItems="center">
                      <Grid item xs="auto" sm={12}>
                        <img src={x.icon} alt="icon-card" style={{margin: '0 10px'}} />
                      </Grid>
                      <Grid item xs sm={12}>
                        <p style={{fontWeight: 600}}>{x.title}</p>
                        <div className="home2-card-content"
                          dangerouslySetInnerHTML={{__html: x.content}}
                          />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )
            }
          </Grid>
        </div>
      </div>
    )
  }
}
