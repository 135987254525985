import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";
import Fab from '@material-ui/core/Fab';
// import Afif from 'styles/assets/about-us/afif.svg';
import Adi from 'styles/assets/about-us/adi.svg';
// import Oki from 'styles/assets/about-us/oki.png';
// import Galih from 'styles/assets/about-us/galih.png';
// import Intan from 'styles/assets/about-us/intan.svg';
// import Haidar from 'styles/assets/about-us/haidar.svg';
// import Retno from 'styles/assets/about-us/retno.png';
import Ardi from 'styles/assets/about-us/ardi.png';
import Tendy from 'styles/assets/about-us/tendy.png';
import Reza from 'styles/assets/about-us/reza.png';
import Dewi from 'styles/assets/about-us/dewi.png';
import Hasna from 'styles/assets/about-us/hasna.png';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';


class About4 extends Component {

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render(){

    const coba_settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      speed: 150,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            infinite: true,
            variableWidth: true,
            dots: false
          }
        }, {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: false,
            dots: false
          }
        }
      ]
    };

    const members = [
      { name: 'Fachrian Adi', pos: 'Chief Executive Officer', pos2: 'Co-Founder', img: Adi },
      { name: 'Adi Masliardi', pos: 'Relationship Manager', img: Ardi },
      { name: 'Deny Rachmat', pos: 'Relationship Manager', img: Tendy },
      { name: 'Dewi Ayu', pos: 'Admin Ads', img: Dewi },
      { name: 'Ariezha Pratama', pos: 'Legal', img: Reza },
      { name: 'Hasna Penta Kurnia', pos: 'Finance', img: Hasna },
      // { name: 'Oki Earlivan', pos: 'Chief Strategy Officer', pos2: 'Co-Founder', img: Oki },
      // { name: 'Retno Ambarwati', pos: 'Head of Business and Risk', img: Retno},
      // { name: 'Galih Windyarto', pos: 'UI/UX Designer', img: Galih },
      // { name: 'Haidar Dzaky', pos: 'Frontend Engineer', img: Haidar },
      // { name: 'Intan W Anugrah', pos: 'Marketing', img: Intan },
    ];

    return(
      <div className="container testimoni" style={{ margin: '0 auto', paddingTop: 20, paddingBottom: 30}}>
        <div className="testimoni-investor">
          Tim Provesty
        </div>
        <div className="about4-content">
          Hal-hal hebat dalam bisnis tidak pernah dilakukan oleh satu orang, melainkan dilakukan oleh satu tim
        </div>

        <Slider ref={c => (this.slider = c)} {...coba_settings}>
          {
            members.map(p =>
              <div key={p.name}>
                <div className="team-card">
                  <Grid container>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <div className="">
                        <div className="circle img-center">
                          <img src={p.img} alt="contoh-testimoni" style={{width: 91, margin: '0 auto'}}/>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <div style={{padding: 10, paddingTop: 10, textAlign: 'left'}}>
                        <div className="testimoni-name">{p.name}</div>
                        <div className="testimoni-content">{p.pos}</div>
                        {p.pos2 ? <div className="testimoni-content">{p.pos2}</div> : ''}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )
          }
        </Slider>
        <div className="ta-center">
          <Fab onClick={this.previous} style={{marginRight: 10, backgroundColor: '#d2ecf9', color: '#3d7dc8'}}>
            <ChevronLeft />
          </Fab>
          <Fab color="primary" onClick={this.next} style={{marginLeft: 10, backgroundColor: '#d2ecf9', color: '#3d7dc8'}}>
            <ChevronRight />
          </Fab>
        </div>
      </div>
    )
  }
}

export default About4
