import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MyTextField from "components/general/MyTextField";
import MyRadioGroup from "components/general/MyRadioGroup";
import ReactSelect from "components/general/ReactSelect";
import MyUploadImage from "components/general/MyUploadImage";
import Grid from '@material-ui/core/Grid';
import ModalSMS from "components/dashboard/ModalSMS";
import { formVal, formData, apiHelper, dispatchMsg, formScrollToError, dispatchUpdateUser, price_d2r, price_r2d, formDef, formValid } from 'helpers';
import { APISTAT, STAT, SEX } from '_constant';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-spinkit';
import KTP from "styles/assets/ktp.png";
import NPWP from "styles/assets/npwp.png";

function getSteps() {
  return ['Lengkapi Informasi Personal', 'Informasi Pengalaman', 'Informasi Dokumen'];
}

export default class IndividualProfile extends React.Component {

  static defaultProps = {
    history: null
  }

  constructor(props) {
    super(props);

    var fields = formDef(this, [
      {name: 'u_name', label: 'Nama Lengkap'},
      {name: 'u_nickname', label: 'Nama Panggilan', valid: ''},
      {name: 'u_dob', label: 'Tanggal Lahir', valid: 'requiredDate'},
      {name: 'u_sex', label: 'Jenis Kelamin'},
      {name: 'id_country_nationality', label: 'Kewarganegaraan'},
      {name: 'u_phone', label: 'Nomor HP'},
      {name: 'u_phone_compact', label: 'Nomor HP Compact', valid: ''},
      {name: 'u_is_phone_verified', label: 'Nomor HP Terverifikasi', valid: ''},
      {name: 'id_province', label: 'Provinsi'},
      {name: 'id_province', label: 'Provinsi'},
      {name: 'id_regency', label: 'Kota/Kabupaten'},
      {name: 'id_district', label: 'Kecamatan'},
      {name: 'id_village', label: 'Kelurahan'},
      {name: 'ad_line', label: 'Alamat'},
      {name: 'ad_postal', label: 'Kode Pos'},
      {name: 'id_job', label: 'Pekerjaan'},
      {name: 'id_income_source', label: 'Sumber Dana'},

      {name: 'u_income_permonth', label: 'Pendapatan Per Bulan', valid: ''},
      {name: 'u_employer', label: 'Nama Perusahaan', valid: ''},
      {name: 'u_job_position', label: 'Jabatan', valid: ''},
      {name: 'u_experince_year', label: 'Lama Bekerja', valid: ''},

      {name: 'id_bank', label: 'Bank'},
      {name: 'bac_branch', label: 'Cabang'},
      {name: 'bac_owner_name', label: 'Atas Nama'},
      {name: 'bac_no', label: 'No Akun'},

      {name: 'u_ic_no', label: 'No KTP'},
      {name: 'u_tax_no', label: 'No NPWP'},
      {name: 'u_ic_img', label: 'Scan KTP', valid: 'requiredImg', validParam: 'u_ic_img_init'},
      {name: 'u_tax_img', label: 'Scan NPWP', valid: 'requiredImg', validParam: 'u_tax_img_init'},
    ]);

    this.state = {
      activeStep: 0,

      ...fields,

      u_ic_img_init: '',
      u_tax_img_init: '',

      verification_code: '',
      is_phone_need_verify: true,

      provinces: {},
      regencies: {},
      districts: {},
      villages: {},
      jobs: {},
      income_sources: {},
      nationalities: {},
      banks: {},

      smsVisible: false,
      smsData: {},
      smsLoading: false,
      smsKey: '',

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = event => {

    var name = event.target.name;
    var val = event.target.value;

    if (name === 'u_phone') {
      // filter phone and decide if we need to verify
      val = val.replace(/[^\d\s()+.-]/g, '');  // filter non number and (,),+,-

      var phone_compact = val.replace(/[^0-9]/g, '').replace(/^62/g, '0');
      var verified_phone_compact = this.state.u_is_phone_verified === STAT.STAT_YES ? this.state.u_phone_compact : '';
      var is_phone_need_verify = verified_phone_compact === '' || phone_compact !== verified_phone_compact;

      this.setState({ [name]: val, is_phone_need_verify });
    } else if (name === 'id_province') {
      this.setState({ [name]: val }, this.loadRegency);
    } else if (name === 'id_regency') {
      this.setState({ [name]: val }, this.loadDistrict);
    } else if (name === 'id_district') {
      this.setState({ [name]: val }, this.loadVillage);
    } else {
      if (name === 'u_income_permonth')
      val = price_d2r(price_r2d(val));

      this.setState({ [name]: val });
    }
  };

  loadVillage = () => {
    apiHelper.request(this, {
      resource: 'villages_select',
      urlParams: [this.state.id_district],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_village: '', villages: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadDistrict = () => {
    apiHelper.request(this, {
      resource: 'districts_select',
      urlParams: [this.state.id_regency],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_district: '', districts: resp.data, id_village: '', villages: [] });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadRegency = () => {
    apiHelper.request(this, {
      resource: 'regencies_select',
      urlParams: [this.state.id_province],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_regency: '', regencies: resp.data, id_district: '', districts: [], id_village: '', villages: [] });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  validate = () => {

    this.errors = {};

    formValid(this);

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }

  phoneValidate = () => {

    this.errors = {};
    formVal(this, 'u_phone', 'Phone', 'required');

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;
    // clear the errors
    this.setState({ buttonLoading: true });

    apiHelper.request(this, {
      resource: 'individual_profile_post',
      data: formData('form-data'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          // next step
          dispatchUpdateUser(resp.data);
          this.setState({ buttonLoading: false });
          dispatchMsg('success', 'Data berhasil disimpan.')
          this.props.history.push( "/sponsor-individual/experience");
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false });
      },
    });
  }

  launchSms = () =>  {

    if(!this.phoneValidate())
    return;

    this.setState({ smsLoading: true });

    apiHelper.request(this, {
      resource: 'send_sms',
      data: formData({u_phone: this.state.u_phone}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ smsLoading: false, smsData: {'u_phone': this.state.u_phone}, smsVisible: true, smsKey: this.state.u_phone});
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ smsLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({smsLoading: false});
      },
    });
  }

  onSmsClose = (isVerified) => {
    if (isVerified === true) {  // must be exact true
      this.setState({
        smsVisible: false,
        u_is_phone_verified: isVerified? STAT.STAT_YES: STAT.STAT_NO,
        is_phone_need_verify: isVerified? false: true
      });
    } else {
      this.setState({smsVisible: false});
    }
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'individual_profile_get', // use the secured one if loggedin
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data, is_phone_need_verify: resp.data.u_is_phone_verified === STAT.STAT_NO});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }


  render() {

    const steps = getSteps();
    const { activeStep } = this.state;
    const stepButtons = steps.map((label, index) => {
      return (
        <Step key={label} name="stepper" >
          <StepLabel classes={{label: 'step-label'}} StepIconProps={{classes: {root: 'step-icon', active: 'step-icon-active', completed: 'step-icon-completed'}}}>
            {label}
          </StepLabel>
        </Step>
      );
    });

    const formContent =
    <Grid container spacing={16}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid item xs={12}>
          <div className="form-legend">Data Individu</div>
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField name="u_name" obj={this}
          />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField name="u_nickname" obj={this} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          name="u_dob" obj={this} shrink type="date" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect name="id_country_nationality" obj={this} options={Object.keys(this.state.nationalities).map(k => ({k: k, v:this.state.nationalities[k]}))} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyRadioGroup
          name="u_sex" obj={this} options={[{k: SEX.MALE, v: 'Laki-laki'}, { k:SEX.FEMALE, v:'Perempuan'}]} />
      </Grid>
      <Grid item sm={12} container justify="flex-end">
        <Grid item xs={12} sm>
          <MyTextField name="u_phone" obj={this}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" classes={{root: 'input-adornment'}}>
                  {
                    this.state.u_phone ?
                    (this.state.is_phone_need_verify ? <div style={{color: "#cb4f5e", whiteSpace: "nowrap"}}>(Belum Terverfikasi)</div> :<div style={{color: "#24b47e", whiteSpace: "nowrap"}}>(Terverifikasi)</div>) :
                    ''
                  }
                </InputAdornment>
              )
            }}
             />
        </Grid>
        { this.state.is_phone_need_verify && this.state.u_phone ?
          <Grid item>
            <div className="button-progress-wrapper">
              <Button disabled={this.state.smsLoading} className="button-blue button-verify-phone"
                onClick={this.launchSms}>Kirim kode verifikasi &nbsp;<i className="material-icons button-icon">send</i></Button>
              {this.state.smsLoading && <CircularProgress size={24} style={{marginTop: -7}} className="button-progress" />}
            </div>
          </Grid> :
          ''
        }
      </Grid>
      <Grid item sm={12} container alignItems="flex-end">
        <Grid item sm >
          <MyTextField name="u_ic_no" obj={this} type="tel" />
        </Grid>
        <Grid item>
          <MyUploadImage name="u_ic_img" obj={this} ButtonProps={{className:"button-upload-doc button-blue"}} ImgProps={{style: {maxWidth: 200}}} buttonContent={<span>Upload Scan KTP &nbsp;<i className="material-icons button-icon">cloud_upload</i></span>}
            defaultImg={KTP} />
        </Grid>
      </Grid>
      <Grid item sm={12} container alignItems="flex-end">
        <Grid item sm>
          <MyTextField name="u_tax_no" obj={this} type="tel" />
        </Grid>
        <Grid item>
          <MyUploadImage name="u_tax_img" obj={this} ButtonProps={{className:"button-upload-doc button-blue"}} ImgProps={{style: {maxWidth: 200}}} buttonContent={<span>Upload Scan NPWP &nbsp;<i className="material-icons button-icon">cloud_upload</i></span>}
            defaultImg={NPWP} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReactSelect name="id_job" obj={this} options={Object.keys(this.state.jobs).map(k => ({k: k, v:this.state.jobs[k]}))} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReactSelect name="id_income_source" obj={this} options={Object.keys(this.state.income_sources).map(k => ({k: k, v:this.state.income_sources[k]}))} />
      </Grid>

      {/*Address Start*/}
      <Grid item xs={12}>
        <div className="form-legend">Alamat</div>
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect name="id_province" obj={this}
          options={Object.keys(this.state.provinces).map(k => ({k: k, v:this.state.provinces[k]}))} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect
          name="id_regency" obj={this} options={Object.keys(this.state.regencies).map(k => ({k: k, v:this.state.regencies[k]}))} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect name="id_district" obj={this}
          options={Object.keys(this.state.districts).map(k => ({k: k, v:this.state.districts[k]}))} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect
          name="id_village" obj={this} options={Object.keys(this.state.villages).map(k => ({k: k, v:this.state.villages[k]}))} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <MyTextField name="ad_line"  obj={this} multiline rows={3} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField name="ad_postal" obj={this} />
      </Grid>

      <Grid item xs={12}>
        <div className="form-legend">Data Pekerjaan <span style={{fontSize: 12, fontWeight: 'normal'}}>(Harus diisi jika berkerja)</span></div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="u_employer" obj={this} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="u_job_position" obj={this} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="u_experince_year" label="Lama Bekerja (tahun)" obj={this} type="number" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="u_income_permonth" obj={this} type="tel" />
      </Grid>
      {/*Address End*/}

      <Grid item xs={12}>
        <div className="form-legend">Data Bank</div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReactSelect name="id_bank" obj={this} options={Object.keys(this.state.banks).map(k => ({k: k, v:this.state.banks[k]}))} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="bac_branch" obj={this} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="bac_owner_name" obj={this} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MyTextField name="bac_no" obj={this} />
      </Grid>
    </Grid>;

    const stepperContent = this.state.isLoading ?
    <div style={{height: 500}}>
      <Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" />
    </div>
    :
    <form onSubmit={this.handleSubmit} id="form-data" autoComplete="off" name="form">
      {formContent}
      <div className="sms-modal-buttons" style={{marginTop: 55, marginBottom: 30, textAlign: 'center'}}>
        <div className="button-progress-wrapper">
          <Button disabled={this.state.buttonLoading} className="btn-stepper-next button-blue" type="submit" style={{width: 120}}>Simpan</Button>
          {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
        </div>
      </div>
    </form>;

    return (
      <div>
        <div className="bg-lightblue">
          <Navbar />
          <div className="container credential-page">
            <Paper >
              <Stepper activeStep={activeStep} alternativeLabel className="stepper-label stepper-loan">
                {stepButtons}
              </Stepper>
            </Paper>
            <Paper className="cred-area">
              <div className="stepper-content">
                {stepperContent}
              </div>
            </Paper>
          </div>
          <Footer />
        </div>
        <ModalSMS
          visible={this.state.smsVisible} key={this.state.smsKey} {...this.state.smsData}
          onClose={this.onSmsClose}
          />
      </div>
    );
  }
}
