import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MyTextField from 'components/general/MyTextField'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/Visibility';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid";
// import { Link } from 'react-router-dom';
import { apiHelper, dispatchMsg, dispatchCloseMsg, dispatchSetUser, formVal, formData, dataHelper } from 'helpers';
import { APISTAT, DATAVAR } from '_constant';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import CircularProgress from '@material-ui/core/CircularProgress';

class LoginComponent extends Component {

  static defaultProps = {
    history: null
  }

  constructor(props){
    super(props);
    this.state = {
      email: '',
      password: '',
      checkedB: '',

      showPassword: false,

      validErrors: {},
      buttonLoading: false,
      fbLoading: false,
      googleLoading: false,

    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }
  handleInputChange = event => {
    var name = event.target.name;
    var val = event.target.value;
    if(name === 'checkedB')
    val = event.target.checked ? val: ''; // toggle
    this.setState({ [name]: val });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({
      showPassword: !state.showPassword
    }));
  };

  validate = () => {
    this.errors = {};

    formVal(this, 'password', 'Password', 'required');
    if(formVal(this, 'email', 'Email', 'required')) {

      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(String(this.state.email).toLowerCase()))
      this.errors['email'] = 'Format email tidak valid';
    }

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  loginSuccess = (userData) => {

    // store user details and jwt token in local storage to keep user logged in between page refreshes
    dispatchSetUser(userData);
    dispatchMsg('success', 'Login berhasil. Redirecting..');

    // GTM
    if(userData.new_user) {
      if(window.dataLayer)
      window.dataLayer.push({'event':'User Register'});
    }

    var redirectTgt = dataHelper.get(DATAVAR.REDIRECT_LOGIN, true) || '/dashboard';

    setTimeout(() => { dispatchCloseMsg(); return this.props.history.push(redirectTgt)
    }, 2000);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    this.setState({ buttonLoading: true});
    apiHelper.request(this, {
      resource: 'login',
      data: formData('form-login'),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success
          this.loginSuccess(resp.data);
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false});
      }
    });
  };

  responseFacebook = (resp) => {

    if(typeof resp.accessToken === 'undefined') {
      dispatchMsg('error', 'Login lewat Facebook gagal');
      return;
    }

    this.setState({ fbLoading: true});

    apiHelper.request(this, {
      resource: 'login_fb',
      data: formData({ accessToken: resp.accessToken }),
      fnSuccess: resp => {

        if (resp.status === APISTAT.SUCCESS) { // success
          this.loginSuccess(resp.data);
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ fbLoading: false});
      },
    });
  }

  responseGoogle = (resp) => {

    if(typeof resp.tokenId === 'undefined') {
      dispatchMsg('error', 'Login lewat Google gagal');
      return;
    }

    this.setState({ googleLoading: true});

    apiHelper.request(this, {
      resource: 'login_google',
      data: formData({ accessToken: resp.tokenId }),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success
          this.loginSuccess(resp.data);
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ googleLoading: false})
      }
    });
  }

  render() {

    // when one access mechanism is loading, disable all mechanism
    var disableChannelButtons = this.state.buttonLoading || this.state.fbLoading || this.state.googleLoading;

    return (
      <div>
        <div className="auth-form">
          <form onSubmit={this.handleSubmit} id="form-login">

            <Grid container spacing={8}>
              <Grid item md={4} sm={4} xs={4}>
                <div className="with-auth">
                  Gunakan:
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <GoogleLogin
                  clientId="768246090390-9jqnlidbs8700os37hoob5tv6aimvhaa.apps.googleusercontent.com"
                  render={renderProps => (
                    <div className="button-progress-wrapper-full">
                      <Button disabled={disableChannelButtons} onClick={renderProps.onClick} className="button-google button-socmed-login" variant="contained"><i className="fa fa-google-plus fa-login"></i></Button>
                      {this.state.googleLoading && <CircularProgress size={24} className="button-progress" style={{marginTop: -18}}  />}
                    </div>
                  )}
                  buttonText="Login"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  />
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <FacebookLogin
                  appId="356109988164669"
                  callback={this.responseFacebook}
                  fields="name,email,picture"
                  render={renderProps => (
                    <div className="button-progress-wrapper-full">
                      <Button disabled={disableChannelButtons} onClick={renderProps.onClick} className="button-fb button-socmed-login" variant="contained" ><i className="fa fa-facebook fa-login"></i></Button>
                      {this.state.fbLoading && <CircularProgress size={24} className="button-progress" style={{marginTop: -18}} />}
                    </div>
                  )}
                  />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <hr style={{width: '100%'}}/>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className="or-sign-in">Atau masuk dengan:</div>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <hr style={{width: '100%'}}/>
              </Grid>
            </Grid>
            <br />
            <MyTextField name="email" label="Email" obj={this}
              className='input-width' variant="outlined" InputLabelProps={{ style: {fontSize: 16}}} InputProps={{ inputProps: {style: {fontSize: 16}, autoComplete: 'username'}}}/>
            <MyTextField name="password" label="Password" obj={this}
              style={{marginTop: 10}} variant="outlined" InputLabelProps={{ style: {fontSize: 16}}} className='input-width' type={this.state.showPassword? 'text' : 'password'} fullWidth={false}
              InputProps={{
                inputProps: {style: {fontSize: 16}, autoComplete: 'current-password'},
                endAdornment: (<InputAdornment>
                  <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                    { this.state.showPassword ? <VisibilityOff/> : <Visibility/> }
                  </IconButton>
                </InputAdornment>)
              }}/>
              <FormControlLabel
                control={
                  <Checkbox name="checkedB" checked={this.state.checkedB} onChange={this.handleInputChange} value="checkedB" color="primary"/>
                }
                label="Keep me signed in"
                className="font-control"
                />
              <div style={{marginTop:5}}>
                <div className="button-progress-wrapper-full">
                  <Button disabled={disableChannelButtons} type="submit" className="input-width" variant="contained" color="primary" style={{color: 'white'}}>Sign in</Button>
                  {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }

  export default LoginComponent;
