import React, {Component} from "react";
import {Route, Redirect} from "react-router-dom";
import { userHelper, dispatchMsg, dataHelper } from 'helpers';
import { DATAVAR } from '_constant'

export default class MyRoute extends Component {

  shouldComponentUpdate(nextProps, nextState) {

    // sometimes during transition this called several times which trigger unnecessary rendering
    // by right this always translates to FALSE
    return this.props.path !== nextProps.path;
  }

  render() {

    const { component: Page, publicArea, ...rest  } = this.props;

    // console.log(this.props.location.pathname);

    var isLogin = userHelper.isLogin();

    // redirect if not public area AND not login
    var mustRedirect = !publicArea && !isLogin;

    if(mustRedirect) {
      // this happens when user want to go to user area but not yet logged in,
      // store first and go after user gain access (login, register, verify email, etc)
      dataHelper.set(DATAVAR.REDIRECT_LOGIN, this.props.path);
      dispatchMsg('warning', 'Silakan daftar/masuk untuk mengakses area user');
      return (
        <Route {...rest} render={props => <Redirect to={{
          pathname: "/login",
          state: { from: this.props.location }
        }} />} />
      )
    } else {
      return (
        <Route {...rest} render={props => <Page {...props} />} />
      )
    }
  }
}
