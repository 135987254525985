import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppraisalSimple from 'styles/assets/appraisal/appraise_simple.png'
// import AppraisalPic from 'styles/assets/appraisal/appraise.png'
import Hidden from '@material-ui/core/Hidden';
import { withLocalize } from 'react-localize-redux';
import {Link} from 'react-router-dom';


class CarouAppraisal extends Component {

  constructor(props) {
    super(props);
    this.addTranslationsForActiveLanguage();
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.addTranslationsForActiveLanguage();
    }
  }

  addTranslationsForActiveLanguage() {
    const {activeLanguage} = this.props;

    if (!activeLanguage) {
      return;
    }

    import(`../../translations/home/${activeLanguage.code}.home.json`)
    .then(translations => {
      this.props.addTranslationForLanguage(translations, activeLanguage.code)
    });
  }

  render(){
    return(
      <div className="container" style={{maxWidth:'100%'}}>
        <Grid container alignItems="center" style={{marginTop: 15}}>
          <Hidden smDown>
            <Grid item lg={1} md={1} sm={false} xs={false} />
          </Hidden>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div className="home1-left">
              <div className="home1-title">
                Cari Harga Rumah Secara Cepat
              </div>

              <div className="loan1-subtitle">Tanpa Perlu Keluar Rumah</div>
              <div className="home1-content">
                Tertarik untuk membeli rumah tapi tidak tahu kondisi harga pasaran? atau mau menjual tapi bingung berapa mesti pasang harganya? ProEngine siap membantu kamu menghitung harga pasaran properti di hampir semua area populer di Indonesia
              </div>
              <div className="home1-buttons">
                <Grid container spacing={16}>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Link to="/appraisal" style={{textDecoration: 'none'}}>
                      <Button variant="contained" className="btn-dark-blue">
                        Cari Sekarang
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div className="home1-right img-center">
              <Hidden smDown><img className="img-width img-responsive" src={AppraisalSimple} alt="" style={{maxWidth: 300}} /></Hidden>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withLocalize(CarouAppraisal);
