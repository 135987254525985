import React, {Component} from 'react';
import Navbar from 'components/general/Navbar';
import Footer from "components/general/Footer";
import DashboardSidebar from "components/general/DashboardSidebar";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Spinner from 'react-spinkit';
// import Spinner from 'react-spinkit';
import { APISTAT, UNSUB } from '_constant';
import DashboardTab from 'components/dashboard/DashboardTab';
import { apiHelper, dispatchMsg, formData, dispatchUpdateUser } from 'helpers';
import UserContainer from 'redux/container/UserContainer'
import ModalPassword from "components/dashboard/ModalPassword";
import ModalEmail from "components/dashboard/ModalEmail";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import MyCheckboxes from "components/general/MyCheckboxes";

export default class DashboardAcount extends Component {

  static defaultProps = {
    history: null, // for redirect to other location
  }


  constructor(props){
    super(props);
    this.state={
      // u_email : '', use redux info
      u_google_id: '',
      u_facebook_id: '',
      is_password_set: false,

      u_unsub_matrix: '',
      u_sub_new_listing: '',

      unsubLoading: false,

      passVisible: false,
      emailVisible: false,

      fbLoading: false,
      googleLoading: false,

      isLoading: true,
      validErrors: {},
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  componentDidMount() {
    this.loadData();
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;

    if (name === 'u_sub_new_listing') {
      var extra = {};
      var mask = 1 << UNSUB.UNSUB_NEW_LISTING;
      var u_unsub_matrix = this.state.u_unsub_matrix;
      if(val === '1') {
        u_unsub_matrix &= ~mask;
      } else{
        u_unsub_matrix |= mask;
      }
      extra['u_unsub_matrix'] = u_unsub_matrix;
      this.setState({ [name]: val, ...extra }, this.submitUnsub);
    } else {
      this.setState({ [name]: val });
    }
  };

  submitUnsub = () => {

    this.setState({ unsubLoading: true});

    var u_unsub_matrix = this.state.u_unsub_matrix;

    apiHelper.request(this, {
      resource: 'email_unsub',
      data: formData({ u_unsub_matrix }),
      fnSuccess: resp => {

        if (resp.status === APISTAT.SUCCESS) { // success
          dispatchUpdateUser(resp.data);
          dispatchMsg('success', 'Preferensi Langganan Email berhasil disimpan.');
          this.setState({ unsubLoading: false});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ unsubLoading: false});
      },
    });
  }


  responseFacebook = (resp) => {

    if(typeof resp.accessToken === 'undefined') {
      dispatchMsg('error', 'Gagal untuk menghubungkan akun Facebook');
      return;
    }

    this.setState({ fbLoading: true});

    apiHelper.request(this, {
      resource: 'connect_fb',
      data: formData({ accessToken: resp.accessToken }),
      fnSuccess: resp => {

        if (resp.status === APISTAT.SUCCESS) { // success
          dispatchUpdateUser(resp.data);
          dispatchMsg('success', 'Akun FB berhasil dihubungkan');
          this.setState({ fbLoading: false}, this.loadData());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ fbLoading: false});
      },
    });
  }

  responseGoogle = (resp) => {

    if(typeof resp.tokenId === 'undefined') {
      dispatchMsg('error', 'Gagal untuk menghubungkan akun Google');
      return;
    }

    this.setState({ googleLoading: true});

    apiHelper.request(this, {
      resource: 'connect_google',
      data: formData({ accessToken: resp.tokenId }),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success
          dispatchUpdateUser(resp.data);
          dispatchMsg('success', 'Akun Google berhasil dihubungkan');
          this.setState({ googleLoading: false}, this.loadData());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ googleLoading: false})
      }
    });
  }

  loadData = () => {

    this.setState({ isLoading: true });

    apiHelper.request(this, {
      resource: 'account',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS ) { // success

          // derive unsub matrix to subs. Remember when unsub matrix is the inversion of sub
          var u_unsub_matrix = resp.data.u_unsub_matrix;
          var u_sub_new_listing = (u_unsub_matrix & (1 << UNSUB.UNSUB_NEW_LISTING)) ? '0': '1';

          this.setState({ isLoading: false, u_sub_new_listing, ...resp.data });
        } else
        throw new Error(resp.msg);
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }

  render(){

    const { isLoading, u_google_id, u_facebook_id, is_password_set} = this.state;

    // when one access mechanism is loading, disable all mechanism
    var disableChannelButtons = this.state.fbLoading || this.state.googleLoading;

    var content =

    <React.Fragment>
      <div className="form-legend" style={{marginBottom: 2}}>Email & Password</div>
      <div>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={2}>
            Email:
          </Grid>
          <Grid item xs>
            <UserContainer part="email" /> <Button onClick={() => this.setState({emailVisible: true})} className="button-blue" size="small" style={{marginLeft: 15}}><Icon style={{fontSize: 16}}>edit</Icon> Ubah</Button>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={2}>
            Password:
          </Grid>
          <Grid item xs>
            {is_password_set? 'Sudah diset' : 'Belum diset'}
            <Button onClick={() => this.setState({passVisible: true})} className="button-blue" size="small" style={{marginLeft: 15}}><Icon style={{fontSize: 16}}>edit</Icon> {is_password_set? 'Ubah' : 'Buat'}</Button>
          </Grid>
        </Grid>


        <div className="form-legend" style={{marginTop: 18}}>Langganan Email &nbsp;
          { this.state.unsubLoading ? <CircularProgress size={14}/> : '' }
        </div>
        <MyCheckboxes name="u_sub_new_listing" obj={this}
          options={[
            {k: '1', v:'Kirimkan saya email tentang peluang investasi baru.'},
          ]}
          />

        <div className="form-legend" style={{marginTop: 18}}>Social Media</div>
        <div style={{margin: "10px 0px 12px"}}>Hubungkan akun social media kamu untuk kemudahan login dengan sekali klik</div>

        <Grid container spacing={24} style={{ lineHeight: 1.6 }}>
          <Grid item className="ta-center">
            <div><i className="fa fa-google-plus" style={{color: '#dd4b39', fontSize: 30}}></i></div>
            <div style={{fontSize:12}}>Google</div>
            {u_google_id?
              <div><Icon style={{fontSize: 13, color: 'limegreen'}}>check</Icon> Terhubung</div>
              :
              <div>Tidak terhubung
                <GoogleLogin
                  clientId="768246090390-9jqnlidbs8700os37hoob5tv6aimvhaa.apps.googleusercontent.com"
                  render={renderProps => (
                    <div className="button-progress-wrapper-full">
                      <Button disabled={disableChannelButtons} onClick={renderProps.onClick} className="button-google button-socmed-login" variant="contained" size="small">Hubungkan</Button>
                      {this.state.googleLoading && <CircularProgress size={24} className="button-progress" style={{marginTop: -18}}  />}
                    </div>
                  )}
                  buttonText="Login"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  />
              </div>}
            </Grid>
            <Grid item className="ta-center">
              <div><i className="fa fa-facebook" style={{color: '#3B5998', fontSize: 30}}></i></div>
              <div style={{fontSize:12}}>Facebook</div>
              {u_facebook_id?
                <div><Icon style={{fontSize: 13, color: 'limegreen'}}>check</Icon> Terhubung</div>
                :
                <div>Tidak terhubung
                  <FacebookLogin
                    appId="356109988164669"
                    callback={this.responseFacebook}
                    fields="name,email,picture"
                    render={renderProps => (
                      <div className="button-progress-wrapper-full">
                        <Button disabled={disableChannelButtons} onClick={renderProps.onClick} className="button-fb button-socmed-login" variant="contained" size="small" >Hubungkan</Button>
                        {this.state.fbLoading && <CircularProgress size={24} className="button-progress" style={{marginTop: -18}} />}
                      </div>
                    )}
                    />
                </div>}
              </Grid>
            </Grid>
          </div>
        </React.Fragment>;

        if(isLoading) {
          content = (
            <div className="my-spinner-container">
              <Spinner fadeIn='quarter' name="chasing-dots" />
            </div>
          );
        }

        return(
          <div className="pageWidth">
            <Navbar />
            <Grid container className="container">
              <Grid item className="dbsidebarplace">
                <DashboardSidebar history={this.props.history} />
              </Grid>
              <Grid item xs>
                <DashboardTab paths={[
                    { l: 'Profil', v: '/dashboard/profile' },
                    { l: 'Akun', v: '/dashboard/account' },
                  ]} history={this.props.history}
                  />
                <Paper classes={{root:'dash-area'}} style={{borderRadius: '0px 0px 4px 4px'}}>
                  {content}
                </Paper>
              </Grid>
            </Grid>
            <div className="wavyflip pushupx">
              <Footer />
            </div>
            <ModalPassword
              visible={this.state.passVisible}
              isPasswordSet={this.state.is_password_set}
              onClose={
                (result) => this.setState({passVisible: false},
                  () => { result === true && this.loadData() }
                )
              }
              />
            <ModalEmail
              visible={this.state.emailVisible}
              history={this.props.history}
              onClose={
                () => this.setState({emailVisible: false})
              }
              />
          </div>
        )
      }
    }
