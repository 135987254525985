import React, {Component} from 'react';


export default class Attention extends Component {
  render(){
    return(
      <div className="container" style={{}}>
        <p className="" style={{textAlign: 'left'}}><b>PERHATIAN</b></p>
        <div className="attention-content" style={{textAlign: 'justify'}}>
          <ol>
            <li>
              Layanan Pinjam Meminjam Berbasis Teknologi Informasi merupakan kesepakatan perdata antara Pemberi
              Pinjaman dengan Penerima Pinjaman, sehingga segala risiko yang timbul dari kesepakatan tersebut
              ditanggung sepenuhnya oleh masing-masing pihak.
            </li>
            <li>
              Resiko Kredit atau Gagal Bayar dan seluruh kerugian dari atau terkait dengan kesepakatan
              pinjam meminjam ditanggung sepenuhnya oleh Pemberi Pinjaman. Tidak ada lembaga atau
              otoritas negara yang bertanggung jawab atas resiko gagal bayar dan kerugian tersebut.
            </li>
            <li>
              Penyelenggara dengan persetujuan dari masing-masing Pengguna (Pemberi Pinjaman dan/atau
                Penerima Pinjaman) mengakses, memperoleh, menyimpan, mengelola dan/atau menggunakan
                data pribadi Pengguna ("Pemanfaatan Data") pada atau di dalam benda, perangkat
                elektronik (termasuk smartphone atau telepon seluler), perangkat keras (hardware)
                maupun lunak (software), dokumen elektronik, aplikasi atau sistem elektronik milik
                Pengguna atau yang dikuasai Pengguna, dengan memberitahukan tujuan, batasan dan mekanisme
                Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan sebelum memperoleh persetujuan
                yang dimaksud.
              </li>
              <li>
                Pemberi Pinjaman yang belum memiliki pengetahuan dan pengalaman pinjam meminjam,
                disarankan untuk tidak menggunakan layanan ini.
              </li>
              <li>
                Penerima Pinjaman harus mempertimbangkan tingkat bunga pinjaman dan biaya lainnya
                sesuai dengan kemampuan dalam melunasi pinjaman.
              </li>
              <li>
                Setiap kecurangan tercatat secara digital di dunia maya dan dapat diketahui masyarakat
                luas di media sosial.
              </li>
              <li>
                Pengguna harus membaca dan memahami informasi ini sebelum membuat keputusan menjadi
                Pemberi Pinjaman atau Penerima Pinjaman.
              </li>
              <li>
                Pemerintah yaitu dalam hal ini Otoritas Jasa Keuangan,tidak bertanggung jawab atas
                setiap pelanggaran atau ketidakpatuhan oleh Pengguna,baik Pemberi Pinjaman maupun
                Penerima Pinjaman(baik karena kesengajaan atau kelalaian Pengguna)terhadap ketentuan
                peraturan perundang-undangan maupun kesepakatan atau perikatan antara Penyelenggara
                dengan Pemberi Pinjaman dan/atau Penerima Pinjaman.
              </li>
            </ol>
          </div>
        </div>
      )
    }
  }
