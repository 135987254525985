import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Cari from 'styles/assets/home/cari.png';
import Invest from 'styles/assets/home/invest.png';
import Return from 'styles/assets/home/return.png';
import ChevronRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
  iconic: {
    fontSize: 48,
    marginTop: 180,
    color: '#0f3c68',
    margin: '0 auto',
    justifyContent: 'center',
  },
  iconicmobile:{
    fontSize: 60,
    color: '#0f3c68',
  },
});

class Home3 extends Component{
    render(){

      const { classes } = this.props;

        return(
          <div className="home3">
            <Grid container>
              <Grid item lg={1} md={1} />
              <Grid item lg={11} md={11} sm={12} xs={12}>
                <div className="container home3-title">
                  <div className="how-to-invest">Cara Berinvestasi di Provesty</div>
                  <div className="invest-content">Investasi Flipping Properti, dalam 3 langkah mudah</div>
                </div>
              </Grid>
            </Grid>
            <div className="home3-bg">
              <div className="container">
                <div className="home3-content-container">
                  <Grid container spacing={16}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <div className="ta-center">
                        <img src={Cari} className="img-home-3" alt="" style={{marginTop: 50}}/>
                      </div>
                      <div className="home3-content">
                        <b>Temukan</b> properti Flipping yang menarik untukmu. Pastikan kamu mengetahui risk dan return dari tiap pilihan.
                      </div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={12} xs={12}>
                      <Hidden smDown>
                        <ChevronRight className={classes.iconic}/>
                      </Hidden>
                      <Hidden mdUp>
                        <div style={{width: '100%', textAlign: 'center'}}>
                          <KeyboardArrowDown className={classes.iconicmobile}/>
                        </div>
                      </Hidden>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <div className="ta-center">
                        <img src={Invest} alt="" className="img-home-3" style={{marginTop: 20}}/>
                      </div>
                      <div className="home3-content">
                        <b>Investasikan</b> uangmu secara online. Kamu juga dapat menginvestasikan kembali hasil return dari portfolio sebelumnya.
                      </div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={12} xs={12}>
                      <Hidden smDown>
                        <ChevronRight className={classes.iconic}/>
                      </Hidden>
                      <Hidden mdUp>
                        <div style={{width: '100%', textAlign: 'center'}}>
                          <KeyboardArrowDown className={classes.iconicmobile}/>
                        </div>
                      </Hidden>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <div className="ta-center">
                        <img src={Return} alt="" className="img-home-3"/>
                      </div>
                      <div className="home3-content">
                        <b>Dapatkan</b> imbal hasil jangka pendek dari proyek Flipping properti ketika properti tersebut terjual atau di akhir masa tenor.
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

Home3.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home3);
