import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import How1 from 'styles/assets/appraisal/howto1.png';
import How2 from 'styles/assets/appraisal/howto2.png';
import How3 from 'styles/assets/appraisal/howto3.png';


export default class HowAppraisal extends Component {
  render(){

    let content = [
      {icon: How1, alt: 'input data', txt: 'Masukkan tipe, luas tanah dan/atau bangunan'},
      {icon: How2, alt: 'pilih lokasi', txt: 'Cari atau klik lokasi pada peta'},
      {icon: How3, alt: 'tunggu hasil', txt: 'ProEngine akan mengkalkulasi harga pasaran'},
    ];

    return(
      <div className="ta-center">
        <h3 style={{marginBottom: 30}}>3 langkah mudah menggunakan ProEngine</h3>
        {/*md and above*/}
        <Hidden smDown>
          <Grid container alignItems="center">
            {
              content.map((x, idx) =>
              <Grid item lg={4} md={4} key={idx}>
                <img src={x.icon} alt={x.alt} style={{ maxWidth: idx===0?120: 110}}/>
              </Grid>)
            }
          </Grid>
          <Grid container alignItems="center" style={{marginTop: 13}}>
            {
              content.map((x, idx) =>
              <Grid item lg={4} md={4} key={idx}>
                <div style={{fontWeight: 'bold'}}>{(idx+1) + '. ' + x.txt}</div>
              </Grid>)
            }
          </Grid>
        </Hidden>

        {/*sm*/}
        <Hidden mdUp xsDown>
          {
            content.map((x, idx) =>
            <Grid container alignItems="center" key={idx} spacing={16}>
              <Grid item>
                <div style={{width: 240}}>
                  <img src={x.icon} alt="beli" style={{maxWidth: 100}}/>
                </div>
              </Grid>
              <Grid item sm style={{textAlign: 'left', fontWeight: 'bold'}}>
                {(idx+1) + '. ' + x.txt}
              </Grid>
            </Grid>)
          }
        </Hidden>

        {/*xs*/}
        <Hidden smUp>
          <Grid container alignItems="center" justify="center" direction="column" spacing={16}>
            {

              content.map((x, idx) =>
              <Grid item key={idx}>
                <div style={{ marginTop: 10}}>
                  <img src={x.icon} alt="beli" style={{maxWidth: 120}}/>
                </div>
                <div style={{fontWeight: 'bold', marginTop: 10}}>{(idx+1) + '. ' + x.txt}</div>
              </Grid>

            )
          }
        </Grid>
      </Hidden>
    </div>);
  }
}
