import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import Aman from 'styles/assets/how-works/IconAman.png'
import Terjangkau from 'styles/assets/how-works/IconTerjangkau.png'
import Sekali from 'styles/assets/how-works/IconSekaliKlik.png'
import AsetRiil from 'styles/assets/how-works/IconAsetRill.png'
import Return from 'styles/assets/how-works/IconReturnMenarik.png'
import SektorRiil from 'styles/assets/how-works/IconSektorRill.png'

class How5 extends Component {
  render(){
    return(
      <div className="how5 container">
        <p className="flipping-title">Mengapa Berinvestasi di Provesty?</p>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div style={{paddingTop: 20}}>
              <Grid container spacing={Number(8)}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <div className="circle img-center" style={{marginTop: 10}}>
                    <img src={Terjangkau} alt="icon aman"/>
                  </div>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                <div className="loan3-card">
                  <div className="loan3-title">Terjangkau</div>
                  <div className="loan3-content">Dengan hanya 1 juta rupiah, kamu sudah bisa investasi di Properti dan menikmati keuntungan dan keamanan dari instrumen ini.</div>
                </div>
                </Grid>
              </Grid>
            </div>
            <div style={{paddingTop: 20}}>
              <Grid container spacing={Number(8)}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                <div className="circle img-center" style={{marginTop: 10}}>
                  <img src={Sekali} alt="icon aman"/>
                </div>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                <div className="loan3-card">
                  <div className="loan3-title">Diversifikasi Hanya dengan Sekali Klik</div>
                  <div className="loan3-content">Untuk mengurangi Resiko, kamu bisa dengan mudah mendiversifikasi aset di berbagai Properti melalui platform kami. <i>The eggs are safe in many baskets</i></div>
                </div>
                </Grid>
              </Grid>
            </div>
            <div style={{paddingTop: 20}}>
              <Grid container spacing={Number(8)}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                <div className="circle img-center" style={{marginTop: 10}}>
                  <img src={AsetRiil} alt="icon aman"/>
                </div>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                <div className="loan3-card">
                  <div className="loan3-title">Di Ikat dengan Aset riil</div>
                  <div className="loan3-content">Semua investasi diikat dengan asset riil properti tanah atau bangunan (tangible asset) sehingga resiko capital lost sangat kecil.</div>
                </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div style={{paddingTop: 20}}>
              <Grid container spacing={Number(8)}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                <div className="circle img-center" style={{marginTop: 10}}>
                  <img src={Return} alt="icon aman"/>
                </div>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                <div className="loan3-card">
                  <div className="loan3-title">Return yang Menarik</div>
                  <div className="loan3-content">Dengan skema Flipping Provesty (Beli-Renovasi-Jual), kamu dapat menikmati keuntungan investasi Properti jangka pendek 12-30% per tahun.</div>
                </div>
                </Grid>
              </Grid>
            </div>
            <div style={{paddingTop: 20}}>
              <Grid container spacing={Number(8)}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                <div className="circle img-center" style={{marginTop: 10}}>
                  <img src={Aman} alt="icon aman"/>
                </div>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                <div className="loan3-card">
                  <div className="loan3-title">Aman & Terpercaya</div>
                  <div className="loan3-content">Kami melakukan due diligence ketat pada Properti dan partner flipper. Urusan legal, notaris dll juga ditangani tim kami yang berpengalaman</div>
                </div>
                </Grid>
              </Grid>
            </div>
            <div style={{paddingTop: 20}}>
              <Grid container spacing={Number(8)}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                <div className="circle img-center" style={{marginTop: 10}}>
                  <img src={SektorRiil} alt="icon aman"/>
                </div>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                <div className="loan3-card">
                  <div className="loan3-title">Berkontribusi di Sektor Rill</div>
                  <div className="loan3-content">Melalui Provesty, kamu turut berkontribusi dalam sektor riil, dengan membuka lapangan kerja dan menyediakan rumah bagi yang membutuhkan</div>
                </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default How5
