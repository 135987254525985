import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Step1 from 'styles/assets/loan/step1.png';
import Step2 from 'styles/assets/loan/step2.png';
import Step3 from 'styles/assets/loan/step3.png';
import Step4 from 'styles/assets/loan/step4.png';
import Step5 from 'styles/assets/loan/step5.png';
import Step6 from 'styles/assets/loan/step6.png';
import VerticalLine from 'styles/assets/loan/vertical-line.png';
import Hidden from '@material-ui/core/Hidden'
import LineToRight from 'styles/assets/loan/line-to-right.png';
import LineToLeft from 'styles/assets/loan/line-to-left.png';


class Loan3New extends Component {
  render(){


    return(
      <div className="container">
        <div className="ta-center" style={{marginBottom: 35, marginTop: 50}}>
          <div className="loan1-title">Cara jual rumah di Prohouse by Provesty</div>
        </div>

        {/*layout for web and tab*/}
        <Hidden xsDown>

          {/*Step 1*/}
          <Grid container>
            <Grid item lg={1} md={1} sm={1} xs={false} />
            <Grid item lg={2} md={2} sm={2} xs={12}>
              <div style={{textAlign:'right'}}>
                <img className="img-about-2" src={Step1} alt="step 1" style={{width: 137.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={false}>
              <div className="ta-center">
                <img className="img-about-2" src={VerticalLine} alt="vertical line" style={{width: 2.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="loan1-subtitle">
                Isi data rumah
                yang akan dijual
              </div>
              <div style={{marginTop: 20}}>
                Lengkapi data rumah yang akan dijual dengan mengakses Beli Rumah Saya dengan mengisi form yang ada
              </div>
            </Grid>
          </Grid>

          <div className="ta-center">
            <img src={LineToRight} alt="line to right" style={{width: '63%', height: 50, marginRight: '5%', marginTop: 20, marginBottom: 20}} />
          </div>

          {/*Step 2*/}
          <Grid container>
            <Grid item lg={1} md={1} sm={1} xs={false} />
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div style={{textAlign:'right'}}>
                <div className="loan1-subtitle">
                  Rumah direview
                </div>
                <div style={{marginTop: 20}}>
                  Rumah yang akan dijual akan diminta melengkapi data legal dan akan melalui proses review dan survey oleh tim Provesty.
                </div>
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={false}>
              <div className="ta-center">
                <img className="img-about-2" src={VerticalLine} alt="vertical line" style={{width: 2.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={12}>
              <div style={{textAlign:'left'}}>
                <img className="img-about-2" src={Step2} alt="step 2" style={{width: 137.5, height: 145.5}}  />
              </div>
            </Grid>
          </Grid>

          <div className="ta-center">
            <img src={LineToLeft} alt="line to left" style={{width: '63%', height: 50, marginRight: '5%', marginTop: 20, marginBottom: 20}} />
          </div>

          {/*Step 3*/}
          <Grid container>
            <Grid item lg={1} md={1} sm={1} xs={false} />
            <Grid item lg={2} md={2} sm={2} xs={12}>
              <div style={{textAlign:'right'}}>
                <img className="img-about-2" src={Step3} alt="step 3" style={{width: 137.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={false}>
              <div className="ta-center">
                <img className="img-about-2" src={VerticalLine} alt="vertical line" style={{width: 2.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="loan1-subtitle">
                Rumah dijual
              </div>
              <div style={{marginTop: 20}}>
                Rumah yang sudah didaftarkan dan direview akan dijual oleh agen berpengalaman kami secara offline dan online
              </div>
            </Grid>
          </Grid>

          <div className="ta-center">
            <img src={LineToRight} alt="line to right" style={{width: '63%', height: 50, marginRight: '5%', marginTop: 20, marginBottom: 20}} />
          </div>

          {/*Step 4*/}
          <Grid container>
            <Grid item lg={1} md={1} sm={1} xs={false} />
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div style={{textAlign:'right'}}>
                <div className="loan1-subtitle">
                  dana talangan
                </div>
                <div style={{marginTop: 20}}>
                  Jika membutuhkan dana talangan selama rumah dijual. Dapatkan 50% dari harga pasar harga rumah. Tim akan menawarkan pilihan dana talangan yang kemudian tim kami akan menganalisis lebih lanjut sesuai syarat dan ketentuan.
                </div>
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={false}>
              <div className="ta-center">
                <img className="img-about-2" src={VerticalLine} alt="vertical line" style={{width: 2.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={12}>
              <div style={{textAlign:'left'}}>
                <img className="img-about-2" src={Step4} alt="step 4" style={{width: 137.5, height: 145.5}}  />
              </div>
            </Grid>
          </Grid>

          <div className="ta-center">
            <img src={LineToLeft} alt="line to left" style={{width: '63%', height: 50, marginRight: '5%', marginTop: 20, marginBottom: 20}} />
          </div>

          {/*Step 5*/}
          <Grid container>
            <Grid item lg={1} md={1} sm={1} xs={false} />
            <Grid item lg={2} md={2} sm={2} xs={12}>
              <div style={{textAlign:'right'}}>
                <img className="img-about-2" src={Step5} alt="step 5" style={{width: 137.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={false}>
              <div className="ta-center">
                <img className="img-about-2" src={VerticalLine} alt="instant selling" style={{width: 2.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="loan1-subtitle">
                <i>Instant Selling</i>
              </div>
              <div style={{marginTop: 20}}>
                Jika membutuhkan penjualan rumah secara mendesak. Provesty akan menawarkan proses <i>instant selling</i>. Provesty akan menawarkan opsi membeli rumah 100% dengan harga yang disetujui.
              </div>
            </Grid>
          </Grid>

          <div className="ta-center">
            <img src={LineToRight} alt="line to right" style={{width: '63%', height: 50, marginRight: '5%', marginTop: 20, marginBottom: 20}} />
          </div>

          {/*Step 6*/}
          <Grid container>
            <Grid item lg={1} md={1} sm={1} xs={false} />
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div style={{textAlign:'right'}}>
                <div className="loan1-subtitle">
                  Rumah terjual
                </div>
                <div style={{marginTop: 20}}>
                  Nikmati kemudahan terjual
                </div>
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={false}>
              <div className="ta-center">
                <img className="img-about-2" src={VerticalLine} alt="vertical line" style={{width: 2.5, height: 145.5}}  />
              </div>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={12}>
              <div style={{textAlign:'left'}}>
                <img className="img-about-2" src={Step6} alt="step 6" style={{width: 137.5, height: 145.5}}  />
              </div>
            </Grid>
          </Grid>


        </Hidden>

        {/*layout for mobile apps*/}

        <Hidden smUp>

          {/*step 1 mobile*/}
          <div>
            <div className="ta-center loan-step1">
              Lengkapi data rumah yang akan dijual dengan mengakses Beli Rumah Saya dengan mengisi form yang ada
            </div>
            <div className="ta-center">
              <div className="arrow-down">
                <Icon style={{fontSize: 48}}>double_arrow</Icon>
              </div>
            </div>
          </div>
          <div>
            <div className="ta-center loan-step2">
              Rumah yang akan dijual akan diminta melengkapi data legal dan akan melalui proses review dan survey oleh tim Provesty.
            </div>
            <div className="ta-center">
              <div className="arrow-down">
                <Icon style={{fontSize: 48}}>double_arrow</Icon>
              </div>
            </div>
          </div>
          <div>
            <div className="ta-center loan-step3">
              Rumah yang sudah didaftarkan dan direview akan dijual oleh agen berpengalaman kami secara offline dan online
            </div>
            <div className="ta-center">
              <div className="arrow-down">
                <Icon style={{fontSize: 48}}>double_arrow</Icon>
              </div>
            </div>
          </div>
          <div>
            <div className="ta-center loan-step4">
              Jika membutuhkan dana talangan selama rumah dijual. Dapatkan 50% dari harga pasar harga rumah. Tim akan menawarkan pilihan dana talangan yang kemudian tim kami akan menganalisis lebih lanjut sesuai syarat dan ketentuan.
            </div>
            <div className="ta-center">
              <div className="arrow-down">
                <Icon style={{fontSize: 48}}>double_arrow</Icon>
              </div>
            </div>
          </div>
          <div>
            <div className="ta-center loan-step5">
              Jika membutuhkan penjualan rumah secara mendesak. Provesty akan menawarkan proses <i>instant selling.</i> Provesty akan menawarkan opsi membeli rumah 100% dengan harga yang disetujui.
            </div>
            <div className="ta-center">
              <div className="arrow-down">
                <Icon style={{fontSize: 48}}>double_arrow</Icon>
              </div>
            </div>
          </div>
          <div>
            <div className="ta-center loan-step6">
              Nikmati kemudahan rumah terjual
            </div>
          </div>
        </Hidden>

        <div style={{height: 100}} />
      </div>
    )
  }
}

export default Loan3New
