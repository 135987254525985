import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider';
import { NavLink } from "react-router-dom";


export default class BlogPrevNext extends Component {

  render() {
    var isLoading = this.props.isLoading;
    var {prev, next} = this.props.data;

    var rootClass = '';
    if(isLoading) {
      rootClass += ' loading';
    }
    return(
      <div className={rootClass} style={{marginTop: 30}}>
        <Grid container alignItems="center" spacing={16}>
          <Grid item>
            {prev ?
              <NavLink to={'/blog-detail/' + prev.ps_slug} className="casual-link">
                <Button className="button-blog-prevnext">Post Sebelumnya</Button>
              </NavLink>
              :
              <Button disabled={true}>Post Sebelumnya</Button>
            }
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
          <Grid item>
            {next ?
              <NavLink to={'/blog-detail/' + next.ps_slug} className="casual-link">
                <Button className="button-blog-prevnext">Post Berikutnya</Button>
              </NavLink>
              :
              <Button disabled={true}>Post Berikutnya</Button>
            }
          </Grid>
        </Grid>
      </div>
    )
  }
}
