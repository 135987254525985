import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { price_d2r } from 'helpers';
import { PAYSTAT } from '_constant';

export default function PaymentDetail({isLoading, pt = {}, pw = {}, pro_title, pro_price_per_slot, iv_slot, iv_calc_value}) {

  if(isLoading)
  return '';

  var admin_fee = 0;

  var payments = [
    {title: 'Harga/Slot ' + pro_title, value: price_d2r(pro_price_per_slot) + ',-' },
    {title: 'Jumlah Slot', value: iv_slot},
    {title: 'Total Investasi', value: price_d2r(iv_calc_value) + ',-'},
    {title: 'Biaya Admin', value: price_d2r(admin_fee) + ',-'},
    {title: 'Dibayar dengan Wallet', value: '-' + (pw.py_amount? price_d2r(pw.py_amount) : price_d2r('')) + ',-'},
  ];

  var leftover = 0;
  if (pt.py_status === PAYSTAT.CONFIRMED) {
    payments.push({
      title: 'Pembayaran Transfer', value: '-' + price_d2r(pt.py_amount)
    })
    leftover = iv_calc_value - admin_fee - (pw.py_amount? pw.py_amount : 0) - pt.py_amount;
  } else { // no transfer is required
    leftover = iv_calc_value - admin_fee - (pw.py_amount? pw.py_amount : 0);
  }

  return(
    <Paper className="soft-paper">
      <div className="paper-title">
        Detail Pembayaran
      </div>
      <div className="paper-body" style={{paddingBottom: 9}}>
        <Grid container spacing={16}>
          {
            payments.map((x, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={6} sm={8}>
                    {x.title}
                  </Grid>
                  <Grid item xs={6} sm={4} className="paper-value" style={index <= 1? {color: 'inherit'} : {}}>
                    {x.value}
                  </Grid>
                </React.Fragment>
              )
            })
          }
        </Grid>
      </div>

      <div className="paper-body" style={{ backgroundColor: '#fbfbfb'}}>
        <Grid container spacing={16} alignItems="center" >
          <Grid item xs={6} sm={8}>
            Sisa yang harus ditransfer
          </Grid>
          <Grid item xs={6} sm={4} className="paper-value">
            { price_d2r(leftover) },-
          </Grid>
        </Grid>
      </div>

    </Paper>
  )
}
