import React, { Component } from 'react';
import ResetPasswordComponent from 'components/auth/ResetPasswordComponent';
import Logo from 'styles/assets/logo.png'
import { NavLink } from 'react-router-dom'

export default class ResetPassword extends Component {

  render(){
    return(
      <div className="login-page">
        <div className="container container-login">
          <NavLink to='/'>
            <img src={Logo} alt="logo-white" style={{zIndex: 1000, width: 150, marginTop: 26, cursor: 'pointer'}} />
          </NavLink>
          <div id="auth-root">
            <ResetPasswordComponent history={this.props.history} />
          </div>
        </div>

      </div>
    )
  }
}
