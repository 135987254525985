import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid'
import { imgPath } from 'helpers';
import DefAvatarPic from "styles/assets/default-avatar-pic.png";
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import { NavLink } from "react-router-dom";
import ContentLoader from 'react-content-loader'

export default class BlogRecentPost extends Component {

  render() {
    var isLoading = this.props.isLoading;
    var list = this.props.list;

    var rootClass = 'popular-posts';
    if(isLoading) {
      rootClass += ' loading';
    }

    return(
      <div className={rootClass}>
        <Grid container alignItems="center" spacing={16}>
          <Grid item>
            <Chip label="Post Populer" variant="outlined" classes={{root: 'mychip', label: 'mychip-label'}} />
          </Grid>
          <Grid item xs>  
            <Divider />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={16} style={{marginTop: 16}}>
          {
            list.map((x, index) => {
              if(isLoading)
                x = {
                  ps_slug: 'Investasi Properti dengan Strategi Flip',
                  ps_published_tstamp: '16 Januari 2018'
                }
              return (
                <Grid key={index} item xs={12} sm={6} md={12}>

                  <Grid container justify="flex-start"
                    alignItems="center" spacing={16}>
                    <Grid item style={{width: 95}}>
                      {
                        isLoading?
                        <ContentLoader preserveAspectRatio="xMidYMid slice" width={80} height={80} speed={1}>
                          <rect x="0" y="0" rx="40" ry="40" width="80" height="80" />
                        </ContentLoader>
                        :
                        <NavLink to={'/blog-detail/' + x.ps_slug }>
                          <img src={x.ps_image ? imgPath(x.ps_image) : DefAvatarPic} alt="post" className="img-circle img-popular-post" />
                        </NavLink>
                      }
                    </Grid>
                    <Grid item xs>
                      <NavLink to={'/blog-detail/' + x.ps_slug } className="casual-link color-theme">
                        <div className="loading-effect">{x.ps_title}</div>
                        <div className="loading-effect" style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{x.ps_published_tstamp}</div>
                      </NavLink>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            )
          }
        </Grid>
      </div>
    )
  }
}
