import React, {Component} from 'react';
import Navbar from 'components/general/Navbar'
import Footer from 'components/general/Footer'
import BlogHeader from 'components/blog/BlogHeader'
import BlogListing from 'components/blog/BlogListing'

class Blog extends Component {

  render(){
    return(
      <div className="career-page career-top">
        <Navbar />
        <div style={{margin: "30px 0"}}>
          <BlogHeader />
        </div>
        <div style={{margin: "60px 0px 30px"}}>
          <BlogListing />
        </div>
        <Footer />
      </div>
    )
  }
}

export default Blog;
