// Promise
// pending
// fulfilled
// rejected

import { SET_LISTING, LOAD_LISTING } from '_constant'

export function listing(state = {}, action) {
  // first time
  if (typeof(state.recordsFiltered) === 'undefined')
    state = {
      isLoading: true,
      recordsFiltered: 0,
      data: []
    };

  switch (action.type) {
    case SET_LISTING:
      return {
        isLoading: false,
        recordsFiltered: action.payload.recordsFiltered,
        data: action.payload.data
      }
    case LOAD_LISTING:
      return {
        isLoading: true,
        recordsFiltered: 0,
        data: []
      }
    default:
      return state
  }
}
