import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ProhouseBig from 'styles/assets/prohouse/prohouse-big.png'
import Hidden from '@material-ui/core/Hidden';
// import { withLocalize } from 'react-localize-redux';
import {Link} from 'react-router-dom';


class CarouProhouse extends Component {

  render(){
    return(
      <div className="container" style={{maxWidth:'100%'}}>
        <Grid container alignItems="center" style={{marginTop: 15}}>
          <Grid item lg={1} md={1} sm={false} xs={false} />
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div className="home1-left">
              <div className="home1-title">
                {/*<Translate id={`home1.header`}/>*/}
                Jual Rumahmu Dengan Mudah
              </div>
              {/*<h3><Translate id={`movie1.title`} /></h3>
            <p><Translate id={`movie1.description`} /></p>*/}
            <div className="loan1-subtitle">Lewat PROHOUSE by PROVESTY</div>
            <div className="home1-content">
              Prohouse dari Provesty memberikan akses untuk kamu menjual rumah dengan mudah dan cepat. Dapatkan penawaran <i>cash advance</i> atau opsi <i>instant selling</i> dengan Provesty membeli rumah kamu secara cash dalam 1 hari
            </div>
            <div className="home1-buttons">
              <Grid container spacing={16}>
                {/*<Grid item lg={5} md={6} sm={6} xs={12}>
                <Link to="/how-it-works" style={{textDecoration:'none'}}>
                <Button variant="contained" className="btn-dark-blue">
                Pelajari Investasi
                </Button>
                </Link>
                </Grid>*/}
                <Grid item lg={7} md={12} sm={12} xs={12}>
                  <Link to="/prohouse" style={{textDecoration: 'none'}}>
                    <Button variant="contained" className="btn-dark-blue">
                      Jual Rumahmu Sekarang
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <div className="home1-right img-center">
            <Hidden smDown><img className="img-width" src={ProhouseBig} alt="" /></Hidden>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
}

export default CarouProhouse;
