import React, {Component} from 'react';

import FAQ from 'components/general/FAQ';
import Appr1 from 'styles/assets/appraisal/faq_poly1.jpg'
import Appr2 from 'styles/assets/appraisal/faq_poly2.jpg'

export default class FAQAppraisal extends Component {
  render() {

    let qs = [
      {
        q: 'Bagaimana cara mengkalkulasi perkiraan harga properti?',
        a: 'ProEngine mencari data properti dengan tipe yang sama (misal: rumah atau apartemen) yang dekat dengan lokasi dan sedang dalam usaha penjualan dalam beberapa bulan terakhir. Properti dengan spesifikasi yang mirip (dari segi luas tanah dan/atau bangunan) akan diprioritaskan sehingga mendapatkan perkiraan yang lebih akurat.'
      },
      {
        q: 'Bukankah selain luas tanah dan bangunan ada banyak faktor yang mempengaruhi harga properti?',
        a: 'Benar, bahkan 2 properti yang berdekatan meski luas tanah dan bangunannya sama kemungkinan besar akan dihargai berbeda dari pemiliknya karna satu atau lain hal seperti misalnya kondisi, atau karena 2 penjual tersebut memiliki perbedaan ekspektasi seberapa cepat propertinya harus terjual. Meski begitu yang paling mempengaruhi variasi harga di <u>satu lingkungan yang serupa</u> adalah 2 faktor tersebut. Ditambah lagi info seputar faktor lain lebih sulit didapatkan dan kadang nilainya terlalu subjektif untuk bahan perbandingan.'
      },
      {
        q: 'Bagaimana jika lokasi yang ingin ketahui harganya bersebelahan dengan area yang sangat berbeda profil harganya? misalnya bersebelahan dengan komplek perumahan elit. Bukankah itu akan mengacaukan perkiraan harga?',
        a: 'Iya, secara umum jika lokasi properti yang menjadi perbandingan berada di lingkungan yang berbeda maka akan mengakibatkan perkiraan harga lokasi yang kita ingin ketahui menjadi kurang akurat. Untuk itu kami menyediakan cara untuk "memaksa" sistem untuk hanya membandingkan properti hanya di daerah yang <u>kita tahu serupa</u>. <br/><br/>Pertama klik tanda polygon di kanan atas peta <img src="'+ Appr1 +'" alt="polygon icon" style="max-width: 100%; height: auto;"/> <br/>Setelah itu kelilingi area yang serupa tersebut dengan mengklik sudut-sudutnya sehingga membentuk polygon tertutup <img src="'+ Appr2 +'" alt="gambar polygon" style="max-width: 100%; height: auto;"/><br /><br />Setelah itu bisa terlihat properti-properti yang dijadikan perbandingan hanya ada di dalam area tersebut.'
      },
      {
        q: 'Apakah harga yang ditampilkan ProEngine bisa dianggap appraisal resmi?',
        a: 'Tidak, harga yang tercantum disini adalah estimasi yang meskipun tidak bisa dianggap hasil appraisal resmi, tapi tetap bisa memberikan gambaran awal secara cepat tentang harga properti di lingkungan tersebut. Jika kamu membutuhkan jasa appraisal resmi, bisa menghubungi tim kami karena kami juga menyediakan jasanya.'
      },
      {
        q: 'Kenapa terkadang daerah yang saya tertarik untuk tahu harganya ternyata kurang data pembanding?',
        a: 'Kekurangan data bisa terjadi karena beberapa hal, dan yang paling memungkinkan karena kurangnya penjualan di daerah tersebut dalam beberapa bulan terakhir. Faktor lainnya adalah data yang tersedia di daerah tersebut tidak mencantumkan info lokasi geografis.'
      },
      {
        q: 'Kenapa beberapa data pembanding terkadang berkumpul menjadi satu di lokasi yang persis sama?',
        a: 'Hal itu karena terkadang data yang ProEngine temui mempunyai lokasi data yang tidak spesifik atau tidak ada sama sekali sehingga disatukan di satu titik yang sama. Titik-titik ini biasanya adalah titik tengah dari suatu area (misalnya kawasan khusus, kelurahan, atau kota)'
      }
    ];

    let splitIdx = 3;

    return (<FAQ qs={qs} splitIdx={splitIdx} />);

  }
}
