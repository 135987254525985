import React, { Component } from 'react';
import Logo from 'styles/assets/logo.png'
import { NavLink } from 'react-router-dom'
import Auth from 'components/auth/Auth'


export default class Login extends Component {

  render(){
    return(
      <div className="login-page">
        <div className="container container-login">
          <NavLink to='/'>
            <img src={Logo} alt="logo-white" style={{zIndex: 1000, width: 150, marginTop: 26, cursor: 'pointer'}} />
          </NavLink>
          <div id="auth-root">
            <Auth history={this.props.history} source="login" />
          </div>
        </div>
      </div>
    )
  }
}
