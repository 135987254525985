import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";



export default class Privacy extends React.Component {



  render() {

    return (
      <div className="career-page">
        <div className="career-top">
          <Navbar />

          <div className="container">
            <h3>Privacy Policy for Provesty</h3>
            <p>If you require any more information or have any questions about our privacy policy, please feel free to contact us by email at <a href="mailto:info@provesty.com">mailto:info@provesty.com</a>.</p>

            <p>At Provesty.com we consider the privacy of our visitors to be extremely important. This privacy policy document describes in detail the types of personal information is collected and recorded by provesty.com and how we use it.</p>

            <h4>Personal Data</h4>
            <p>We don’t sell your personal data to advertisers, and we don’t share information that directly identifies you (such as your name, email address or other contact information) with advertisers. Password is stored in encrypted format so not even the administrator know them.</p>

            <h4>Log Files</h4>
            <p>Like many other Web sites, Provesty.com makes use of log files. These files merely logs visitors to the site - usually a standard procedure for hosting companies and a part of hosting services's analytics. The information inside the log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks. This information is used to analyze trends, administer the site, track user's movement around the site, and gather demographic information. IP addresses, and other such information are not linked to any information that is personally identifiable.</p>

            <h4>Online Privacy Policy Only</h4>
            <p>This privacy policy applies only to our online activities and is valid for visitors to our website and regarding information shared and/or collected there. This policy does not apply to any information collected offline or via channels other than this website.</p>

            <h4>Consent</h4>
            <p>By using our website, you hereby consent to our privacy policy and agree to its terms.</p>

            <h4>Update</h4>
            <p>This Privacy Policy was last updated on: Saturday, September 14th, 2019.</p>

            <i>Should we update, amend or make any changes to our privacy policy, those changes will be posted here.</i>

          </div>
        </div>
        <div className="container">
          <Footer />
        </div>
      </div>

    )
  }
}
