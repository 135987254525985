import React, {Component} from 'react';
import ReactFullpage from '@fullpage/react-fullpage'
import ProvestyBig from 'styles/assets/logo-07big.png'
import OnlyLogo from 'styles/assets/only_logo.png'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Section31 from 'styles/assets/artboard/section3-1.png'
import Section32 from 'styles/assets/artboard/section3-2.png'
import Section33 from 'styles/assets/artboard/section3-3.png'
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { apiHelper, dispatchMsg, date_d2r } from 'helpers';
import { APISTAT } from '_constant';
import SelebrationAudio from 'audio/selebrasi.mp3';
import BuildingAudio from 'audio/bangunan.mp3';
import CountUp from 'react-countup';
import PlayStore from 'styles/assets/playstore.png'
import AppStore from 'styles/assets/app_store.svg'


class UserArtboard extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: {},
      isLoading: false,
      thirdPageCount: false,
      fourthPageCount: false,
      fifthPageCount: false,
    };
  }

  componentDidMount(){
    this.loadData();
  }

  loadData = () => {

    this.setState({ isLoading: true });

    apiHelper.request(this, {
      resource: 'rewind',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS ) { // success
          // incase user info is updated from somewhere else, update here
          this.setState({ isLoading: false, data: resp.data});
        } else
        throw new Error(resp.msg);
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }

  roundUp = (num, precision) => {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
  }

  intToFloat = (num, decPlaces) => {
    return num + '.' + Array(decPlaces + 1).join('0');
  }

  _price_d2r_custom =(number)  =>{
    var _number = parseFloat(isNaN(number) ? 0 : number);
    let formatted = 0;
    let unit = '';
    if (_number > 10000 && _number < 1000000) {// 10 rb - 1 jt => 124 rb
      formatted = parseFloat((_number / 1000).toFixed(3)) ;
      unit = 'ribu';
    } else if (_number >= 1000000 && _number < 1000000000) { // 1 - 10 jt -> e.g. 5,84 jt
      formatted = parseFloat((_number / 1000000).toFixed(3)) ;
      unit = 'juta';
    } else if(_number >= 1000000000){ // > 1 M -> 5,34 M
      formatted = parseFloat((_number / 1000000000).toFixed(3)) ;
      unit = 'milyar';
    } else {
      formatted = _number;
      unit = 'rupiah';
    }

    return {formatted: parseFloat(formatted), unit};

  }

  onFullpageLeave = ( origin, destination, direction) => {
    //get current anchor
    if (destination.anchor==="firstPage"){

    } else if(destination.anchor==="secondPage"){

    } else if (destination.anchor==="thirdPage"){

      // document.getElementById("my-audio").play();

      let promise = document.querySelector('#my-audio').play();

      if (promise !== undefined) {
        promise.then(_ => {
          // Autoplay started!
        }).catch(error => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
        });
      }


    } else if (destination.anchor==="fourthPage"){

    } else if (destination.anchor==="fifthPage"){

      // document.getElementById("my-audio-2").play();

      let promise = document.querySelector('#my-audio-2').play();

      if (promise !== undefined) {
        promise.then(_ => {
          // Autoplay started!
        }).catch(error => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
        });
      }

    } else {

    }

    if (destination.anchor === "thirdPage"){
      this.setState({ thirdPageCount: false }, () => this.setState({ thirdPageCount: true }));
    }
    if (destination.anchor === "fourthPage"){
      this.setState({ fourthPageCount: false }, () => this.setState({ fourthPageCount: true }));
    }
    if (destination.anchor === "fifthPage"){
      this.setState({ fifthPageCount: false }, () => this.setState({ fifthPageCount: true }));
    }
    // if (origin.anchor === "thirdPage") {
    //   this.setState({ thirdPageCount: false });
    // }

    if(document.getElementById("title-section-2") !== null)
    document.getElementById("title-section-2").className = destination.anchor==="secondPage" ? "fade-in-fwd" : "";

    if(document.getElementById("title-section-3") !== null)
    document.getElementById("title-section-3").className = destination.anchor==="thirdPage" ? "bounce-in-top" : "";

    if(document.getElementById("section-4-content") !== null)
    document.getElementById("section-4-content").className = destination.anchor==="fourthPage" ? "bounce-in-top" : "";

    if(document.getElementById("welcome-2020") !== null)
    document.getElementById("welcome-2020").className = destination.anchor==="sixthPage" ? "bounce-in-top" : "";
  }


  render(){

    var { data, isLoading } = this.state;

    var delay = 5;
    var decimals = 2;
    var decimal = ',';
    var duration = 2;
    // short style only support indonesian

    var {formatted: data_invest, unit: data_invest_unit} = this._price_d2r_custom(data.lifetime_invest);
    var {formatted: data_profit, unit: data_profit_unit} = this._price_d2r_custom(data.lifetime_profit);
    var {formatted: invest_total, unit: invest_total_unit} = this._price_d2r_custom(data.investment_total);
    var {formatted: profit_total, unit: profit_total_unit} = this._price_d2r_custom(data.profit_total);

    // uppercase the first letter for some unit
    invest_total_unit = invest_total_unit.charAt(0).toUpperCase() + invest_total_unit.slice(1);
    profit_total_unit = profit_total_unit.charAt(0).toUpperCase() + profit_total_unit.slice(1);

    let countContent = {fontWeight: 'bold', fontFamily: "'Open Sans', sans-serif"}

    var section_5_text = <React.Fragment>
      Begitu banyak hal yang sudah kita lewati bersama.
      Karena kamu tahun ini kami bisa menyediakan
      <span style={countContent}> <CountUp
        delay={0}
        duration={2}
        start={0}
        end={this.state.fifthPageCount ? data.project_total: 0} /> properti
      </span>
      &nbsp;untuk diinvestasikan, mengumpulkan dana sebesar
      <span style={countContent}> Rp <CountUp
        delay={0}
        decimals={decimals}
        decimal={decimal}
        duration={2}
        start={0}
        end={this.state.fifthPageCount ? invest_total: 0} /> {invest_total_unit}
      </span>,
      membagikan
      <span style={countContent}> Rp <CountUp
        delay={0}
        decimals={decimals}
        decimal={decimal}
        duration={2}
        start={0}
        end={this.state.fifthPageCount ? profit_total: 0} /> {profit_total_unit}
      </span> imbal hasil, serta menyediakan aplikasi <i>mobile</i> yang bisa kamu download
      di Playstore dan Apps Store.
    </React.Fragment>;

    return(
      <ReactFullpage

        menu={"#myMenu"}

        scrollBar = {false}
        controlArrows = {true}

        navigation = {window.innerWidth <= 600 ? false : true}
        navigationPosition = {'left'}

        anchors= {['firstPage', 'secondPage', 'thirdPage', 'fourthPage', 'fifthPage', 'sixthPage']}

        // fullpage options
        licenseKey = {'59A170B6-9AAA4AE2-B8886230-AC182940'}
        scrollingSpeed = {1000} /* Options here */

        onLeave={this.onFullpageLeave}

        render={({
          state,
          fullpageApi,
        }) => {

          return(
            <ReactFullpage.Wrapper>

              {/*section 1*/}
              <div className="section">
                <div className="artboard-1">
                  <div className="container artboard-content">
                    <div>
                      <div className="fade-in">
                        <img src={ProvestyBig} alt="big logo" width={250} height={75}/>
                      </div>
                    </div>
                    <div className="about-title"> Hai {isLoading?<span style={{backgroundColor: 'lightgray', color:'lightgray' }}>Haidar</span>:data.u_nickname}, tidak terasa 2019 sudah mau berakhir.</div>
                    <div>
                      <p>scroll ke bawah atau gunakan tombol panah menurun di keyboard untuk melihat perjalananmu bersama provesty</p>
                    </div>
                    <div className="down-infinite">
                      <Icon style={{fontSize: 72}}>double_arrow</Icon>
                    </div>
                  </div>
                </div>
              </div>

              {/*section 2*/}
              <div className="section">
                <div className="artboard-2">
                  <div className="container artboard-content">
                    <div id="title-section-2">
                      <div className="about-title">
                        Terima kasih telah bersama dengan kami dan memajukan pembangunan Indonesia.
                      </div>
                    </div>
                    <div className={this.state.secondSlide === true ?"bounce-in-top":""}>
                      <div>
                        <p>Berikut kilas balik perjalanan kamu sejak  <b>{date_d2r(data.join_date)}</b> dengan Provesty</p>
                      </div>
                    </div>
                    <img src={OnlyLogo} alt="big logo" width={200} height={175} style={{marginBottom: 30}}/>
                  </div>
                  <div className="artboard-2-bottom" />
                </div>
              </div>

              {/*section 3*/}
              <div className="section">
                <div className="artboard-2">
                  <audio id="my-audio">
                    <source id="src_mp3" src={SelebrationAudio} type="audio/mpeg" />
                    <source id="src_ogg" type="audio/ogg" src=""/>
                    <object id="audio_object" type="audio/x-mpeg" width="200px" height="45px" data={SelebrationAudio}>
                      <param id="param_src" name="src" value={SelebrationAudio} />
                      <param id="param_src" name="src" value={SelebrationAudio} />
                      <param name="autoplay" value="true" />
                      <param name="muted" value="muted" />
                      <param name="autostart" value="false" />
                    </object>
                  </audio>

                  <div className="container artboard-3-content" style={{width:'100%', textAlign:'center'}}>
                    <Hidden smDown>
                      <div className="about-title" id="title-section-3" style={{fontSize: 24, fontWeight:'bold'}}>
                        Bersama PROVESTY kamu telah
                      </div>
                      <Grid container alignItems="center">
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <div className="about-title" style={{maxWidth: 250, marginTop: 100}}>
                            <div>Berinvestasi sebanyak</div>
                            <div style={{...countContent, ...{fontSize: 60}}}>
                              <CountUp
                                delay={delay}
                                duration={duration}
                                decimals={this.state.thirdPageCount && (data_invest % 1)? decimals: 0}
                                decimal={decimal}
                                start={0}
                                end={this.state.thirdPageCount ? data_invest: 0}
                                />
                            </div>
                            <div>{data_invest_unit}</div>
                          </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <div className="about-title" style={{maxWidth: 250, marginLeft: 20}}>
                            <div>dengan total imbal hasil</div>
                            <div style={{...countContent, ...{fontSize: 60}}}>
                              <CountUp
                                delay={delay}
                                duration={duration}
                                decimals={this.state.thirdPageCount && (data_profit % 1)? decimals: 0}
                                decimal={decimal}
                                start={0}
                                end={this.state.thirdPageCount ? data_profit: 0}
                                />
                            </div>
                            <div>{data_profit_unit}</div>
                          </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <div className="about-title" style={{maxWidth: 250, marginLeft: 30, marginTop:100}}>
                            <div>dan jumlah rumah yang diinvestasi</div>
                            <div style={{...countContent, ...{fontSize: 60}}}>
                              <CountUp
                                delay={delay}
                                duration={duration}
                                start={0}
                                end={this.state.thirdPageCount ? parseInt(data.lifetime_project): 0}
                                />
                            </div>
                            <div>buah</div>
                          </div>
                        </Grid>
                      </Grid>
                    </Hidden>

                    <Hidden mdUp>
                      <Grid container direction="column" justify="space-between" alignItems="center">
                        <Grid>
                          <div className="about-title"  id="title-section-3"  style={{fontSize: 24, fontWeight:'bold'}}>
                            Bersama PROVESTY kamu telah
                          </div>
                        </Grid>
                        <Grid>
                          <div style={{maxWidth: 250}}>
                            <div>Berinvestasi sebanyak</div>
                            <div className="about-title" style={{fontSize: 16, fontWeight:'bold', marginTop: 15}}>
                              <img src={Section31} alt="total invest" width={50} height={50} style={{marginRight:15}} />
                              <div>
                                <CountUp
                                  delay={delay}
                                  duration={duration}
                                  decimals={this.state.thirdPageCount && (data_invest % 1)? decimals: 0}
                                  decimal={decimal}
                                  start={0}
                                  end={this.state.thirdPageCount ? data_invest: 0}
                                  />
                                <br/>{data_invest_unit}</div>
                              </div>
                            </div>
                          </Grid>
                          <Grid>
                            <div style={{maxWidth: 250 , marginTop: 20}}>
                              <div>dengan total imbal hasil</div>
                              <div className="about-title" style={{fontSize: 16, fontWeight:'bold', marginTop: 15}}>
                                <img src={Section32} alt="total imbal hasil" width={50} height={50} style={{marginRight: 15}} />
                                <div>
                                  <CountUp
                                    delay={delay}
                                    duration={duration}
                                    decimals={this.state.thirdPageCount && (data_profit % 1)? decimals: 0}
                                    decimal={decimal}
                                    start={0}
                                    end={this.state.thirdPageCount ? data_profit: 0}
                                    />
                                  <br/>{data_profit_unit}</div>
                                </div>
                              </div>
                            </Grid>
                            <Grid>
                              <div style={{maxWidth: 250, marginTop: 20}}>
                                <div>dan jumlah rumah yang diinvestasi</div>
                                <div className="about-title" style={{fontSize: 16, fontWeight:'bold', marginTop: 15}}>
                                  <img src={Section33} alt="jumlah rumah" width={50} height={50} style={{marginRight: 15}} />
                                  <div>
                                    <CountUp
                                      duration={1}
                                      delay={3}
                                      start={0}
                                      end={this.state.thirdPageCount ? parseInt(data.lifetime_project): 0}
                                      />
                                    <br/>buah</div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Hidden>
                        </div>
                        <div style={{width:'100%', textAlign:'center'}}>
                          <Hidden smDown>
                            <div id="title-section-3">
                              <div className="artboard-3-bottom"/>
                            </div>
                          </Hidden>
                        </div>
                      </div>
                    </div>

                    {/*section 4*/}
                    <div className="section">
                      <div className="artboard-4">
                        <div className="container artboard-content">
                          <div className="about-title">
                            Kamu membantu perkembangan properti tanah air lewat PROVESTY sebesar &nbsp;
                            <span style={{fontSize: 27, fontWeight: 'bold', fontFamily: 'sans-serif'}}>
                              <CountUp
                                delay={delay}
                                duration={duration}
                                decimals={decimals}
                                decimal={decimal}
                                start={0}
                                end={this.state.fourthPageCount ? parseFloat(this.roundUp(data.lifetime_project/35*100,2)) : 0}
                                />
                              %
                            </span>
                            &nbsp;
                            dari jumlah proyek PROVESTY yang telah berjalan.
                          </div>
                        </div>
                        <div style={{width:'100%', textAlign:'center'}}>
                          <div className="artboard-4-bottom" />
                        </div>
                      </div>
                    </div>

                    {/*section 5*/}
                    <div className="section">
                      <div className="artboard-2">
                        <audio id="my-audio-2">
                          <source id="src_mp3" src={BuildingAudio} type="audio/mpeg" />
                          <source id="src_ogg" type="audio/ogg" src=""/>
                          <object id="audio_object" type="audio/x-mpeg" width="200px" height="45px" data={SelebrationAudio}>
                            <param id="param_src" name="src" value={SelebrationAudio} />
                            <param id="param_src" name="src" value={SelebrationAudio} />
                            <param name="autoplay" value="true" />
                            <param name="muted" value="muted" />
                            <param name="autostart" value="false" />
                          </object>
                        </audio>
                        <Hidden lgUp>
                          <div className="container artboard-content">
                            <div>
                              <div className="section-5-font mobile-justify" style={{fontSize:18}}>
                                {section_5_text}
                              </div>
                            </div>
                          </div>
                        </Hidden>
                        <Hidden mdDown>
                          <div className="container">
                            <div className="section-5-font" style={{paddingTop: 100}}>
                              {section_5_text}
                            </div>
                          </div>
                        </Hidden>
                        <div style={{width:'100%', textAlign:'center'}}>
                          <div className="artboard-5-bottom" />
                        </div>
                      </div>
                    </div>

                    {/*section 6*/}
                    <div className="section">
                      <div className="artboard-6">
                        <div className="container artboard-content">
                          <div>
                            <p className="about-title">
                              Sekali lagi, Terima kasih telah bersama dengan kami
                              dan memajukan pembangunan Indonesia,
                              karena kamu, kita jauh lebih baik dan semua #bisaakses
                            </p>
                          </div>
                          <div className="about-title" id="welcome-2020" style={{fontSize: 32}}>
                            <b>
                              <div>WELCOME</div>
                              <div>2020</div>
                            </b>
                          </div>
                          <br/>
                          <div>
                            <Grid container spacing={16}>
                              <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Button variant="outlined" className="fullWidth button-transparent" style={{width: 240}} onClick={()=>{fullpageApi.moveTo(3)}}>
                                  Lihat Lagi Statistik
                                </Button>
                              </Grid>
                              <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Button variant="outlined" className="fullWidth button-navy" style={{width: 240}} onClick={()=>{this.props.history.push( "");}}>
                                  Pergi ke Beranda
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                          <br /><br />
                          <div className="about-title" style={{fontSize: 16}}>Jangan lupa unduh aplikasi</div>
                          <Grid container direction="row">
                            <Grid item xs container justify="flex-end">
                              <a href="https://play.google.com/store/apps/details?id=com.provesty.provesty" target="_blank" rel="noopener noreferrer">
                                <img src={PlayStore} alt="Google Play" style={{width: 150, maxWidth: '100%'}} />
                              </a>
                            </Grid>
                            <Grid item xs container justify="flex-start">
                              <a href="https://apps.apple.com/us/app/provesty/id1481804972" target="_blank" rel="noopener noreferrer">
                                <img src={AppStore} alt="App Store" style={{width: 150, maxWidth: '100%', marginTop: 8}} />
                              </a>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>

                  </ReactFullpage.Wrapper>
                );
              }}
              />
          )
        }
      }



      export default UserArtboard;
