import React, { Component } from 'react';
import Navbar from 'components/general/Navbar';
import Footer from 'components/general/Footer';
import PropInvest from 'components/detail/PropInvest';
import PropCarousel from 'components/detail/PropCarousel';
import PropStat from 'components/detail/PropStat';
import PropDetail from 'components/detail/PropDetail';
import Grid from '@material-ui/core/Grid';
import { apiHelper, userHelper, dispatchMsg, escapeHTML, imgPath } from 'helpers';
import { APISTAT } from '_constant';
import {Helmet} from 'react-helmet';


class PropDetailPage extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: {},
      isLoading: true,
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: userHelper.isLogin() ? 'sproperty' : 'property', // use the secured one if loggedin
      urlParams: [ this.props.match.params.pro_slug],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, data: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ data: [], isLoading: false });
      }
    });
  }

  render(){

    const { data, isLoading } = this.state;

    return(
      <div className="bg-blue-to-white">
        {
          !isLoading && data.pro_title?
          <Helmet>
            <title>{data.pro_title} | Provesty</title>
            <meta property="og:title" content={data.pro_title} />
            <meta name="description" property="og:description" content={escapeHTML(data.pro_short_desc)} />
            <meta name="keywords" content={'flipping rumah, danai pinjaman, invetasi properti, pendanaan properti, return tinggi, alternatif, jangka pendek, ' + data.rg_name + ', ' + data.pr_name + ', ' + data.ivt_name}  />
            <meta property="og:image" content={imgPath(data.meta_img)} />
            <meta name="author" content="Team Provesty" />
          </Helmet>
          :
          ''
        }
        <Navbar />
        <div className="container prop-detail-page">
          {/*<div style={{width:'100%', overflow: 'hidden' }}>*/}
          <Grid container direction="row" spacing={24}>
            <Grid item lg={8} md={7} xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <PropCarousel isLoading={isLoading} {...data} />
                </Grid>
                <Grid item xs={12}>
                  <PropDetail isLoading={isLoading} {...data} history={this.props.history} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={5} sm={12} xs={12}>
              <Grid container spacing={24}>
                <Grid item md={12} sm={12} xs={12}>
                  <PropStat isLoading={isLoading} {...data}/>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <PropInvest isLoading={isLoading} {...data} history={this.props.history}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*</div>*/}
        </div>
        <Footer />
      </div>
    )
  }
}

export default PropDetailPage
