import React from 'react';
// import PropTypes from 'prop-types';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
// import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MyTextField from "components/general/MyTextField";
import MyUploadImage from "components/general/MyUploadImage";
import ReactSelect from "components/general/ReactSelect";
import Grid from '@material-ui/core/Grid';
import { formVal, formData, apiHelper, dataHelper, dispatchMsg } from 'helpers';
import { scroller } from 'react-scroll';
import { APISTAT, DATAVAR } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-spinkit';
import KTP from "styles/assets/ktp.png";
import NPWP from "styles/assets/npwp.png";

function getSteps() {
  return ['Lengkapi Informasi Data Personal', 'Lengkapi Informasi Bank & Dokumen'];
}

export default class CredentialDocument extends React.Component {

  static defaultProps = {
    history: null
  }

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      completed: {},

      id_bank: '',
      bac_no: '',
      bac_owner_name: '',
      bac_branch: '',
      u_ic_no: '',
      u_tax_no: '',
      u_ic_img: '',
      u_tax_img: '',

      u_ic_img_init: '',
      u_tax_img_init: '',

      banks: {},

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  // form
  handleInputChange = event => {

    var name = event.target.name;
    var val = event.target.value;

    if (name === 'u_tax_no') {
      // format tax to xx.xxx.xxx.x-xxx.xxx
      let nums = val.replace(/\D/g,'');
      val = nums.substring(0,2) + (nums.length > 2? '.' : '' ) + nums.substring(2,5) + (nums.length > 5? '.' : '' ) + nums.substring(5,8) + (nums.length > 8? '.' : '' ) + nums.substring(8,9) + (nums.length > 9? '-' : '' ) + nums.substring(9,12) + (nums.length > 12? '.' : '' ) + nums.substring(12,15);
      this.setState({ [name]: val });
    } else if (name === 'u_ic_no') {
      let nums = val.replace(/\D/g,'');
      val = nums.substring(0,16);
      this.setState({ [name]: val });
    } else {
      this.setState({ [name]: val });
    }
  };

  validate = () => {

    this.errors = {};

    formVal(this, 'id_bank', 'Nama', 'required');
    formVal(this, 'bac_branch', 'Cabang', 'required');
    formVal(this, 'bac_owner_name', 'Atas Nama', 'required');
    formVal(this, 'bac_no', 'Nomor Rekening', 'required');
    formVal(this, 'u_ic_no', 'No KTP', 'required');
    formVal(this, 'u_tax_no', 'No NPWP', ' ');
    formVal(this, 'u_ic_img', 'Scan KTP', 'requiredImg', 'u_ic_img_init');
    formVal(this, 'u_tax_img', 'Scan NPWP', ' ', 'u_tax_img_init');

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      scroller.scrollTo('form-credential', {duration: 300, delay: 100, smooth: 'easeInOutCubic'});
      return false;
    }
    return true;
  }

  sendDataInvest = (dataInvest) => {

    this.setState({ buttonLoading: true });

    var {pro_slug, ...data} = dataInvest;

    apiHelper.request(this, {
      resource: 'invest',
      urlParams: [ dataInvest.pro_slug],
      data: formData(data),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          dispatchMsg('success', 'Investasi berhasil dibuat.');

          // GTM
          if(window.dataLayer) {
            window.dataLayer.push({event:'User Invest', iv_calc_value: parseInt(resp.data.iv_calc_value)});
          }

          this.setState({ buttonLoading: false }, () => {this.props.history.push( "/payment/"+ pro_slug + '-' + resp.data.id_investment)});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({buttonLoading: false});
      },
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    // clear the errors
    this.setState({ buttonLoading: true });

    apiHelper.request(this, {
      resource: 'credential_document_post',
      data: formData('form-credential'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          // check if user invested before
          var dataInvest = dataHelper.get(DATAVAR.INVEST, true);
          if(dataInvest) {
            this.sendDataInvest(dataInvest);
          } else {
            this.props.history.push("/Dashboard");
          }
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false });
      },
    });
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'credential_document_get', // use the secured one if loggedin
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }


  render() {

    const steps = getSteps();
    const { activeStep } = this.state;
    const stepButtons = steps.map((label, index) => {
      return (
        <Step key={label} name="stepper" >
          <StepLabel classes={{label: 'step-label'}} StepIconProps={{classes: {root: 'step-icon', active: 'step-icon-active', completed: 'step-icon-completed'}}}>
            {label}
          </StepLabel>
        </Step>
      );
    });

    const content =
    <Grid container spacing={16}>
      <Grid item sm={6}>
        <ReactSelect name="id_bank" label="Nama Bank" obj={this} options={Object.keys(this.state.banks).map(k => ({k: k, v:this.state.banks[k]}))} required />
      </Grid>
      <Grid item sm={6}>
        <MyTextField name="bac_branch" label="Cabang" obj={this} required />
      </Grid>
      <Grid item sm={6}>
        <MyTextField name="bac_owner_name" label="Atas Nama" obj={this} required />
      </Grid>
      <Grid item sm={6}>
        <MyTextField name="bac_no" label="Nomor Rekening" obj={this} required />
      </Grid>
      <Grid item sm={12} container alignItems="flex-end">
        <Grid item sm >
          <MyTextField name="u_ic_no" label="Nomor KTP" obj={this} required type="tel" />
        </Grid>
        <Grid item>
          <MyUploadImage name="u_ic_img" label="Scan KTP" obj={this} ButtonProps={{className:"button-upload-doc button-blue"}} ImgProps={{style: {maxWidth: 200}}} buttonContent={<span>Upload Scan KTP &nbsp;<i className="material-icons button-icon">cloud_upload</i></span>}
            defaultImg={KTP}  required />
        </Grid>
      </Grid>
      <Grid item sm={12} container alignItems="flex-end">
        <Grid item sm>
          <MyTextField name="u_tax_no" label="Nomor NPWP (Optional)" obj={this} type="tel" />
        </Grid>
        <Grid item>
          <MyUploadImage name="u_tax_img" label="Scan NPWP" obj={this} ButtonProps={{className:"button-upload-doc button-blue"}} ImgProps={{style: {maxWidth: 200}}} buttonContent={<span>Upload Scan NPWP &nbsp;<i className="material-icons button-icon">cloud_upload</i></span>}
            defaultImg={NPWP} />
        </Grid>
      </Grid>
    </Grid>;

    const formContent = content;

    const stepperContent = this.state.isLoading? <div style={{height: 500}}><Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" /></div>:
    <form onSubmit={this.handleSubmit} id="form-credential" autoComplete="off" name="form">
      {formContent}
      <div className="sms-modal-buttons" style={{marginTop: 55, marginBottom: 30, textAlign: 'center'}}>
        <div className="button-progress-wrapper">
          <Button disabled={this.state.buttonLoading} className="btn-stepper-next button-blue" type="submit" style={{width: 120}}>Simpan</Button>
          {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
        </div>
      </div>
    </form>;

    return (
      <div>
        <div className="bg-lightblue">
          <Navbar />
          <div className="container credential-page">
            <Paper >
              <Stepper activeStep={activeStep} alternativeLabel className="stepper-label">
                {stepButtons}
              </Stepper>
            </Paper>
            <Paper className="cred-area">
              <div className="stepper-content">
                {stepperContent}
              </div>
            </Paper>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
