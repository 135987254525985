import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import { APISTAT } from '_constant';
import Slider from "react-slick";
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import PropertiesItem from './PropertiesItem';
import Hidden from '@material-ui/core/Hidden';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { apiHelper, dispatchMsg, formData } from 'helpers';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

const styles = theme => ({
  icon: {
    marginTop: 25,
    fontSize: 60,
    color: '#3d7dc8'
  }
});

class Home5 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {

    this.setState({ isLoading:true });

    // cancel previous if any
    apiHelper.cancelAllRequest(this);

    apiHelper.request(this, {
      resource: 'listing',
      data: formData({start: 0, length: 5}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({
            data: resp.data.data,
            isLoading: false,
          });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ data: [], isLoading: false });
      }
    });
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {

    const {isLoading, data} = this.state;
    const lists = isLoading? [0,0,0,0,0,0] : data;

    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '0px',
      variableWidth: true,
      slidesToShow: 3,
      speed: 300,
      responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          infinite: true,
          variableWidth: true
        }
      },
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            speed: 150,
            dots: true,
            centerMode: true,
            variableWidth: true
          }
        },
        {
          breakpoint: 350,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            speed: 150,
            dots: true,
            centerMode: true,
            variableWidth: true
          }
        }
      ]
      };

    // const { classes } = this.props
    return (<div className="home5 container">
    <div className="home5-container ta-center">
      <div style={{
          margin: '0 auto',
          marginBottom: 80,
          maxWidth: 650
        }}>
      </div>
      <Grid container>
        <Hidden smDown>
          <Grid item lg={1} md={1} sm={false} xs={false}>
            <Fab onClick={this.previous} style={{marginRight: 10, backgroundColor: '#d2ecf9', color: '#3d7dc8', marginTop: 200}}>
              <ChevronLeft />
            </Fab>
          </Grid>
        </Hidden>
        <Grid item lg={10} md={10} sm={12} xs={12}>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {lists.map((item, index) => {
              return(
                <div key={index} className="ciomo">
                  <PropertiesItem isLoading={isLoading} {...item}/>
                </div>
              )
            })}
          </Slider>
        </Grid>
        <Hidden smDown>
          <Grid item lg={1} md={1} sm={false} xs={false}>
            <Fab color="primary" onClick={this.next} style={{marginLeft: 10, backgroundColor: '#d2ecf9', color: '#3d7dc8', marginTop: 200}}>
              <ChevronRight />
            </Fab>
          </Grid>
        </Hidden>
      </Grid>
    </div>
    <div style={{width: '100%', textAlign:'center', paddingBottom: 30, marginTop: 50}}>
      <Link to="/invest" style={{textDecoration:'none'}}>
        <Button className="button-lihat-proyek">
          Lihat Semua Proyek
        </Button>
      </Link>
    </div>
  </div>)
}
}

Home5.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home5);
