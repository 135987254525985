import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

class SkemaPinjaman extends Component{
  render(){
    return(
      <div className="fade-in-right skema-pinjaman">
        <Grid container>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <div className="oval-3" />
          </Grid>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <p className="skema-kontent">
              Skema yang digunakan bisa jual beli (Murabahah) atau sewa (Ijarah), yaitu investor membeli propertinya terlebih dahulu dengan skema crowdfunding, dengan kepemilikan dikuasakan melalui badan hukum Provesty. Untuk skema sewa, unit ini kemudian disewakan ke flipper dan kemudian dibayarkan seluruh pokok dan marginnya ketika rumah terjual
            </p>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <div className="oval-3" />
          </Grid>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <p className="skema-kontent">
              Jangka waktu dapat kurang dari 6 bulan hingga 1 tahun
            </p>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <div className="oval-3" />
          </Grid>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <p className="skema-kontent">
              Imbal hasil mulai dari 15% - 30% per tahun
            </p>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SkemaPinjaman;
