import React, {
  Component
} from 'react';


export default class Ribbon extends Component {
  render() {
    return (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 47">
          <g fill="none" fillRule="evenodd">
            <path fill="#2E80DE" d="M5 0h340a5 5 0 0 1 5 5v28H0V5a5 5 0 0 1 5-5z"/>
            <path fill="#2E80DE" d="M41.703 47L16 23h50.043z"/>
            <text fill="#FFF" fontFamily="Montserrat-Bold, Montserrat" fontSize="15" fontWeight="bold" letterSpacing=".1">
            <tspan x="87.362" y="21">Menerima Pendanaan</tspan>
            </text>
          </g>
        </svg>
      </div>
    )
  }
}
