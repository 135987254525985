import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import ChevronRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import ProhouseLeft from 'styles/assets/prohouse/prohouse-left.png'
import ProhouseCenter from 'styles/assets/prohouse/prohouse-center.png'
import ProhouseRight from 'styles/assets/prohouse/prohouse-right.png'
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
  iconic: {
    fontSize: 48,
    marginTop: 100,
    color: '#0f3c68',
    margin: '0 auto',
    justifyContent: 'center',
  },
  iconicmobile:{
    fontSize: 60,
    color: '#0f3c68',
  },
});

class Prohouse2 extends Component{
    render(){

      const { classes } = this.props;

        return(
          <div className="prohouse2-height">
            <Grid container>
              <Grid item lg={1} md={1} />
              <Grid item lg={11} md={11} sm={12} xs={12}>
                <div className="container home3-title">
                  <div className="how-to-invest">Mudahnya Menjual Rumah di Prohouse by Provesty!</div>
                  <p className="invest-content">isi data rumah yang kamu ingin jual, dan tunggu tim kita untuk menjual rumah kamu.</p>
                </div>
              </Grid>
            </Grid>
            <div className="home3-bg" style={{minHeight: 0}}>
              <div className="container">
                <div className="home3-content-container">
                  <Grid container spacing={16}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <div className="ta-center">
                        <img src={ProhouseLeft} className="img-prohouse"  alt=""/>
                      </div>
                      <div className="home3-content">
                        <b>LANGKAH 1</b>
                        <br/>
                        isi data rumah kamu.
                      </div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={12} xs={12}>
                      <Hidden smDown>
                        <ChevronRight className={classes.iconic}/>
                      </Hidden>
                      <Hidden mdUp>
                        <div style={{width: '100%', textAlign: 'center'}}>
                          <KeyboardArrowDown className={classes.iconicmobile}/>
                        </div>
                      </Hidden>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <div className="ta-center">
                        <img src={ProhouseCenter} style={{height:210, objectFit:'contain'}} alt=""/>
                      </div>
                      <div className="home3-content" style={{marginTop:-15}}>
                        <b>LANGKAH 2</b>
                        <br/>
                        ProHouse pasarkan rumah kamu.
                      </div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={12} xs={12}>
                      <Hidden smDown>
                        <ChevronRight className={classes.iconic}/>
                      </Hidden>
                      <Hidden mdUp>
                        <div style={{width: '100%', textAlign: 'center'}}>
                          <KeyboardArrowDown className={classes.iconicmobile}/>
                        </div>
                      </Hidden>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <div className="ta-center">
                        <img src={ProhouseRight} alt="" className="img-prohouse"/>
                      </div>
                      <div className="home3-content">
                        <b>LANGKAH 3</b>
                        <br/>
                        Rumah terjual semua senang.
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

Prohouse2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Prohouse2);
