import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import ProhouseLogo from 'styles/assets/prohouse/prohouse-logo.png'
import ProhouseBig from 'styles/assets/prohouse/prohouse-big.png'
import {Link as Scroller} from 'react-scroll'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'

export default class Prohouse1 extends Component {
  render(){
    return(
    <div className="">
      <div className="home1 container">
        <Grid container className="home1-grid" alignItems="flex-end">
          <Grid item lg={1} />
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <div className="home1-left">
            <div>
              <img src={ProhouseLogo} alt="" width="185" height="58.5" />
            </div>
            <br />
            <div className="home1-title">
              Rumah Kamu Sulit Terjual ?
            </div>
            <div className="loan1-subtitle">
              Pusing Menaksir Harga Rumah ?
            </div>
            <Hidden mdUp>
              <div className="home1-right img-center">
                <img src={ProhouseBig} alt="" className="img-width"/>
              </div>
            </Hidden>
            <p className="home1-content">
              Menjual rumah bukan lagi menjadi hal yang sulit.
              ProHouse by Provesty hadir untuk memberikan
              solusi baru kamu untuk menjual rumah.
            </p>
            <div className="prohouse-button-location">
              <Grid container>
                <Grid item lg={5} md={6} sm={6} xs={12}>
                  <Scroller activeClass="active" value="how-2" to={this.props.buttonLink} spy={true} smooth={true} duration={1000} className="casual-link">
                    <Button className="btn-dark-blue" variant='contained'>Jual Sekarang</Button>
                  </Scroller>
                </Grid>
              </Grid>
            </div>
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Hidden smDown>
              <div className="home1-right img-center">
                <img src={ProhouseBig} alt="" className="img-width"/>
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </div>
    )
  }
}
