import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

class BagiHasil extends Component{
    render(){
        return(
            <div className="fade-in-left skema-pinjaman">
                <Grid container>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <div className="oval-3" />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                        <p className="skema-kontent">
                        Skema yang digunakan adalah profit sharing. Yaitu investor dan Flipper mengumpulkan dana untuk membeli Properti. Merenovasinya. Kemudian menjualnya kembali. Margin keuntungan yang didapat dibagi antara investor dan Flipper sesuai persetujuan yang sudah disepakati.
                        </p>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <div className="oval-3" />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                        <p className="skema-kontent">
                        Skema bagi hasil lebih beresiko dari pinjaman, namun investor juga bisa mendapatkan imbal hasil lebih tinggi jika properti terjual lebih cepat
                        </p>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                        <div className="oval-3" />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                        <p className="skema-kontent">
                        Pastikan kamu mempelajari resiko di setiap investasi yang akan kamu lakukan
                        </p>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default BagiHasil;
