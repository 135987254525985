import React, { Component } from 'react';
import Navbar from 'components/general/Navbar';
import Footer from 'components/general/Footer';
import JobPostingDeception from 'components/home/JobPostingDeception';
import CarouInvest from 'components/home/CarouInvest';
import CarouAppraisal from 'components/home/CarouAppraisal';
import CarouProhouse from 'components/home/CarouProhouse';
//import Home1Slide1 from 'components/home/Home1Slide1';
import Home2 from 'components/home/Home2';
import Home3 from 'components/home/Home3';
import Home5 from 'components/home/Home5';
import Statistics from 'components/general/Statistics';
import ForFlipper from 'components/general/ForFlipper';
import Testimoni from 'components/home/Testimoni';
import ScrollAnimation from 'react-animate-on-scroll';
import Dialog from '@material-ui/core/Dialog';
import Zoom from '@material-ui/core/Zoom';
import {dataHelper} from 'helpers';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
// import ChevronRight from '@material-ui/icons/ChevronRight';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import Fab from '@material-ui/core/Fab'
import SliderWrapper from "styles/SlickSliderStyle";
import Icon from '@material-ui/core/Icon'
import { GP } from '_constant'



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Home extends Component {

  constructor(props){
    super(props);
    this.state = {
      isVisible: true,
      showWelcome: false // hide welcome
    };
  }

  hidePopup = () => {
    this.setState({showWelcome:false});
    dataHelper.set("welcomeShown", "dismissMobile");
  }

  //componentWillMount() {
  //dataHelper.clear("welcomeShown"); // for testing the mobile welcome
  //}

  componentDidMount() {
    if(dataHelper.get("welcomeShown")===null) {
      setTimeout(() => {
        // show only after a second
        // this.setState({showWelcome: true});
      }, 1500);
    }
  }

  render(){

    const coba_settings = {
      className: 'center',
      infinite: true,
      centerMode:true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      centerPadding: '0px',
      dots: true,
      appendDots: dots => <ul>{dots}</ul>,
      customPaging: i => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
      responsive: [
        {
          breakpoint: 1920,
          settings: {
          }
        },
        {
          breakpoint: 959,
          settings: {
            initialSlide: 0,
            speed: 150,
          }
        }
      ]
    };

    return(
      <div className="detail-product-width">
        <div className="nav-home">
          <Navbar />
          <div className="home1 container" style={{textAlign:'center', minHeight: 500}}>
            <div className="home1-container">
              <SliderWrapper>
                <Slider {...coba_settings} ref={c => (this.slider = c)}>
                  <div>
                    <CarouInvest />
                  </div>
                  <div>
                    <CarouAppraisal />
                  </div>
                  { GP.SHOW_PROHOUSE &&
                    <div>
                      <CarouProhouse />
                    </div>
                  }
                </Slider>
              </SliderWrapper>
            </div>
            <div className="down-infinite" style={{marginVertical: 35}}>
              <Icon style={{fontSize: 48}}>double_arrow</Icon>
            </div>
          </div>
          <div className="home2-padding" />
        </div>

        <Home2 />
        <div className="bg-lightgreen">
          <div className="home2-padding" />
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Home3 />
          </ScrollAnimation>
        </div>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <Statistics />
        </ScrollAnimation>
        <div className="wavy">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Home5 />
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Testimoni />
          </ScrollAnimation>
          { GP.SHOW_PROHOUSE &&
            <ForFlipper/>
          }
          <Footer />
        </div>
        <div>
          {
            <Dialog
              open={this.state.showWelcome}
              onClose={this.hidePopup}
              TransitionComponent={Transition}
              keepMounted
              PaperProps={{style: {margin: 10, borderRadius: 20, overflow:'hidden'}}}
              >
              <JobPostingDeception closeButton={this.hidePopup}/>
            </Dialog>
          }
        </div>
      </div>
    )
  }
}

export default Home
