import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { price_d2r, imgPath } from 'helpers';
import DefaultPropImg from 'styles/assets/default-project-image.png';

export default class InvestmentDetail extends Component {

  render() {
    var {isLoading, img_path, pro_title, iv_calc_value, pro_duration, pro_start_project_date, pro_return, pro_repayment_scheme, guarantees = []} = this.props;

    if(isLoading)
    return '';

    return(
      <Paper className="soft-paper">
        <div className="paper-title">
          Review & Invoice
        </div>
        <div className="paper-body">
          <Grid container spacing={16}>
            <Grid item xs={12} sm={5}>
              <img src={img_path ? imgPath(img_path): DefaultPropImg} alt="property" style={{borderRadius: 6, maxWidth: '100%'}} />
            </Grid>
            <Grid item xs={12} sm={7}>
              {pro_title}
            </Grid>
          </Grid>
          <div className="paper-body-inverse" style={{marginTop: 20, marginBottom: 20, border: 'dashed 2px #e6e6e6'}} />
          <Grid container spacing={16}>
            {
              [
                {title: 'Jumlah Investasi', value: price_d2r(iv_calc_value)},
                {title: 'Jangka Waktu', value: pro_duration},
                {title: 'Imbal Hasil', value: pro_return},
                {title: 'Waktu Dimulai', value: pro_start_project_date},
              ].map(x =>
                <Grid key={x.title} item xs={6}>
                  <div className="py-title">{x.title}</div>
                  <div className="py-content">{x.value}</div>
                </Grid>
              )

            }
          </Grid>
          <div className="paper-body-inverse" style={{marginTop: 20, marginBottom: 20, border: 'dashed 2px #e6e6e6'}} />
          <Grid container spacing={16}>
            {
              [
                {title: 'Pembayaran', value: pro_repayment_scheme},
                {title: 'Jaminan', value: (guarantees.length ?
                  <ul style={{paddingLeft: 17, margin: 0}}>{
                      guarantees.map((x, index) => <li key={index}>{x.gu_name}</li>)
                    }
                  </ul> : '')
                }
              ].map(x => {
                if(x.value) {
                  return <Grid item key={x.title} xs={12}>
                    <div className="py-title">{x.title}</div>
                    <div className="py-content">{x.value}</div>
                  </Grid>
                } else {
                  return '';
                }
              })
            }
          </Grid>
        </div>

      </Paper>
    )
  }
}
