import { connect } from 'react-redux';
import MyMessage from 'components/general/MyMessage';
import { closeMessage } from 'redux/actions';

const mapStateToProps = (state) => ({
  ...state.message
})
const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => closeMessage(dispatch)
  }
}

const MessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyMessage)

export default MessageContainer;
