import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Appraise from 'styles/assets/appraisal/appraise.png';

export default class InfoAppraisal extends Component {

  render(){
    return(
      <div className='how-width how1'>
        <Grid container alignItems="center">
          <Grid item lg={1} />
          <Grid item lg={5} md={6} sm={12}>
            <div className="how1-left">
              {/*<h3><Translate id= {`movie2.title`} /></h3>
            <p><Translate id={`movie2.description`} /></p>*/}
            <div className="loan1-title">Cek Nilai Properti Tanpa Perlu Keluar Rumah</div>
            <Hidden mdUp>
              <div className="how1-image">
                <img src={Appraise} alt="appraisal" className="img-responsive"/>
              </div>
            </Hidden>
            <p className="loan1-content">ProEngine adalah mesin penghitung yang memudahkan kamu melihat estimasi harga pasaran properti di hampir semua area populer di Indonesia. Teknologi ProEngine memantau data jutaan penawaran harga dalam beberapa bulan terkahir untuk menentukan estimasi harga yang sesuai</p>
          </div>
        </Grid>

        <Grid item lg={5} md={6} sm={12}>
          <Hidden smDown>
            <div style={{paddingLeft: 15}}>
              <img src={Appraise} alt="loan" className="img-responsive"/>
            </div>
          </Hidden>
        </Grid>
        <Grid item lg={1} />

      </Grid>
    </div>
  )
}
}
