import React, { Component } from 'react';
import Navbar from 'components/general/Navbar';
import Footer from 'components/general/Footer';
import About1 from 'components/about/About1';
import About2 from 'components/about/About2';
import About3 from 'components/about/About3';
import About4 from 'components/about/About4';
import CallUs from 'components/about/CallUs';
import OurPartner from 'components/about/OurPartner';
import Ojk from 'components/general/Ojk';
import Hidden from '@material-ui/core/Hidden'
import { Element } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
// import AboutPartner from 'components/about/AboutPartner';

class AboutUs extends Component {

  render(){
    return(
      <div className="mobile-justify">

        <div className="about-section">
          <Navbar />
          <div className="container">
            <About1 to="call-us"/>
            <About2 />
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <About3/>
            </ScrollAnimation>
          </div>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <About4/>
          </ScrollAnimation>
        </div>

        <div style={{backgroundImage: "linear-gradient(to top, #ffffff, #f1f8ff)"}}>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} style={{animationFillMode: 'inherit'}}>
            <OurPartner />
          </ScrollAnimation>
          <Element name="call-us">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <CallUs/>
            </ScrollAnimation>
          </Element>
        </div>
        <div className="bg-lightgreen-reverse">
          <Footer />
        </div>
        <Hidden>
          <div className="wavy" style={{display:"none"}}>
            <div className="ojk-container">
              <Ojk />
            </div>
          </div>
        </Hidden>
      </div>

    )
  }
}

export default AboutUs;
