import React, {Component} from 'react';
import BigLogo from 'styles/assets/logo-17big.png'
import CloseIcon from '@material-ui/icons/Close';



export default class ProhousePopUp extends Component{
  render(){
    return(
      <div className="modal-prohouse-content mobile-center ta-center" style={{color:'white'}}>
        <div id="mobile-app-header">
          <CloseIcon onClick={this.props.closeButton} />
        </div>
        <div style={{width: '100%', textAlign:'center'}}><img src={BigLogo} alt="Alert" style={{height: 50}} /></div>
        <p style={{fontSize: 24, fontWeight:'bold'}}>Yeay! Data kamu sudah kami terima.</p>
        <p>Tunggu tim kami akan menghubungi kamu maksimal 2 hari dari sekarang.</p>
      </div>
    )
  }
}
