import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FaqIcon from 'styles/assets/how-works/FAQ.png'
// import Button from '@material-ui/core/Button'
// import {Link} from 'react-router-dom'

const styles = theme => ({
  root: {
    // maxWidth: 500,
    margin: '0 auto',
    marginTop: 30,
    marginRight: 10,
    marginLeft: 10,
  },
  container: {
    margin: '10'
  },
  expansion: {
    boxShadow: 'none',
    border: '1px solid #1d5d91',
    color: '#1d5d91',
    backgroundColor: 'white',
  }
});

class FAQ extends Component {
  render() {

    const { classes, qs, splitIdx } = this.props;

    let qsSplit2 = [ qs.slice(0, splitIdx), qs.slice(splitIdx, splitIdx + qs.length)];

    return (
      <React.Fragment>
        <div className="ta-center">
          <img src={FaqIcon} alt="faq" styles={{width: 65}} />
        </div>
        <div className="loan6-title">Pertanyaan Yang Sering Ditanyakan</div>
        <div className="loan6-content">Silahkan hubungi kontak kami yang terletak di bagian Footer jika masih terdapat pertanyaan</div>
        <Grid container spacing={16}>
          {
            qsSplit2.map((arr2, idx) =>
            <Grid key={idx} item lg={6} md={6} sm={12} xs={12}>
              {
                arr2.map((item, i) =>
                <div key={i} className={classes.root}>
                  <ExpansionPanel className={classes.expansion}>
                    <ExpansionPanelSummary classes={{expanded: 'ExpansionPanelSummary-expanded'}} style={{marginBottom: 0}} expandIcon={<ExpandMoreIcon />}>
                      <b dangerouslySetInnerHTML={{
                          __html: item.q
                        }}></b>

                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <p dangerouslySetInnerHTML={{
                            __html: item.a
                          }}>
                        </p>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>)
                }
              </Grid>)
            }
          </Grid>

        </React.Fragment>)
      }
    }

    FAQ.propTypes = {
      classes: PropTypes.object.isRequired,
    };

    export default withStyles(styles)(FAQ);
