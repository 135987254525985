import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Team from 'styles/assets/about-us/team.svg';
import Alexander from 'styles/assets/about-us/alexander.png';
// import Shinta from 'styles/assets/about-us/shinta.png';
// import Ayla from 'styles/assets/about-us/ayla.png';
import Subir from 'styles/assets/about-us/subir.png';
import Virta from 'styles/assets/about-us/virta.png';
import Emmy from 'styles/assets/about-us/emmy.png';
import Pradjoto from 'styles/assets/about-us/pradjoto.png';
// import Fab from '@material-ui/core/Fab';


class About3 extends Component {
  render(){
    return(
      <div className="about3 ta-center">
        <img src={Team} alt="team-provesty" className="team-image"/>
        <div className="about3-title">Advisor Provesty</div>
        <div className="about3-content">Anggota Dewan & Penasehat Provesty dengan pengalaman di industri properti, keuangan & teknologi</div>
        <div className="divider-advisor"/>
        <Grid container spacing={16} justify="center" style={{marginBottom: 60}}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div style={{marginTop: 10}}>
              <div className="about-card-bg about-card-except">
                <div className="about-card-content">
                  <img src={Alexander} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
                </div>
                <div className="advisor-card">
                  <p className="advisor-name">Alexander Rusli</p>
                  <p className="advisor-title">Business Strategy Advisor</p>
                  <p>• Founder & Director DigiAsia Bios</p>
                  <p>• Managing Director NorthStar Pacific</p>
                  <p>• Commissioner Unilever Indonesia</p>
                  <p>• Former CEO Indosat Ooredoo</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div style={{marginTop: 10}}>
              <div className="about-card-bg">
                <div className="about-card-content">
                  <img src={Subir} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
                </div>
                <div className="advisor-card">
                  <p className="advisor-name">Subir Lohani</p>
                  <p className="advisor-title">Finance & Capital Advisor</p>
                  <p>• Chief of Growth DigiAsia Bios</p>
                  <p>• Former CEO Carmudi Indonesia</p>
                  <p>• Assistant VP Barclays Capital</p>
                  <p>• Boston University</p>
                </div>
              </div>
            </div>
          </Grid>
          {/*<Grid item lg={3} md={3} sm={6} xs={12}>
          <div style={{marginTop: 10}}>
          <div className="about-card-bg">
          <div className="about-card-content">
          <img src={Ayla} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
          </div>
          <div className="advisor-card">
          <p className="advisor-name">Ayla Dewi A. Aldjufrie</p>
          <p className="advisor-title">Real Estate Acquisition Advisor</p>
          <p>• Chairwoman Global Property Center</p>
          <p>• Woman World Leading Change UN</p>
          <p>• Vice Treasurer Real Estate Indonesia</p>
          <p>• Founder & Principal Ayla and Associate</p>
          </div>
          </div>
          </div>
          </Grid>*/}
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div style={{marginTop: 10}}>
              <div className="about-card-bg">
                <div className="about-card-content">
                  <img src={Virta} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
                </div>
                <div className="advisor-card">
                  <p className="advisor-name">Virta DC Diputra</p>
                  <p className="advisor-title">Real Estate Risk Advisor</p>
                  <p>• President Director IM Land</p>
                  <p>• Director PT Gaya Properti Sarana</p>
                  <p>• Director PT Bumitama Properti</p>
                  <p>• Director Edelweis Hospital</p>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div style={{marginTop: 10}}>
              <div className="about-card-bg">
                <div className="about-card-content">
                  <img src={Pradjoto} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
                </div>
                <div className="advisor-card">
                  <p className="advisor-name">Pradjoto</p>
                  <p className="advisor-title">Legal & Banking Advisor</p>
                  <p>• Lawyer Pradjoto & Associate</p>
                  <p>• Former Commissioner BNI</p>
                  <p>• Former Commissioner Mandiri</p>
                  <p>• Economic Researcher Kyoto University</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div style={{marginTop: 10}}>
              <div className="about-card-bg">
                <div className="about-card-content">
                  <img src={Emmy} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
                </div>
                <div className="advisor-card">
                  <p className="advisor-name">Emmy Yuhassarie</p>
                  <p className="advisor-title">Legal & Compliance Advisor</p>
                  <p>• Lawyer  E.Y. Ruru & Partners</p>
                  <p>• Domestic Consultant World Bank</p>
                  <p>• Commissioner BFI</p>
                  <p>• Ethic Committee OJK</p>
                </div>
              </div>
            </div>
          </Grid>

        </Grid>
        {/*<div className="advisor-space-2">
          <Grid container spacing={16}>*/}
            {/*<Grid item lg={4} md={4} sm={6} xs={12}>
            <div style={{marginTop: 10}}>
            <div className="about-card-bg">
            <div className="about-card-content">
            <img src={Shinta} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
            </div>
            <div className="advisor-card">
            <p className="advisor-name">Shinta Dhanuwardoyo</p>
            <p className="advisor-title">Digital Strategy Advisor</p>
            <p>• Co-founder & CEO Bubu.com</p>
            <p>• Managing Partner Nusantara Ventures</p>
            <p>• 99 Most Powerful Women Globe Asia</p>
            <p>• Inspiring Women 2013 Forbes Indonesia</p>
            </div>
            </div>
            </div>
            </Grid>*/}
            {/*<Grid item lg={6} md={6} sm={6} xs={12}>
              <div style={{marginTop: 10}}>
                <div className="about-card-bg">
                  <div className="about-card-content">
                    <img src={Pradjoto} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
                  </div>
                  <div className="advisor-card">
                    <p className="advisor-name">Pradjoto</p>
                    <p className="advisor-title">Legal & Banking Advisor</p>
                    <p>• Lawyer Pradjoto & Associate</p>
                    <p>• Former Commissioner BNI</p>
                    <p>• Former Commissioner Mandiri</p>
                    <p>• Economic Researcher Kyoto University</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div style={{marginTop: 10}}>
                <div className="about-card-bg">
                  <div className="about-card-content">
                    <img src={Emmy} alt="img-advisor" style={{width: 146, margin: '0 auto'}}/>
                  </div>
                  <div className="advisor-card">
                    <p className="advisor-name">Emmy Yuhassarie</p>
                    <p className="advisor-title">Legal & Compliance Advisor</p>
                    <p>• Lawyer  E.Y. Ruru & Partners</p>
                    <p>• Domestic Consultant World Bank</p>
                    <p>• Commissioner BFI</p>
                    <p>• Ethic Committee OJK</p>
                  </div>
                </div>
              </div>
            </Grid>*/}
          {/*</Grid>
        </div>*/}
      </div>
    )
  }
}


export default About3
