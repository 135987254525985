import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { PAYSTAT } from '_constant'
import ModalConfirm from 'components/payment/ModalConfirm'
import { apiHelper, dispatchMsg, formData } from 'helpers';
import { APISTAT, STAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

export default class ConfirmPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      potUploaded: false,

      cmVisible: false, // cm = confirm modal
      cmData: {},
    };
  }


  handleConfirm = () => {

    const { pt } = this.props;

    if( !pt || pt.py_status === PAYSTAT.CONFIRMED) {
      dispatchMsg('warning', 'Pembayaran ini sudah terverifikasi');
      return;
    }

    this.setState({ buttonLoading: true});

    apiHelper.request(this, {
      resource: 'confirm_payment',
      data: formData({id_payment: pt.id_payment}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success

          this.setState({ buttonLoading: false, cmVisible: true, cmData: resp.data });

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({  buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false});
      },
    });

  }

  handleReadingImg = (upload) => {

    this.setState({ buttonLoading: true});

    apiHelper.request(this, {
      resource: 'upload_pot',
      data: formData({
        id_payment: this.props.pt.id_payment,
        py_transfer_proof: upload.target.result
      }),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ buttonLoading: false, potUploaded: true });

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({  buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false});
      },
    });
  }

  handleFileChange = (event) => {
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = this.handleReadingImg;
    reader.readAsDataURL(file);
  }

  uploadPot = () => {

    const { pt } = this.props;

    if( !pt || pt.py_status === PAYSTAT.CONFIRMED) {
      dispatchMsg('warning', 'Pembayaran ini sudah terverifikasi');
      return;
    }

    this.setState({ buttonLoading: true});

    apiHelper.request(this, {
      resource: 'confirm_payment',
      data: formData({id_payment: pt.id_payment}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success

          this.setState({ buttonLoading: false, cmVisible: true, cmData: resp.data });

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({  buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false});
      },
    });

  }

  render () {
    const { pt } = this.props;

    var confirm_payment = <React.Fragment>
      <div className="paper-body-title">
        Sudah Melakukan Transfer?
      </div>
      <p>
        Konfirmasi transfer kamu dengan menekan tombol di bawah. Investasi Kamu akan segera diproses ke tahap selanjutnya setelah Tim kami memverifikasi dananya.
      </p>

      <div className="button-progress-wrapper-full" style={{minWidth: 200}}>
        <Button onClick={this.handleConfirm} disabled={this.state.buttonLoading} fullWidth className="button-blue">Saya sudah transfer</Button>
        {this.state.buttonLoading && <CircularProgress size={24} className="button-progress"/>}
      </div>
    </React.Fragment>;

    var upload_pot = <React.Fragment>
      <div className="paper-body-title">
        Sudah Melakukan Transfer?
      </div>
      <p>
        Kamu sudah mengkonfirmasi transfer kamu sebelumnya. Upload bukti transfer untuk mempercepat proses verifikasi.
      </p>

      <div className="button-progress-wrapper-full">
        <Button disabled={this.state.potUploaded || this.state.buttonLoading} variant="contained" component="label" className="button-blue" fullWidth>
          {
            this.state.potUploaded ?
            <React.Fragment><Icon>check</Icon>&nbsp;&nbsp;<span>Berhasil</span></React.Fragment>
            :
            <React.Fragment><Icon>cloud_upload</Icon>&nbsp;&nbsp;<span>Upload</span></React.Fragment>
          }
          <input type="file" style={{display:'none'}} onChange={ this.handleFileChange } accept="image/*" />
        </Button>
        {this.state.buttonLoading && <CircularProgress size={24} className="button-progress"/>}
      </div>
    </React.Fragment>;

    var waiting_admin_confirmation =
    <React.Fragment>
      <div className="paper-body-title">
        Menunggu Konfirmasi Admin
      </div>
      <p>
        Kamu sudah mengkonfirmasi pembayaran serta mengupload bukti transaksi sebelumnya. Admin kami akan segera mengkonfirmasi pembayaran kamu.
      </p>
    </React.Fragment>;

    var no_payment_necessary = <div>Tidak ada transfer yang harus dikonfirmasi
    </div>;

    return (
      <Paper className="soft-paper">
        <div className="paper-title">
          Konfirmasi
        </div>
        <div className="paper-body">
          {
            !pt || pt.py_status === PAYSTAT.CONFIRMED ?
            no_payment_necessary
            :
            ( pt.py_user_confirmed_transfer === STAT.STAT_YES ?
              (pt.py_transfer_proof === ''? upload_pot : waiting_admin_confirmation) :
              confirm_payment
            )
          }

        </div>
        <ModalConfirm
          visible={this.state.cmVisible} key={new Date().toString()} {...this.state.cmData}
          />
      </Paper>
    );
  }
}
