import React from 'react';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {Progress} from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Place from 'styles/assets/place.png';
import Tag from 'styles/assets/tag.png';
import { price_d2r, imgPath } from 'helpers';
import Floater from 'react-floater';
import Icon from '@material-ui/core/Icon';

function PropStat({isLoading, ivt_name, rg_name, pr_name, percentage, pro_price, img_path, pro_title, pro_return, pro_aggr_funded, pro_slug, pro_duration, pro_repayment_scheme, pro_risk, pro_total_slot, backer_num, pro_code,
  remaining_day, remaining_time}) {

    let time_left = '';

    if(isLoading) {
      ivt_name = 'proflip';
      pro_code = 'PRO-00000';
      rg_name = 'Kuningan, Jakarta Selatan';
      pro_title = "Refinancing Unit Fountain Park Apartment, Pancoran, Jakarta";
      img_path = '//:0';
      pro_duration = '1 tahun';
    } else {
      // if there's time left then selective string
      if (remaining_day || remaining_time !== '00:00') {
        if(remaining_day) {
          time_left = remaining_day + ' Hari ';
        } else {
          var tmp = remaining_time.split(':');
          time_left += tmp[0] + ' Jam ' + tmp[1] + ' Menit';
        }
        time_left += ' lagi';
      } else {
        time_left = 'Sudah Selesai';
      }
      img_path = imgPath(img_path);
    }

    //condition for full funded
    const isFull = (percentage === 100);

    const percentage_info =
    <div className="time-remaining">
      Terkumpul:&nbsp;
      {
        isFull ?
        <span style={{color: ' #24b47e'}}><b>{percentage}%</b></span>
        :
        <span style={{color:'#3d7dc8'}}><b>{percentage}% <span style={{float: 'right'}}>{time_left}</span></b></span>
      }
    </div>

    const percentage_symbol =  <Progress
      theme={{active: {
        color: '#3d7dc8',
        trailColor: 'lightblue',
        width: '100%',
      }}}
      percent={percentage}
      className={isFull ? 'progress-full':'progress-unfull'}
      symbolClassName= {isFull ? '':'symbol-hide'} />

    //variable for minimum invest based on total slot
    const minimum_invest = parseInt(pro_price/pro_total_slot);

    return (

      <Card className={'prop-detail-rights soft-paper prop-detail-stat' + (isLoading? ' loading' : '')}>
        <div className="info-offer prop-detail-type">
          <Grid container>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <img src={Tag} alt="tag-icon" width={20}/>
            </Grid>
            <Grid item lg={4} md={4} sm={5} xs={4}>
              <div className="card-info-content">
                <span>{pro_code}</span>
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <img src={Place} alt="tag-icon" width={14}/>
            </Grid>
            <Grid item lg={6} md={6} sm={5} xs={6}>
              <div className="card-info-content">
                <span>{rg_name}, {pr_name}</span>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="card-progress">
          <div className="progress-width" style={{height: 40}}>
            {percentage_info}
            {percentage_symbol}
          </div>
        </div>
        <div>
          <div className="card-detail" style={{paddingTop: 20, paddingBottom: 30}}>
            <Grid container>
              <Grid item xs={6}>
                <div className="card-title-content">
                  Imbal Hasil:
                </div>
                <div className="card-content">
                  {pro_return}
                </div>
                <div className="card-title-content">
                  Durasi Proyek:
                </div>
                <div className="card-content">
                  {pro_duration}
                </div>
                <div className="card-title-content">
                  Resiko:
                </div>
                <div className="card-content">
                  {pro_risk}
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="card-title-content">
                  Terkumpul:
                </div>
                <div className="card-content">
                  {
                    !isLoading ?
                    <span style={{color:'#24b47e'}}>
                      {price_d2r(pro_aggr_funded)}
                      <Floater
                        open={true}
                        placement="top-end"
                        positionWrapper={false}
                        styles={{
                          container: {
                            padding: 7, minWidth: 50, minHeight: 10, borderRadius: 4,
                            color: 'lightyellow',
                            backgroundColor: isFull? '#24b47e' : '#3d7dc8'
                          },
                          arrow: {
                            color: isFull? '#24b47e' : '#3d7dc8',
                            spread: 6
                          }
                        }}
                        offset={1}
                        content={
                          <Grid container alignItems="center">
                            <Icon style={{fontSize: 22, marginRight: 5}}>supervised_user_circle</Icon>
                            <div style={{ fontSize: 12}}>
                              {backer_num} Investor
                            </div>
                          </Grid>
                        }
                        wrapperOptions={{offset: 100}}
                        >&nbsp;</Floater>
                    </span>
                    :
                    price_d2r(pro_aggr_funded)
                  }
                </div>
                <div className="card-title-content">
                  Target:
                </div>
                <div className="card-content">
                  {price_d2r(pro_price)}
                </div>
                <div className="card-title-content">
                  Minimum Investasi:
                </div>
                <div className="card-content">
                  {price_d2r(minimum_invest)}
                </div>
              </Grid>
            </Grid>
            <div>
              *Kamu sudah pernah investasi di proyek ini
            </div>
            <div className="card-content" style={{marginTop: 20, fontStyle: 'italic', backgroundColor: '#F4F9FF', borderRadius: 5, padding: '13px 15px'}}>
              {pro_repayment_scheme}
            </div>
          </div>
        </div>
      </Card>

    );
  }

  export default PropStat;
