import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
//import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
import Easy from 'styles/assets/loan/easy.png'
import Quick from 'styles/assets/loan/quick.png'
import CashAdvanced from 'styles/assets/loan/cash_advanced.png'
import Choices from 'styles/assets/loan/choices.png'



class Loan4New extends Component {
  render() {



    return (<div className="container loan6" style={{marginBottom: 70}}>
      <div className="ta-center">
        <div className="loan1-title">Mengapa harus menjual rumah</div>
        <div className="loan1-subtitle">di Prohouse by Provesty?</div>
        <div style={{height: 30}}/>
        <Grid container spacing={0}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className="ta-center" style={{marginBottom: 20}}>
              <img src={Easy} alt="mudah" width={90} height={100}/>
            </div>
            <div className="loan-title-2">Mudah</div>
            <div className="loan2-content">
              Prohouse memberikan kemudahan akses untuk menjual rumah. Kamu hanya perlu memberikan data rumah, kemudian agen berpengalaman kami untuk melakukan jual-beli.
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className="ta-center" style={{marginBottom: 20}}>
              <img src={CashAdvanced} alt="mudah" width={90} height={100}/>
            </div>
            <div className="loan-title-2"><i>Cash Advance</i></div>
            <div className="loan2-content">
              Kamu bisa mendapatkan dana tunai sebelum rumah terjual.
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className="ta-center" style={{marginBottom: 20}}>
              <img src={Quick} alt="mudah" width={90} height={100}/>
            </div>
            <div className="loan-title-2">Cepat</div>
            <div className="loan2-content">
              Rumah kamu bisa juga dibeli oleh Provesty secara instan.
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className="ta-center" style={{marginBottom: 20}}>
              <img src={Choices} alt="mudah" width={90} height={100}/>
            </div>
            <div className="loan-title-2">Beragam Opsi Jual</div>
            <div className="loan2-content">
              Kamu bisa jual rumah dengan 3 skema yang kami sediakan.
            </div>
          </Grid>
        </Grid>
      </div>
    </div>)
  }
}


export default Loan4New;
