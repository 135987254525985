import React, {Component} from "react";

export default class Navbar extends Component {

  // use fix to avoid jump scroll
  render() {
    return (
      <div style={{height: 100}} />
    );
  }
}
