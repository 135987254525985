import React, {Component} from 'react';
import Navbar from 'components/general/Navbar';
import PropList from 'components/boards/PropList';
import Footer from 'components/general/Footer';
import ForFlipper from 'components/general/ForFlipper';
import 'styles/index.css';
import Statistics from 'components/general/Statistics';
import { GP } from '_constant'

class Listing extends Component {

  render(){

    return(
      <div className="" style={{}}>
        <div className="boards">
          <div style={{backgroundImage:'linear-gradient(to top, #ffffff, #f1f8ff)'}}>
            <Navbar />
            <Statistics />
            <div>
              <PropList />
            </div>
          </div>
          <div className="wavy pushupx">
            { GP.SHOW_PROHOUSE &&
              <ForFlipper/>
            }
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default Listing;
