import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
// import Dollar from 'styles/assets/dollar.png';
// import Round from 'styles/assets/round.png';
// import Time from 'styles/assets/time.png';
// import Question from 'styles/assets/question.png';
// import Asset2 from 'styles/assets/asset2.png'
//import RenovLoan from 'styles/assets/loan/renov_loan.png';
//import Plus from 'styles/assets/loan/plus.png';
import TitipJual from 'styles/assets/loan/titip-jual.png';
import BridgingLoan from 'styles/assets/loan/bridging-loan.png';
import InstantSelling from 'styles/assets/loan/instant-selling.png';

class Loan2 extends Component {
  render() {
    return (<div className="loan-2 ta-center">
      <div className="container">
        <div className="loan1-title">
          Skema jual rumah di Prohouse
        </div>
        <div className="loan1-subtitle">
           by Provesty
        </div>
      </div>
      <div className="bg-build">
        <div className="container" style={{marginTop: 30}}>
          <Grid container spacing={0}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div>
                <img className="img-about-2" src={TitipJual} alt="titip jual" style={{width: '30%', height: '30%'}}  />
                <p className="loan-title-2">Titip Jual</p>
                <p className="loan2-content">Prohouse melayani penjualan rumah melalui agen properti berpengalaman. Cukup masukan data yang diperlukan, maka tim agen kami akan menjualnya. </p>
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div>
                <img className="img-about-2" src={BridgingLoan} alt="bridging loan" style={{width: '25%', height: '25%'}}  />
                <p className="loan-title-2">Bridging Loan</p>
                <p className="loan2-content">Dapatkan <i>cash advance</i>/dana talangan sebesar 50% dari harga pasar rumah kamu.</p>
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div>
                <img className="img-about-2" src={InstantSelling} alt="instant selling" style={{width: '23%', height: '23%'}}  />
                <p className="loan-title-2"><i>Instant Selling</i></p>
                <p className="loan2-content">Jual rumah dengan instan melalui Prohouse by Provesty. Rumahmu akan dibeli langsung oleh kami, cash dalam waktu maksimal 1 minggu.</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>)
  }
}

export default Loan2
