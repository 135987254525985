import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
// import Skema from 'styles/assets/skema.png';
// import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Bata from 'styles/assets/how-works/VisualCarousel.png';
import {Link as Scroller} from 'react-scroll'
import { withLocalize } from 'react-localize-redux';
import {Link} from 'react-router-dom'

class How1 extends Component {

  constructor(props) {
    super(props);
    this.addTranslationsForActiveLanguage();
  }

  componentDidUpdate(prevProps, prevState) {
    const hasActiveLanguageChanged = prevProps.activeLanguage !== this.props.activeLanguage;

    if (hasActiveLanguageChanged) {
      this.addTranslationsForActiveLanguage();
    }
  }

  addTranslationsForActiveLanguage() {
    const {activeLanguage} = this.props;

    if (!activeLanguage) {
      return;
    }

    import(`../../translations/${activeLanguage.code}.movies.json`)
      .then(translations => {
        this.props.addTranslationForLanguage(translations, activeLanguage.code)
      });
  }

  render(){
    return(
      <div className='how-width how1'>
        <Grid container alignItems="center" className="how1-grid">
          <Grid item lg={1} />
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <div className="how1-left">
              {/*<h3><Translate id={`movie2.title`} /></h3>
            <p><Translate id={`movie2.description`} /></p>*/}
              <div className="loan1-title">Akses & Kemudahan Investasi</div>
              <div className="loan1-subtitle">Flipping properti melalui Provesty</div>
              <Hidden mdUp>
                <div className="how1-image">
                  <img src={Bata} alt="loan" style={{width: '90%', maxWidth: 406 }}/>
                </div>
              </Hidden>
              <p className="loan1-content">Provesty saat ini fokus pada investasi jangka pendek Flipping properti. Sederhananya adalah mencari properti di bawah harga pasar, merenovasinya untuk menambah nilai jual, dan menjualnya kembali dalam waktu singkat.</p>
              <div className="how1-buttons">
                <Grid container>
                  <Grid item lg={5} md={6} sm={6} xs={12}>
                    <Scroller activeClass="active" value="how-2" to={this.props.to} spy={true} smooth={true} duration={1000} className="casual-link">
                      <Button className="btn-dark-blue" variant='contained'>Pelajari Investasi</Button>
                    </Scroller>
                  </Grid>
                  <Grid item lg={5} md={6} sm={6} xs={12}>
                    <Link to='/invest' className="casual-link">
                      <Button className="button-mulai-investasi">Mulai Investasi</Button>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item md={1} lg="auto" />
          <Grid item lg={6} md={5} sm={12} xs={12}>
            <Hidden smDown>
              <div className="how1-image">
                <img src={Bata} alt="loan" style={{width: '90%', maxWidth: 406 }}/>
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withLocalize(How1)
