import React from "react";
import NotFound from 'styles/assets/notfound.png'


export default function QueryNotFound(props) {

  return (
    <div className="search-not-found">
      <div style={{width: 330, maxWidth: '100%', margin: 'auto'}}>
        <div className="image-wrapper" style={{paddingBottom: '63.94%'}}>
          <img src={NotFound} alt="not found" className="not-found-img" />
        </div>
      </div>
      <div>Tidak ada data yang cocok</div>
    </div>
  )
}
