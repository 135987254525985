import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
// import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

class DashboardSidebar extends Component {

  static defaultProps = {
    history: 'null'
  }
  state = {
    tab: '',
    bottomNav: ''
  };

  handleNavChange = (event, value) => {
    this.setState({ bottomNav: value });
    setTimeout(() => {this.props.history.push(value)}, 300);
  };
  handleTabChange = (event, value) => {
    this.setState({ tab: value });
    setTimeout(() => {this.props.history.push(value)}, 300);
  };

  render() {

    // wrapper for button sidebar
    const ButtonDash = (props) => {
      var currentPath = this.props.history.location.pathname;
      var activeClass = props.urls.indexOf(currentPath) !== -1? 'active' : '';
      return (
        <Button className={activeClass} onClick={()=> this.props.history.push(props.urls[0])}>
          {props.children}
        </Button>
      )
    }

    const buttonLinks =
    <React.Fragment>
      <ButtonDash urls={['/dashboard', '/dashboard/transaction', '/dashboard/portfolio', '/dashboard/message']}>Investasi
      </ButtonDash>
      {/*<ButtonDash urls={['/dashboard/loan']}>Flipper</ButtonDash>*/}
      <ButtonDash urls={['/dashboard/profile', '/dashboard/account']}>Pengaturan</ButtonDash>
    </React.Fragment>
    ;

    // const tab = this.state.tab ? this.state.tab : this.props.history.location.pathname;

    const tmp =
    <React.Fragment>
      <Hidden smDown>
        <div id="dashboard-buttons-area">
          <Grid container direction="column" justify="center" alignItems="center">
            {buttonLinks}
          </Grid>
        </div>
      </Hidden>
      <Hidden only={['xs','md','lg', 'xl']}>
        <div id="dashboard-buttons-area">
          <Grid container direction="row" justify="space-around" alignItems="center">
            {buttonLinks}
          </Grid>
        </div>
      </Hidden>
    </React.Fragment>;

    return tmp;
  }
}

export default DashboardSidebar;
