import React, {Component} from 'react';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import DefaultPropImg from 'styles/assets/default-project-image.png';
import { imgPath } from 'helpers'
import { Link } from "react-router-dom";
import ContentLoader from 'react-content-loader'
// import FaceIcon from '@material-ui/icons/Face';

function process_dom (dom_string) {
  // var doc = new DOMParser().parseFromString(dom_string, "text/html");
  //
  // var els = doc.querySelectorAll('table');
  // if(els.length) {
  //   for(var i=0; i < els.length; i++) {
  //     var el = els[i];
  //     var wrapper = doc.createElement('div')
  //     wrapper.className = "table-container";
  //     el.parentNode.insertBefore(wrapper, el);
  //     wrapper.appendChild(el);
  //   }
  //   return doc.body.innerHTML
  // } else
  return dom_string;
}

export default class BlogCard extends Component {

  render(){

    let { isLoading, ps_title, ps_content, ps_slug, ps_published_tstamp, u_name, ps_image
    } = this.props;

    let cardClass = 'blog-card card';

    if(isLoading) {
      cardClass += ' loading';
      ps_title = 'Belanja Jangan Cuma Hemat Tapi Harus Cerdas';
      ps_content = 'Kamu sedang mencari developer untuk membeli properti atau sekedar berinvestasi? Kebutuhan akan pro...';
      u_name = 'Intan Widya';
      ps_published_tstamp = '16 Jan 2018';
    }

    return(
      <Card className={cardClass} style={{textAlign:'justify !important'}}>
        {isLoading ?
          <ContentLoader preserveAspectRatio="xMidYMid slice" width={320} height={179} speed={1}>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="179" />
          </ContentLoader>
          :
          <Link to={'/blog-detail/' + ps_slug} style={{textDecoration: 'none'}}>
            <CardMedia image={ps_image ? imgPath(ps_image) : DefaultPropImg} alt="blog" className="blog-image" style={{borderRadius: '3px 3px 0 0', minHeight: 179}} />
          </Link>
        }
        <div className="blog-card-title">{ps_title}</div>
        <div className="blog-card-content html-content" dangerouslySetInnerHTML={{
            __html: process_dom(ps_content)
          }} />
          <div style={{marginLeft: 15}}>
            <Chip
              label=<div className="loading-effect">{u_name}</div>
              clickable
              color="primary"
              variant="outlined"
              style={{marginTop: 20}}
              />
            <Chip
              label=<div className="loading-effect">{ps_published_tstamp}</div>
              clickable
              color="primary"
              variant="outlined"
              style={{marginLeft: 10, marginTop: 20}}
              />
          </div>
        </Card>
      )
    }
  }
