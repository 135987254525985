
import { SET_MESSAGE, CLOSE_MESSAGE } from '_constant'

export function message(state = {}, action) {
  // first time
  if (typeof(state.msg) === 'undefined')
  state = {
    msgType: '',
    msg: '',
    msgShow: false
  };

  switch (action.type) {
    case SET_MESSAGE:
    return {
      msgType: action.payload.msgType,
      msg: action.payload.msg,
      msgShow: true
    }
    case CLOSE_MESSAGE:
    return Object.assign({}, state, {
        msgShow: false
      })
    default:
    return state
  }
}
