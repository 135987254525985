import React, { Component } from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import DashboardSidebar from "components/general/DashboardSidebar";
import MyTextField from "components/general/MyTextField";
import MyUploadImage from "components/general/MyUploadImage";
import ReactSelect from "components/general/ReactSelect";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { APISTAT, STAT } from '_constant';
import DefProfilePic from "styles/assets/default-profile-pic.png";
import { apiHelper, dispatchMsg, formVal, formData, formScrollToError, dispatchUpdateUser } from 'helpers'
import ModalSMS from "components/dashboard/ModalSMS";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import ContentLoader from 'react-content-loader'
import KTP from "styles/assets/ktp.png";
import NPWP from "styles/assets/npwp.png";
import DashboardTab from 'components/dashboard/DashboardTab'

class DashboardProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      u_photo: '',
      u_photo_init: '',

      u_name: '',
      u_nickname: '',
      u_dob: '',
      u_phone: '',
      u_phone_compact: '',
      u_is_phone_verified: '',
      id_province: '',
      id_regency: '',
      ad_line: '',
      id_job: '',
      id_income_source: '',

      verification_code: '',
      is_phone_need_verify: true,

      id_bank: '',
      bac_no: '',
      bac_owner_name: '',
      bac_branch: '',
      u_ic_no: '',
      u_tax_no: '',
      u_ic_img: '',
      u_tax_img: '',

      u_ic_img_init: '',
      u_tax_img_init: '',

      provinces: {},
      regencies: {},
      jobs: {},
      income_sources: {},
      banks: {},

      smsVisible: false,
      smsData: {},
      smsLoading: false,
      smsKey: '',

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = event => {

    var name = event.target.name;
    var val = event.target.value;

    if (name === 'u_phone') {
      // filter phone and decide if we need to verify
      val = val.replace(/[^\d\s()+.-]/g, '');  // filter non number and (,),+,-

      var phone_compact = val.replace(/[^0-9]/g, '').replace(/^62/g, '0');
      var verified_phone_compact = this.state.u_is_phone_verified === STAT.STAT_YES ? this.state.u_phone_compact : '';
      var is_phone_need_verify = verified_phone_compact === '' || phone_compact !== verified_phone_compact;

      this.setState({ [name]: val, is_phone_need_verify });
    } else if (name === 'id_province') {
      this.setState({ [name]: val }, this.loadRegency);
    } else if (name === 'u_tax_no') {
      // format tax to xx.xxx.xxx.x-xxx.xxx
      let nums = val.replace(/\D/g,'');
      val = nums.substring(0,2) + (nums.length > 2? '.' : '' ) + nums.substring(2,5) + (nums.length > 5? '.' : '' ) + nums.substring(5,8) + (nums.length > 8? '.' : '' ) + nums.substring(8,9) + (nums.length > 9? '-' : '' ) + nums.substring(9,12) + (nums.length > 12? '.' : '' ) + nums.substring(12,15);
      this.setState({ [name]: val });
    } else if (name === 'u_ic_no') {
      let nums = val.replace(/\D/g,'');
      val = nums.substring(0,16);
      this.setState({ [name]: val });
    } else {
      this.setState({ [name]: val });
    }
  };

  validate = () => {

    this.errors = {};

    formVal(this, 'u_name', 'Nama', 'required');
    formVal(this, 'u_dob', 'Tanggal Lahir', 'requiredDate');
    formVal(this, 'u_phone', 'Phone', 'required');
    formVal(this, 'id_province', 'Provinsi', 'required');
    formVal(this, 'id_regency', 'Kota/Kabupaten', 'required');
    formVal(this, 'ad_line', 'Alamat', 'required');
    formVal(this, 'id_job', 'Pekerjaan', 'required');
    formVal(this, 'id_income_source', 'Sumber Dana', 'required');

    formVal(this, 'id_bank', 'Nama', 'required');
    formVal(this, 'bac_branch', 'Cabang', 'required');
    formVal(this, 'bac_owner_name', 'Atas Nama', 'required');
    formVal(this, 'bac_no', 'Nomor Rekening', 'required');
    formVal(this, 'u_ic_no', 'No KTP', 'required');
    formVal(this, 'u_tax_no', 'No NPWP',' ');
    formVal(this, 'u_ic_img', 'Scan KTP', 'requiredImg', 'u_ic_img_init');
    formVal(this, 'u_tax_img', 'Scan NPWP', ' ', 'u_tax_img_init');

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }

  phoneValidate = () => {

    this.errors = {};
    formVal(this, 'u_phone', 'Phone', 'required');

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;
    // clear the errors
    this.setState({ buttonLoading: true });

    apiHelper.request(this, {
      resource: 'profile_post',
      data: formData('form-profile'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          dispatchUpdateUser(resp.data);
          dispatchMsg('success', 'Profile berhasil diupdate.')
          this.setState({ buttonLoading: false });
          window.scrollTo(0, 0);
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({buttonLoading: false});
      },
    });
  }

  launchSms = () =>  {

    if(!this.phoneValidate())
    return;

    this.setState({ smsLoading: true });

    apiHelper.request(this, {
      resource: 'send_sms',
      data: formData({u_phone: this.state.u_phone}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ smsLoading: false, smsData: {'u_phone': this.state.u_phone}, smsVisible: true, smsKey: this.state.u_phone});
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ smsLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({smsLoading: false});
      },
    });
  }

  onSmsClose = (isVerified) => {
    if (isVerified === true) {  // must be exact true
      this.setState({
        smsVisible: false,
        u_is_phone_verified: isVerified? STAT.STAT_YES: STAT.STAT_NO,
        is_phone_need_verify: isVerified? false: true
      });
    } else {
      this.setState({smsVisible: false});
    }
  }

  loadRegency = () => {
    apiHelper.request(this, {
      resource: 'regencies_select',
      urlParams: [this.state.id_province],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_regency: '', regencies: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'profile_get', // use the secured one if loggedin
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data, is_phone_need_verify: resp.data.u_is_phone_verified === STAT.STAT_NO});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }



  render(){

    const { isLoading } = this.state;

    return (
      <div>
        <Navbar />
        <Grid container className="container">
          <Grid item className="dbsidebarplace">
            <DashboardSidebar history={this.props.history} />
          </Grid>
          <Grid item xs={12} sm={12} md>
            <DashboardTab paths={[
                { l: 'Profil', v: '/dashboard/profile' },
                { l: 'Akun', v: '/dashboard/account' },
              ]} history={this.props.history}
              />
            <Paper classes={{root:"dash-area"}} className={isLoading ? 'loading' : ''} style={{borderRadius: '0px 0px 4px 4px'}}>
              <form onSubmit={this.handleSubmit} autoComplete="off" id="form-profile">
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <div style={{maxWidth: 250, margin:"auto", marginBottom: 32 }}>
                      {isLoading?
                        <ContentLoader preserveAspectRatio="xMidYMid slice" width={320} height={320} speed={1}>
                          <rect x="0" y="0" rx="5" ry="5" width="100%" />
                        </ContentLoader>
                        :
                        <MyUploadImage name="u_photo" obj={this} defaultImg={DefProfilePic} ButtonProps={{ fullWidth: true, className:"button-blue" }} buttonContent={<span><i className="material-icons button-icon">camera_alt</i> Upload</span>}/>
                      }
                    </div>
                  </Grid>
                  <Grid item xs={false} md={1} />
                  <Grid item sm={12} md={8}>

                    <Grid container spacing={16} name="first-field">
                      <Grid item xs={12} sm={6}>
                        <MyTextField name="u_name" label="Nama Lengkap" obj={this} required
                          />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <MyTextField name="u_nickname" label="Nama Panggilan" obj={this}
                          />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <MyTextField
                          name="u_dob" label="Tanggal Lahir" obj={this} required shrink type="date" />
                      </Grid>
                      <Grid item sm={12} container justify="flex-end">
                        <Grid item xs={12} sm>
                          <MyTextField name="u_phone" label="Phone" obj={this}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" classes={{root: 'input-adornment'}}>
                                  {
                                    this.state.u_phone ?
                                    (this.state.is_phone_need_verify ? <div style={{color: "#cb4f5e", whiteSpace: "nowrap"}}>(Belum Terverfikasi)</div> :<div style={{color: "#24b47e", whiteSpace: "nowrap"}}>(Terverifikasi)</div>) :
                                    ''
                                  }
                                </InputAdornment>
                              )
                            }}
                            required />
                        </Grid>
                        { this.state.is_phone_need_verify && this.state.u_phone ?
                          <Grid item>
                            <div className="button-progress-wrapper">
                              <Button disabled={this.state.smsLoading} className="button-blue button-verify-phone"
                                onClick={this.launchSms}>Kirim kode verifikasi &nbsp;<i className="material-icons button-icon">send</i></Button>
                              {this.state.smsLoading && <CircularProgress size={24} style={{marginTop: -7}} className="button-progress" />}
                            </div>
                          </Grid> :
                          ''
                        }
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <ReactSelect name="id_province" label="Provinsi" obj={this}
                          options={Object.keys(this.state.provinces).map(k => ({k: k, v:this.state.provinces[k]}))}
                          required />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <ReactSelect
                          name="id_regency" label="Kota" obj={this} options={Object.keys(this.state.regencies).map(k => ({k: k, v:this.state.regencies[k]}))}
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <MyTextField name="ad_line" label="Address" obj={this} multiline rows={3} />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <ReactSelect name="id_job" label="Pekerjaan" obj={this} options={Object.keys(this.state.jobs).map(k => ({k: k, v:this.state.jobs[k]}))}
                          required />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <ReactSelect name="id_income_source" label="Sumber Dana" obj={this} options={Object.keys(this.state.income_sources).map(k => ({k: k, v:this.state.income_sources[k]}))}
                          required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReactSelect name="id_bank" label="Nama Bank" obj={this} options={Object.keys(this.state.banks).map(k => ({k: k, v:this.state.banks[k]}))} required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MyTextField name="bac_branch" label="Cabang" obj={this} required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MyTextField name="bac_owner_name" label="Atas Nama" obj={this} required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MyTextField name="bac_no" label="Nomor Rekening" obj={this} required />
                      </Grid>
                      <Grid item xs={12} sm={12} container alignItems="flex-end" justify="center">
                        <Grid item xs={12} sm>
                          <MyTextField name="u_ic_no" label="Nomor KTP" obj={this} required type="tel"/>
                        </Grid>
                        <Grid item>
                          <MyUploadImage name="u_ic_img" label="Scan KTP" obj={this} ButtonProps={{className:"button-upload-doc button-blue"}} ImgProps={{style: {maxWidth: 200}}} buttonContent={<span>Upload Scan KTP &nbsp;<i className="material-icons button-icon">cloud_upload</i></span>}
                            defaultImg={KTP}  required />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} container alignItems="flex-end" justify="center">
                        <Grid item xs={12} sm>
                          <MyTextField name="u_tax_no" label="Nomor NPWP (Optional)" obj={this} type="tel" />
                        </Grid>
                        <Grid item>
                          <MyUploadImage name="u_tax_img" label="Scan NPWP" obj={this} ButtonProps={{className:"button-upload-doc button-blue"}} ImgProps={{style: {maxWidth: 200}}} buttonContent={<span>Upload Scan NPWP &nbsp;<i className="material-icons button-icon">cloud_upload</i></span>}
                            defaultImg={NPWP} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <div id="button-dashprof-wrapper">
                      <div className="button-progress-wrapper">
                        <Button disabled={this.state.buttonLoading} className="button-blue" type="submit" style={{width: 170}}>Simpan</Button>
                        {this.state.buttonLoading && <CircularProgress size={24} style={{marginTop: -12}} className="button-progress" />}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </form>
              <ModalSMS
                visible={this.state.smsVisible} key={this.state.smsKey} {...this.state.smsData}
                onClose={this.onSmsClose}
                />
            </Paper>
          </Grid>
        </Grid>
        <div className="wavyflip pushupx">
          <Footer />
        </div>
      </div>
    );
  }
}

export default DashboardProfile;
