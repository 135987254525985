import React, {Component} from "react";
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { formGetLabel, formIsRequired } from 'helpers';

class MyTextField extends Component {

  static propTypes = {
    obj: PropTypes.shape({
      state: PropTypes.shape({
        validErrors:  PropTypes.object.isRequired
      }).isRequired,
      handleInputChange: PropTypes.func.isRequired,
    }).isRequired
  }

  static defaultProps = {
    // default props
    fullWidth: true,
    required: false,

    // props that needs to be taken out
    obj: null,
    shrink: false
  }

  getStateValue = (state, name) => {
    if(name === '')
    return undefined;

    var tmps = name.split(/[[\]]+/);
    var val = state[tmps[0]];
    for(var i=1; i<tmps.length; i++) {
      if(tmps[i] !== '')
      val = val[tmps[i]];
    }
    return val;
  }


  render() {

    const p = this.props;

    const fc_class = {
      root:"my-form-control"
    };
    const helperProps = { classes: {
      root:"my-form-helpertxt"
    }};
    const labelProps = { classes: {
      root: "my-form-label",
      focused: "label-focused",
      shrink: "label-shrink",
      outlined: "label-outlined"
    }};
    const inputProps = { classes: {
      root: p.variant === 'outlined'? "my-form-input-outlined" : "my-form-input",
      focused: "input-focused",
      disabled: 'input-disabled',
      error: 'input-error'
    }};
    if(typeof p.variant === 'undefined' || p.variant === 'standard') {
      inputProps.classes.underline = 'input-underline';
    }

    const {obj, shrink, required, InputLabelProps, InputProps, FormHelperTextProps, ...ptf} = p; // separate extra props, take the required

    var name = this.props.name;
    if(typeof this.getStateValue(obj.state, name) === 'undefined')
    throw new Error('state ' + name + ' is not declared!');

    ptf.value = this.getStateValue(obj.state, name);

    // for number we need to make sure the leading 0 is gone
    ptf.onChange = p.type !== 'number' ? obj.handleInputChange : (e => {
      let {name, value}  = e.target;
      value = value ? parseInt(value, 10).toString() : '0';
      value = value < 0? 0: value;
      obj.handleInputChange({target: {name, value}});
    });
    if(typeof obj.state.validErrors !== 'undefined' && typeof obj.state.validErrors[name] !== 'undefined') {
      ptf.helperText = obj.state.validErrors[name];
      ptf.error = true;
    }

    ptf.label = ptf.label ? ptf.label : formGetLabel(obj, name);
    if (required || formIsRequired(obj, name)) {
      ptf.label = ptf.label + '*';
    }
    if (shrink) {
      labelProps.shrink = true;
    }

    var inputPropsComb = null;
    if(InputProps && InputProps.classes) {// need to do deep merging
      let tmp = InputProps.classes;
      inputPropsComb = {...InputProps, ...inputProps};
      for (var key in tmp) {
        if (tmp.hasOwnProperty(key)) {
          inputPropsComb.classes[key] =  inputProps.classes[key]? inputProps.classes[key] + ' ' + tmp[key] : tmp[key];
        }
      }
    } else {
      inputPropsComb = {...InputProps, ...inputProps};
    }

    return (
      <TextField {...ptf} classes={fc_class}
        InputLabelProps={{...InputLabelProps, ...labelProps}}
        InputProps={inputPropsComb}
        FormHelperTextProps={{...FormHelperTextProps, ...helperProps}}
        />
    );
  }
}

export default MyTextField;
