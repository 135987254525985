import React, { Component } from 'react';
import Navbar from 'components/general/Navbar';
import Footer from 'components/general/Footer';
import PaymentInstruction from 'components/payment/PaymentInstruction';
import ConfirmPayment from 'components/general/ConfirmPayment';
import { apiHelper, dispatchMsg } from 'helpers';
import { APISTAT } from '_constant';
import Grid from '@material-ui/core/Grid';
import PaymentTopupDetail from 'components/payment/PaymentTopupDetail';

class ReviewTopup extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: {},
      isLoading: true
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'payment_topup', // use the secured one if loggedin
      urlParams: [ this.props.match.params.slug_id_payment],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, data: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false, data: {} });
      }
    });
  }

  render(){

    const { data, isLoading } = this.state;

    return(
      <div className="bg-lightblue">
        <Navbar/>
        <div className="container">
          <Grid container spacing={32}>
            <Grid item md={7}>
              <Grid container spacing={32} direction="column">
                <Grid item xs={12}>
                  <PaymentTopupDetail isLoading={isLoading} {...data} />
                </Grid>
                <Grid item xs={12}>
                  <PaymentInstruction isLoading={isLoading} {...data} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <ConfirmPayment isLoading={isLoading} {...data} />
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ReviewTopup
