import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

export default class CallUs extends Component {
  render(){
    return(
      <div className="call-us container">
        <div style={{overflow: 'hidden', width: '100%'}}>
          <Grid container spacing={16} >
            <Grid item lg={1} md={1} />
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <div>
                <div className="call-us-title">Hubungi Kami</div>
                <p>Demi upaya kami meningkatkan pelayanan, jika Kamu ingin bertanya mengenai informasi atau mempunyai saran dan kritik atas layanan kami, silahkan hubungi kami di live chat di pojok kanan bawah pada saat jam kerja Senin - Jumat (09.00 - 17.00).</p>
                <p>Atau kamu juga bisa menghubungi kami di nomor +62 811-199-8000 pada saat jam kerja Senin - Jumat (09.00 - 17.00).</p>
              </div>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2743961285128!2d106.83129381436054!3d-6.227507962721949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69edf826da3abd%3A0x4b5e0e95304d802a!2sProvesty!5e0!3m2!1sen!2sid!4v1551024419062" style={{width: '100%', height: 360}} title="Lokasi Provesty"></iframe>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
