import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import MyTextField from 'components/general/MyTextField'
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';
import Spinner from 'react-spinkit';
import { apiHelper, dispatchMsg, formVal, formData } from 'helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { APISTAT } from '_constant';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});
class ResetPasswordComponent extends Component {

  state = {
    u_password_new: '',
    u_password_conf: '',

    isLoading: true,
    linkValid: false,

    buttonLoading: false,
    validErrors: {},
  };

  timerHandle = null;

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  componentDidMount() {
    this.loadData();
  }

  handleInputChange = event => {
    var name = event.target.name;
    var val = event.target.value;

    this.setState({ [name]: val });
  };

  loadData = () => {

    var params = new URLSearchParams(this.props.history.location.search);

    this.setState({ isLoading: true});

    apiHelper.request(this, {
      resource: 'is_reset_data_valid',
      data: formData({
        e: params.get('e'),
        c: params.get('c')
      }),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ isLoading: false, linkValid: true});
        } else {
          this.setState({ isLoading: false, linkValid: false});
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false});
      }
    });

  }

  validate = () => {
    this.errors = {};

    formVal(this, 'u_password_new', 'Password Baru', 'required');
    formVal(this, 'u_password_conf', 'Konfirmasi Password', 'required');

    if(this.state.u_password_new !== '' && this.state.u_password_conf !== '' && (this.state.u_password_new !== this.state.u_password_conf)) {
      this.errors['u_password_conf'] = 'Konfirmasi Password tidak sesuai dengan password baru.';
    }

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    var params = new URLSearchParams(this.props.history.location.search);

    this.setState({ buttonLoading: true});
    apiHelper.request(this, {
      resource: 'reset_password',
      data: formData({
        e: params.get('e'),
        c: params.get('c'),
        u_password_new: this.state.u_password_new,
        u_password_conf: this.state.u_password_conf,
      }),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success

          // show success and redirect to login
          dispatchMsg('success', 'Password berhasil diubah. Silahkan coba login kembali.');
          this.setState({ buttonLoading: true}, this.props.history.push('/login'));

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false});
      }
    });
  };

  render() {

    const resetForm = <form onSubmit={this.handleSubmit} id="forgot-password">
      <Grid container>
        <div className="or-sign-in">Masukkan password baru kamu.</div>
      </Grid>
      <br />
      <MyTextField type="password" name="u_password_new" label="Password Baru" obj={this} className='input-width' variant="outlined" InputLabelProps={{ style: {fontSize: 16}}} InputProps={{ inputProps: {style: {fontSize: 16}, autoComplete: 'password'}}}/>

      <MyTextField type="password" name="u_password_conf" label="Konfirmasi Password" obj={this} className='input-width' variant="outlined" style={{marginTop: 10}} InputLabelProps={{ style: {fontSize: 16}}} InputProps={{ inputProps: {style: {fontSize: 16}, autoComplete: 'password'}}}/>

      <div className="button-progress-wrapper-full" style={{marginTop: 20}}>
        <Button disabled={this.state.buttonLoading} type="submit" className="input-width button-blue" variant="contained">Ubah Password</Button>
        {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
      </div>
    </form>;

    const loadingAnim = <div className="my-spinner-container">
      <Spinner fadeIn='quarter' name="chasing-dots" />
    </div>;

    const linkNotValidInfo = <div>
      Gagal untuk mereset password, link dari email mungkin telah expired atau invalid.
    </div>;

    return (
      <React.Fragment>
        <Grid container justify="center" alignItems="center" className="faux-tab auth-box" >
          Reset Password
        </Grid>
        <div className="auth-box">
          <div className="tab-container">
            <div className="auth-form">
              {this.state.isLoading? loadingAnim : (this.state.linkValid? resetForm : linkNotValidInfo)}
            </div>
          </div>
        </div>
        <div className="sign-up-now">
          <p>Kembali ke halaman<span className="sign-up-button"> <Link to="/login"><u>Login</u></Link></span></p>
        </div>
      </React.Fragment>
    )
  }
}

ResetPasswordComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPasswordComponent);
