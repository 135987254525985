import React from 'react';
import Rodal from 'rodal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTextField from "components/general/MyTextField";
import { price_d2r, price_r2d, dispatchMsg, imgPath } from 'helpers';
import { APISTAT } from '_constant';
import { apiHelper } from 'helpers';
import CircularProgress from '@material-ui/core/CircularProgress';


export default class ModalWithdrawal extends React.Component {

  static defaultProps = {
    id_bank_account_dst: 0,
    u_aggr_liquid_bal: 0,
    onClose: null
  };

  constructor(props) {
    super(props);
    this.state = {
      py_amount: price_d2r(0),
      validErrors: {},
      buttonLoading: false,

      modalHeight: 370
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = event => {
    var name = event.target.name;
    var val = event.target.value;
    if (name === 'py_amount') {
      val = price_d2r(val);
    }
    this.setState({ [name]: val });
  };

  validate = () => {
    var errors = {};
    const { u_aggr_liquid_bal, bank_account } = this.props;
    const { py_amount } = this.state;

    const _py_amount = price_r2d(py_amount);

    if(_py_amount === 0) {
      errors['py_amount'] = 'Jumlah penarikan tidak boleh kosong';
    } else if(_py_amount > u_aggr_liquid_bal) {
      errors['py_amount'] = 'Jumlah penarikan tidak boleh lebih dari saldo wallet';
    }
    if(!bank_account.id_bank_account) {
      errors['id_bank_account'] = 'Bank target belum diset';
    }

    if(Object.keys(errors).length) {
      this.setState({validErrors: errors});
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    // clear the errors
    this.setState({validErrors: {}, buttonLoading: true});

    var data = new FormData(document.getElementById('form-withdrawal'));
    data.append('id_bank_account_dst', this.props.bank_account.id_bank_account);

    apiHelper.request(this, {
      resource: 'withdrawal_post',
      data,
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success, close the modal
          dispatchMsg('success', 'Penarikan berhasil. Dana akan sampai di rekening kamu max 3 hari kerja');
          this.props.onClose(true);
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ validErrors: resp.valid_errors });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
      },
      fnFinally: () => {this.setState({ buttonLoading: false })}
    });

  }

  componentDidUpdate() {
    // responsive height
    const height = this.divElement.clientHeight; // 378
    const { modalHeight } = this.state; // 370
    var diff = 25;
    if(height > diff && modalHeight !== height - diff)
    this.setState({modalHeight: (height - diff)});
  }


  render() {

    const { visible, bank_account, u_aggr_liquid_bal } = this.props;

    const bank_account_data = bank_account?
    <Table className="table-standard" style={{width: "100%", margin: 10}}>
      <TableBody>
        <TableRow>
          <TableCell style={{width: 100}}>Bank</TableCell>
          <TableCell style={{width: 12}}>:</TableCell>
          <TableCell><span>{bank_account.ba_name} </span><img src={imgPath(bank_account.ba_logo)} alt="bank" /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cabang</TableCell>
          <TableCell>:</TableCell>
          <TableCell>{bank_account.bac_branch}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Atas Nama</TableCell>
          <TableCell>:</TableCell>
          <TableCell>{bank_account.bac_owner_name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>No. Rekening</TableCell>
          <TableCell>:</TableCell>
          <TableCell>{bank_account.bac_no}</TableCell>
        </TableRow>
      </TableBody>
    </Table> :
    <p className="error-text">Isi dulu informasi bank kamu di kolom Profile sebelum melakukan penarikan
    </p>;

    return (
      <Rodal className="sms-modal" visible={visible} onClose={this.props.onClose}
        showCloseButton={false} width={420} height={this.state.modalHeight}
        >
        <div className="sms-modal-content" ref={ (divElement) => this.divElement = divElement}>
          <form onSubmit={this.handleSubmit} id="form-withdrawal">
            <h3 className="sms-modal-title">Pencairan</h3>

            <Grid container className="form-group">
              <Grid item xs={6} className="form-label">Saldo Kamu</Grid>
              <Grid item xs={6} className="form-content"><div className="form-content-info">{price_d2r(u_aggr_liquid_bal)}</div></Grid>
            </Grid>
            <Grid container className="form-group">
              <Grid item xs={6} className="form-label">Total Pencairan</Grid>
              <Grid item xs={6} className="form-content">
                <MyTextField name="py_amount" label="" obj={this} fullWidth
                  variant="outlined" type="tel"
                  />
              </Grid>
            </Grid>
            <Grid container className="form-group">
              <Grid item xs={12}>Tujuan Transfer</Grid>
              <Grid item xs={12}>
                {bank_account_data}
              </Grid>
            </Grid>
            <div className="sms-modal-buttons">
              <div className="button-progress-wrapper">
                <Button disabled={this.state.buttonLoading} className="button-blue" type="submit" style={{width: 120}}>Cairkan</Button>
                {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
              </div>
              <Button style={{marginLeft: 16}} className="button-white" onClick={this.props.onClose}>Batal</Button>
            </div>
          </form>
        </div>
      </Rodal>
    );
  }
}
