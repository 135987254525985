import React from 'react';
import Rodal from 'rodal';
import Button from '@material-ui/core/Button';
import { apiHelper, dispatchMsg, formData } from 'helpers';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'rodal/lib/rodal.css'; // apparently including once will be used for all
import ReactCodeInput from 'react-code-input';
// import { reactCodeInput } from 'CodeInputField.scss'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';


const styleInput = {
  "fontFamily": "monospace",
  "MozAppearance": "textfield",
  "borderRadius": "6px",
  "border": "1px solid",
  "boxShadow": "0px 0px 10px 0px rgba(0,0,0,.10)",
  "margin": "4px",
  "paddingLeft": "8px",
  "boxSizing": "border-box",
  "color": "black",
  "backgroundColor": "white",
  "borderColor": "lightgrey",
  width: 50,
  height: 65,
  fontSize: 60
};
const propsReact = {
  inputStyle: styleInput,
  inputStyleInvalid: {
    ...styleInput,
    "borderColor": "red"
  }
}

export default class ModalSMS extends React.Component {

  static defaultProps = {
    u_phone: '',
    onClose: null,
    visible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      verification_code: '',
      validErrors: {},
      buttonLoading: false,
      smsLoading: false,

      modalHeight: 370,
      seconds: 60
    };
  }

  timerHandle = null;

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
    this.stopTimer();
  }

  componentDidMount() {
    this.startTimer();
  }

  startTimer() {
    if (this.timerHandle === null) {
      this.setState({seconds: 59});
      this.timerHandle = setInterval(this.countDown, 1000);
    }
  }
  stopTimer() {
    clearInterval(this.timerHandle);
    this.timerHandle = null;
  }
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({ seconds: seconds });

    // Check if we're at zero.
    if (seconds === 0)
    this.stopTimer();
  }

  handlePinChange = (val) => {
    this.setState({ verification_code: val });
  };

  validate = () => {
    var errors = {};
    const { verification_code } = this.state;

    if(verification_code === "") {
      errors['verification_code'] = 'Kode verifikasi tidak boleh kosong';
    }

    if(Object.keys(errors).length) {
      this.setState({validErrors: errors});
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    // clear the errors
    this.setState({validErrors: {}, buttonLoading: true});

    var data = new FormData(document.getElementById('form-sms'));
    data.append('u_phone', this.props.u_phone);

    apiHelper.request(this, {
      resource: 'verify_phone',
      data: formData(
        { u_phone: this.props.u_phone,
          verification_code: this.state.verification_code
        }
      ),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success, show code verified
          dispatchMsg('success', "Nomor HP berhasil diverifikasi");
          this.setState({ buttonLoading: false });
          this.props.onClose(true);
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ validErrors: resp.valid_errors, buttonLoading: false});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        this.setState({buttonLoading: false});
      },
    });
  }


  send_sms = () => {

    this.setState({ smsLoading: true });

    apiHelper.request(this, {
      resource: 'send_sms',
      data: formData({u_phone: this.props.u_phone}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.startTimer();
          this.setState({smsLoading: false});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({smsLoading: false});
      },
    });
  }

  componentDidUpdate() {
    // responsive height
    const height = this.divElement.clientHeight; // 378
    const { modalHeight } = this.state; // 370
    var diff = -20;
    if(height > diff && modalHeight !== height - diff)
    this.setState({modalHeight: (height - diff)});
  }

  render() {

    var { seconds } = this.state;

    var timer = seconds < 10 ? "0" + seconds: seconds;
    timer = '00:' + timer;

    var send_again = seconds === 0 ?
    <div className="button-progress-wrapper">
      <Button disabled={this.state.smsLoading} onClick={this.send_sms} className="button-blue" style={{padding: "0px 5px", fontSize: 13, marginTop: -4
      }}>Kirim Ulang &nbsp;<i className="material-icons button-icon">send</i>
    </Button>
    {this.state.smsLoading && <CircularProgress size={16} style={{marginTop: -10}} className="button-progress" />}
  </div> :
  <React.Fragment><b>Kirim Ulang
  </b> {timer}</React.Fragment>;



  return (
    <Rodal className="sms-modal" visible={this.props.visible} onClose={() => this.props.onClose()}
      showCloseButton={false} height={this.state.modalHeight}
      >
      <div className="sms-modal-content" ref={ (divElement) => this.divElement = divElement}>
        <form onSubmit={this.handleSubmit} id="form-sms">
          <h3 className="sms-modal-title">Konfirmasi nomor kamu</h3>
          <p>Provesty telah mengirimkan kode otentifikasi lewat sms ke nomor <b>{this.props.u_phone}</b></p>
          <p>Masukkan kode verifikasi ke kotak di bawah:</p>

          {/*PIN Input*/}
          <div style={{ textAlign: 'center'}}>
            <FormControl className="my-form-control">
              <ReactCodeInput name="verification_code" type='number' fields={4}
                autoFocus={true}
                value={this.state.verification_code}
                onChange={this.handlePinChange}
                isValid={this.state.validErrors['verification_code'] ? false : true}
                {...propsReact}/>
              {
                this.state.validErrors['verification_code'] ? <FormHelperText className="my-form-helpertxt error" style={{textAlign: 'center'}} >{this.state.validErrors['verification_code']}</FormHelperText> : ''
              }
            </FormControl>
          </div>

          <div className="sms-modal-buttons" style={{marginTop: 30, marginBottom: 30}}>
            <div className="button-progress-wrapper">
              <Button disabled={this.state.buttonLoading} className="button-blue" type="submit" style={{width: 120}}>Konfirmasi</Button>
              {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
            </div>
            <Button style={{marginLeft: 16}} className="button-white" onClick={() => this.props.onClose()}>Batal</Button>
          </div>

          {/*send again*/}
          <div className="sms-modal-sendagain">Tidak menerima kode? {send_again}  </div>
        </form>
      </div>
    </Rodal>
  );
}
}
