import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class ModalViewEmail extends React.Component {

  render() {

    const { visible, emb_template, emb_attachment_csv} = this.props;
    // const [scroll, setScroll] = React.useState('paper');
    return (
      <Dialog
        classes={{paper: 'view-email-paper'}}
        open={visible}
        onClose={this.props.onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        maxWidth="md"
        >
        <DialogTitle id="scroll-dialog-title" style={{textAlign: 'right'}}
          onClick={this.props.onClose}>X</DialogTitle>
        <DialogContent style={{padding: "0 0 24px"}}>
          <div dangerouslySetInnerHTML={{
              __html: emb_template
            }}
            />
          {
            emb_attachment_csv?
            <div>Attachment: {
                emb_attachment_csv.split(',').map((item, index) => {
                  return (<a key={index} href={item} rel="noopener noreferrer" target="_blank">{(index + 1)}</a>)
                })}
              </div> :
              ''
            }
          </DialogContent>
        </Dialog>
      );
    }
  }
