import React from 'react';
import Rodal from 'rodal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MyTextField from "components/general/MyTextField";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { apiHelper, dispatchMsg, formData, formDef, formValid } from 'helpers';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/Visibility';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ModalPassword extends React.Component {

  static defaultProps = {
    history: null,
    bank_list: [],
    u_aggr_liquid_bal: 0,
    onClose: null
  };

  constructor(props) {
    super(props);

    var fields = formDef(this, [
      {name: 'u_password_old', label: 'Password Lama', valid: ['required', 'requiredMin|6']},
      {name: 'u_password_new', label: 'Password Baru', valid: ['required', 'requiredMin|6']},
      {name: 'u_password_conf', label: 'Ulang Password Baru', valid: ['required', 'requiredMin|6']},
    ]);

    this.state = {
      ...fields,

      showPasswordOld: false,
      showPasswordNew: false,
      showPasswordConf: false,
      buttonLoading: false,
      validErrors: {},
      modalHeight: 400,
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;
    this.setState({ [name]: val });
  };

  validate = () => {

    this.errors = {};

    formValid(this);

    const isNewPass = this.props.isPasswordSet ? false: true;
    if(isNewPass) { // old password is not necessary for new password
      delete this.errors.u_password_old;
    }

    if(this.state.u_password_new && this.state.u_password_conf && (this.state.u_password_new !== this.state.u_password_conf)) {
      this.errors['u_password_conf'] = 'Nilai ini tidak sesuai dengan password baru';
    }

    this.setState({validErrors: this.errors});

    return !Object.keys(this.errors).length;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    // clear the errors
    this.setState({ buttonLoading: true});

    apiHelper.request(this, {
      resource: 'change_password',
      data: formData('form-change-password'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          const isNewPass = this.props.isPasswordSet ? false: true;
          dispatchMsg('success', 'Password berhasil ' + (isNewPass? 'dibuat' : 'diganti'));
          this.props.onClose(true); // reload data as well

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({  buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        this.setState({ buttonLoading: false});
      },
    });
  }

  handleClickShowPassword = (name) => () => {
    this.setState({[name]: !this.state[name]});
  };

  componentDidUpdate() {
    // responsive height
    const height = this.divElement.clientHeight; // 378
    const { modalHeight } = this.state; // 370
    var diff = 1;
    if(height > diff && modalHeight !== height - diff)
    this.setState({modalHeight: (height - diff)});
  }

  render() {

    const { visible } = this.props;

    const isNewPass = this.props.isPasswordSet ? false: true;

    return (
      <Rodal className="sms-modal" visible={visible} onClose={() => this.props.onClose()}
        showCloseButton={false} width={420} height={this.state.modalHeight}
        >
        <div className="sms-modal-content" ref={ (divElement) => this.divElement = divElement}>
          <form onSubmit={this.handleSubmit} id="form-change-password">
            <h3 className="sms-modal-title">{ isNewPass? 'Buat' : 'Ganti' } Password</h3>
            { isNewPass ?
              '' :
              <Grid container className="form-group">
                <MyTextField name="u_password_old" obj={this}
                  variant="outlined" type={this.state.showPasswordOld? 'text' : 'password'} fullWidth={false} className='input-width'
                  InputProps={{
                    style: {paddingRight: 0},
                    inputProps: {style: {fontSize: 16, backgroundColor: 'white'}, autoComplete: 'current-password'},
                    endAdornment: (<InputAdornment>
                      <IconButton aria-label="Toggle password visibility" onClick={ this.handleClickShowPassword('showPasswordOld')}>
                        { this.state.showPasswordOld ? <VisibilityOff/> : <Visibility/> }
                      </IconButton>
                    </InputAdornment>)
                  }}
                  />
              </Grid>
            }
            <Grid container className="form-group">
              <MyTextField name="u_password_new" obj={this}
                variant="outlined" type={this.state.showPasswordNew? 'text' : 'password'} fullWidth={false} className='input-width'
                InputProps={{
                  style: {paddingRight: 0},
                  inputProps: {style: {fontSize: 16, backgroundColor: 'white'}, autoComplete: 'new-password'},
                  endAdornment: (<InputAdornment>
                    <IconButton aria-label="Toggle password visibility" onClick={ this.handleClickShowPassword('showPasswordNew')}>
                      { this.state.showPasswordNew ? <VisibilityOff/> : <Visibility/> }
                    </IconButton>
                  </InputAdornment>)
                }}
                />
            </Grid>
            <Grid container className="form-group">
              <MyTextField name="u_password_conf" obj={this}
                variant="outlined" type={this.state.showPasswordConf? 'text' : 'password'} fullWidth={false} className='input-width'
                InputProps={{
                  style: {paddingRight: 0},
                  inputProps: {style: {fontSize: 16, backgroundColor: 'white'}, autoComplete: 'new-password'},
                  endAdornment: (<InputAdornment>
                    <IconButton aria-label="Toggle password visibility" onClick={ this.handleClickShowPassword('showPasswordConf')}>
                      { this.state.showPasswordConf ? <VisibilityOff/> : <Visibility/> }
                    </IconButton>
                  </InputAdornment>)
                }}
                />
            </Grid>

            <div className="sms-modal-buttons" style={{marginTop: 30, marginBottom: 30}}>
              <Grid container>
                {/*<Grid item>
                  <Button style={{marginRight: 16, width: 120}} className="button-white" onClick={() => this.props.onClose()}>Batal</Button>
                  </Grid>*/}
                  <Grid item xs={12}>
                    <div className="button-progress-wrapper-full">
                      <Button disabled={this.state.buttonLoading} className="button-blue" type="submit" fullWidth={true}>Simpan</Button>
                      {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
            {
              isNewPass ?
              <div><p style={{textAlign: "center"}}>
                <b>Note!</b> Dengan membuat password kamu bisa login dengan menggunakan kombinasi email + password.
                </p>
              </div> :
              ''
            }
          </div>
        </Rodal>
      );
    }
  }
