import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import OjkLogo from "styles/assets/ojk.png";

export default class Ojk extends Component {
  render(){
    return(
      <div className="container ojk">
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="ojk-left ta-center">
              <img className="ojk-image" src={OjkLogo} alt="logo OJK"  />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="ojk-right">
              <p className="ojk-title">Provesty Telah Terdaftar OJK</p>
              <p className="ojk-content">We invite you all great talent who interested to be involved in the future of fintech in Indonesia to jumpship from your current place and join us building halofina vision. We seek people who wants to make a big impact and looking for a challenge.</p>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}
