import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from 'react-slick';
import { apiHelper, dispatchMsg, formData, imgPath } from 'helpers';
import { APISTAT } from '_constant';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import DefaultPropImg from 'styles/assets/default-project-image.png';
import ContentLoader from 'react-content-loader'
import { Link } from "react-router-dom";

export default class BlogHeader extends Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading: true,
      data: [],
      currentIdx: 0
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  componentDidMount() {
    this.loadData();
  }
  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'blog_hilites',
      data: formData({start: 0, length: 3}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading: false, data: resp.data, currentIdx: 0 });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ data: [], isLoading: false });
      }
    });
  }

  beforeChange = (oldIndex, newIndex) => {
    this.setState({currentIdx: newIndex});
  }

  render(){

    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.beforeChange
    };

    const { isLoading, currentIdx, data } = this.state;

    var item = isLoading ? {ps_slug: ''}: data[currentIdx];

    var rootClass = 'blog-header container';
    if(isLoading)
    rootClass += ' loading';

    return(
      <div className={rootClass}>
        <Grid container spacing={16}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {isLoading?
              <ContentLoader preserveAspectRatio="xMidYMid slice" width={700} height={375} speed={1}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="375" />
              </ContentLoader>
              :
              <TransitionGroup style={{position: 'relative'}}>
                <CSSTransition
                  key={'image' + currentIdx}
                  timeout={{ enter: 500, exit: 500 }}
                  classNames={'fade500'}
                  >
                  <Link className="casual-link" to={'/blog-detail/' + item.ps_slug} style={{textDecoration: 'none'}}>
                    <img className="blog-img-hl" src={isLoading || typeof item === 'undefined' ? DefaultPropImg : imgPath(item.ps_image) } style={{width: '100%'}} alt="blog" />
                  </Link>
                </CSSTransition>
              </TransitionGroup>
            }
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {isLoading ?
              <React.Fragment>
                <div className="blog-title-hl loading-effect">Tips Awal Berinvestasi Properti</div>
                <div className="blog-author-hl loading-effect">Intan Widya, 16 Januari 2019</div>
              </React.Fragment>
              :
              <React.Fragment>
                <TransitionGroup style={{position: 'relative'}}>
                  <CSSTransition
                    key={'title' + currentIdx}
                    timeout={{ enter: 500, exit: 500 }}
                    classNames={'fade500'}
                    >
                    <Link className="casual-link color-theme" to={'/blog-detail/' + item.ps_slug} style={{textDecoration: 'none'}}>
                      <div className="blog-title-hl">{item.ps_title}</div>
                    </Link>
                  </CSSTransition>
                  <CSSTransition
                    key={'author' + currentIdx}
                    timeout={{ enter: 500, exit: 500 }}
                    classNames={'fade500'}
                    >
                    <div className="blog-author-hl">{
                        isLoading || typeof item === 'undefined'?
                        'testing'
                        :
                        <div className="blog-author-hl">
                          <span className="blue-color">{item.u_name}</span>, {item.ps_published_tstamp}
                          </div>
                        }
                      </div>
                    </CSSTransition>
                  </TransitionGroup>
                  <Slider {...settings} className="blog-hl">
                    {
                      data.map((b, index) =>
                      <Link className="casual-link color-theme" key={index} to={'/blog-detail/' + b.ps_slug}>
                        <div>
                          <div className="risk-slider-content html-content" dangerouslySetInnerHTML={{__html: b.ps_content}} />
                        </div>
                      </Link>
                    )
                  }
                </Slider>
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </div>
    )
  }
}
