import React from 'react';
import Rodal from 'rodal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { price_d2r } from 'helpers';
import { apiHelper, dispatchMsg, formData } from 'helpers';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import {Link} from 'react-router-dom'

export default class ModalConfirm extends React.Component {

  static defaultProps = {
    history: null,
    id_payment: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,

      modalHeight: 340,
      potUploaded: false
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleReadingImg = (upload) => {

    this.setState({ buttonLoading: true});

    apiHelper.request(this, {
      resource: 'upload_pot',
      data: formData({
        id_payment: this.props.id_payment,
        py_transfer_proof: upload.target.result
      }),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ buttonLoading: false, potUploaded: true });

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({  buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false});
      },
    });
  }

  handleFileChange = (event) => {
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = this.handleReadingImg;
    reader.readAsDataURL(file);
  }

  componentDidUpdate() {
    // responsive height
    // const height = this.divElement.clientHeight; // 378
    // const { modalHeight } = this.state; // 370
    // var diff = 0;
    // if(modalHeight !== height - diff) {
    //   this.setState({modalHeight: (height - diff)});
    // }
  }

  render() {

    const { visible } = this.props;

    return (
      <Rodal className="sms-modal payment-modal" visible={visible} onClose={() => {}}
        showCloseButton={false} width={420} height={this.state.modalHeight}
        >
        <div className="payment-modal-content" ref={ (divElement) => this.divElement = divElement}>
          <div style={{backgroundColor: '#3873B8', color: 'white', padding: '15px 24px'}}>
            <Grid container alignItems="center" spacing={16}>
              <Hidden xsDown>
                <Grid item xs="auto">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 24 24" style={{fill: 'white'}}><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
                </Grid>
              </Hidden>
              <Grid item xs>
                <div style={{fontWeight: 'bold'}}>Berhasil!</div>
                <div style={{fontSize: 13}}>Konfirmasi kamu telah dikirim ke tim kami untuk segera di proses. Terima kasih atas kerjasamanya.</div>
              </Grid>
            </Grid>
          </div>
          <div style={{backgroundColor: '#3D7DC8', color: 'white'}} >
            <div style={{padding: 18}}>
              <div style={{textAlign: 'center', fontSize: 13}}>Jumlah Pembayaran</div>
              <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: 16}} >{price_d2r(this.props.py_amount_requested)},-</div>
              <div style={{marginTop: 13, fontSize: 13}}>Upload Bukti Pembayaran</div>
              <div style={{border: '1px white solid', padding: 17, borderRadius: 8}}>
                <Grid container alignItems="center" spacing={16}>
                  <Grid item xs={12} sm>
                    Silakan Upload bukti transfer untuk mempercepat proses verifikasi
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <div className="button-progress-wrapper">
                      <Button disabled={this.state.potUploaded || this.state.buttonLoading} variant="contained" component="label" className="button-white">
                        {
                          this.state.potUploaded ?
                          <React.Fragment><Icon>check</Icon> <span> Berhasil</span></React.Fragment>
                          :
                          <React.Fragment><Icon>cloud_upload</Icon> <span> Upload</span></React.Fragment>
                        }
                        <input type="file" style={{display:'none'}} onChange={ this.handleFileChange } accept="image/*" />
                      </Button>
                      {this.state.buttonLoading && <CircularProgress size={24} className="button-progress"/>}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div>
            <Link to="/dashboard" className="casual-link">
              <Button fullWidth className="button-white" style={{fontWeight: 'bold', padding: 10}}>Lihat Dashboard</Button>
            </Link>
          </div>
        </div>

      </Rodal>
    );
  }
}
