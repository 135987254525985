import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { userHelper, dispatchMsg, dispatchClearUser } from 'helpers';

class Logout extends Component {

  // componentDidMount() {
  //   var isLogin = userHelper.isLogin();
  //   if(isLogin) {
  //     dispatchClearUser();
  //     // dispatchMsg('info', 'Logout berhasil');
  //   }
  //   window.location = window.location.origin;
  // }
  render(){
    var isLogin = userHelper.isLogin();
    if(isLogin) {
      dispatchClearUser();
      dispatchMsg('info', 'Logout berhasil');
    }

    return(
      <Redirect push to={{pathname: "/"}} />
    )
  }
}

export default Logout
