import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Faq from 'styles/assets/how-works/FAQ.png'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'

const styles = theme => ({
  root: {
    maxWidth: 500,
    margin: '0 auto',
    marginTop: 30,
    marginRight: 10,
    marginLeft: 10,
  },
  container: {
    margin: '10'
  },
  expansion: {
    boxShadow: 'none',
    border: '1px solid #1d5d91',
    color: '#1d5d91',
    backgroundColor: 'white',
  }
});

class How6 extends Component {
  render() {

    const { classes } = this.props;

    return (<div className="container how6">
      <div className="ta-center">
        <img src={Faq} alt="faq" styles={{width: 65}} />
      </div>
      <div className="loan6-title">Pertanyaan Yang Sering Ditanyakan</div>
      <div className="loan6-content">Silahkan hubungi kontak kami yang terletak di bagian Footer jika masih terdapat pertanyaan</div>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Siapa saja yang bisa Invest di Provesty?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Siapa saja yang memiliki KTP dapat berinvestasi di Provesty
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Apakah Investasi di Provesty aman?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Aman. Karena dana investasi di Provesty 100% dijadikan dalam bentuk aset TANAH dan BANGUNAN yang sudah di analisis ketat oleh tim Provesty yang berpengalaman. Namun secara umum setiap investasi pasti tetap memiliki resiko, lebih lengkapnya bisa dilihat di tab "resiko" di website ini.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Apa itu Flipping Properti?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Flipping properti adalah jenis investasi di unit properti dengan jangka pendek. Flipper mencari properti di bawah harga pasar (dibuktikan dari data appraisal), mengumpulkan dana investor melalui crowdfunding pada platform provesty, membelinya dengan harga di bawah pasar tersebut, merenovasinya jika diperlukan dan menjualnya kembali dalam waktu singkat. Margin keuntungan penjualan digunakan untuk mengembalikan dana investor.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Bagaimana Flipper bisa mendapatkan Properti Flip di bawah harga pasar?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Properti untuk di Flip tidak harus di bawah harga pasar, yang terpenting adalah adanya margin keuntungan ketika dijual kembali. Namun Flipper kebanyakan mencari properti dibawah harga pasar biasanya dengan cara mencari properti yang bangunannya butuh direnovasi sehingga dijual murah, atau juga mendapatkan properti hasil lelang dari Bank atau dengan mencari pemilik rumah yang ingin rumahnya terjual cepat sehingga memberikan diskon kepada Flipper.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Bagaimana dengan kepemilikan Properti?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Tujuan pembelian properti melalui Provesty ini adalah untuk investasi bukan untuk ditempati, karena akan dijual kembali (Flip) dan mendapat margin. Sehingga kepemilikan secara intrinsik adalah kepemilikan bersama seluruh investor. Namun secara legal, sertifikat rumah akan atas nama badan hukum Provesty atau Flipper sebagai kuasa/wakil dari para investor. Namun sertifikat properti akan dipegang oleh Tim Provesty melalui Notaris rekanan sehingga Flipper tidak memungkinkan untuk menjual secara sepihak tanpa persetujuan Tim Provesty.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Bagaimana jika Properti tidak terjual?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  <b>Untuk skema pinjaman</b>, Flipper wajib mengembalikan dana investor beserta margin terlepas dari Properti tersebut terjual atau tidak. Jika Flipper tidak bisa mengembalikan (wanprestasi), Provesty akan melelang Properti melalui proses hukum untuk mengembalikan dana investor.
                  <br/><br/>
                  <b>Untuk skema bagi hasil</b>, investor harus siap menanggung penundaan jika Properti belum terjual. Namun investor juga berpeluang mendapat imbal hasil lebih besar jika terjual cepat. Untuk memitigasi, Provesty akan membuat perjanjian dengan Flipper tentang hak & kewajiban flipper, salah satunya kewajiban untuk menjual. Jika terbukti lalai/wanprestasi, maka Provesty berhak menjual sepihak untuk mengembalikan dana investor. Flipper juga harus menaruh dana pribadi dalam skema bagi hasil agar resiko ditanggung Bersama.
                  <br/><br/>
                  Kelebihan Properti sebagai asset investasi adalah bentuk asset nya yang riil sehingga kecil resiko kerugian. Namun Provesty tetap sangat menyarankan untuk mendiversifikasi investasi kamu di berbagai Properti. Dan secara umum setiap investasi pasti tetap memiliki resiko, lebih lengkapnya bisa dilihat di tab "resiko" di website kami.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Apakah setiap Properti yang masuk ke Provesty
                   langsung ditampilkan di website?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  TIDAK. Provesty akan melakukan analisis dan due diligence untuk setiap properti yang masuk. Mulai dari legal, keuangan, resiko, kredibilitas flipper, track record dll. Provesty memiliki tim dan advisor yang sudah berpengalaman dalam dunia properti. Jika diperlukan Provesty juga akan menggunakan pihak ketiga untuk melakukan appraisal dari property tersebut. Sehingga setiap properti yang ditampilkan sudah melewati serangkaian panjang analisis untuk memastikan keamanan, return yang efisien dan resiko yang terukur untuk para investor.
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className={classes.root}>
            <ExpansionPanel className={classes.expansion}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <p><b>Apakah saya bisa mencairkan sewaktu-waktu investasi saya?</b></p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <p>
                  Untuk saat ini, pencairan hanya dapat dilakukan di akhir masa tenor (untuk pinjaman) atau ketika property tersebut terjual (untuk bagi hasil). Namun dengan skema Flipping, jangka waktu pengembalian ditargetkan hanya jangka pendek, kurang dari 6 bulan sampai 1 tahun. Kedepannya Provesty juga akan membuat fitur trading, yaitu investor dapat menjual investasinya ke investor lain melalui secondary market kapanpun investor mau (coming soon).
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Grid>
      </Grid>
      <div style={{maxWidth: 900, margin: '0 auto', paddingTop: 100}}>
        <Grid container>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <div className="masih-ada-pertanyaan"><span style={{fontWeight: 600}}>Masih ada pertanyaan?</span> Kami siap untuk membantu</div>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Link to="/invest" style={{textDecoration: 'none'}}>
              <Button className="btn-dark-blue" variant="contained">Mulai Investasi</Button>
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>)
  }
}

How6.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(How6);
