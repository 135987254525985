import { connect } from 'react-redux';
import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -10,
    top: 0,
    width: 18,
    height: 18,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: `0 8px`,
    fontSize: 10,
  },
}))(Badge);

function UnreadContainer(props) {

  return(
    <div>
      <StyledBadge color="secondary" max={99} badgeContent={props.number}>
        {props.badgeLabel}
      </StyledBadge>
    </div>
  );
}


const mapStateToProps = (state) => ({
  ...state.unread
})

export default connect(mapStateToProps) (UnreadContainer);
