import React, {Component} from 'react';
import Navbar from 'components/general/Navbar'
import Footer from 'components/general/Footer'
import Career1 from 'components/career/Career1'

class Career extends Component {
  render(){
    return(
      <div className="career-page">
        <div className="career-top">
          <Navbar />
          <Career1 />
        </div>
        <div className="container">
          <Footer />
        </div>
      </div>
    )
  }
}

export default Career;
