import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MyTextField from "components/general/MyTextField";
import Button from '@material-ui/core/Button';
import MyRadioGroup from "components/general/MyRadioGroup";
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactSelect from "components/general/ReactSelect";
import {withStyles} from '@material-ui/core/styles';
import { formDef, apiHelper, userHelper, formData, dispatchMsg, formScrollToError, formValid, price_d2r } from 'helpers'
import { scroller } from 'react-scroll';
import Icon from '@material-ui/core/Icon';
import { STAT, APISTAT, COLORS } from '_constant'
//import {dataHelper} from 'helpers';
import Dialog from '@material-ui/core/Dialog';
import ProhousePopUp from './ProhousePopUp';
import Zoom from '@material-ui/core/Zoom';

const styles = theme => ({
  iconic: {
    fontSize: 48,
    marginTop: 100,
    color: '#0f3c68',
    margin: '0 auto',
    justifyContent: 'center',
  },
  iconicmobile:{
    fontSize: 60,
    color: '#0f3c68',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class ProhouseForm extends Component{

  constructor(props) {
    super(props);

    var fields = formDef(this, [
      {name: 'ph_name', label: 'Nama Lengkap'},
      {name: 'ph_phone', label: 'No. HP'},
      {name: 'ph_email', label: 'Email', valid: ['required', 'validEmail']},
      {name: 'ph_is_owner', label: 'CP adalah pemilik aset?'},

      // {name: 'ph_address', label: 'Alamat Lengkap'},
      {name: 'ph_title', label: 'Judul Properti'},
      {name: 'id_province', label: 'Provinsi'},
      {name: 'id_regency', label: 'Kota/Kabupaten'},
      {name: 'id_district', label: 'Kecamatan'},
      {name: 'id_village', label: 'Kelurahan'},
      {name: 'ad_line', label: 'Alamat'},

      {name: 'ph_price', label: 'Harga jual rumah', valid: ['required', 'requiredPrice', 'requiredMinPrice[10000000]']},
      {name: 'ph_duration', label: 'Kebutuhan waktu terjual'},
      {name: 'ph_has_try_sell', label: 'Sudah memasarkan rumah kamu?'},
      {name: 'ph_market_link', label: 'Link ke iklan rumah kamu (jika ada)', 'valid': ''},

      {name: 'ph_land_area', label: 'Luas Tanah'},
      {name: 'ph_build_area', label: 'Luas Bangunan'},
      {name: 'ph_cert', label: 'Sertifikat'},
      {name: 'ph_has_imb', label: 'Properti ini memiliki IMB?'},

    ]);

    this.state = {
      ...fields,

      provinces: {},
      regencies: {},
      districts: {},
      villages: {},

      isLoading: false,
      submitSuccess: false,
      buttonLoading: false,
      validErrors: {},
      isVisible: true,
      showPopUp: false
    };

  }



  hide(){
    this.setState({showPopUp:false});
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  validate = () => {

    this.errors = {};

    formValid(this);

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    // clear the errors
    this.setState({ buttonLoading: true});

    apiHelper.request(this, {
      resource: userHelper.isLogin() ? 'sprohouse_post' : 'prohouse_post', // use the secured one if loggedin
      data: formData('form-prohouse'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          // ok, no problem with credential, go to review
          dispatchMsg('success', 'Berhasil. Kami akan segera hubungi kamu ya!');
          this.setState({ buttonLoading: false, submitSuccess: true, showPopUp: true });
          scroller.scrollTo('prohouse-section', {duration: 300, delay: 100, smooth: 'easeInOutCubic'});
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, showPopUp:false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false });
      },
    });
  }


  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;

    if (name === 'id_province') {
      this.setState({ [name]: val }, this.loadRegency);
    } else if (name === 'id_regency') {
      this.setState({ [name]: val }, this.loadDistrict);
    } else if (name === 'id_district') {
      this.setState({ [name]: val }, this.loadVillage);
    } else {

      if (name === 'ph_price') {
        val = price_d2r(val);
      } else if (name === 'ph_phone') {
        // filter phone and decide if we need to verify
        val = val.replace(/[^\d\s()+.-]/g, '');  // filter non number and (,),+,-
      }
      this.setState({ [name]: val });
    }
  };

  loadVillage = () => {
    apiHelper.request(this, {
      resource: 'villages_select',
      urlParams: [this.state.id_district],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_village: '', villages: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }


  loadDistrict = () => {
    apiHelper.request(this, {
      resource: 'districts_select',
      urlParams: [this.state.id_regency],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_district: '', districts: resp.data, id_village: '', villages: [] });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadRegency = () => {
    apiHelper.request(this, {
      resource: 'regencies_select',
      urlParams: [this.state.id_province],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_regency: '', regencies: resp.data, id_district: '', districts: [], id_village: '', villages: [] });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'prohouse_get', // use the secured one if loggedin
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }


  render(){

    const { submitSuccess } = this.state;

    return(
      <div className="container" id="prohouse-section" style={{paddingTop: 30}}>

        <div style={{textAlign: 'center', fontWeight: 600, fontSize: 24}}>Ayo jual<span style={{color: COLORS.BG_DARKBLUE}}>*</span> rumah kamu bersama Prohouse</div>
        <div style={{textAlign: 'center', fontSize: 20}}>Silahkan isi form di bawah ini</div>
        <div style={{textAlign: 'center', fontSize: 14, marginTop: 10}}><span style={{fontWeight: 'bold'}}>Catatan: </span>saat ini kami baru melayani wilayah Jabodetabek dan Bandung Raya</div>
        <div style={{textAlign: 'center', fontSize: 12, marginTop: 10, color:"#aaa"}}>(<span style={{color: COLORS.BG_DARKBLUE}}>*</span>kolom dengan tanda bintang harus diisi)</div>
        {
          submitSuccess &&
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{textAlign: 'center', marginTop: 30, color: 'forestgreen'}}
            >
            <Grid item>
              <div>Aplikasi kamu sudah kami terima </div>
            </Grid>
            <Grid item>
              <Icon color="inherit">check</Icon>
            </Grid>
          </Grid>
        }
        <div className={submitSuccess? 'slide-up close' : 'slide-up'}>
          <div style={{marginTop: 30}}>
            <form onSubmit={this.handleSubmit} id="form-prohouse">
              <div className="form-legend">Contact Person (CP)</div>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={6} md={4}>
                  <MyTextField name="ph_name" obj={this} variant="outlined" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <MyTextField name="ph_phone" obj={this} variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MyTextField name="ph_email" obj={this} variant="outlined" />
                </Grid>

                <Grid item xs={12} sm={6} md={4} style={{marginTop: -7}}>
                  <MyRadioGroup
                    name="ph_is_owner" obj={this} options={[{k: STAT.STAT_YES, v: 'Ya, CP adalah pemilik aset'}, { k:STAT.STAT_NO, v:'Bukan, CP adalah agen/orang lain'}]} rgStyle={{marginTop: 15}} />
                </Grid>
              </Grid>
              <div className="form-legend">Judul & Alamat Properti</div>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={6} md={4}>
                  <MyTextField name="ph_title" obj={this} variant="outlined" placeholder="Contoh: Rumah 2 lantai di Ciledug" />
                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                  <ReactSelect name="id_province" obj={this}
                    options={Object.keys(this.state.provinces).map(k => ({k: k, v:this.state.provinces[k]}))} variant="outlined" classes={{input: 'padding11'}} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ReactSelect name="id_regency" obj={this} options={Object.keys(this.state.regencies).map(k => ({k: k, v:this.state.regencies[k]}))} variant="outlined" noOption="Pilih Provinsi terlebih dahulu" classes={{input: 'padding11'}} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ReactSelect name="id_district" obj={this}
                    options={Object.keys(this.state.districts).map(k => ({k: k, v:this.state.districts[k]}))} variant="outlined" noOption="Pilih Kota terlebih dahulu" classes={{input: 'padding11'}} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ReactSelect name="id_village" obj={this} options={Object.keys(this.state.villages).map(k => ({k: k, v:this.state.villages[k]}))} variant="outlined" noOption="Pilih Kecamatan terlebih dahulu" classes={{input: 'padding11'}} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <MyTextField name="ad_line" obj={this} variant="outlined" placeholder="Jalan & No. Rumah / Komplek Perumahan" />
                </Grid>
              </Grid>

              <div className="form-legend">Harga & Kebutuhan</div>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={6} md={4}>
                  <MyTextField name="ph_price" obj={this} variant="outlined" type="tel" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ReactSelect name="ph_duration" obj={this} variant="outlined" classes={{input: 'padding11'}}
                    options={[
                      {k: 'Mendesak (maks 3 bulan)', v: 'Mendesak (maks 3 bulan)'},
                      {k: 'Normal (maks 6 bulan)', v: 'Normal (maks 6 bulan)'},
                      {k: 'Santai (maks 1 tahun)', v: 'Santai (maks 1 tahun)'},
                    ]}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} style={{marginTop: -7}}>
                  <MyRadioGroup
                    name="ph_has_try_sell" obj={this} options={[{k: STAT.STAT_YES, v: 'Ya, sudah dipasarkan'}, { k:STAT.STAT_NO, v:'Belum, rumah ini belum dipasarkan'}]} rgStyle={{marginTop: 15}} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MyTextField name="ph_market_link" obj={this} variant="outlined" placeholder="Contoh: https://www.olx.co.id/item/dijual-rumah" />
                </Grid>

              </Grid>

              <div className="form-legend">Detail Properti</div>
              <Grid container spacing={24}>

                <Grid item xs={12} sm={6} md={4}>
                  <Grid container>
                    <Grid item style={{width: '48%'}}>
                      <MyTextField name="ph_land_area" obj={this} variant="outlined" type="number"  />
                    </Grid>
                    <Grid item style={{width: '4%'}} />
                    <Grid item style={{width: '48%'}}>
                      <MyTextField name="ph_build_area" obj={this} variant="outlined" type="number" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ReactSelect name="ph_cert" obj={this} variant="outlined" classes={{input: 'padding11'}}
                    options={[
                      {k: 'SHM', v: 'SHM/SHMSRS'},
                      {k: 'SHGB', v: 'SHGB'},
                      {k: 'PPJB', v: 'PPJB'},
                      {k: 'AJB', v: 'AJB'},
                      {k: 'Girik', v: 'Girik'}
                    ]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{marginTop: -7}}>
                  <MyRadioGroup
                    name="ph_has_imb" obj={this} options={[{k: STAT.STAT_YES, v: 'Ya, properti ini memiliki IMB'}, { k:STAT.STAT_NO, v:'Tidak, rumah ini belum memiliki IMB'}]} rgStyle={{marginTop: 15}} />
                </Grid>
              </Grid>

              <div className="button-progress-wrapper-full" style={{textAlign: 'center'}}>
                <Button disabled={this.state.buttonLoading} className="button-blue" type="submit"  style={{marginTop: 30, minWidth: 200}}>Daftarkan Properti</Button>
                {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" style={{marginTop: 3}} />}
              </div>
            </form>
          </div>
        </div>
        <div>
          {
            <Dialog
              open={this.state.showPopUp}
              onClose={this.hide.bind(this)}
              TransitionComponent={Transition}
              keepMounted
              PaperProps={{style: {margin: 10, borderRadius: 20, overflow:'hidden'}}}
              >
              <ProhousePopUp closeButton={this.hide.bind(this)}/>
            </Dialog>
          }
        </div>
      </div>
    )
  }
}

ProhouseForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProhouseForm);
