import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import FaqSection from 'components/faq/FaqSection';
import { apiHelper, debounceFn, dispatchMsg, formData, formatter_hilite } from 'helpers';
import MyTextField from "components/general/MyTextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Search from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Spinner from 'react-spinkit';
import { APISTAT } from '_constant';
import QueryNotFound from 'components/general/QueryNotFound';

class Faq extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      faqs: [],
      keyword: '',
      isDebouncing: false,

      validErrors: {} // just for the sake of using MyTextField
    };
  }

  componentDidMount() {
    // debouncing used by search field
    this.debounceLoadData = debounceFn(this.loadData, 350);
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  // this comes ONLY from search
  handleInputChange = event => {
    var name = event.target.name;
    var val = event.target.value;
    this.setState({ [name]: val, isDebouncing: true }, () => {
      this.debounceLoadData();
    });
  };

  loadData = () => {

    this.setState({ isLoading: true });
    apiHelper.cancelAllRequest(this);
    apiHelper.request(this, {
      resource: 'faq',
      data: formData({keyword: this.state.keyword}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS ) { // success
          this.setState({ isLoading: false, isDebouncing: false, faqs: resp.data });
        } else
        throw new Error(resp.msg);
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false, isDebouncing: false });
      }
    });
  }

  render() {

    let faqs = this.state.faqs;

    // overwrite with hilite
    if(faqs.length && !this.state.isDebouncing && this.state.keyword.trim()) {

      var keywords = this.state.keyword.split(/\s+/).filter((x) => x !== '');

      faqs = faqs.map(x => ({
        label: formatter_hilite(x.label, keywords),
        data: x.data.map(y => ({
          id_faq: y.id_faq,
          fq_question: formatter_hilite(y.fq_question, keywords),
          fq_answer: formatter_hilite(y.fq_answer, keywords),
        }))
      }));
    }

    faqs = faqs.map((x) => <FaqSection key={x.label} sectionTitle={x.label} items={x.data} />);


    const loadingAnimation = <div className="faq-loading-animation">
      <Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" />
    </div>;

    var faqClass = 'faq-section' + (this.state.isLoading && this.state.faqs.length !== 0 ? ' loading' : '');


    return (<div className="bg-lightblue">
    <Navbar/>
    <div className="container">
      <div>
        <Grid container>
          <Grid item md={3} />
          <Grid item xs={12} md={6} >
            <MyTextField name="keyword" obj={this} placeholder="Cari Pertanyaan/Jawaban" variant="outlined" fullWidth={true} InputLabelProps={{ style: {fontSize: 15}}}
              InputProps={{
                classes: { input: 'search-field', adornedStart: 'search-field adorned-start'},
                inputProps: {style: {fontSize: 15}},
                startAdornment: (
                  <InputAdornment>
                    {this.state.isLoading && this.state.keyword ?  <CircularProgress size={24} style={{margin: 12}} /> :
                    <IconButton aria-label="Search">
                      <Search/>
                    </IconButton>}
                  </InputAdornment>
                )
              }}
              />
          </Grid>
          <Grid item md={3} >
          </Grid>
        </Grid>
      </div>
      <div className={faqClass}>
        {this.state.isLoading && this.state.faqs.length === 0? loadingAnimation : (this.state.faqs.length === 0? <QueryNotFound /> : faqs) }
      </div>
      <Footer/>
    </div>

  </div>);
}

}

export default Faq;
