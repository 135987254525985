import { combineReducers } from "redux"

import { user } from './user.reducer'
import { listing } from './listing.reducer'
import { message } from './message.reducer'
import { unread } from './unread.reducer'
import { localizeReducer } from 'react-localize-redux';


const rootReducer = combineReducers({
  localize: localizeReducer,
  user,
  listing,
  message,
  unread
})

export default rootReducer
