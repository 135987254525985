import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { imgPath, price_d2r, apiHelper, date_d2r, date_r2d, dispatchMsg } from 'helpers';
import Tooltip from '@material-ui/core/Tooltip';
import ContentLoader from 'react-content-loader';
import { IVSTAT, APISTAT, COLORS } from '_constant';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DefaultPropImg from 'styles/assets/default-project-image.png';
import { NavLink, Link } from "react-router-dom";
import { Progress } from 'react-sweet-progress';
import ModalTransact from "components/dashboard/ModalTransact";
import ModalMOU from "components/dashboard/ModalMOU";
import Icon from '@material-ui/core/Icon';

var status = {
  [IVSTAT.PENDING]: {
    desc: 'Investasi sudah dibuat tetapi pembayaran belum terkonfimasi',
    color: '#F8910E'
  },
  [IVSTAT.EXPIRED]: {
    desc: 'Investasi batal karena pembayaran tidak terkonfimasi sampai batas waktu yang ditentukan',
    color: '#222222'
  },
  [IVSTAT.REGISTERED]: {
    desc: 'Pembayaran untuk investasi sudah terkonfirmasi dan tinggal menunggu semua slot properti terisi',
    color: '#3D68F1'
  },
  [IVSTAT.REFUNDED]: {
    desc: 'Pembayaran untuk investasi dikembalikan karena slot properti tidak terpenuhi dalam waktu yang ditentukan',
    color: '#E81977'
  },
  [IVSTAT.ACTIVE]: {
    desc: 'Properti slot properti terpenuhi yang menandakan proyek sudah berjalan dan siap memberikan penghasilan',
    color: '#24b47e'
  },
  [IVSTAT.PROCESSING]: {
    desc: 'Semua pembayaran telah terverifikasi. Pihak Provesty sedang mempersiapkan administrasi serta dokumen untuk memulai proyek.',
    color: '#EAB039'
  },
  [IVSTAT.COMPLETED]: {
    desc: 'Proyek sudah selesai dan tidak akan ada lagi penghasilan tambahan dari properti',
    color: '#41A533'
  },
  [IVSTAT.TRANSFERRED]: {
    desc: 'Investasi ini sudah dialihkan (dijual) ke user lain',
    color: '#32AFA9'
  },
}


export default class Portfolio extends React.Component {

  state = {
    mouVisible: false,
    mouContent: null,
    mouLoading: false,
    mou_id_investment: 0,

    transactVisible: false,
    transactData: {},
    transactLoading: false,

    extUserOpen: false,
  }

  load_mou = (event, id_investment) => {

    event.preventDefault();

    this.setState({mouLoading: true, mou_id_investment: id_investment});

    apiHelper.request(this, {
      resource: 'mou',
      urlParams: [id_investment],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ mouLoading: false, mouVisible: true, mouContent: resp.data });
        }  else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        this.setState({mouLoading: false});
      },
    });
  }

  handleUserToggle = () => {
    this.setState({ extUserOpen: !this.state.extOpen });
  };
  handleUserClose = (event, id_investment) => {
    if (event && this.anchorUserEl.contains(event.target))
    return;

    this.setState({ extUserOpen: false });
    if(id_investment)
    this.load_mou(event, id_investment);
  };

  multiple_list(list, symbol) {
    return (
      <React.Fragment>
        <Button
          style={{padding: 0, minWidth: 30, maxHeight: 20}}
          buttonRef={node => {
            this.anchorUserEl = node;
          }}
          aria-owns={this.state.extUserOpen ? 'menu-list-grow-user' : undefined}
          aria-haspopup="true"
          onClick={this.handleUserToggle} >
          <span>{symbol} </span>
          <i className="material-icons button-icon">keyboard_arrow_down</i>
        </Button>
        <Popper open={this.state.extUserOpen} className="popselect" anchorEl={this.anchorUserEl} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow-user"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
              <Paper>
                <ClickAwayListener onClickAway={this.handleUserClose}>
                  <MenuList className="menu-other">
                    {list}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }

  getGradientColor = (start_color, end_color, percent) => {
    // strip the leading # if it's there
    start_color = start_color.replace(/^\s*#|\s*$/g, '');
    end_color = end_color.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if(start_color.length === 3){
      start_color = start_color.replace(/(.)/g, '$1$1');
    }

    if(end_color.length === 3){
      end_color = end_color.replace(/(.)/g, '$1$1');
    }

    // get colors
    var start_red = parseInt(start_color.substr(0, 2), 16),
    start_green = parseInt(start_color.substr(2, 2), 16),
    start_blue = parseInt(start_color.substr(4, 2), 16);

    var end_red = parseInt(end_color.substr(0, 2), 16),
    end_green = parseInt(end_color.substr(2, 2), 16),
    end_blue = parseInt(end_color.substr(4, 2), 16);

    // calculate new color
    var diff_red = end_red - start_red;
    var diff_green = end_green - start_green;
    var diff_blue = end_blue - start_blue;

    diff_red = ( (diff_red * percent) + start_red ).toString(16).split('.')[0];
    diff_green = ( (diff_green * percent) + start_green ).toString(16).split('.')[0];
    diff_blue = ( (diff_blue * percent) + start_blue ).toString(16).split('.')[0];

    // ensure 2 digits by color
    if( diff_red.length === 1 ) diff_red = '0' + diff_red
    if( diff_green.length === 1 ) diff_green = '0' + diff_green
    if( diff_blue.length === 1 ) diff_blue = '0' + diff_blue

    return '#' + diff_red + diff_green + diff_blue;
  };

  launchTransaction = (event) => {
    this.setState({ transactLoading: true });
    apiHelper.request(this, {
      resource: 'transact_get',
      urlParams: [ this.props.id_investment],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ transactLoading: false, transactVisible: true, transactData: resp.data });
        } else if (resp.status === APISTAT.ERR_USER_CREDENTIAL) { // user need to fill in credential first

          this.setState({ transactLoading: false }, () => this.props.history.push("/credential/profile"));
        } else {
          // EXIT_USER_CREDENTIAL
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ transactLoading: false });
      }
    });
  }

  render() {

    var {isLoading, pro_title, iv_status, img_path, iv_calc_value, iv_slot, total_profit, sc_date, sc_info, iv_status_tstamp, id_investments, id_investment, iv_slots, iv_registered_tstamps, pro_slug, pro_start_project_date, iv_pending_tstamps, elapse_percent, duration_elapsed, days_extra, pro_duration, pro_code } = this.props;

    const { mouVisible, mouContent, mouLoading, mou_id_investment } = this.state;

    var mou = '';
    var next_schedule = '';

    if(isLoading) { // still loading
      pro_title = 'Project ABC in Jakarta Indonesia';
      iv_calc_value= 'Rp 1.000.000.000';
      total_profit= 'Rp 1.000.000';
      mou = <React.Fragment>
        <div>&nbsp;</div>
        <div><span className="loading-effect">Lihat</span></div>
      </React.Fragment>;
      next_schedule = 'Still loading';

    } else {

      var ids = id_investments.split(',');
      var slots = iv_slots.split(',');
      var reg_dates = iv_registered_tstamps.split(',');
      var pending_dates = iv_pending_tstamps.split(',');

      var mou_icon = <div className="mou-icon-wrapper"><i className="fa fa-file-text-o mou-icon">
      </i></div>;

      if ([IVSTAT.ACTIVE, IVSTAT.REGISTERED, IVSTAT.COMPLETED].indexOf(iv_status) !== -1) {

        let link = null;
        if (mouLoading && ids.indexOf(mou_id_investment) !== -1) {
          // loading
          link = <div>{mou_icon} &nbsp;
            <CircularProgress size={14}/>
          </div>;
        } else if (ids.length > 1 ) {
          // multiple MOUs
          link = this.multiple_list(
            ids.map((id, index) => {
              return (
                <MenuItem key={id} onClick={(e)=>this.handleUserClose(e, id)}>{date_d2r(reg_dates[index]) + '/ ' + slots[index] + ' slot'}</MenuItem>
              )
            }),
            mou_icon
          );
        } else {
          // single MOU
          link = <a href='# ' onClick={(e) => this.load_mou(e, id_investment)}>{mou_icon}
          </a>;
        }

        mou = <React.Fragment>
          <div className="pf-title">MOU:</div>
          <div><span className="loading-effect">{link}</span></div>
        </React.Fragment>;

        next_schedule = sc_date ? sc_date + ', ' + sc_info : '-';

      } else if ([IVSTAT.PENDING].indexOf(iv_status) !== -1) {

        let link = null;
        if (mouLoading && ids.indexOf(mou_id_investment) !== -1) {
          // loading
          link = <div>{mou_icon} &nbsp;
            <CircularProgress size={14} />
          </div>;
        } else if (ids.length > 1 ) {
          // multiple MOUs
          link = this.multiple_list(
            ids.map((id, index) => {
              return (
                <NavLink to={"/payment/" + pro_slug + '-' + id} key={id}>
                  <MenuItem onClick={this.handleUserClose}>{date_d2r(pending_dates[index]) + '/ ' + slots[index] + ' slot'}</MenuItem>
                </NavLink>
              )
            }),
            'Lihat'
          );
        } else {
          // single Pending
          link = <NavLink to={"/payment/" + pro_slug + '-' + id_investment}>Lihat
          </NavLink>;
        }

        mou = <div style={{backgroundColor: COLORS.BG_GRAB_ATTN, padding: 5, margin: -5, borderRadius: 5}}>
          <div className="pf-title">Pending:</div>
          <div><span className="loading-effect">{link}</span></div>
        </div>;
        next_schedule = sc_date ? sc_date + ', ' + sc_info : '-';

      } else {

        mou = <React.Fragment>
          <div className="pf-title">MOU:</div>
          <div><span className="loading-effect">-</span></div>
        </React.Fragment>;

        next_schedule = '-';
      }

    }

    // className={isFull ? 'progress-full':'progress-unfull'}
    // symbolClassName= {isFull ? '':'symbol-hide'}
    if(iv_status === IVSTAT.ACTIVE) {
      // check if this is in processing or not
      if (date_r2d(new Date()) < date_r2d(pro_start_project_date)) {
        iv_status = IVSTAT.PROCESSING;
      } else {
        iv_status_tstamp = pro_start_project_date; // start from start project
      }
    }

    // elapse_percent, duration_elapsed, days_extra
    // #049E51
    // only show percentage when status is active
    const percentage_symbol = iv_status === IVSTAT.ACTIVE || iv_status === IVSTAT.PROCESSING?
    <Tooltip disableTouchListener classes={{popper:"tooltip-content"}} title={
        iv_status === IVSTAT.PROCESSING ?
        <div>Proyek ini sedang dalam persiapan dan ditargetkan mulai tanggal <b>{pro_start_project_date}</b>
      </div> :
      <div>
        Waktu Berjalan: {duration_elapsed}
        <br />
        Target Durasi: {pro_duration}
      </div>
    } >
    <div>
      <Progress
        theme={{
          active: {
            color: '#3d7dc8',
            trailColor: 'lightblue',
            width: '100%',
            symbol: <Icon style={{fontSize:15, color:'#999'}}>access_time</Icon>
        },
        default: { symbol: <Icon style={{fontSize:15, color:'#999'}}>access_time</Icon> },
        success: {
          color: '#049E51'
          // color: this.getGradientColor('#049E51', '#F8A000', (elapse_percent - 100 ? 100: elapse_percent))
        }
      }}
      percent={elapse_percent < 0? 0: (elapse_percent > 100 ? 100: elapse_percent)}
      className="info-pop-link"
      symbolClassName={days_extra ? 'symbol-hide': ''}
      />
  </div>
</Tooltip>
:
'';

var color = isLoading? '' : status[iv_status].color;
var statusDesc = isLoading? '' : status[iv_status].desc;

return (
  <Paper className="pf-paper soft-paper">
    <Grid container>
      <Grid item xs={12} md={2}>
        {isLoading ?
          <ContentLoader preserveAspectRatio="xMidYMid slice" width={300} height={200} speed={1}>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="179" />
          </ContentLoader>
          :
          <div>
            <Link to={"/detail/" + pro_slug}>
              <img src={img_path? imgPath(img_path): DefaultPropImg} alt="property" className="img-responsive"/>
            </Link>
            {percentage_symbol}
          </div>
        }

      </Grid>
      <Grid item md={1} />
      <Grid item md={9} container spacing={16}>
        <Grid item xs={12}>
          <div><Link to={"/detail/" + pro_slug} className="casual-link color-theme"><b className="loading-effect">{pro_title} {pro_code? <span className="pro_code bigger"> ({pro_code})</span>: ''}</b></Link></div>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4}>
          <div className="pf-title">Investasi:</div>
          <div><span className="loading-effect"><b>{price_d2r(iv_calc_value)}</b>/ {iv_slot} Slot</span></div>
        </Grid>
        <Hidden only={["md"]}>
          <Grid item xs sm md={false} lg={3}>
            <div className="pf-title">Profit:</div>
            <div><span className="loading-effect"><b>{price_d2r(total_profit)}</b></span></div>
          </Grid>
        </Hidden>
        <Grid item xs sm md={4} lg={3}>
          <div className="pf-title">Transaksi:</div>
          <div className="loading-effect">
            <div className="button-progress-wrapper-full">
              <Icon style={{fontSize: 14}}>edit</Icon><div disabled={this.state.transactLoading} className="faux-link loading-effect" onClick={this.launchTransaction} style={{display: 'inline-block', color: 'inherit'}}>detail</div>
              {this.state.transactLoading && <CircularProgress size={16} style={{marginTop: -8, marginLeft: -5}} className="button-progress" />}
            </div>
          </div>
        </Grid>
        <Grid item xs="auto" md={3} lg={2}>
          {mou}
        </Grid>


        <Grid item xs={12} sm md lg>
          <div className="pf-title">Jadwal Selanjutnya:</div>
          <div className="loading-effect">{next_schedule}</div>
        </Grid>
        <Grid item sm="auto" md="auto" lg="auto">
          <div className="pf-title">Status:</div>
          <div className="loading-effect"><b><span style={{ color }}>{iv_status}</span></b>&nbsp;
            <Tooltip disableTouchListener classes={{popper:"tooltip-content"}} title={
                <div>{statusDesc}</div>
              }>
              <i style={{fontSize: 13}} className="material-icons tiny info-pop-link">info</i>
            </Tooltip>, {iv_status_tstamp}
          </div>
        </Grid>

      </Grid>
    </Grid>
    {/*mou document*/}
    <ModalMOU
      visible={mouVisible}
      content={mouContent}
      onClose={() => this.setState({ mouVisible: false }) }
      />
    {/*transaction detail*/}
    <ModalTransact
      visible={this.state.transactVisible}  {...this.state.transactData}
      history={this.props.history}
      onClose={
        (msgObj) => this.setState ({transactVisible: false, ...msgObj},
          () => { typeof msgObj !== 'undefined' && this.loadData() }
        )
      }
      />

  </Paper>
);

}
}
