import React from 'react';
import Rodal from 'rodal';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MyTextField from "components/general/MyTextField";
import { dispatchUpdateUser, apiHelper, formData, formDef, formValid } from 'helpers';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ModalEmail extends React.Component {

  constructor(props) {
    super(props);

    var fields = formDef(this, [
      {name: 'u_email', label: 'Email Baru', valid: ['required', 'validEmail']},
    ]);

    this.state = {
      ...fields,
      buttonLoading: false,
      validErrors: {},
      modalHeight: 370,
    };
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;
    this.setState({ [name]: val });
  };

  validate = () => {

    this.errors = {};

    formValid(this);

    this.setState({validErrors: this.errors});

    return !Object.keys(this.errors).length;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    // clear the errors
    this.setState({ buttonLoading: true});

    apiHelper.request(this, {
      resource: 'change_verified_email',
      data: formData('form-email'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success

          dispatchUpdateUser(resp.data);
          this.setState({ buttonLoading: false }, () => this.props.history.push('/send-email-verify'));

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({  buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        this.setState({ buttonLoading: false});
      },
    });
  }

  componentDidUpdate() {
    // responsive height
    const height = this.divElement.clientHeight; // 378
    const { modalHeight } = this.state; // 370
    var diff = 1;
    if(height > diff && modalHeight !== height - diff)
    this.setState({modalHeight: (height - diff)});
  }

  render() {

    const { visible } = this.props;

    return (
      <Rodal className="sms-modal" visible={visible} onClose={() => this.props.onClose()}
        showCloseButton={false} width={420} height={this.state.modalHeight}
        >
        <div className="sms-modal-content" ref={ (divElement) => this.divElement = divElement}>
          <form onSubmit={this.handleSubmit} id="form-email">
            <h3 className="sms-modal-title">Ganti Email</h3>

            <Grid container className="form-group">
              <MyTextField name="u_email" label="" obj={this}
                variant="outlined"
                InputProps={{
                  style: {paddingRight: 0},
                  inputProps: {style: {fontSize: 16, backgroundColor: 'white'}, autoComplete: 'new-email'}
                }}
                />
            </Grid>

            <div className="sms-modal-buttons" style={{marginTop: 30, marginBottom: 30}}>
              <div className="button-progress-wrapper">
                <Button disabled={this.state.buttonLoading} className="button-blue" type="submit" style={{width: 120}}>Simpan</Button>
                {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
              </div>
              <Button style={{marginLeft: 16}} className="button-white" onClick={() => this.props.onClose()}>Batal</Button>
            </div>
          </form>
          <div><p style={{textAlign: "center"}}>
            <b>Note!</b> Sistem akan mengirimkan email verifikasi ke alamat email yang baru.
            </p></div>
          </div>
        </Rodal>
      );
    }
  }
