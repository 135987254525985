import React, { Component } from 'react'
import WalletIcon from 'styles/assets/wallet.png'


class InvestedMark extends Component {
  render(){
    return(
      <div>
        <div
          style={{
            backgroundColor: '#FFF762',
            color: '#4589D6',
            width: this.props.width_size,
            borderRadius: 8,
            textAlign: 'center',
            padding: 3,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            fontSize: this.props.font_size,
            marginTop: this.props.margin_top
        }}>
          <img src={WalletIcon} alt="wallet-icon" style={{width: 15, height:20}}/>
          <span style={{marginLeft: 10}}>
            Kamu invest disini.
          </span>
        </div>
      </div>
    )
  }
}

export default InvestedMark;
