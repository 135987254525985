import React, { Component } from 'react';
import Logo from 'styles/assets/logo.png'
import { APISTAT } from '_constant';
import { apiHelper, dispatchMsg, formData, formVal, dispatchUpdateUser } from 'helpers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MyTextField from "components/general/MyTextField";
import UserContainer from 'redux/container/UserContainer'
import { Link, NavLink } from "react-router-dom";


export default class SendEmailVerify extends Component {

  constructor(props) {
    super(props);
    this.state = {

      seconds: 60,
      u_email: '',
      sendAgainLoading: false,
      changeEmailLoading: false,

      validErrors: {}
    };
  }

  timerHandle = null;

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
    this.stopTimer();
  }

  componentDidMount() {
    // check if we need to resend first time
    var params = new URLSearchParams(this.props.history.location.search);
    var action = params.get('action')
    if(action === 'resend')
    this.sendEmail()
    else
    this.startTimer()
  }

  handleInputChange = event => {
    var name = event.target.name;
    var val = event.target.value;

    this.setState({ [name]: val });
  };

  startTimer() {
    if (this.timerHandle === null) {
      this.setState({seconds: 59});
      this.timerHandle = setInterval(this.countDown, 1000);
    }
  }
  stopTimer() {
    clearInterval(this.timerHandle);
    this.timerHandle = null;
  }
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({ seconds: seconds });

    // Check if we're at zero.
    if (seconds === 0)
    this.stopTimer();
  }

  sendEmail = (event) => {
    if(event)
    event.preventDefault();

    this.setState({ sendAgainLoading: true });

    apiHelper.request(this, {
      resource: 'send_email_verify',
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success

          dispatchMsg('success', 'Email verifikasi berhasil dikirim');
          this.startTimer();
          this.setState({ sendAgainLoading: false });

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ sendAgainLoading: false });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ sendAgainLoading: false});
      }
    });
  }

  validate = () => {

    this.errors = {};

    formVal(this, 'u_email', 'Email', ['required', 'validEmail']);

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  changeEmail = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    this.setState({ changeEmailLoading: true });

    apiHelper.request(this, {
      resource: 'change_email',
      data: formData({u_email: this.state.u_email}),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success

          dispatchMsg('success', 'Email verifikasi terkirim ke ' + this.state.u_email);
          dispatchUpdateUser(resp.data);
          this.startTimer();
          this.setState({ changeEmailLoading: false, u_email: ''}); // empty the email

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ changeEmailLoading: false });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ changeEmailLoading: false});
      }
    });
  }

  render(){

    var { seconds } = this.state;

    var timer = seconds < 10 ? "0" + seconds: seconds;
    timer = '00:' + timer;

    var send_again = seconds === 0 ?
    <div className="button-progress-wrapper">
      <Button disabled={this.state.sendAgainLoading} onClick={this.sendEmail} className="button-blue" style={{padding: "0px 5px", fontSize: 13, marginTop: -4
      }}>Kirim Ulang &nbsp;
      <i className="material-icons button-icon">send</i>
    </Button>
    {this.state.sendAgainLoading && <CircularProgress size={16} style={{marginTop: -10}} className="button-progress" />}
  </div> :
  <React.Fragment><b>Kirim Ulang
  </b> {timer}</React.Fragment>;

  return(
    <div className="login-page">
      <div className="container container-login">
        <NavLink to='/'>
          <img src={Logo} alt="logo-white" style={{zIndex: 1000, width: 150, marginTop: 26, cursor: 'pointer'}} />
        </NavLink>
        <div id="auth-root">
          <Grid container justify="center" alignItems="center" className="faux-tab auth-box" >
            Verifikasi Email
          </Grid>
          <div className="auth-box">
            <div className="tab-container">
              <div className="auth-form">
                Untuk memverifikasi email silakan klik link yang telah dikirimkan ke alamat email <u><UserContainer part="email" /></u>

              {/*send again*/}
              <div style={{marginTop: 14, fontSize: 13}}>Tidak menerima kode? {send_again} </div>


              <div style={{marginTop: 15}}>
                <form onSubmit={this.changeEmail} id="form-send-email">
                  <Grid container direction="column" spacing={8}>
                    <Grid item>
                      Ingin menggunakan email selain <UserContainer part="email" /> untuk email akun Provesty?
                    </Grid>
                    <Grid item>
                      <MyTextField name="u_email" label="Email Baru" obj={this}
                        className='input-width' variant="outlined" InputLabelProps={{ style: {fontSize: 16}}} InputProps={{ inputProps: {style: {fontSize: 16}, autoComplete: 'username'}}}/>
                    </Grid>
                    <Grid item>
                      <div className="button-progress-wrapper-full">
                        <Button disabled={this.state.changeEmailLoading} type="submit" fullWidth className="button-blue">Ganti Email
                        </Button>
                        {this.state.changeEmailLoading && <CircularProgress size={24} className="button-progress" />}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="sign-up-now">
          <p>Kembali ke halaman<span className="sign-up-button"> <Link to="/login"><u>Login</u></Link></span></p>
        </div>
      </div>
    </div>

  </div>
)
}
}
