import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
//import Pemodalan from 'styles/assets/home/permodalan.png';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';
import LoanImage1 from 'styles/assets/loan/loan1.png'


class ForFlipper extends Component {
  render(){
    return(
      <div className="container for-flipper">
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <div className="img-center">
                <img src={LoanImage1} alt="loan" className="loan1-image"/>
              </div>
            </ScrollAnimation>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="flipper-right">
              <ScrollAnimation animateIn="swing" animateOnce={true}>
                <div className="capsule" style={{width: 200}}>
                  Untuk Penjual Rumah
                </div>
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <div style={{paddingTop: 20}}>
                <div className="flipper-title">
                  <div>Jual rumahmu dengan Cepat & Mudah </div>
                </div>
              <div style={{paddingTop: 20, lineHeight: 2, textAlign: 'justify', marginBottom: 20}}>
                  Provesty memberikan akses untuk kamu menjual rumah dengan mudah dan cepat. Dapatkan penawaran <i>cash advance</i> atau opsi <i>instant selling</i> dengan Provesty membeli rumah kamu secara cash dalam 1 hari.
                </div>
              </div>
              <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Link to="/prohouse" style={{textDecoration: "none"}}>
                    <Button variant="outlined" className="btn-dark-blue">
                      Jual Rumahmu Sekarang
                    </Button>
                  </Link>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Link to="/how-to-sell" style={{textDecoration: "none"}}>
                    <Button variant="outlined" className="button-mulai-investasi">
                      Pelajari Lebih Lanjut
                    </Button>
                  </Link>
                </Grid>
              </Grid>

            </ScrollAnimation>
          </Grid>
        </Grid>
      </div>
    )
  }
}


export default ForFlipper;
