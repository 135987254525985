import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid'
import { APISTAT } from '_constant'
import { apiHelper, dispatchMsg } from 'helpers';
// import ContentLoader from 'react-content-loader';
// import Lock from 'styles/assets/Icon.png'

class Statistics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        pr_count: 10,
        investor_count: 1000,
        investment_sum: 'Rp 7,49 M',
        profit_sum: 'Rp 376 Jt'
      },
      isLoading: true
    }
  }

  componentDidMount() {
    this.loadData();
  }
  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }
  loadData = () => {
    this.setState({ isLoading: true });

    apiHelper.request(this, {
      resource: 'statistic',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS ) { // success
          this.setState({ isLoading: false, data: resp.data });
        } else
        throw new Error(resp.msg);
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }

  render(){

    const data = this.state.data;

    var rootClass = "container stat-container";

    if(this.state.isLoading) {
      rootClass += ' loading';
    }

    return(
      <div className={rootClass}>
        <Grid container spacing={16}>
          {/*<Grid item xs={12}>
          <img src={Lock} className="stat-icon" alt='kunci gembok'
          />
          </Grid>*/}
          <Grid item lg={2} md={2} sm={6} xs={6}>
            <div className="data-statistic">
              <div><span className="statistic-number">{data.pr_count}</span></div>
              <div className="statistic-title">Proyek</div>
            </div>
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={6}>
            <div className="data-statistic">
              <div><span className="statistic-number">{data.investor_count}</span></div>
              <div className="statistic-title">Investor terdaftar</div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <div className="data-statistic">
              <div><span className="statistic-number">{data.investment_sum}</span></div>
              <div className="statistic-title">Dana Terkumpul</div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <div className="data-statistic">
              <div><span className="statistic-number">{data.profit_sum}</span></div>
              <div className="statistic-title">Keuntungan dibagikan</div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="stat-statement">
              Memberikan kemudahaan dan aksesibilitas bagi setiap orang untuk bisa berinvestasi pada properti dengan menggunakan teknologi.
            </div>
          </Grid>
        </Grid>

      </div>
    )
  }
}

export default Statistics
