import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid'
import { imgPath } from 'helpers';
import DefaultPropImg from 'styles/assets/default-project-image.png';
import DefAvatarPic from "styles/assets/default-avatar-pic.png";
import Chip from '@material-ui/core/Chip';
import ContentLoader from 'react-content-loader'

export default class BlogContent extends Component {

  render() {
    var {isLoading} = this.props;
    var {ps_title, ps_content, ps_image, u_photo, u_name, ps_published_tstamp, ago, tg_texts='', ps_view_count} = this.props.data;

    var rootClass = '';
    if(isLoading) {
      rootClass += ' loading';
      ps_title = 'Investasi Properti dengan Strategi Flip';
      u_name = 'Intan Widya';
      ps_published_tstamp = '16 Januari 2018';
      ago = '1 Bulan lalu';
    }

    return(
      <div className={rootClass}>
        <div className="blog-title-det loading-effect">{ps_title}</div>

        <div style={{margin: '15px 0 14px'}}>
          <Grid container justify="flex-start" className="blog-content-header"
            alignItems="center" spacing={32}>
            <Grid item>
              <Grid container justify="flex-start"
                alignItems="center" spacing={16}>
                <Grid item >
                  {
                    isLoading ?
                    <div className="loading-effect img-circle" style={{display: 'block', width: 40, height: 40}}
                      />
                    :
                    <img src={u_photo ? imgPath(u_photo) : DefAvatarPic} alt="author" className="img-circle" style={{display: 'block', width: 40, height: 40}}
                      />
                  }

                </Grid>
                <Grid item>
                  <div className="loading-effect" style={{fontWeight: 'bold', textTransform: 'uppercase'}}>by {u_name}</div>
                  <div className="loading-effect">{ago}</div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className="right-blog-divider hidden-xs" />
            <Grid item className="hidden-xs">
              <div className="loading-effect" style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{ps_published_tstamp}</div>
            </Grid>
            <Grid item className="right-blog-divider hidden-xs" />
            <Grid item className="hidden-xs">
              <Grid container justify="center" style={{textAlign: 'center'}}>
                <div>
                  <div className="loading-effect" style={{fontSize: 18, fontWeight: 'bold'}}>{ps_view_count}</div>
                  <div>Dilihat</div>
                </div>
              </Grid>
            </Grid>
            <Grid item className="right-blog-divider hidden-xs" />
            <Grid item className="hidden-xs">
              <Grid container justify="center" style={{textAlign: 'center'}}>
                <div>
                  <div className="loading-effect" style={{fontSize: 18, fontWeight: 'bold'}}>0</div>
                  <div>Komentar</div>
                </div>
              </Grid>
            </Grid>
          </Grid>

        </div>



        {
          isLoading?
          <ContentLoader preserveAspectRatio="xMidYMid slice" width={700} height={300} speed={1}>
            <rect x="0" y="0" rx="5" ry="5" width="700" height="300" />
          </ContentLoader>
          :
          <img src={ps_image ? imgPath(ps_image) : DefaultPropImg} alt="blog" className="blog-image img-responsive" style={{margin: '14px 0 25px'}} />
        }

        <div className="html-content" dangerouslySetInnerHTML={{
            __html: ps_content
          }}
          />
        <Grid container spacing={8} style={{marginTop: 30}}>
          {
            tg_texts.split(',').filter(x => x).map( x =>
              <Grid item key={x}><Chip label={x} variant="outlined" classes={{root: 'mychip', label: 'mychip-label'}} /></Grid>
            )
          }
        </Grid>
      </div>
    )
  }
}
