import React, {Component} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.secondary.main,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
    backgroundColor: '#f4fafd',
    boxShadow:'0 2px 4px 0 rgba(100, 100, 100, 0.5)',
    marginBottom: 0
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});

class DashboardTab extends Component {

  static defaultProps = {
    history: 'null'
  }

  state = {
    tab: ''
  }

  handleTabChange = (event, value) => {
    this.setState({ tab: value });
    setTimeout(() => {this.props.history.push(value)}, 250)
  }

  render(){

    const { classes } = this.props;

    var tabVal = this.state.tab? this.state.tab : this.props.history.location.pathname;

    // sanity check
    if(!this.props.paths.map(x => x.v).includes(tabVal))
    tabVal = this.props.paths[0].v;

    const tabs = this.props.paths.map(x => <Tab style={{fontWeight: 500}} key={x.l} label={x.l} value={x.v}/>)

    const tabLinks = <Tabs
      key="tab-dashboard-nav"
      classes={{
        root: classes.tabsRoot,
      }}
      value={tabVal}
      indicatorColor="primary"
      textColor="primary"
      onChange={this.handleTabChange}
      >
      {tabs}
    </Tabs>;

    const tabLinksSmDown = <Tabs
      key="tab-dashboard-nav"
      classes={{
        root: classes.tabsRoot,
      }}
      value={tabVal}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      onChange={this.handleTabChange}
      >
      {tabs}
    </Tabs>;


    return(<div>
      <Hidden only={['xs','sm']}>
        {tabLinks}
      </Hidden>
      <Hidden only={['md','lg', 'xl']}>
        {tabLinksSmDown}
      </Hidden>
    </div>
  )
  ;
}
}

DashboardTab.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(DashboardTab);
