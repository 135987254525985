import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { imgPath } from 'helpers';
import { COLORS } from '_constant'

class PaymentInstruction extends Component {

  render() {
    const { pt, pw } = this.props;

    let _payments = '';
    let _expired = '';
    let _bank_transfer = '';
    if(!pt && !pw) { // no payment at all
      _payments = <p></p>
    } else {
      if(!pt) {
        _payments = <div>
          Tidak ada yang harus ditransfer
        </div>
        ;
      }

      if(pt && pt.py_exp_deadline_str) {
        var exp_str = pt.py_exp_deadline_date + ' ' + pt.py_exp_deadline_time + ', (' + pt.py_exp_deadline_str + ' lagi) ';
        _expired =
        <React.Fragment>
          <div className="paper-body-title">Lakukan Pembayaran Sebelum</div>

          <div className="db-box">
            {exp_str}
          </div>
        </React.Fragment>;
      }
      if(pt && pt.id_bank_account_dst) {
        _bank_transfer =
        <React.Fragment>

          <div className="paper-body-title" style={{marginTop: 20}}>Silakan Transfer ke</div>

          <div style={{borderRadius: 5, border: '1px solid #f5f8fa', overflow: 'hidden' }}>
            <div style={{ backgroundColor: '#f5f8fa'}} className="payment-bank">
              <Grid container alignItems="center">
                <Grid item xs={9} md={4}>
                  {pt.ba_name}
                </Grid>
                <Grid item xs={false} md={3}/>
                <Grid item xs={3} md={5}>
                  <img src={imgPath(pt.ba_logo)} alt="bank" />
                </Grid>
              </Grid>
            </div>

            <div className="payment-bank">
              <Grid container spacing={16}>
                {
                  [
                    {title: 'No. Rekening', value: pt.bac_no},
                    {title: 'Atas Nama', value: pt.bac_owner_name},
                    {title: 'Jumlah Transfer', value: <span style={{fontWeight: 500, fontSize: 16}} dangerouslySetInnerHTML={{
                      __html: pt.py_amount_hl
                    }}
                    />},
                  ].map((x, index) =>
                  <Grid item container alignItems="center" key={index}>
                    <Grid item xs={4}>
                      {x.title}
                    </Grid>
                    <Grid item xs={1}>:</Grid>
                    <Grid item xs={7}>
                      {x.value}
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </div>
          {
            pt.py_amount !== pt.py_amount_requested ?
            <div className="payment-bank" style={{backgroundColor: COLORS.BG_GRAB_ATTN, fontSize: 12}}>
              <b>PENTING!</b> Kode unik (angka yang dihighlight kuning
                dan ditambahkan ke nilai transfer) diperlukan untuk mengidentifikasi pembayaran. Transfer yang dilakukan tanpa kode unik beresiko tidak dikenali. Nilai ekstra ini akan ditambahkan ke Wallet kamu.
              </div>
              :
              ''
            }
          </div>
        </React.Fragment>;
      }
    }

    return (
      <Paper className="soft-paper">
        <div className="paper-title">
          Detail Transfer
        </div>
        <div className="paper-body">
          {_expired}
          {_payments}
          {_bank_transfer}
        </div>
      </Paper>
    )
  }
}


export default PaymentInstruction;
