import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
// import Lock from 'styles/assets/Icon.png';
// import Probuild from 'styles/assets/vector1.png';
// import Proflip from 'styles/assets/vector2.png';
// import Prorent from 'styles/assets/vector3.png';
import Visual2 from 'styles/assets/how-works/Visual2.png';
import Switch, {State} from 'react-switchable';
import SkemaPinjaman from './SkemaPinjaman';
import BagiHasil from './BagiHasil'

export default class How3 extends Component {


  state = {
    activeTab: 0,
    tabs: [
      {
        value: "Skema Fix Margin",
        coba: "satu",
        content: <SkemaPinjaman />
    }, {
      value: "Skema Bagi Hasil",
      coba: "dua",
      content: <BagiHasil />
  }
]
};

render() {

  const {tabs, activeTab} = this.state;

  return (
    <div className="how3 container">
      <p className="skema-title">Skema Investasi</p>
      <p className="skema-content">Terdapat 2 skema dalam berinvestasi di provesty yaitu skema pinjaman dan skema bagi hasil</p>
      <Grid container>
        <Grid item lg={6} md={5} sm={12} xs={12}>
          <img src={Visual2} alt="visual2" className="how3-image"/>
        </Grid>
        <Grid item lg={6} md={7} sm={12} xs={12}>
          <div className="quiz">
            <Switch onValueChange={(_, newPosition) => this.setState({activeTab: newPosition})}>
              {
                tabs.map((tab, index) => (<State key={index} active={activeTab === index} value={tab.value}>
                  {tab.value}
                </State>))
              }
            </Switch>
            <div>
              {tabs[activeTab].content}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>)
  }
}
