import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PlayStore from 'styles/assets/home/playstore.png'
import Sirine from 'styles/assets/pop-up/sirine.png'
import AppStore from 'styles/assets/home/app-store.png'
import BigLogo from 'styles/assets/logo-17big.png'
import CloseIcon from '@material-ui/icons/Close';


export default class JobPostingDeception extends Component{
  render(){
    return(
      <div className="modal-home-content mobile-center" style={{color:'white'}}>
        <div id="mobile-app-header">
          <CloseIcon onClick={this.props.closeButton} />
        </div>
        <Grid container>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            {/*<div className="loan1-title" id="mobile-app-title" style={{fontSize:24}}>Sekarang #BisaAkses</div>*/}
            <Hidden smDown>
              <div style={{width: '100%', textAlign:'center'}}><img src={BigLogo} alt="Alert" style={{height: 50}} /></div><br />
              <div style={{width: '100%', textAlign:'center'}}><img src={Sirine} alt="Alert" style={{width: 100, marginBottom: 20}} /></div><br />
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginBottom: 8}}>Temukan Sekarang</div>
              <Grid container direction="row">
                <Grid item xs container justify="flex-end">
                    <a href="https://play.google.com/store/apps/details?id=com.provesty.provesty" target="_blank" rel="noopener noreferrer">
                      <img src={PlayStore} alt="Google Play" style={{width: 150, maxWidth: '100%'}} />
                    </a>
                  </Grid>
                  <Grid item style={{width: "5%"}} />
                  <Grid item xs container justify="flex-start">
                    <a href="https://apps.apple.com/us/app/provesty/id1481804972" target="_blank" rel="noopener noreferrer">
                      <img src={AppStore} alt="App Store" style={{width: 150, maxWidth: '100%'}} />
                    </a>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <div>
                <div className="loan1-subtitle mg-up30" style={{fontSize: 13, textAlign:'center', paddingLeft: 10, paddingRight: 10, paddingTop: -30, marginBottom: 20}}>
                  PENGUMUMAN :
                  <br />
                  <p style={{textAlign:'justify'}}>PT Provesty Global Nusantara
                  menghimbau agar mewaspadai penipuan
                  bermodus rekrutmen karyawan.
                  <br /><br />
                  Mohon untuk diketahui bahwa
                  PT Provesty Global Nusantara tidak pernah
                  meminta uang atau pembayaran
                  dalam bentuk apapun dari kandidat
                  dalam setiap tahapan rekrutmen karyawan.
                  <br /><br />
                  Waspadailah informasi dari alamat
                  yang bukan milik PT Provesty Global Nusantara
                  Selalu periksa kebenaran dari informasi
                  yang Anda peroleh ke pihak
                  Rekrutmen PT Provesty Global Nusantara</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )
    }
  }
