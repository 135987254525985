export const dataHelper = {
  set: function (varName, data) {
    return localStorage.setItem(varName, JSON.stringify(data));
  },
  get: function (varName, clear)  {
    // clear is used to clear the message once is read
    if(typeof clear === 'undefined')
    clear = false;
    // can be used to check if valid data is stored
    var data = localStorage.getItem(varName);
    var ret = data? JSON.parse(data) : null;
    if(clear)
    this.clear(varName);
    return ret;
  },
  clear: function (varName) {
    localStorage.removeItem(varName);
  }
}

export default dataHelper;
