import React, {Component} from "react";
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

class NormalSelect extends Component {

  static defaultProps = {
    name: "",
    label: "",
    value: "",
    init: null, // {k="", v=""}
    options: [], // array of object with key (k) and val (v)
    onChange: null,
    fullWidth: true,
    required: false,
    variant: 'standard',
    error: null,
    helperText: ''
  }

  render() {

    const p = this.props;

    const { obj, options } = p;

    const fc_class = {root:"my-form-control my-form-control-select"};
    const fc_label = {root: "my-form-label", focused: "my-form-label-focused", shrink: "my-form-label-shrink"};
    const fc_input = {root: "my-form-select"};

    let fc_param = {
      fullWidth: p.fullWidth,
      variant: p.variant,
      classes: fc_class
    };

    let label_param = {
      htmlFor: p.name,
      variant: p.variant,
      classes: fc_label
    };
    let input_param = {
      id: p.name,
      name: p.name,
      classes: fc_input,
      // value: p.value,
      type: p.type ,
      variant: p.variant,
    };

    if(p.id) {
      input_param.id = p.id;
      label_param.htmlFor = p.id;
    }

    var sOptions = options.map(x => ({ value: x.k, label: x.v}));
    var sVal = {value: '', label: 'Pilih'};
    let helperText = '';
    if (obj) {
      if(!(p.name in obj.state))
      throw new Error('state ' + p.name  + ' is not declared!');

      // check value against options
      for(var i=0; i<sOptions.length; i++) {
        if(sOptions[i].value.toString() === obj.state[p.name].toString()) {
          sVal = sOptions[i];
          break;
        }
      }

      if(p.helperText) {
        helperText = p.helperText;
      }
      if(typeof obj.state.validErrors[p.name] !== 'undefined') {
        helperText = obj.state.validErrors[p.name];
        fc_param.error = true;
      }
      if(helperText)
      helperText = <FormHelperText className="my-form-helpertxt">
        {helperText}
      </FormHelperText>;
    }

    var label = p.label;
    if(p.required && label) {
      p.label += '*';
    }

    return (
      <FormControl {...fc_param}>
        <InputLabel {...label_param}>{p.label}</InputLabel>
        <Select options={sOptions} {...input_param}
          value={sVal}
          onChange={obj => this.props.obj.handleInputChange({target: {name:this.props.name, value:obj.value}})}
          />
        {helperText}
      </FormControl>
    );
  }
}

export default NormalSelect;
