import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";



export default class DataDeletion extends React.Component {



  render() {

    return (
      <div className="career-page">
        <div className="career-top">
          <Navbar />

          <div className="container">
            <h3>Policy for Data Deletion</h3>
            <p>If you are an existing Provesty user and wish to have your data deleted please contact us at <a href="mailto:info@provesty.com">info@provesty.com</a>.</p>

            <p>This Privacy Policy was last updated on: Saturday, Feb 20th, 2022.</p>

            <i>Should we update, amend or make any changes to our data deletion policy, those changes will be posted here.</i>

          </div>
        </div>
        <div className="container">
          <Footer />
        </div>
      </div>

    )
  }
}
