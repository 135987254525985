import React from 'react';
import ImageGallery from 'react-image-gallery';
import Paper from '@material-ui/core/Paper'
import ContentLoader from 'react-content-loader'
import { imgPath } from 'helpers'
import DefaultPropImg from 'styles/assets/default-project-image.png';

export default class PropCarousel extends React.Component {

  static defaultProps = {
    isLoading: true,
    images: []
  };

  render() {

    var { isLoading, images } = this.props;

    const svg = () => {
      return(
        <ContentLoader preserveAspectRatio="xMidYMid slice" width={750} height={390} speed={1}>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="390" />
        </ContentLoader>
      );
    };

    const loadingImages = [
      {
        renderItem: svg,
        renderThumbInner: svg
      }, {
        renderItem: svg,
        renderThumbInner: svg
      }, {
        renderItem: svg,
        renderThumbInner: svg
      }
    ]

    var imgs = isLoading ? loadingImages : (
      images.length? images.map(img => ({
        original:  imgPath(img.img_path),
        thumbnail: imgPath(img.img_path),
        sizes: "750x390"
      })): [{original: DefaultPropImg, thumbnail: DefaultPropImg}]
    );

    return (

      <Paper className="soft-paper prop-detail-carou">
        <ImageGallery
          infinite={true}
          showBullets={true}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={true}
          showIndex={false}
          disableArrowKeys={true}
          autoPlay={!this.props.isLoading}
          items={imgs}
          slideDuration={250}
          />
      </Paper>);
    }
  }
