import React, {Component} from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import DashboardSidebar from "components/general/DashboardSidebar";
import Grid from '@material-ui/core/Grid';
import { apiHelper, dispatchMsg, formData, imgPath } from 'helpers';
import { APISTAT } from '_constant'
import Hidden from '@material-ui/core/Hidden';
import Spinner from 'react-spinkit';
import { scroller } from 'react-scroll';
// import QueryNotFound from 'components/general/QueryNotFound';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactPaginate from 'react-paginate';
import DashboardTab from 'components/dashboard/DashboardTab';
import { ReactSelectFilter as ReactSelect } from "components/general/ReactSelect";
import ReceiptIcon from "styles/assets/receipt.png";
//import UnreadBadges from 'components/general/UnreadBadges'
import UnreadContainer from 'redux/container/UnreadContainer'

export default class DashboardTransaction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,

      id_property: '',
      prop_list: [],
      iv_status_x: '',
      validErrors: {},

      currentPage: 0, // 0 based page number
      pageCount: 1,
    };
  }

  perPageCount = 6; // decide

  componentDidMount() {
    this.loadData();
    this.loadPropertySelect();
  }
  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;

    // this only comes for filtering purpose, so load again
    this.setState({ [name]: val }, this.loadData);

  };

  loadPropertySelect = () => {
    apiHelper.request(this, {
      resource: 'property_select',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({prop_list: resp.data});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ data: [], isLoading: false });
      }
    });
  }

  loadData = (pageNum) => {

    // if undefined then it's calledfrom keyword
    var currentPage = typeof pageNum === 'undefined'? 0: pageNum;

    var data = {
      start: currentPage * this.perPageCount,
      length: this.perPageCount,
      id_property: this.state.id_property,
      iv_status_x: this.state.iv_status_x
    };

    this.setState({ isLoading: true });

    apiHelper.request(this, {
      resource: 'transaction',
      data: formData(data),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setState({
            pageCount: Math.ceil(resp.data.recordsFiltered/this.perPageCount) ,
            data: resp.data.data,
            isLoading: false,
            currentPage: currentPage });
          } else {
            throw new Error(resp.msg);
          }
        },
        fnError: err => {
          dispatchMsg('error', err.message);
          this.setState({ data: [], isLoading: false });
        }
      });
    }

    handlePageClick = (e) => {
      // pagination use 0-based page
      this.loadData(e.selected);
      // scroll to top if necessary
      if(document.querySelector('#myScrollToElement').getBoundingClientRect().top < 0) {
        scroller.scrollTo('myScrollToElement', {
          duration: 300,
          delay: 100,
          smooth: 'easeInOutCubic'}
        );
      }
    }

    render() {

      const { data, isLoading } = this.state;

      const table =
      <Table className="table table-transact">
        <TableHead>
          <TableRow style={{height: 45}}>
            <Hidden mdDown>
              <TableCell>No</TableCell>
            </Hidden>
            <TableCell>Tanggal</TableCell>
            <Hidden smDown>
              <TableCell>Tipe Transaksi</TableCell>
              <TableCell>Properti</TableCell>
            </Hidden>
            <Hidden mdUp>
              <TableCell>Tipe Transaksi & Properti</TableCell>
            </Hidden>
            <TableCell>Saldo Wallet</TableCell>
            <TableCell>Saldo Properti</TableCell>
            <Hidden mdDown>
              <TableCell>Total Saldo</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((x, index) => {
              return(
                <TableRow key={index}>
                  <Hidden mdDown>
                    <TableCell align="center">
                      <span className="loading-effect">
                        {this.state.currentPage * this.perPageCount + index + 1}
                      </span>
                    </TableCell>
                  </Hidden>
                  <TableCell align="right">
                    <span className="loading-effect">
                      {x.tstamp}
                    </span>
                  </TableCell>
                  <Hidden smDown>
                    <TableCell>
                      <span className="loading-effect">
                        {x.type} {
                          x.pot ? <a href={imgPath(x.pot)} title="receipt" target="_blank" rel="noopener noreferrer"><img src={ReceiptIcon} align="middle" alt="receipt" /></a> : ''
                        }
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="loading-effect">{x.prop}
                        {x.pro_code? <span className="pro_code"> ({x.pro_code})</span>: ''}</span>
                    </TableCell>
                  </Hidden>
                  <Hidden mdUp>
                    <TableCell>
                      <span className="loading-effect" dangerouslySetInnerHTML={{__html: x.type}} />
                      <br />
                      <span className="loading-effect">{x.prop}</span>
                    </TableCell>
                  </Hidden>
                  <TableCell align="right" className="transact-cell"><div><span className="loading-effect">
                    {
                      x.bal_liquid_change ? (x.bal_liquid_change.charAt(0) === '+' ? <span className="green">({x.bal_liquid_change})<br/></span>: <span className="red">({x.bal_liquid_change})<br /></span>)
                      : ''
                    }
                    {x.bal_liquid}
                  </span></div></TableCell>
                  <TableCell align="right" className="transact-cell"><div><span className="loading-effect">
                    {
                      x.bal_asset_change ? (x.bal_asset_change.charAt(0) === '+' ? <span className="green">({x.bal_asset_change})<br/></span>: <span className="red">({x.bal_asset_change})<br /></span>)
                      : ''
                    }
                    {x.bal_asset}
                  </span></div></TableCell>
                  <Hidden mdDown>
                    <TableCell align="right" className="transact-cell"><div><span className="loading-effect">{x.bal_total}</span></div></TableCell>
                  </Hidden>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>;

      return (
        <div>
          <Navbar/>
          <Grid container className="container">
            <Grid item className="dbsidebarplace">
              <DashboardSidebar history={this.props.history} />
            </Grid>
            <Grid item xs name="myScrollToElement" id="myScrollToElement">
              <DashboardTab paths={[
                  { l: 'Dashboard', v: '/dashboard' },
                  { l: 'Portfolio', v: '/dashboard/portfolio' },
                  { l: 'Transaksi', v: '/dashboard/transaction' },
                  { l: <UnreadContainer badgeLabel="Pesan"/>, v: '/dashboard/message' },
                ]} history={this.props.history}
                />
              <Paper classes={{root:"dash-area"}} className={isLoading? 'loading' : ''} style={{borderRadius: '0px 0px 4px 4px'}}>


                <Grid container alignItems="center" spacing={16} style={{marginBottom: 8}}>
                  <Grid item xs={12} sm={12} md={12} lg="auto">
                    <div className="table-title">Daftar Transaksi</div>
                  </Grid>

                  <Hidden mdDown>
                    <Grid item>
                      <div style={{margin: '0 20px'}}>|</div>
                    </Grid>
                  </Hidden>

                  <Grid item>
                    <div style={{fontSize: 13}}>filter:</div>
                  </Grid>

                  <Grid item xs={12} sm md lg>
                    <ReactSelect name="id_property" label="" obj={this}
                      options={[
                        {k: '', v: '- Semua Proyek -'},
                        ...this.state.prop_list
                      ]}
                      />
                  </Grid>

                  <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                    <ReactSelect name="iv_status_x" label="" obj={this}
                      options={[
                        {k:'', v: ' - Semua Tipe -'},
                        {k:'Invest', v: 'Invest'},
                        {k:'Return', v: 'Return'},
                        {k:'Top-Up', v: 'Top-Up'},
                        {k:'Pencairan', v: 'Pencairan'},
                        {k:'Refund', v: 'Refund'}
                      ]}
                      />
                  </Grid>
                </Grid>
                <div className="table-container">
                  {table}
                </div>
                {
                  isLoading && data.length === 0?
                  <div className="my-spinner-container">
                    <Spinner fadeIn='quarter' name="chasing-dots" />
                  </div>
                  :
                  <React.Fragment>
                    {
                      data.length === 0 && !isLoading && this.state.currentPage === 0 ?
                      <div style={{textAlign: 'center', margin: '20px 0 30px', fontStyle: 'italic', fontSize: 15}}>Belum ada transaksi
                      </div> : ''
                    }
                    <div className="pagination-container">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        forcePage={this.state.currentPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                    </div>
                  </React.Fragment>
                }
              </Paper>
            </Grid>
          </Grid>
          <div className="wavyflip pushupx">
            <Footer/>
          </div>
        </div>
      );
    }
  }
