import React from 'react';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {Progress} from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Place from 'styles/assets/location.svg';
import Tag from 'styles/assets/proflip.svg';
import { Link } from "react-router-dom";
import ContentLoader from 'react-content-loader'
import {PROPSTATUS,STAT} from '_constant';
import { price_d2r, imgPath, userHelper } from 'helpers';
import DefaultPropImg from 'styles/assets/default-project-image.png';
import DoneImg from 'styles/assets/stamp_done.png';
import Ribbon from 'components/general/Ribbon';
import InvestedMark from 'components/general/InvestedMark';

const PropListItem = ({isLoading, ivt_name, rg_name, pr_name, percentage,
  pro_price, img_path, pro_title, pro_aggr_status, pro_is_coming_soon, pro_return, pro_aggr_funded, pro_slug, pro_duration, pro_risk,
  remaining_day, remaining_time, pro_total_slot, pro_code, my_slot}) =>
  {

    let time_left = '';

    let cardClass = 'proplist-container';
    // add class and create dummy to fill up space
    if(isLoading) {
      cardClass += ' loading';
      ivt_name = 'proflip';
      pro_code = 'PRO-00000'
      rg_name = 'Kuningan, Jakarta Selatan';
      pro_title = "Refinancing Unit Fountain Park Apartment, Pancoran, Jakarta";
      img_path = '//:0';
    } else {
      // if there's time left then selective string
      if (remaining_day || remaining_time !== '00:00') {
        if(remaining_day) {
          time_left = remaining_day + ' Hari ';
        } else {
          var tmp = remaining_time.split(':');
          time_left += tmp[0] + ' Jam ' + tmp[1] + ' Menit';
        }
        time_left += ' lagi';
      } else {
        time_left = 'Sudah Selesai';
      }
    }

    // condition for product whether using ribbon or not for the new open funding
    const showRibbon = (pro_aggr_status === PROPSTATUS.PROPSTAT_OPEN && pro_is_coming_soon === STAT.STAT_NO);

    // condition for full funded
    const isFull = (percentage === 100);

    // variable for minimum invest based on total slot
    const minimum_invest = parseInt(pro_price/pro_total_slot);

    // ribbon styling
    const ribbon_new = <div style={{position:'absolute', zIndex:'50', top:-25, left: 0, width: 350, maxWidth: '100%'}}>
      <Ribbon/>
    </div>

    const percentage_info =
    <div className="time-remaining">
      Terkumpul:&nbsp;
      {
        isFull ?
        <span style={{color: ' #24b47e'}}><b>{percentage}%</b></span>
        :
        <span style={{color:'#3d7dc8'}}><b>{percentage}% <span style={{float: 'right'}}>{time_left}</span></b></span>
      }
    </div>

    const done_stamp = pro_aggr_status === PROPSTATUS.PROPSTAT_COMPLETED ?
    <img src={DoneImg} alt="completed" style={{position: 'absolute', width: 150, top: 12, left: 13}}>
    </img> : '';

    const user_isInvest =
    <div className="img-center" style={{marginTop:-20}}>
      <InvestedMark
        font_size={11}
        margin_top={-6}
        width_size={150}
      />
    </div>

    // info that used inside product image
    const image_info =
    <div className="info-offer-detail">
      <Grid container>
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <img src={Tag} alt="tag-icon" width={20}/>
        </Grid>
        <Grid item lg={3} md={2} sm={2} xs={2}>
          <div className="card-info-content">
            <span style={{fontSize: 13}}>{pro_code}</span>
          </div>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1} />
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <img src={Place} alt="tag-icon" width={14}/>
        </Grid>
        <Grid item lg={6} md={7} sm={7} xs={7}>
          <div className="card-info-content">
            <span>{rg_name}, {pr_name}</span>
          </div>
        </Grid>
      </Grid>
    </div>

    const property_pic =
    <CardMedia className="card-info-image" image={img_path ? imgPath(img_path) : DefaultPropImg}
      style={{ minHeight: 179, marginTop: '0', borderRadius: '5px 5px 0px 0px' }}
      >
      { done_stamp }
      { showRibbon ? <div className="ribbon-placeholder">{ribbon_new}{image_info}</div> : <div>{image_info}</div> }
    </CardMedia>

    const percentage_symbol =  <Progress
      theme={{active: {
        color: '#3d7dc8',
        trailColor: 'lightblue',
        width: '100%',
      }}}
      percent={percentage}
      className={isFull ? 'progress-full':'progress-unfull'}
      symbolClassName= {isFull ? '':'symbol-hide'} />


    const img_card = isLoading ?
    <ContentLoader preserveAspectRatio="xMidYMid slice" width={320} height={179} speed={1}>
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="179" />
    </ContentLoader>
    :
    <Link to={`/detail/${pro_slug}`} style={{textDecoration: 'none'}}>
      {property_pic}
    </Link>


    const product_title =
    <div className="card-title-info" style={{textAlign: 'center'}}>
      <div className="card-title-info">
        {
          isLoading?
          <ContentLoader preserveAspectRatio="xMidYMid slice" width={100} height={38} speed={1}>
            <rect x="0" y="0" rx="5" ry="5" />
          </ContentLoader>
          :
          <b>{pro_title}</b>
        }
      </div>
    </div>


    const card_detail=<div>
      {product_title}
      {userHelper.isLogin() ?
        my_slot>0 ?
          user_isInvest
          :
          null
        :
        null
      }
      <div className="card-progress">
        <div className="progress-width" style={{height: 40}}>
          {percentage_info}
          {percentage_symbol}
        </div>
      </div>
      <div>
        <div className="card-detail">
          <Grid container>
            <Grid item xs={6}>
              <div className="card-title-content">
                Imbal Hasil:
              </div>
              <div className="card-content">
                {pro_return}
              </div>
              <div className="card-title-content">
                Durasi Proyek:
              </div>
              <div className="card-content">
                {pro_duration}
              </div>
              <div className="card-title-content">
                Resiko:
              </div>
              <div className="card-content">
                {pro_risk}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="card-title-content">
                Terkumpul:
              </div>
              <div className="card-content">
                <span style={isFull? {color:'#24b47e'}: {}}>
                  {price_d2r(pro_aggr_funded)}
                </span>
              </div>
              <div className="card-title-content">
                Target:
              </div>
              <div className="card-content">
                {price_d2r(pro_price)}
              </div>
              <div className="card-title-content">
                Minimum Investasi:
              </div>
              <div className="card-content">
                {price_d2r(minimum_invest)}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>

    const card_detail_condition = isLoading ?
    <div>
      {card_detail}
    </div>
    :
    <Link to={`/detail/${pro_slug}`} style={{textDecoration: 'none', color: '#6b7c93'}}>
      {card_detail}
    </Link>

    //Render to display products
    return (
      <div className={cardClass}>
        <Card className="card">
          <div>
            <div style={{display: 'absolute', zIndex: '-1', top: 0}}>
              {img_card}
            </div>
          </div>
          {card_detail_condition}
        </Card>
      </div>
    );
  }


  export default PropListItem
