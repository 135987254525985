import React, {Component} from "react";
import Hidden from '@material-ui/core/Hidden';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Icon from '@material-ui/core/Icon';

export default class DashboardMobileNav extends Component {

  static defaultProps = {
    history: 'null'
  }
  state = {
    tab: '',
    bottomNav: ''
  };

  handleNavChange = (event, value) => {
    this.setState({ bottomNav: value });
    setTimeout(() => {this.props.history.push(value)}, 200);
  };

  render() {

    const bottomNav = this.state.bottomNav ? this.state.bottomNav : this.props.history.location.pathname;

    const overrideClasses = {selected: 'nav-selected'};
    const bottomNavs =
    <div className="bottom-navigation">
      <BottomNavigation
        key="my-bottom-navigation"
        value={bottomNav}
        onChange={this.handleNavChange}
        showLabels>
        <BottomNavigationAction
          value="/dashboard"
          label="Dashboard"  icon={<Icon>dashboard</Icon>} classes={overrideClasses} />
        {/*<BottomNavigationAction
          label="Flipper" value="/dashboard/loan" icon={<Icon>art_track</Icon>} classes={overrideClasses} />*/}
        <BottomNavigationAction
          label="Pengaturan" value="/dashboard/profile" icon={<Icon>person</Icon>} classes={overrideClasses} />
      </BottomNavigation>
    </div>
    ;

    const tmp =
    <div id="dashboard-buttons-area">
      <Hidden smUp>
        {bottomNavs}
      </Hidden>
    </div>;

    return tmp;
  }
}
