import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid'
import Skema from 'styles/assets/how-works/skema.png'

class How4 extends Component {
  render(){
    return(
      <div className="how4 container">
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="how4-left">
              <div className="ta-center">
                <p className="how4-title">Struktur Hukum Provesty</p>
              </div>
              <Grid container>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <div className="how4-number-1">
                    1
                  </div>
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <div>
                    <div className="how4-left-title">
                      Investor Crowdfunding
                    </div>
                    <div className="how4-left-content">
                      Investor mengumpulkan dana & memberi kuasa pada Provesty
                    </div>
                  </div>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <div className="how4-number-2">
                    2
                  </div>
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <div>
                    <div className="how4-left-title">
                      Penandatangan Perjanjian
                    </div>
                    <div className="how4-left-content">
                      Pemilik Rumah & Provesty menandatangani perjanjian pinjaman atau bagi hasil
                    </div>
                  </div>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <div className="how4-number-1">
                    3
                  </div>
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <div>
                    <div className="how4-left-title">
                      Beli, Renovasi & Jual Properti
                    </div>
                    <div className="how4-left-content">
                      Dengan dana investor, Provesty membeli & merenovasi Properti untuk dijual dengan harga lebih tinggi
                    </div>
                  </div>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <div className="how4-number-2">
                    4
                  </div>
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <div>
                    <div className="how4-left-title">
                      Notaris Mengikat Sertifikat
                    </div>
                    <div className="how4-left-content">
                      Untuk proteksi investor, tim legal Provesty mengikat sertifikat Properti melalui notaris rekanan
                    </div>
                  </div>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <div className="how4-number-1">
                    5
                  </div>
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <div>
                    <div className="how4-left-title">
                      Sertifikat disimpan Provesty
                    </div>
                    <div className="how4-left-content">
                      Sertifikat yang sudah dijaminkan melalui badan hukum Provesty disimpan dalam deposit box yang aman
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
              <img src={Skema} alt="skema provesty" style={{maxWidth: 600, width: '98%', margin: '0 auto', paddingTop: 30}} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default How4;
