
import { SET_UNREAD } from '_constant'

export function unread(state = {}, action) {
  // first time
  if (typeof(state.number) === 'undefined')
  state = {
    number: 0,
  };

  switch (action.type) {
    case SET_UNREAD:
    return {
      number: action.payload
    }
    default:
    return state
  }
}
