import React, { Component } from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import DashboardSidebar from "components/general/DashboardSidebar";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { apiHelper, dispatchMsg, dispatchUnread } from 'helpers';
import { APISTAT, PAYDIR, COLORS } from '_constant';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { price_d2r, dispatchUpdateUser } from 'helpers';
import ModalWithdrawal from "components/dashboard/ModalWithdrawal";
import ModalTopup from "components/dashboard/ModalTopup";
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from "react-router-dom";
import Hidden from '@material-ui/core/Hidden';
import DashboardTab from 'components/dashboard/DashboardTab'
import WalletIcon from 'styles/assets/Icons/wallet.png';
import HouseIcon from 'styles/assets/Icons/house.png';
import CorrectIcon from 'styles/assets/Icons/correct-circle.png';
import ProfitIcon from 'styles/assets/Icons/profit.png';
import PendingInvestIcon from 'styles/assets/Icons/pending-invest.png';
import PendingWithIcon from 'styles/assets/Icons/pending-with.png';
//import UnreadBadges from 'components/general/UnreadBadges'
import UnreadContainer from 'redux/container/UnreadContainer'
import { Link } from "react-router-dom";

class Dashboard extends Component {

  static defaultProps = {
    history: null, // for redirect to other location
  }


  constructor(props) {
    super(props);
    this.state = {
      u_aggr_liquid_bal: '',
      u_aggr_asset_bal: '',
      total_bal: '',
      total_profit: '',
      total_investment: '',
      iv_count: '',
      pending_in: '',
      pending_in_id: '',
      pending_in_slug: '',
      pending_in_dir: '',
      pending_out: '',
      income_potential: '',
      profit_potential: '',
      schedules: [],

      isLoading: true,

      withdrawalVisible: false,
      withdrawalData: {},
      withdrawalLoading: false,

      topupVisible: false,
      topupData: {},
      topupLoading: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }
  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  loadData = () => {

    this.setState({ isLoading: true });

    apiHelper.request(this, {
      resource: 'dashboard',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS ) { // success
          // incase user info is updated from somewhere else, update here
          dispatchUpdateUser(resp.data);
          this.setState({ isLoading: false, ...resp.data });
          dispatchUnread(resp.data.unread_message);
        } else
        throw new Error(resp.msg);
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }

  launchWithdrawal = (event) => {
    this.setState({ withdrawalLoading: true });
    apiHelper.request(this, {
      resource: 'withdrawal_get',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ withdrawalLoading: false, withdrawalVisible: true, withdrawalData: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ withdrawalLoading: false });
      }
    });
  }

  launchTopup = (event) => {
    this.setState({ topupLoading: true });
    apiHelper.request(this, {
      resource: 'topup_get',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ topupLoading: false, topupVisible: true, topupData: resp.data });
        } else if (resp.status === APISTAT.ERR_USER_CREDENTIAL) { // user need to fill in credential first
          dispatchMsg('warning', 'Silahkan melengkapi data di bawah ini sebelum top up dana.')
          this.setState({ topupLoading: false }, () => this.props.history.push("/credential/profile"));
        } else {
          // EXIT_USER_CREDENTIAL
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ topupLoading: false });
      }
    });
  }

  render(){
    var { u_aggr_liquid_bal, pending_in, pending_out, schedules, total_investment, total_profit, isLoading, u_aggr_asset_bal, profit_potential, pending_in_id, pending_in_dir, pending_in_slug, open_project_num
    } = this.state;

    if(isLoading) {
      u_aggr_liquid_bal = pending_in = pending_out = total_investment = total_profit = 100000000;
    }

    const MyToolTip = (props) =>
    <Tooltip disableTouchListener classes={{popper:"tooltip-content"}} title={
        <div>{props.text}</div>
      } >
      <i className="material-icons tiny info-pop-link" style={props.fontSize? {fontSize: props.fontSize} : {}}>info</i>
    </Tooltip>;

    // boxes:
    const wallet =
    <div className="db-subbox hover-hlight box-wallet">
      <Grid container alignItems="center" pacing={16}>
        <Grid item xs={12} container direction="column" justify="center">
          <div className="db-title"><img src={WalletIcon} alt="wallet" /> Saldo Wallet
          </div>
          <div>
            <span className="db-highlight">{price_d2r(u_aggr_liquid_bal)} <MyToolTip text="Jumlah uang di wallet kamu. Saldo ini bisa diinvestasikan langsung (secara parsial/seluruhnya) ke proyek yang membuka investasi. Atau juga dicairkan ke rekeningmu."
              /></span>
          </div>
        </Grid>
        <Grid item xs={12} style={{marginTop: 10}}>
          <Grid container justify="center" spacing={8}>
            <Grid item xs={open_project_num ? 4:6}>
              <div className="button-progress-wrapper-full">
                <Button disabled={this.state.topupLoading} className="button-wallet" onClick={this.launchTopup}><i className="fa fa-caret-up"></i>&nbsp;Top Up</Button>
                {this.state.topupLoading && <CircularProgress size={24} style={{marginTop: -12}} className="button-progress" />}
              </div>
            </Grid>
            <Grid item xs={open_project_num ? 4:6}>
              <div className="button-progress-wrapper-full">
                <Button disabled={this.state.withdrawalLoading} className="button-wallet" fullWidth onClick={this.launchWithdrawal}><i className="fa fa-caret-down"></i>&nbsp;Pencairan</Button>
                {this.state.withdrawalLoading && <CircularProgress size={24} style={{marginTop: -12}} className="button-progress" />}
              </div>
            </Grid>
            {
              open_project_num ?
              <Grid item xs={4}>
                <div className="button-progress-wrapper-full">
                  <Button className="button-wallet" fullWidth
                    onClick={() => {
                      dispatchMsg('info', 'Lihat daftar proyek kami yang masih menerima pendanaan!');
                      this.props.history.push("/invest?status=active");
                    }}
                    ><i className="fa fa-repeat"></i>&nbsp;Reinvest</Button>
                </div>
              </Grid>
              :
              null
            }

          </Grid>
        </Grid>
      </Grid>
    </div>;

    const activeBalance =
    <div className="db-subbox hover-hlight box-active-balance" onClick={this.handlePopoverOpen}>
      <div className="db-title"><img src={HouseIcon} alt="wallet" /> Permodalan Aktif
      </div>
      <div>
        <span className="db-highlight">{price_d2r(u_aggr_asset_bal)} <MyToolTip text="Total jumlah dana yang sedang kamu investasikan di platform Provesty." /></span>
      </div>
      <div style={{marginTop: 10, fontSize: 12}}>
        Potensi Profit
        <Hidden mdDown> : </Hidden>
        <Hidden lgUp><br /></Hidden>
        <span className="db-highlight" style={{fontSize: 12}}>{price_d2r(profit_potential)} <MyToolTip text="Potensi margin yang masih bisa kamu dapatkan dari proyek kamu yang sedang aktif. Angka ini bisa berbeda jika waktu pengembalian atau harga jual berbeda dari perkiraan."
          fontSize={12}/></span>
      </div>
    </div>;


    const contentTimeline = isLoading?
    <TableRow className="loading-effect">
      <TableCell colSpan={2}>

      </TableCell>
    </TableRow>
    :
    (
      schedules.length ? schedules.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell>
              {row.pro_title ? row.pro_title + '. ' + row.sc_info : ''}
            </TableCell>
            <TableCell><div className="nowrap">{row.sc_date}</div>{row.str ? '(' + row.str + ')' : ''}
            </TableCell>
          </TableRow>
        );
      })
      :
      <TableRow>
        <TableCell colSpan={2} style={{textAlign: 'center', borderBottom: 'none', fontStyle: 'italic', height: 55}}>
          Belum ada jadwal pengembalian untuk investasi kamu
        </TableCell>
      </TableRow>
    );

    const returnTimeline =
    <div className="db-box timeline-box" style={{overflow:'auto'}}>
      <div className="db-title">Return Timeline</div>
      <Table className="table table-timeline">
        <TableHead>
          <TableRow style={{height: 'auto'}}>
            <TableCell>Event</TableCell>
            <TableCell align="right">Tanggal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { contentTimeline }
        </TableBody>
      </Table>
    </div>;

    const totalInvestment =
    <div className="db-subbox hover-hlight ta-center">
      <div className="db-title"><img src={CorrectIcon} alt="correct" />Total Investasi
      </div>
      <div>
        <span className="db-highlight">{price_d2r(total_investment)} <MyToolTip text="Jumlah dana yang sedang & pernah kamu investasikan di Provesty." /></span>
      </div>
    </div>;

    const totalProfit =
    <div className="db-subbox hover-hlight ta-center">
      <div className="db-title"><img src={ProfitIcon} alt="profit" />Profit Diterima
      </div>
      <div>
        <span className="db-highlight">{price_d2r(total_profit)} <MyToolTip text="Total penerimaan keuntungan dari investasi kamu di Provesty." /></span>
      </div>
    </div>;

    const pendingInvestInfo = pending_in_id?
    <div style={{fontSize: 12, backgroundColor: COLORS.BG_GRAB_ATTN, padding: 3, margin: -3, marginTop: 5, borderRadius: 3}}>
      <Link style={{color: 'inherit'}} to={ pending_in_dir === PAYDIR.PAY_TOPUP ?  '/payment_topup/' + pending_in_id : '/payment/' + pending_in_slug + '-' + pending_in_id }> Lihat Info &gt;&gt;</Link>
    </div>: '';

    const pendingInvest =
    <div className="db-subbox hover-hlight ta-center">
      <div className="db-title"><img src={PendingInvestIcon} alt="pending invest" />Pending Transfer
      </div>
      <div>
        <span className="db-highlight">{price_d2r(pending_in)} <MyToolTip text="Jumlah yang telah dibuat untuk investasi/topup tetapi belum dikonfirmasi oleh admin. Sebagai catatan dibutuhkan waktu untuk transfer dan juga untuk admin mengkonfirmasi dana masuk secara manual." /></span>
      </div>
      {pendingInvestInfo}
    </div>;

    const pendingWithdrawal =
    <div className="db-subbox hover-hlight ta-center">
      <div className="db-title"><img src={PendingWithIcon} alt="pending invest" />Pending Pencairan
      </div>
      <div>
        <span className="db-highlight">{price_d2r(pending_out)} <MyToolTip text="Jumlah permintaan pencairan yang menunggu transfer dari kami. Butuh waktu maksimal 3 hari kerja setelah transfer dibuat untuk sampai ke rekening kamu." /></span>
      </div>
    </div>;

    return (
      <div className="page-width">
        <Navbar />
        <Grid container className="container">
          <Grid item className="dbsidebarplace">
            <DashboardSidebar history={this.props.history} />
          </Grid>
          <Grid item sm>
            <DashboardTab paths={[
                { l: 'Dashboard', v: '/dashboard' },
                { l: 'Portfolio', v: '/dashboard/portfolio' },
                { l: 'Transaksi', v: '/dashboard/transaction' },
                { l: <UnreadContainer badgeLabel="Pesan"/>, v: '/dashboard/message' },
              ]} history={this.props.history}
              />
            <Paper classes={{root:"dash-area"}} className={isLoading? 'loading' : ''} style={{borderRadius: '0px 0px 4px 4px'}}>
              <Grid container direction="row" spacing={24}>
                <Grid item xs={12} md={5}>
                  <div className="db-box db-main-box" style={{padding: 0}}>
                    { wallet }
                    <div style={{borderTop: '1px solid rgb(0, 0, 0, 0.13)'}}/>
                    { activeBalance }
                  </div>
                </Grid>
                <Grid item xs={12} md={7}>
                  { returnTimeline }
                </Grid>
                <Grid item xs={12}>
                  <div className="db-box db-info-box" style={{padding: 0}}>
                    <Grid container alignItems="stretch">
                      <Grid item xs={12} sm={6} md={3}>
                        { totalInvestment }
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        { totalProfit }
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        { pendingInvest }
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        { pendingWithdrawal }
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="db-box ta-center">
                    <div className="db-title">Temukan Proyek Properti yang Cocok Untukmu</div>
                    <NavLink to="/invest"><Button className="button-blue font12">Modali Sekarang</Button></NavLink>
                  </div>
                </Grid>
                {/*<Grid item xs={12} sm={6}>
                <div className="db-box ta-center">
                <div className="db-title">Ajukan Pendanaan Proyek <Hidden mdDown><br /> </Hidden>Properti</div>
                <NavLink to="/dashboard/loan"><Button className="button-blue font12">Ajukan Pinjaman</Button></NavLink>
                </div>
                </Grid>*/}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <ModalTopup
          visible={this.state.topupVisible}  {...this.state.topupData}
          history={this.props.history}
          onClose={
            (msgObj) => this.setState ({topupVisible: false, ...msgObj},
              () => { typeof msgObj !== 'undefined' && this.loadData() }
            )
          }
          />
        <ModalWithdrawal
          visible={this.state.withdrawalVisible}  {...this.state.withdrawalData}
          onClose={
            (result) => this.setState({withdrawalVisible: false},
              () => { result === true && this.loadData() }
            )
          }
          />
        <div className="wavyflip pushupx">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Dashboard;
