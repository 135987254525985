import React, {Component} from "react";
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { formGetLabel, formIsRequired } from 'helpers';

export default class MyRadioGroup extends Component {

  static propTypes = {
    obj: PropTypes.shape({
      state: PropTypes.shape({
        validErrors:  PropTypes.object.isRequired
      }).isRequired,
      handleInputChange: PropTypes.func.isRequired,
    }).isRequired
  }

  static defaultProps = {
    obj: null,
    options: [] // format [{k: a, v: b}, ...]
  }

  render() {

    const p = this.props;

    const fc_class = {
      root:"my-form-control"
    };
    const labelProps = { classes: {
      root: "my-form-label",
      focused: "label-focused",
      shrink: "label-shrink"
    }};

    const {obj, options, name, label} = p;

    if(!(name in obj.state))
    throw new Error('state ' + name  + ' is not declared!');

    // check value against options
    var _value = '';

    for(var i=0; i<options.length; i++) {
      if(options[i].k.toString() === obj.state[name].toString()) {
        _value = obj.state[name];
        break;
      }
    }

    var radios = options.map(x =>
      {
        return(
          <FormControlLabel
            key={x.k}
            value={x.k}
            control={<Radio color="primary" classes={{root: 'my-radio', checked: 'radio-checked'}} />}
            label={x.v}
            classes={{label: "myTheme"}}
            checked={_value.toString() === x.k.toString()}
            />
        )
      }
    );

    var helperText = '';
    var radioGrpClass = '';
    if(typeof obj.state.validErrors[name] !== 'undefined') {
      helperText = <FormHelperText className="my-form-helpertxt error">{obj.state.validErrors[name]}
      </FormHelperText>;
      radioGrpClass += ' input-error'
    }

    var _label = label ? label : formGetLabel(obj, name);
    if(p.required || formIsRequired(obj, name))
    _label += '*';

    var defRgStyle = { flexDirection: 'row', marginTop: 8, marginBottom: -4 };
    return (
      <FormControl fullWidth classes={fc_class}>
        <InputLabel shrink {...labelProps}>{_label}</InputLabel>
        <RadioGroup
          name={name}
          value={_value}
          onChange={(e) => obj.handleInputChange({target: {name: name, value: e.target.value}})}
          style={{...defRgStyle, ...this.props.rgStyle}}
          className={radioGrpClass}
          >
          {radios}
        </RadioGroup>
        {helperText}
      </FormControl>
    );
  }
}
