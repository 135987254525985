import { connect } from 'react-redux';
import React from 'react';
import { imgPath } from 'helpers';
import DefAvatarPic from "styles/assets/default-avatar-pic.png";
import Avatar from '@material-ui/core/Avatar';

function UserContainer(props) {

  switch(props.part) {
    case 'name': return props.u_name;
    case 'photo': return props.u_photo;
    case 'email': return props.u_email;
    case 'avatar': return <Avatar alt="user" src={props.u_photo? imgPath(props.u_photo) : DefAvatarPic } className="img-circle nav-user-photo" />;
    default: return '';
  }
}

UserContainer.defaultProps = {
  part: '', // name, photo, avatar
  token: '',
  u_name: '',
  u_photo: '',
  u_email: ''
}

const mapStateToProps = (state) => ({
  ...state.user
})

export default connect(mapStateToProps) (UserContainer);
