import React, {Component} from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import DashboardSidebar from "components/general/DashboardSidebar";
// import ReactPaginate from 'react-paginate';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { apiHelper, dispatchMsg, formData } from 'helpers';
import Radio from '@material-ui/core/Radio';
import { scroller } from 'react-scroll';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Portfolio from 'components/dashboard/Portfolio';
import { APISTAT } from '_constant';
import UserContainer from 'redux/container/UserContainer'
import PortfolioAnimation from "styles/assets/portfolio_animation.png";
import { Link } from "react-router-dom";
import DashboardTab from 'components/dashboard/DashboardTab'
//import UnreadBadges from 'components/general/UnreadBadges'
import UnreadContainer from 'redux/container/UnreadContainer'

class DashboardPortfolio extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      status: 'ongoing',

      currentPage: 0, // 0 based page number
      pageCount: 1,

      isLoading: true,

    };
  }

  perPageCount =  6; // sync with server

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = event => {
    var name = event.target.name;
    var val = event.target.value;

    this.setState({ [name]: val }, () => {
      this.loadData();
    });

  };

  loadData = (pageNum) => {

    // if undefined then it's calledfrom keyword
    // var currentPage = typeof pageNum === 'undefined'? 0: pageNum;

    var data = {
      // start: currentPage * this.perPageCount,
      // length: this.perPageCount,
      status: this.state.status
    };

    this.setState({ isLoading: true });

    apiHelper.request(this, {
      resource: 'portfolio',
      data: formData(data),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({
            isLoading: false,
            data: resp.data.data
            // pageCount: Math.ceil(resp.data.recordsFiltered/this.perPageCount),
            // currentPage: currentPage
          });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }

  componentDidMount() {
    this.loadData();
  }

  handlePageClick = (e) => {
    // pagination use 0-based page
    scroller.scrollTo('myScrollToElement', {
      duration: 300,
      delay: 100,
      smooth: 'easeInOutCubic'}
    );
    this.loadData(e.selected);

  }

  render() {


    var { data, isLoading } = this.state;

    data = isLoading? [0, 0] : data;

    var portfolios = data.map((x, index) => {
      return (
        <Grid item xs={12} key={isLoading? index: x.id}>
          <Portfolio key={index} isLoading={isLoading} { ...x } />
        </Grid>)
      }
    );

    var noActiveInvest =
    <div style={{textAlign: 'center'}}>
      <img src={PortfolioAnimation} alt="no active investment" style={{maxWidth: '98%', margin: '32px auto 25px', height: 'auto'}} />

      <div style={{lineHeight: 1.86, width: '100%', margin: 'auto', textAlign: 'center'}}>
        <span style={{color: '#3d7dc8', fontWeight: 'bold'}}>Hi <UserContainer part="name" /></span>, Saat ini kamu belum punya investasi aktif di Provesty.<br />
        <b>Yuk mulai sekarang!</b>
      </div>
      <div style={{margin: '15px auto 30px'}}>
        <Link to="/how-it-works" className="casual-link">
          <Button className="button-blue" style={{marginRight: 10}}>Pelajari Investasi</Button>
        </Link>
        <Link to="/invest" className="casual-link">
          <Button className="button-white">Mulai Investasi</Button>
        </Link>
      </div>
    </div>

    var noCompletedInvestment =
    <div style={{textAlign: 'center', padding: '40px 0', fontStyle: 'italic'}}>
      Saat ini kamu belum punya investasi di proyek yang sudah selesai.
    </div>

    return (<div name="myScrollToElement">
    <Navbar/>
    <Grid container className="container">
      <Grid item className="dbsidebarplace">
        <DashboardSidebar history={this.props.history} />
      </Grid>
      <Grid item sm>
        <DashboardTab paths={[
            { l: 'Dashboard', v: '/dashboard' },
            { l: 'Portfolio', v: '/dashboard/portfolio' },
            { l: 'Transaksi', v: '/dashboard/transaction' },
            { l: <UnreadContainer badgeLabel="Pesan"/>, v: '/dashboard/message' },
          ]} history={this.props.history}
          />
        <Paper classes={{root:"dash-area bg-lightblue portfolio-list"}} className={this.state.isLoading? 'loading' : ''} style={{borderRadius: '0px 0px 4px 4px', paddingTop: 0}}>
          <div id="listing-bar" style={{margin: 0}}>
            <Grid container>
              <div style={{ paddingTop: 14, fontSize: 16, fontWeight: 700, paddingRight: 23 }}>Status</div>
              <RadioGroup
                aria-label="status"
                name="status"
                value={this.state.status}
                onChange={this.handleInputChange}
                style={{ flexDirection: 'row' }}
                >
                <FormControlLabel
                  value="ongoing"
                  control={<Radio classes={{checked: 'radio-checked'}} color="primary" />}
                  label="Berlangsung"
                  classes={{root: 'radio-root', label: "myTheme"}}
                  />
                <FormControlLabel
                  value="completed"
                  control={<Radio classes={{checked: 'radio-checked'}} color="primary" />}
                  label="Selesai"
                  classes={{root: 'radio-root', label: "myTheme"}}
                  />
                <FormControlLabel
                  value="all"
                  control={<Radio classes={{checked: 'radio-checked'}} color="primary" />}
                  label="Semua"
                  classes={{root: 'radio-root', label: "myTheme"}}
                  />
              </RadioGroup>
            </Grid>
          </div>
          <div>
            {
              !isLoading && !this.state.data.length ?
              (
                this.state.status === 'completed' ? noCompletedInvestment: noActiveInvest
              )
              :
              <React.Fragment>
                <Grid container spacing={24} className={'db-portfolio ' +  (isLoading? 'loading' : '')}>
                  {portfolios}
                </Grid>
                {
                  // !isLoading && this.state.data.length &&
                  // <div className="pagination-container">
                  //   <ReactPaginate
                  //     previousLabel={"<"}
                  //     nextLabel={">"}
                  //     breakLabel={"..."}
                  //     breakClassName={"break-me"}
                  //     pageCount={this.state.pageCount}
                  //     forcePage={this.state.currentPage}
                  //     marginPagesDisplayed={2}
                  //     pageRangeDisplayed={2}
                  //     onPageChange={this.handlePageClick}
                  //     containerClassName={"pagination"}
                  //     subContainerClassName={"pages pagination"}
                  //     activeClassName={"active"} />
                  // </div>
                }
              </React.Fragment>
            }
          </div>
        </Paper>
      </Grid>
    </Grid>
    <Footer/>
  </div>);
}
}

export default DashboardPortfolio;
