import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MyTextField from "components/general/MyTextField";
import Grid from '@material-ui/core/Grid';
import { formData, apiHelper, dispatchMsg, formScrollToError, imgPath } from 'helpers';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-spinkit';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';

function getSteps() {
  return ['Informasi Proyek', 'Lengkapi Detail Proyek', 'Lengkapi Informasi Dokumen'];
}

export default class ProjectDocument extends React.Component {

  static defaultProps = {
    history: null
  }

  mandatoryFiles = [{
    doc_title: 'Sertifikat Kepemilikan Properti',
    doc_path: '',
    mandatory: true,
  }];
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 2,

      files: [], // we're going to add the localFile attr to reflect the new uploaded file, and initIndex to make sure individual dom does not get rebuild after addition or deletion

      delFileIdx: 0,
      delDialogShow: false,
      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = event => {

    var name = event.target.name;
    var val = event.target.value;

    if(name.indexOf('[') !== -1) {
      // name with Array. we'll reply entire key
      let nameFormatted = name.replace(/\[(.*?)\]/g, (e => { return '["' + e.replace(/[[\]]/g, '') + '"]'}));
      var tmps = name.split(/[[\]]+/);
      name = tmps[0];
      // declare global with string name
      window[name] = JSON.parse(JSON.stringify(this.state[tmps[0]]));
      eval(nameFormatted +'= val;'); // eslint-disable-line no-eval
      val = window[name]; //override

      if(name === 'files')
      val = val.map((x, index) => Object.assign(x, {doc_order: index}));
    }

    this.setState({ [name]: val });

  };

  validate = () => {

    this.errors = {};

    var {files} = this.state;
    for(var i=0; i < files.length; i++){
      if(files[i].doc_title === '') {
        this.errors['files['+i+'][doc_title]'] = 'Name File tidak boleh kosong';
      }
      if(files[i].doc_path === '' && files[i].localFile === '') {
        this.errors['files['+i+'][doc_title]'] = 'File tidak boleh kosong';
      }
    }
    // formVal(this, 'bac_owner_name', 'Atas Nama', 'required');
    // formVal(this, 'bac_no', 'Nomor Rekening', 'required');


    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    this.setState({ buttonLoading: true });

    var data = formData('form-data');


    apiHelper.request(this, {
      resource: 'project_document_post',
      urlParams: [ this.props.match.params.id_project],
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data,
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success

          this.setState({ buttonLoading: false });
          dispatchMsg('success', 'Data berhasil disimpan.')
          this.props.history.push( "/dashboard/loan");
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false });
      },
    });
  }
  indexFile = 0;

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'project_document_get', // use the secured one if loggedin
      urlParams: [ this.props.match.params.id_project],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {

          var files = resp.data.files.length < this.mandatoryFiles.length? this.mandatoryFiles.concat(resp.data.files) : resp.data.files;
          // we're going to add the localFile attr to reflect the new uploaded file, and initIndex to make sure individual dom does not get rebuild after addition or deletion
          files = files.map((x, index) => Object.assign(x, {localFile: '', initIndex: this.indexFile++, doc_order: index}));
          this.setState({isLoading:false, files});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }

  handleFileChange = index => (event) => {
    var file = event.target.files[0];
    var files = JSON.parse(JSON.stringify(this.state.files));
    files[index].localFile = file.name;
    this.setState({files});
  }

  addFile = () => {
    var files = JSON.parse(JSON.stringify(this.state.files));
    files = files.concat({
      doc_title:'',
      doc_order: '',
      doc_path: '',
      localFile: '',
      initIndex: this.indexFile++
    }).map((f, index) => {f.doc_order = index; return f;});
    this.setState({files});
  }

  delFileConfirmation = index => () => {
    this.setState({
      delDialogShow: true,
      delFileIdx: index
    });
  }

  delFile = () => {
    var files = JSON.parse(JSON.stringify(this.state.files));
    var delFileIdx = this.state.delFileIdx;
    files.splice(delFileIdx, 1);
    files = files.map((f, index) => {f.doc_order = index; return f;});
    this.setState({delDialogShow: false, files});
  };

  render() {

    const steps = getSteps();
    const { activeStep } = this.state;
    const stepButtons = steps.map((label, index) => {
      return (
        <Step key={label} name="stepper" >
          <StepLabel classes={{label: 'step-label'}} StepIconProps={{classes: {root: 'step-icon', active: 'step-icon-active', completed: 'step-icon-completed'}}}>
            {label}
          </StepLabel>
        </Step>
      );
    });

    const delDialog =
    <Dialog
      classes={{paper: 'del-dialog', scrollPaper: 'paper'}}
      open={this.state.delDialogShow}
      onClose={() => this.setState({delDialogShow: false})}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogContent style={{textAlign: 'center', padding: '13px 10px 16px'}}>

        <Icon style={{color: '#F8C886', fontSize: 63}}>error_outline</Icon>
        <div>Yakin untuk menghapus file ini?</div>
        <div style={{marginTop:13}} />
        <Button onClick={() => this.setState({delDialogShow: false})} variant="contained" style={{marginRight: 10}}>
          Batal
        </Button>
        <Button onClick={this.delFile} className="button-red" autoFocus>
          Hapus
        </Button>
      </DialogContent>
    </Dialog>;

    const { files } = this.state;

    const formContent =
    <Grid container spacing={16}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid item xs={12}>
          <div className="form-legend">Dokumen Pendukung</div>
          <div style={{textAlign: 'left', fontSize: 13, marginTop: 7 }}>Silakan mengupload dokumen-dokumen yang kiranya berkaitan dengan Proyek. Khusus untuk Bukti Sertifikat harus disertakan. Kelengkapan dokumen ini menjadi penentu disetujuinya proyek ini. Format file .pdf, .xls, .xlxs. Maksimum file 10MB. Silahkan klik tambah file untuk memulai. </div>
        </Grid>
      </Grid>
      {
        files.length ? files.map((f, index) =>
        <Grid key={f.initIndex} item sm={12} container spacing={8} justify="flex-end">
          <Grid item xs={12} sm>
            <MyTextField name={'files['+ index +'][doc_title]'} label="Nama Dokumen" obj={this}
              InputProps={{
                readOnly: f.mandatory === true,
                endAdornment: (
                  <InputAdornment position="end" classes={{root: 'input-adornment'}}>
                    {
                      <div className="truncate file-peek">{f.localFile? f.localFile: (f.doc_path? <a href={imgPath(f.doc_path)} target="_blank" rel="noopener noreferrer">Lihat Dokumen</a> : '') }</div>
                    }
                  </InputAdornment>
                )
              }}
              required
              />
            <input type="hidden" name={'files['+ index +'][id_doc]'} value={f.id_doc} />
            <input type="hidden" name={'files['+ index +'][doc_order]'} value={f.doc_order} />
          </Grid>
          <Grid item>
            <Button className="button-blue action-hook" variant="contained" component="label">
              <Icon>edit</Icon><span>&nbsp;Pilih File</span>
              <input type="file" name={'files['+ index +'][doc_path]'} style={{display:'none'}} onChange={this.handleFileChange(index)}
                />
            </Button>
          </Grid>
          {f.mandatory === true? '' :
            <Grid item>
              <Button className="button-red del-button action-hook" onClick={this.delFileConfirmation(index)}>
                <Icon>delete</Icon>
              </Button>
            </Grid>
          }
        </Grid>)
        : ''
      }
      <Grid item xs={12}>
        <Button variant="outlined" onClick={this.addFile} style={{width: '100%', marginTop: 30, backgroundColor: '#f8f8f8', boxShadow: '0 2px 4px 0 rgba(100, 100, 100, 0.5)'}}><i className="material-icons md-24">add_circle_outlined</i>Tambah File</Button>
      </Grid>
      {delDialog}
    </Grid>;

    const stepperContent = this.state.isLoading ?
    <div style={{height: 500}}>
      <Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" />
    </div>
    :
    <form onSubmit={this.handleSubmit} id="form-data" autoComplete="off" name="form">
      {formContent}
      <div className="sms-modal-buttons" style={{marginTop: 55, marginBottom: 30, textAlign: 'center'}}>
        <div className="button-progress-wrapper">
          <Button disabled={this.state.buttonLoading} className="btn-stepper-next button-blue" type="submit" style={{width: 120}}>Simpan</Button>
          {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
        </div>
      </div>
    </form>;

    return (
      <div>
        <div className="bg-lightblue">
          <Navbar />
          <div className="container credential-page">
            <Paper >
              <Stepper activeStep={activeStep} alternativeLabel className="stepper-label stepper-loan">
                {stepButtons}
              </Stepper>
            </Paper>
            <Paper className="cred-area">
              <div className="stepper-content">
                {stepperContent}
              </div>
            </Paper>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
