import React, {Component} from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import DashboardSidebar from "components/general/DashboardSidebar";
import Grid from '@material-ui/core/Grid';
import { apiHelper, dispatchMsg, formData, dispatchUnread } from 'helpers';
import { APISTAT, STAT } from '_constant'
import Spinner from 'react-spinkit';
import Hidden from '@material-ui/core/Hidden';
import { scroller } from 'react-scroll';
import CircularProgress from '@material-ui/core/CircularProgress';
// import QueryNotFound from 'components/general/QueryNotFound';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactPaginate from 'react-paginate';
import DashboardTab from 'components/dashboard/DashboardTab';
import { ReactSelectFilter as ReactSelect } from "components/general/ReactSelect";
//import UnreadBadges from 'components/general/UnreadBadges'
import UnreadContainer from 'redux/container/UnreadContainer'
import ModalViewEmail from "components/dashboard/ModalViewEmail";
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

export default class DashboardMessage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,

      id_email_broadcast_loading: 0,

      id_property: '',
      prop_list: [],
      iv_status_x: '',
      validErrors: {},

      emailKey: 0, // will be the ID
      emailVisible: false,

      currentPage: 0, // 0 based page number
      pageCount: 1,

      menuOpen: false,
      menuLoading: false,
    };
  }

  perPageCount = 8; // decide

  componentDidMount() {
    this.loadData();
    this.loadPropertySelect();
  }
  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;

    // this only comes for filtering purpose, so load again
    this.setState({ [name]: val }, this.loadData);

  };

  loadPropertySelect = () => {
    apiHelper.request(this, {
      resource: 'property_select',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({prop_list: resp.data});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadData = (pageNum) => {

    // if undefined then it's calledfrom keyword
    var currentPage = typeof pageNum === 'undefined'? 0: pageNum;

    var data = {
      start: currentPage * this.perPageCount,
      length: this.perPageCount,
      id_property: this.state.id_property,
      iv_status_x: this.state.iv_status_x
    };

    this.setState({ isLoading: true });

    apiHelper.request(this, {
      resource: 'message',
      data: formData(data),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({
            pageCount: Math.ceil(resp.data.recordsFiltered/this.perPageCount) ,
            data: resp.data.data,
            isLoading: false,
            currentPage: currentPage
          });
          dispatchUnread(resp.data.unread_message);

        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ data: [], isLoading: false });
      }
    });
  }

  handlePageClick = (e) => {
    // pagination use 0-based page
    this.loadData(e.selected);
    // scroll to top if necessary
    if(document.querySelector('#myScrollToElement').getBoundingClientRect().top < 0) {
      scroller.scrollTo('myScrollToElement', {
        duration: 300,
        delay: 100,
        smooth: 'easeInOutCubic'}
      );
    }
  }

  handleRowClick = (id_email_broadcast, isRead) => (e) =>  {
    // console.log(id_email_broadcast);
    this.setState({id_email_broadcast_loading: id_email_broadcast})
    apiHelper.request(this, {
      resource: 'message_view',
      data: formData({id_email_broadcast}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          // mark as read in local
          var data = [];
          this.state.data.forEach((item, index) => {
            data.push(Object.assign({}, item, (parseInt(id_email_broadcast) === parseInt(item.id_email_broadcast)? {ebr_is_read: STAT.STAT_YES}: {})));
          });


          const { emb_template, emb_attachment_csv} = resp.data;
          this.setState({
            emb_template,
            emb_attachment_csv,
            emailVisible: true,
            emailKey: id_email_broadcast,
            id_email_broadcast_loading: 0,
            data
          });

          dispatchUnread(resp.data.unread_message);

        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({
          emailVisible: false,
          id_email_broadcast_loading: 0
        });
      }
    })
  }

  markAllRead = () => {
    this.setState({ menuOpen: false, menuLoading: true });

    apiHelper.request(this, {
      resource: 'message_all_read',
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success

          // mark all as read in local
          var data = [];
          this.state.data.forEach((item, index) => {
            data.push(Object.assign({}, item, {ebr_is_read: STAT.STAT_YES}));
          });

          this.setState({ menuLoading: false, data });
          dispatchUnread(resp.data.unread_message);

        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        this.setState({ menuLoading: false });
        dispatchMsg('error', err.message);
      }
    });
  }

  render() {

    const { data, isLoading } = this.state;

    const table =
    <Table className="table table-transact">
      <TableHead>
        <TableRow style={{height: 45}}>
          <Hidden mdDown>
            <TableCell align="center">No</TableCell>
          </Hidden>
          <TableCell>Message</TableCell>
          <Hidden mdDown>
            <TableCell>Proyek</TableCell>
          </Hidden>
          <TableCell align="right">Tanggal</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((x, index) => {
            return(

              <TableRow key={index} onClick={this.handleRowClick(x.id_email_broadcast,x.ebr_is_read)} style={{cursor: 'pointer', backgroundColor: x.ebr_is_read === STAT.STAT_YES? '#F5F7F7': 'white'}}
                >
                <Hidden mdDown>
                  <TableCell align="center">
                    <span className="loading-effect">
                      {this.state.currentPage * this.perPageCount + index + 1}
                    </span>
                  </TableCell>
                </Hidden>

                <TableCell>
                  <span className="loading-effect">
                    <b>{x.emb_subject}
                    </b>&nbsp;
                    {
                      parseInt(x.id_email_broadcast) === parseInt(this.state.id_email_broadcast_loading)?
                      <CircularProgress size={14}/>
                      :
                      ''
                    }
                    <br />
                    {x.emb_template}
                  </span>
                </TableCell>
                <Hidden mdDown>
                  <TableCell>
                    <span className="loading-effect">
                      {x.pro_title ? <span>{x.pro_title} <span className="pro_code"> ({x.pro_code})</span></span> : '-' }
                    </span>
                  </TableCell>
                </Hidden>
                <TableCell align="right">
                  <span className="loading-effect">
                    {x.created_at}
                  </span>
                </TableCell>


              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>;

    return (
      <div>
        <Navbar/>
        <Grid container className="container">
          <Grid item className="dbsidebarplace">
            <DashboardSidebar history={this.props.history} />
          </Grid>
          <Grid item xs name="myScrollToElement" id="myScrollToElement">
            <DashboardTab paths={[
                { l: 'Dashboard', v: '/dashboard' },
                { l: 'Portfolio', v: '/dashboard/portfolio' },
                { l: 'Transaksi', v: '/dashboard/transaction' },
                { l: <UnreadContainer badgeLabel="Pesan"/>, v: '/dashboard/message' },
              ]} history={this.props.history}
              />

            <Paper classes={{root:"dash-area"}} className={isLoading? 'loading' : ''} style={{borderRadius: '0px 0px 4px 4px'}}>


              <Grid container alignItems="center" spacing={16} style={{marginBottom: 8}}>
                <Grid item xs={12} sm={12} md={12} lg="auto">
                  <div className="table-title">Daftar Pesan</div>
                </Grid>

                <Hidden smDown>
                  <Grid item>
                    <div style={{margin: '0 20px'}}>|</div>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item>
                    <div style={{fontSize: 13}}>filter:</div>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm md lg>
                  <ReactSelect name="id_property" label="" obj={this}
                    options={[
                      {k: '', v: '- Semua Proyek -'},
                      ...this.state.prop_list
                    ]}
                    />
                </Grid>

                <Grid item xs={12} sm="auto" >
                  <Grid  container direction="row"
                    justify="flex-end"
                    alignItems="flex-end">
                    <Button
                      onClick={() => this.setState({menuOpen: true})}
                      buttonRef={node => {
                        this.anchorUserEl = node;
                      }}
                      aria-haspopup="true"
                      style={{minWidth: 40, padding: 3}}
                      >
                      {
                        this.state.menuLoading ?
                        <CircularProgress size={24}/>
                        :
                        <Icon>settings</Icon>
                      }
                    </Button>
                  </Grid>
                  <Popper open={this.state.menuOpen} anchorEl={this.anchorUserEl} transition placement="bottom-end" disablePortal={true}>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow-user"
                        >
                        <Paper>
                          <ClickAwayListener onClickAway={() => this.setState({menuOpen: false})}>
                            <MenuList className="menu-other">
                              <MenuItem onClick={this.markAllRead}>Tandai semua Sudah Dibaca</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>


              </Grid>
              <div className="table-container">
                {table}
              </div>
              {
                isLoading && data.length === 0?
                <div className="my-spinner-container">
                  <Spinner fadeIn='quarter' name="chasing-dots" />
                </div>
                :
                <React.Fragment>
                  {
                    data.length === 0 && !isLoading && this.state.currentPage === 0 ?
                    <div style={{textAlign: 'center', margin: '20px 0 30px', fontStyle: 'italic', fontSize: 15}}>Tidak ada pesan
                    </div> : ''
                  }
                  <div className="pagination-container">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      forcePage={this.state.currentPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div>
                </React.Fragment>
              }
            </Paper>
          </Grid>
        </Grid>
        <div className="wavyflip pushupx">
          <Footer/>
        </div>
        <ModalViewEmail
          visible={this.state.emailVisible}
          emb_template={this.state.emb_template}
          emb_attachment_csv={this.state.emb_attachment_csv}
          onClose={() => this.setState({ emailVisible: false }) }
          />
      </div>
    );
  }
}
