import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";
import Fab from '@material-ui/core/Fab';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Danardono from 'styles/assets/testimonial/danardono.png';
import Nadhira from 'styles/assets/testimonial/nadhira.png';
import Tetehintan from 'styles/assets/testimonial/tetehintan.png';
import Ridwan from 'styles/assets/testimonial/ridwan.png';

class Testimoni extends Component {

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render(){

    const coba_settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      speed: 500,
      // slidesToShow: 5,
      centerPadding: '0px',
      variableWidth: true,
      responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          infinite: true,
          dots: true,
          variableWidth: true
        }
      },
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            speed: 150,
            dots: true,
            variableWidth: false
          }
        }
      ]
    };

    var $lang = {};
    $lang['testimony_title_ridwan'] = 'Team Profesional';
    $lang['testimony_ridwan'] = 'Latar belakang profesional kedua founder serta mudahnya investasi melalui Crowdfunding membuat saya mantap untuk menjadi <i>early adapter</i> Provesty.';
    $lang['testimony_title_danardono'] = 'Update Berkala';
    $lang['testimony_danardono'] = 'Provesty mengakomodasi keinginan saya untuk berinvestasi di bidang properti dengan cara yang mudah, akad syariah, dan terjangkau, namun tetap sesuai dengan kaidah kepantasan, peraturan, dan profesionalitas tim Provesty.';
    $lang['testimony_title_nadhira'] = 'Investasi Terjangkau dan Akad Jelas';
    $lang['testimony_nadhira'] = 'Investasi di Provesty terjangkau, dilakukan proses kurasi proyek yang baik dan akad yang jelas. Memantau investasi juga sangat mudah karena diberikan dashboard yang intuitif dan notifikasi email.';
    $lang['testimony_title_lutfi'] = 'Sifat Properti Aman';
    $lang['testimony_lutfi'] = 'Tak diragukan bahwa properti masih jadi pilihan aman dalam berinvestasi. Melalui Provesty, investasi di properti bukan hanya aman, tapi mudah dan transparan';
    $lang['testimony_title_indah'] = 'Cocok untuk Milenial';
    $lang['testimony_indah'] = 'Provesty memudahkan millenial seperti saya untuk berinvestasi di bidang properti secara aman, transparan, dan berprinsip syariah.';


    var testimonies =[
      {
        "pic": Ridwan,
        "name":"Ridwan",
        "age":"29",
        "job":"Karyawan Swasta",
        "title_lang":"testimony_title_ridwan",
        "content_lang":"testimony_ridwan"
      },
      {
        "pic": Danardono,
        "name":"Danardono",
        "age":"41",
        "job":"Karyawan Swasta",
        "title_lang":"testimony_title_danardono",
        "content_lang":"testimony_danardono"
      },
      {
        "pic": Nadhira,
        "name":"Nadhira",
        "age":"24",
        "job":"Karyawan Swasta",
        "title_lang":"testimony_title_nadhira",
        "content_lang":"testimony_nadhira"
      },
      {
        "pic": Tetehintan,
        "name":"Indah",
        "age":"24",
        "job":"Pegawai Negeri",
        "title_lang":"testimony_title_indah",
        "content_lang":"testimony_indah"
      },
      // {
      //   "pic":"assets/img/testimonial/pp_luthfi.jpg",
      //   "name":"Luthfi",
      //   "age":"30",
      //   "job":"Mahasiswa S3 di Australia",
      //   "title_lang":"testimony_title_lutfi",
      //   "content_lang":"testimony_lutfi"
      // }
    ];

    return(
      <div className="testimoni" style={{overflow: 'hidden'}}>
        <div className="testimoni-investor">
          Testimoni Investor
        </div>
        <div className="some-testimoni">
          Beberapa testimoni dari Investor yang sudah berinvestasi melalui Provesty
        </div>

        <Slider ref={c => (this.slider = c)} {...coba_settings}>
          {
            testimonies.map((t, index) =>
            <div key={index} style={{outline: "none"}}>
              <div className="testimoni-card">
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={3} container justify="center">
                    <img src={t.pic} alt="customer" style={{width: 90, height: 90}} />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <div>
                      <div className="testimoni-name">{t.name}, {t.job}</div>
                      <div className="testimoni-content" dangerouslySetInnerHTML={{
                          __html: $lang[t.content_lang]
                        }}
                        />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </Slider>
        <div className="ta-center">
          <Fab onClick={this.previous} style={{marginRight: 10, backgroundColor: '#d2ecf9', color: '#3d7dc8'}}>
            <ChevronLeft />
          </Fab>
          <Fab color="primary" onClick={this.next} style={{marginLeft: 10, backgroundColor: '#d2ecf9', color: '#3d7dc8'}}>
            <ChevronRight />
          </Fab>
        </div>
      </div>
    )
  }
}

export default Testimoni
