import React, { Component } from 'react';
import ForgotPasswordComponent from 'components/auth/ForgotPasswordComponent';
import Logo from 'styles/assets/logo.png'
import { NavLink } from 'react-router-dom'

export default class ForgotPassword extends Component {

  render(){
    return(
      <div className="login-page">
        <div className="container container-login">
          <NavLink to='/'>
            <img src={Logo} alt="logo-white" style={{zIndex: 1000, width: 150, marginTop: 26, cursor: 'pointer'}} />
          </NavLink>
          <div id="auth-root">
            <ForgotPasswordComponent />
          </div>
        </div>

      </div>
    )
  }
}
