import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MyTextField from "components/general/MyTextField";
import ReactSelect from "components/general/ReactSelect";
import { APISTAT, PROPSTATUS, STAT, NUMBERS, DATAVAR, COLORS } from '_constant';
import "react-sweet-progress/lib/style.css";
import { apiHelper, price_d2r, price_r2d, dataHelper, formData, userHelper, dispatchMsg, date_r2d, formScrollToError, get_range, array_length } from 'helpers';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import MyCheckboxes from "components/general/MyCheckboxes";
import UserContainer from 'redux/container/UserContainer';
import InvestedMark from 'components/general/InvestedMark';
// import { Redirect } from 'react-router-dom';

var status = {
  [PROPSTATUS.PROPSTAT_FULL]: {
    desc: 'Slot sudah penuh terisi tetapi masih menunggu pembayaran dari beberapa investor',
    color: '#F8910E'
  },
  [PROPSTATUS.PROPSTAT_EXPIRED]: {
    desc: 'Proyek batal karena slot tidak terpenuhi sampai batas waktu yang ditentukan',
    color: '#222222'
  },
  [PROPSTATUS.PROPSTAT_ACTIVE]: {
    desc: 'Proyek sudah berjalan dan siap memberikan penghasilan',
    color: '#24b47e'
  },
  [PROPSTATUS.PROPSTAT_ACTIVE_PROCESSING]: {
    desc: 'Semua pembayaran telah terverifikasi. Pihak Provesty sedang mempersiapkan administrasi serta dokumen untuk memulai proyek.',
    color: '#EAB039'
  },
  [PROPSTATUS.PROPSTAT_COMPLETED]: {
    desc: 'Proyek sudah selesai dan tidak akan ada lagi penghasilan tambahan dari properti',
    color: '#41A533'
  },
  [PROPSTATUS.PROPSTAT_OPEN]: {
    desc: 'Coming Soon',
    color: '#4180C9'
  },

}

export default class PropInvest extends React.Component {

  static defaultProps = {

    pro_slug: '',
    bank_list: [],
    u_aggr_liquid_bal: 0,
    pro_price: 0,
    pro_total_slot: 0,
    pro_aggr_status: '',
    pro_is_coming_soon: '',
    pro_min_slot: 0,
    pro_aggr_filled_slot: 0,
    history: null, // for redirection
    isLoading: false,
    total_iv_slot_registered_tstamp:'',
    total_iv_slot:0,
  };

  constructor(props) {
    super(props);
    this.state = {
      iv_slot: 0,
      py_amount_reinvest: price_d2r(0),
      id_bank_account_dst: 0,
      validErrors: {},
      cb_understand: '',
      pro_return:0,
      buttonLoading: false,
    };
  }

  componentDidMount() {
    // clean incase we have dirty data there
    dataHelper.clear(DATAVAR.INVEST);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;

    if (name === 'py_amount_reinvest') {
      val = price_d2r(val);
      var _val = price_r2d(val);

      // max cap
      const {pro_total_slot, pro_price, u_aggr_liquid_bal} = this.props;
      const price_per_slot = pro_total_slot ? pro_price/pro_total_slot: 0;
      const iv_calc_value = price_per_slot * this.state.iv_slot;

      if(_val > Math.min(iv_calc_value, u_aggr_liquid_bal)) {
        val = price_d2r(Math.min(iv_calc_value, u_aggr_liquid_bal));
      }

    }

    this.setState({ [name]: val });
  };

  validate = () => {
    var errors = {};
    const { pro_aggr_status, pro_is_coming_soon, pro_min_slot,
      pro_price, pro_total_slot, pro_aggr_filled_slot, u_aggr_liquid_bal
    } = this.props;
    const { iv_slot, py_amount_reinvest, id_bank_account_dst, cb_understand } = this.state;

    const price_per_slot = pro_total_slot ? pro_price/pro_total_slot: 0;
    const iv_calc_value = price_per_slot * iv_slot;

    const min_slot = pro_min_slot;
    const max_slot = pro_total_slot - pro_aggr_filled_slot;

    const _py_amount_reinvest = price_r2d(py_amount_reinvest);

    const open_investment = (pro_aggr_status === PROPSTATUS.PROPSTAT_OPEN && pro_is_coming_soon === STAT.STAT_NO);
    if (!open_investment) {

      errors[''] = 'Tidak menerima investasi';
    } else {

      // slot is more than minimum AND less than the available slot
      if(!iv_slot || iv_slot === '0')
      errors['iv_slot'] = 'Slot investasi tidak boleh 0';
      else if(iv_slot < min_slot || iv_slot > max_slot)
      errors['iv_slot'] = 'Slot investasi tidak boleh kurang dari ' + min_slot + ' dan lebih besar dari ' + max_slot;

      // invest from wallet cannot be more than what the user have
      if (_py_amount_reinvest > u_aggr_liquid_bal)
      errors['py_amount_reinvest'] = "Penggunaan saldo Wallet tidak boleh lebih dari nilai wallet " + price_d2r(u_aggr_liquid_bal);
      // invest from wallet cannot be more than the slot actual price
      else if (_py_amount_reinvest > iv_calc_value)
      errors['py_amount_reinvest'] = "Penggunaan saldo Wallet tidak boleh lebih dari " + price_d2r(iv_calc_value);

      // check if user still need to make transfer
      var py_amount_transfer = iv_calc_value - _py_amount_reinvest;
      py_amount_transfer = py_amount_transfer < 0 ? 0 : py_amount_transfer;

      if(py_amount_transfer) {
        if(!id_bank_account_dst)
        errors['id_bank_account_dst'] = "Bank Tujuan harus diisi.";

        if (py_amount_transfer < NUMBERS.MIN_TRANSFER_AMOUNT)
        errors['py_amount_reinvest'] = "Nilai minimum untuk bank transfer adalah " + price_d2r(NUMBERS.MIN_TRANSFER_AMOUNT);
      }

      // must agree to risk
      if(!cb_understand)
      errors['cb_understand'] = "Kamu harus menyetujui pernyataan ini dengan cara mencek checkbox di atas.";

    }

    this.setState({validErrors: errors}, () => formScrollToError());

    if(Object.keys(errors).length) {
      // generic error use message
      if(typeof errors[''] !== 'undefined')
      dispatchMsg('error',  errors['']);
      return false;
    }
    return true;

  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    // clear the errors
    this.setState({ buttonLoading: true});

    const { iv_slot, py_amount_reinvest, id_bank_account_dst } = this.state;
    const { pro_slug } = this.props;

    apiHelper.request(this, {
      resource: 'invest',
      urlParams: [ this.props.pro_slug],
      data: formData('form-invest'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          // ok, no problem with credential, go to review
          dispatchMsg('success', 'Investasi berhasil dilakukan.');

          // GTM
          if(window.dataLayer) {
            window.dataLayer.push({event:'User Invest', iv_calc_value: parseInt(resp.data.iv_calc_value)});
          }

          this.setState({ buttonLoading: false }, () =>
          this.props.history.push( "/payment/"+ pro_slug + '-' + resp.data.id_investment));

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else if (resp.status === APISTAT.ERR_USER_CREDENTIAL) {
          // need to fill in user credential first. save current data to temp storage
          dataHelper.set(DATAVAR.INVEST, { iv_slot, py_amount_reinvest, id_bank_account_dst, pro_slug: this.props.pro_slug });
          dispatchMsg('success', 'Data telah disimpan. Untuk menyelesaikan investasi silakan lengkapi data diri kamu.');
          this.setState({buttonLoading: false}, () => { this.props.history.push( "/credential/profile") });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false });
      },
    });
  }

  redirectTo = targetUrl => ev => {
    dataHelper.set(DATAVAR.REDIRECT_LOGIN, this.props.history.location.pathname);
    this.props.history.push(targetUrl);
  }


  getMargin = (slot_param, price_slot) => {

    var {pro_share_margin, pro_return} = this.props;

    const iv_calc_value = price_slot * slot_param;

    const margin_total = parseInt(pro_share_margin * iv_calc_value / 100);

    const margin_min = Number((get_range(pro_return,"min") * iv_calc_value / 100));

    const margin_max = Number((get_range(pro_return,"max") * iv_calc_value / 100));

    return array_length(pro_return)>1? price_d2r(margin_min) + " - " + price_d2r(margin_max) : price_d2r(margin_total);
  }


  render() {
    var { isLoading, bank_list, pro_price, pro_total_slot, pro_duration, pro_aggr_status, pro_is_coming_soon, action_req, pro_status_tstamp, u_aggr_liquid_bal, pro_start_project_date } = this.props;
    const { iv_slot } = this.state;

    // this use read format (with Rp)
    const { py_amount_reinvest } = this.state;
    const _py_amount_reinvest = price_r2d(py_amount_reinvest);


    const price_per_slot = pro_total_slot ? pro_price/pro_total_slot: 0;
    const iv_calc_value = price_per_slot * iv_slot;




    let total_transfer = iv_calc_value - _py_amount_reinvest;
    if(total_transfer < 0)
    total_transfer = 0;

    if(isLoading) {
      return '';
    }

    var content = null; // content will be differed based on condition

    const open_investment = (pro_aggr_status === PROPSTATUS.PROPSTAT_OPEN && pro_is_coming_soon === STAT.STAT_NO);

    if(!userHelper.isLogin()) {
      // not logged in yet, ask to login
      content = (
        <div className="please-login">
          <div className="please-login-text"><b>Daftar</b> atau <b>Masuk</b> dengan mudah menggunakan akun Facebook/Gmail/Email kamu untuk melihat info yang lebih lengkap dan juga berinvestasi.
          </div>
          <Button onClick={this.redirectTo('/login')} className="button-login button-login-other button-login-detail button-white">Masuk</Button>

          <Button onClick={this.redirectTo('/register')} className="button-register button-register-other button-register-detail button-blue">Daftar</Button>
        </div>
      );

    } else if(action_req === 'validateEmail') {
      content = <div>
        Validasi email kamu dengan mengklik link yang dikirim ke <u><UserContainer part="email" /></u> untuk melihat info yang lebih lengkap dan juga berinvestasi.
        <div style={{marginTop: 10}}>
          Belum menerima email? <Button onClick={this.redirectTo('/send-email-verify?action=resend')} className="button-blue" style={{padding: "1px 8px", marginLeft: 9}}>Kirim Lagi</Button>
      </div>
    </div>;

  } else if(!open_investment) {

    if(pro_aggr_status === PROPSTATUS.PROPSTAT_ACTIVE) {
      // check if this is in processing or not
      if (date_r2d(new Date()) < date_r2d(pro_start_project_date)) {
        pro_aggr_status = PROPSTATUS.PROPSTAT_ACTIVE_PROCESSING;
      } else {
        pro_status_tstamp = pro_start_project_date; // start from start project
      }
    }

    var color = isLoading? '' : status[pro_aggr_status].color;
    var statusDesc = isLoading? '' : status[pro_aggr_status].desc;

    content = (
      <React.Fragment>
        <div style={{fontStyle: 'italic'}}>
          Proyek ini tidak lagi menerima pendanaan
        </div>
        <div style={{marginTop: 20}}>Status: </div>
        <div className="loading-effect"><b><span style={{ color }}>{pro_aggr_status}</span></b>&nbsp;
          <Tooltip disableTouchListener classes={{popper:"tooltip-content"}} title={
              <div>{statusDesc}</div>
            }>
            <i style={{fontSize: 13}} className="material-icons tiny info-pop-link">info</i>
          </Tooltip>, Sejak {pro_status_tstamp}
        </div>
      </React.Fragment>
    );

  } else {
    content = (
      <form onSubmit={this.handleSubmit} id="form-invest">
        <h4 style={{marginTop: 0}}>Investasi Sekarang!</h4>
        <Grid container className="form-group">
          <Grid item xs={6} className="form-label">Harga/Slot</Grid>
          <Grid item xs={6} className="form-content">
            <div className="form-content-info">{price_d2r(price_per_slot)}</div>
          </Grid>
        </Grid>
        <Grid container className="form-group">
          <Grid item xs={6} className="form-label">Jumlah Slot</Grid>
          <Grid item xs={6} className="form-content">
            <MyTextField name="iv_slot" label="" obj={this} type="number" />
          </Grid>
        </Grid>
        <Grid container className="form-group">
          <Grid item xs={6} className="form-label">Nilai Investasi</Grid>
          <Grid item xs={6} className="form-content">
            <div className="form-content-info">{price_d2r(iv_calc_value)}</div>
          </Grid>
        </Grid>

        <div className={iv_calc_value? 'slide-down': 'slide-down open'} style={{backgroundColor: COLORS.BG_GRAB_ATTN, fontSize: 13, borderRadius: 5, padding: (iv_calc_value ? 0: "13px 15px")}}>
          <b>Hitung</b> imbal hasilmu disini! <br />
          <b>*
          </b>masukan jumlah slot yang ingin kamu investasikan di kolom atas.
        </div>

        <div className={iv_calc_value? 'slide-down open' : 'slide-down'}>

          <Divider variant="middle" />
          <div className="propinvest-detail-inverse propinvest-detail" style={{background: '#f4f4f4', borderRadius: 3}}>
            Kamu akan mendapatkan imbal hasil <b>{this.getMargin(iv_slot, price_per_slot)}</b> selama <b>{pro_duration}</b>, lebih besar dibanding menabung di deposito. Isi form di bawah untuk berinvestasi.
          </div>

          <Divider variant="middle" style={{ marginBottom: 18 }} />

          <Grid container className="form-group">
            <Grid item xs={6} className="form-label">Bayar dari saldo Wallet&nbsp;
              <Tooltip disableTouchListener classes={{popper:"tooltip-content"}} title={
                  <div>Kamu bisa menggunakan saldo wallet untuk membayar sebagian/seluruh investasi kamu. Jumlah saldo di wallet kamu saat ini adalah <span style={{fontSize: 13, fontWeight: '600'}}>{price_d2r(u_aggr_liquid_bal)}</span>.</div>
                }>
                <i style={{fontSize: 12}} className="material-icons tiny info-pop-link">info</i>
              </Tooltip>
            </Grid>
            <Grid item xs={6} className="form-content">
              <MyTextField name="py_amount_reinvest" label="" obj={this} type="tel" helperText={<span>Saldo <span style={{fontWeight: 'bold'}}>{price_d2r(u_aggr_liquid_bal)}</span></span>} />
            </Grid>
          </Grid>
          <div className={total_transfer? 'slide-down open' : 'slide-down'}>
            <Grid container className="form-group">
              <Grid item xs={6} className="form-label">Total Transfer&nbsp;
                <Tooltip disableTouchListener classes={{popper:"tooltip-content"}} title={
                    <div>Total yang harus ditransfer untuk mendanai investasi kamu.</div>
                  }>
                  <i style={{fontSize: 13}} className="material-icons tiny info-pop-link">info</i>
                </Tooltip>
              </Grid>
              <Grid item xs={6} className="form-content">
                <div className="form-content-info">{price_d2r(total_transfer)}</div>
              </Grid>
            </Grid>
            <Grid container className="form-group">
              <Grid item xs={6} className="form-label">Tujuan Transfer</Grid>
              <Grid item xs={6} className="form-content">
                <ReactSelect name="id_bank_account_dst" label="" obj={this}
                  options={bank_list.map(x => ({k:x.id_bank_account, v:x.ba_name}))}
                  />
              </Grid>
            </Grid>
          </div>

          <MyCheckboxes name="cb_understand" style={{margin: '2px 0'}} obj={this}
            options={[
              {k: '1', v:<span style={{fontSize: 13}}>Dengan berinvestasi saya mengetahui dan mengerti <a href="https://provesty.com/risk" target="_blank"  rel="noopener noreferrer">resiko</a> pembiayaan di Provesty</span>},
            ]} required
            />

          <div className="button-progress-wrapper-full" style={{textAlign: 'center'}}>
            <Button disabled={this.state.buttonLoading} className="button-blue" type="submit"  fullWidth style={{marginTop: 20}}>Invest</Button>
            {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" style={{marginTop: -2}} />}
          </div>

        </div>


      </form>
    );
  }

  const { total_iv_registered_tstamp, total_iv_slot } = this.props;

  return (
    <Paper className="soft-paper prop-invest">
      <div className="propinvest-detail">
        <div>
          {
            total_iv_slot>0 ?
            <div>
              <InvestedMark
                font_size={14}
                margin_top={0}
                width_size={'100%'}
              />
              <div style={{marginTop: 10, marginBottom:10}}>
                <div>Investasi terakhir kamu :</div>
                <div>
                  Sebanyak <b>{total_iv_slot} slot</b>
                  <span style={{marginLeft: 5}}>pada tanggal <b>{total_iv_registered_tstamp}</b></span>
                </div>
              </div>
              <hr style={{borderTop: '1px dashed black', marginTop: 15, marginBottom:15}} />
            </div>
            :
            null
          }
        </div>
        {content}
      </div>
    </Paper>
  );
}
}
