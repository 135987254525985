import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export default class ModalMOU extends React.Component {

  static defaultProps = {
    history: null,
    bank_list: [],
    u_aggr_liquid_bal: 0,
    onClose: null
  };

  constructor(props) {
    super(props);
    this.state = {

      modalHeight: 370,
    };
  }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var mywindow = window.open('', 'mydiv');
    mywindow.document.write(printContents);
    mywindow.print();
    mywindow.close();
  }

  render() {
    const { visible, content, onClose } = this.props;
    return (
      <Dialog
        classes={{paper: 'mou-doc', scrollPaper: 'paper'}}
        open={visible}
        onClose={onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        >
        {/*<DialogTitle id="scroll-dialog-title">MOU</DialogTitle>*/}
        <DialogContent>

          <div className="html-content" id="printableArea" dangerouslySetInnerHTML={{
              __html: content
            }} />
          </DialogContent>
          <DialogActions>
            <Button className="button-white button-shadow" onClick={onClose} color="primary">
              Tutup
            </Button>
            <Button className="button-blue button-shadow" onClick={() => this.printDiv('printableArea')} color="primary">
              Print
            </Button>
          </DialogActions>
        </Dialog>
      )

    }
  }
