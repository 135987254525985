import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import MyTextField from 'components/general/MyTextField'
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';
import { apiHelper, dispatchMsg, formVal, formData } from 'helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { APISTAT } from '_constant';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});
class ForgotPasswordComponent extends Component {

  state = {
    email: '',
    buttonLoading: false,
    validErrors: {},

    emailSent: false,
    sendAgainLoading: false,
    seconds: 60
  };

  timerHandle = null;

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
    this.stopTimer();
  }

  handleInputChange = event => {
    var name = event.target.name;
    var val = event.target.value;

    this.setState({ [name]: val });
  };

  startTimer() {
    if (this.timerHandle === null) {
      this.setState({seconds: 59});
      this.timerHandle = setInterval(this.countDown, 1000);
    }
  }
  stopTimer() {
    clearInterval(this.timerHandle);
    this.timerHandle = null;
  }
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({ seconds: seconds });

    // Check if we're at zero.
    if (seconds === 0)
    this.stopTimer();
  }

  validate = () => {
    this.errors = {};

    if(formVal(this, 'email', 'Email', 'required')) {

      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(String(this.state.email).toLowerCase()))
      this.errors['email'] = 'Format email tidak valid';
    }

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;

    this.setState({ buttonLoading: true});
    apiHelper.request(this, {
      resource: 'send_password_reset',
      data: formData({
        email: this.state.email
      }),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success

          dispatchMsg('success', 'Email berhasil dikirim');
          this.startTimer();
          this.setState({ buttonLoading: false, emailSent: true });

        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false});
      }
    });
  };

  sendAgain = () => {
    this.setState({ sendAgainLoading: true});
    apiHelper.request(this, {
      resource: 'send_password_reset',
      data: formData({
        email: this.state.email
      }),
      fnSuccess: resp => {
        // login successful if return valid data
        if (resp.status === APISTAT.SUCCESS) { // success
          this.startTimer();
          this.setState({ sendAgainLoading: false });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ sendAgainLoading: false});
      }
    });
  }

  render() {

    var { seconds } = this.state;
    var timer = seconds < 10 ? "0" + seconds: seconds;
    timer = '00:' + timer;

    const insertEmail = <form onSubmit={this.handleSubmit} id="forgot-password">
      <div>
        Masukkan E-mail yang pernah kamu daftarkan
      </div>

      <MyTextField name="email" label="Alamat Email" obj={this} className='input-width' variant="outlined" style={{marginTop: 10}} InputLabelProps={{ style: {fontSize: 16}}} InputProps={{ inputProps: {style: {fontSize: 16}, autoComplete: 'email'}}}/>

      <div className="button-progress-wrapper-full" style={{marginTop: 20}}>
        <Button disabled={this.state.buttonLoading} type="submit" className="input-width button-blue" variant="contained">Kirim Link Ubah Password</Button>
        {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
      </div>

    </form>;

    const emailSentInfo = <div>
      Email yang berisi link konfirmasi sudah dikirim ke <b>{this.state.email}</b>. Demi keamanan link tersebut akan expired dalam waktu 1 jam.
      <div style={{ marginTop: 13, fontSize: 12}}>
        Tidak mendapatkan email? {
          this.state.seconds === 0?
          <div className="button-progress-wrapper">
            <Button disabled={this.state.sendAgainLoading} onClick={this.sendAgain} className="button-blue" style={{padding: "0px 5px", fontSize: 13, marginTop: -4
            }}>Kirim Ulang &nbsp;<i className="material-icons button-icon">send</i>
          </Button>
          {this.state.sendAgainLoading && <CircularProgress size={16} style={{marginTop: -10}} className="button-progress" />}
        </div>
        :
        <React.Fragment>
          <b>Kirim Ulang</b> {timer}
          </React.Fragment>
        }
      </div>
    </div>;

    return (
      <React.Fragment>
        <Grid container justify="center" alignItems="center" className="faux-tab auth-box" >
          Lupa Password
        </Grid>
        <div className="auth-box">
          <div className="tab-container">
            <div className="auth-form">
              {this.state.emailSent? emailSentInfo : insertEmail}
            </div>
          </div>
        </div>
        <div className="sign-up-now">
          <p>Kembali ke halaman<span className="sign-up-button"> <Link to="/login"><u>Login</u></Link></span></p>
        </div>
      </React.Fragment>
    )
  }
}

ForgotPasswordComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgotPasswordComponent);
