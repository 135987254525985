import { SET_MESSAGE, CLOSE_MESSAGE } from '_constant';

// call by other components
export const setMessage = (msgType, msg) => ({
  type: SET_MESSAGE,
  payload: {msgType, msg}
})

export const setCloseMessage = (msgType, msg) => ({
  type: CLOSE_MESSAGE
})

// called internally
export const closeMessage = (dispatch) => {
  dispatch({
    type: CLOSE_MESSAGE,
  });
}
