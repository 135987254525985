import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MyTextField from "components/general/MyTextField";
import Grid from '@material-ui/core/Grid';
import { formData, apiHelper, dispatchMsg, formScrollToError, formDef, formValid } from 'helpers';
import { APISTAT } from '_constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-spinkit';

function getSteps() {
  return ['Informasi Proyek', 'Lengkapi Detail Proyek', 'Informasi Dokumen'];
}

export default class ProjectDetail extends React.Component {

  static defaultProps = {
    history: null
  }

  constructor(props) {
    super(props);
    var fields = formDef(this, [
      {name: 'pj_desc_parties', label: 'Pihak Yang Terlibat'},
      {name: 'pj_desc_marketing', label: 'Startegi Marketing'},
      {name: 'pj_desc_mitigation', label: 'Mitigasi Resiko'},
      {name: 'pj_desc_repayment', label: 'Skema Pengembalian Investasi'},
      {name: 'pj_desc', label: 'Deskripsi lainnya'},
    ]);

    this.state = {
      activeStep: 1,

      ...fields,

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  handleInputChange = (event) => {
    var name = event.target.name;
    var val = event.target.value;

    this.setState({ [name]: val });
  };


  validate = () => {

    this.errors = {};

    formValid(this);

    this.setState({validErrors: this.errors}, () => formScrollToError());

    return !Object.keys(this.errors).length;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;
    // clear the errors
    this.setState({ buttonLoading: true });

    apiHelper.request(this, {
      resource: 'project_detail_post',
      urlParams: [ this.props.match.params.id_project],
      data: formData('form-data'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ buttonLoading: false });
          dispatchMsg('success', 'Detail proyek berhasil disimpan.')
          this.props.history.push( "/project/document/" + this.props.match.params.id_project);
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ buttonLoading: false });
      },
    });
  }


  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'project_detail_get', // use the secured one if loggedin
      urlParams: [ this.props.match.params.id_project],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }


  render() {

    const steps = getSteps();
    const { activeStep } = this.state;
    const stepButtons = steps.map((label, index) => {
      return (
        <Step key={label} name="stepper" >
          <StepLabel classes={{label: 'step-label'}} StepIconProps={{classes: {root: 'step-icon', active: 'step-icon-active', completed: 'step-icon-completed'}}}>
            {label}
          </StepLabel>
        </Step>
      );
    });

    const formContent =
    <Grid container spacing={16}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid item xs={12}>
          <div className="form-legend">Detail Proyek</div>
          <div style={{textAlign: 'left', fontSize: 13, marginTop: 7 }}>Deskripsi tentang proyek yang menggambarkan proyek yang akan berjalan, deskripsi diharapkan akurat dan tidak menyesatkan, informasi ini akan menjadi pertimbangan kami dalam memutuskan untung menggalang pinjaman</div>
        </Grid>
      </Grid>

      {/*{name: 'pj_desc_parties', label: 'Pihak Yang Terlibat'},
      {name: 'pj_desc_marketing', label: 'Startegi Marketing'},
      {name: 'pj_desc_mitigation', label: 'Mitigasi Resiko'},
      {name: 'pj_desc_repayment', label: 'Skema Pengembalian Investasi'},
      {name: 'pj_desc', label: 'Deskripsi lainnya'},*/}
      <Grid item xs={12}>
        <MyTextField name="pj_desc_parties" obj={this} multiline rows={3} rowsMax={5} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField name="pj_desc_marketing" obj={this} multiline rows={3} rowsMax={5} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField name="pj_desc_mitigation" obj={this} multiline rows={3} rowsMax={5} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField name="pj_desc_repayment" obj={this} multiline rows={3} rowsMax={5} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField name="pj_desc" obj={this} multiline rows={3} rowsMax={5} />
      </Grid>
    </Grid>;

    const stepperContent = this.state.isLoading ?
    <div style={{height: 500}}>
      <Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" />
    </div>
    :
    <form onSubmit={this.handleSubmit} id="form-data" autoComplete="off" name="form">
      {formContent}
      <div className="sms-modal-buttons" style={{marginTop: 55, marginBottom: 30, textAlign: 'center'}}>
        <div className="button-progress-wrapper">
          <Button disabled={this.state.buttonLoading} className="btn-stepper-next button-blue" type="submit" style={{width: 120}}>Simpan</Button>
          {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
        </div>
      </div>
    </form>;

    return (
      <div>
        <div className="bg-lightblue">
          <Navbar />
          <div className="container credential-page">
            <Paper >
              <Stepper activeStep={activeStep} alternativeLabel className="stepper-label stepper-loan">
                {stepButtons}
              </Stepper>
            </Paper>
            <Paper className="cred-area">
              <div className="stepper-content">
                {stepperContent}
              </div>
            </Paper>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
