import React, {Component} from 'react';
import Navbar from 'components/general/Navbar';
import Footer from 'components/general/Footer';
import RiskTop from 'components/risk/RiskTop';



class Risk extends Component {
  render(){


    return(
      <div className="risk bg-lightgreen">
        <Navbar/>
        <div className="container">
          <div className="risk-content">
            <RiskTop />
          </div>
          <Footer/>
        </div>
      </div>
    )
  }
}

export default Risk;
