import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
/*import {
  Link as Scroller,
  animateScroll as scroll
} from 'react-scroll'*/
import {Link} from 'react-router-dom'
import LoanImage1 from 'styles/assets/loan/loan1.png'
import Hidden from '@material-ui/core/Hidden';


class Loan1 extends Component {

  /*scrollToBottom = () => {
    scroll.scrollToBottom()
  */

  render() {
    return (
      <div className="loan-1 container">
        <Grid container alignItems="center" className="loan1-grid">
          <Grid item lg={1} md={1} sm={false} xs={false} />
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div>
              <div className="kemudahan-pengajuan">Dapatkan Kemudahan</div>
              <div className="flipping-property">Menjual Rumah</div>
              <Hidden mdUp>
                <img src={LoanImage1} alt="loan" className="loan1-image"/>
              </Hidden>
              <div className="loan1-content">Melalui Prohouse by Provesty kamu bisa menjual rumah dengan mudah. Dengan agen yang berpengalaman, rumah kamu akan segera terjual.</div>
              <div className="loan1-buttons">
                <Grid container>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <Link to="/prohouse" className="casual-link">
                      <Button className="btn-dark-blue" variant='contained'> Jual Sekarang</Button>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div className="loan1-right">
              <Hidden smDown>
                <div className="loan1-left">
                  <img src={LoanImage1} alt="loan" className="loan1-image"/>
                </div>
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </div>)
    }
  }

  export default Loan1
