import React from 'react';
import Navbar from "components/general/Navbar";
import Footer from "components/general/Footer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MyTextField from "components/general/MyTextField";
import ReactSelect from "components/general/ReactSelect";
import Grid from '@material-ui/core/Grid';
import ModalSMS from "components/dashboard/ModalSMS";
import { formVal, formData, apiHelper, dispatchMsg, formScrollToError, dispatchUpdateUser } from 'helpers';
import { APISTAT, STAT } from '_constant';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-spinkit';

function getSteps() {
  return ['Lengkapi Informasi Data Personal', 'Lengkapi Informasi Bank & Dokumen'];
}

export default class CredentialProfile extends React.Component {

  static defaultProps = {
    history: null
  }

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      completed: {},

      u_name: '',
      u_nickname: '',
      u_dob: '',
      u_phone: '',
      u_phone_compact: '',
      u_is_phone_verified: '',
      id_province: '',
      id_regency: '',
      ad_line: '',
      id_job: '',
      id_income_source: '',

      verification_code: '',
      is_phone_need_verify: true,

      provinces: {},
      regencies: {},
      jobs: {},
      income_sources: {},

      smsVisible: false,
      smsData: {},
      smsLoading: false,
      smsKey: '',

      buttonLoading: false,
      isLoading: true,
      validErrors: {},

    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    apiHelper.cancelAllRequest(this);
  }

  // form
  handleInputChange = event => {

    var name = event.target.name;
    var val = event.target.value;

    if (name === 'u_phone') {
      // filter phone and decide if we need to verify
      val = val.replace(/[^\d\s()+.-]/g, '');  // filter non number and (,),+,-

      var phone_compact = val.replace(/[^0-9]/g, '').replace(/^62/g, '0');
      var verified_phone_compact = this.state.u_is_phone_verified === STAT.STAT_YES ? this.state.u_phone_compact : '';
      var is_phone_need_verify = verified_phone_compact === '' || phone_compact !== verified_phone_compact;

      this.setState({ [name]: val, is_phone_need_verify });
    } else if (name === 'id_province') {
      this.setState({ [name]: val }, this.loadRegency);
    } else {
      this.setState({ [name]: val });
    }
  };

  validate = () => {

    this.errors = {};

    formVal(this, 'u_name', 'Nama', 'required');
    formVal(this, 'u_dob', 'Tanggal Lahir', 'requiredDate');
    formVal(this, 'u_phone', 'Phone', 'required');
    formVal(this, 'id_province', 'Provinsi', 'required');
    formVal(this, 'id_regency', 'Kota/Kabupaten', 'required');
    formVal(this, 'ad_line', 'Alamat', 'required');
    formVal(this, 'id_job', 'Pekerjaan', 'required');
    formVal(this, 'id_income_source', 'Sumber Dana', 'required');

    this.setState({validErrors: this.errors}, () => formScrollToError());

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  phoneValidate = () => {

    this.errors = {};
    formVal(this, 'u_phone', 'Phone', 'required');

    this.setState({validErrors: this.errors});

    if(Object.keys(this.errors).length) {
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if(!this.validate())
    return;
    // clear the errors
    this.setState({ buttonLoading: true });

    apiHelper.request(this, {
      resource: 'credential_profile_post',
      data: formData('form-credential'),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          // next step
          dispatchUpdateUser(resp.data);
          this.setState({ buttonLoading: false });
          this.props.history.push( "/credential/document");
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ buttonLoading: false, validErrors: resp.valid_errors}, () => formScrollToError());
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({buttonLoading: false});
      },
    });
  }

  launchSms = () =>  {

    if(!this.phoneValidate())
    return;

    this.setState({ smsLoading: true });

    apiHelper.request(this, {
      resource: 'send_sms',
      data: formData({u_phone: this.state.u_phone}),
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) { // success
          this.setState({ smsLoading: false, smsData: {'u_phone': this.state.u_phone}, smsVisible: true, smsKey: this.state.u_phone});
        } else if (resp.status === APISTAT.ERR_USER_INPUT) {
          this.setState({ smsLoading: false, validErrors: resp.valid_errors});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({smsLoading: false});
      },
    });
  }

  onSmsClose = (isVerified) => {
    if (isVerified === true) {  // must be exact true
      this.setState({
        smsVisible: false,
        u_is_phone_verified: isVerified? STAT.STAT_YES: STAT.STAT_NO,
        is_phone_need_verify: isVerified? false: true
      });
    } else {
      this.setState({smsVisible: false});
    }
  }

  loadRegency = () => {
    apiHelper.request(this, {
      resource: 'regencies_select',
      urlParams: [this.state.id_province],
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ id_regency: '', regencies: resp.data });
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
      }
    });
  }

  loadData = () => {

    this.setState({ isLoading:true });

    apiHelper.request(this, {
      resource: 'credential_profile_get', // use the secured one if loggedin
      fnSuccess: resp => {
        if (resp.status === APISTAT.SUCCESS) {
          this.setState({ isLoading:false, ...resp.data, is_phone_need_verify: resp.data.u_is_phone_verified === STAT.STAT_NO});
        } else {
          throw new Error(resp.msg);
        }
      },
      fnError: err => {
        dispatchMsg('error', err.message);
        this.setState({ isLoading: false });
      }
    });
  }


  render() {

    const steps = getSteps();
    const { activeStep } = this.state;
    const stepButtons = steps.map((label, index) => {
      return (
        <Step key={label} name="stepper" >
          <StepLabel classes={{label: 'step-label'}} StepIconProps={{classes: {root: 'step-icon', active: 'step-icon-active', completed: 'step-icon-completed'}}}>
            {label}
          </StepLabel>
        </Step>
      );
    });

    const content =
    <Grid container spacing={16}>
      <Grid item xs={12} sm={6}>
        <MyTextField name="u_name" label="Nama Lengkap" obj={this} required
          />
      </Grid>
      <Grid item xs="auto" sm={6}>
        <MyTextField name="u_nickname" label="Nama Panggilan" obj={this}
          />
      </Grid>
      <Grid item xs="auto" sm={6}>
        <MyTextField
          name="u_dob" label="Tanggal Lahir" obj={this} required shrink type="date" />
      </Grid>
      <Grid item sm={12} container justify="flex-end">
        <Grid item xs={12} sm>
          <MyTextField name="u_phone" label="Phone" obj={this}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" classes={{root: 'input-adornment'}}>
                  {
                    this.state.u_phone ?
                    (this.state.is_phone_need_verify ? <div style={{color: "#cb4f5e", whiteSpace: "nowrap"}}>(Belum Terverfikasi)</div> :<div style={{color: "#24b47e", whiteSpace: "nowrap"}}>(Terverifikasi)</div>) :
                    ''
                  }
                </InputAdornment>
              )
            }}
            required />
        </Grid>
        { this.state.is_phone_need_verify && this.state.u_phone ?
          <Grid item>
            <div className="button-progress-wrapper">
              <Button disabled={this.state.smsLoading} className="button-blue button-verify-phone"
                onClick={this.launchSms}>Kirim kode verifikasi &nbsp;<i className="material-icons button-icon">send</i></Button>
              {this.state.smsLoading && <CircularProgress size={24} style={{marginTop: -7}} className="button-progress" />}
            </div>
          </Grid> :
          ''
        }
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect name="id_province" label="Provinsi" obj={this}
          options={Object.keys(this.state.provinces).map(k => ({k: k, v:this.state.provinces[k]}))}
          required />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ReactSelect
          name="id_regency" label="Kota" obj={this} options={Object.keys(this.state.regencies).map(k => ({k: k, v:this.state.regencies[k]}))}
          required />
      </Grid>
      <Grid item xs={12} sm={12}>
        <MyTextField name="ad_line" label="Address" obj={this} multiline rows={3} />
      </Grid>
      <Grid item xs={6} sm={6}>
        <ReactSelect name="id_job" label="Pekerjaan" obj={this} options={Object.keys(this.state.jobs).map(k => ({k: k, v:this.state.jobs[k]}))}
          required />
      </Grid>
      <Grid item xs={6} sm={6}>
        <ReactSelect name="id_income_source" label="Sumber Dana" obj={this} options={Object.keys(this.state.income_sources).map(k => ({k: k, v:this.state.income_sources[k]}))}
          required />
      </Grid>
    </Grid>;


    const formContent = content;

    const stepperContent = this.state.isLoading ?
    <div style={{height: 500}}>
      <Spinner className="my-spinner" fadeIn='quarter' name="chasing-dots" />
    </div>
    :
    <form onSubmit={this.handleSubmit} id="form-credential" autoComplete="off" name="form">
      {formContent}
      <div className="sms-modal-buttons" style={{marginTop: 55, marginBottom: 30, textAlign: 'center'}}>
        <div className="button-progress-wrapper">
          <Button disabled={this.state.buttonLoading} className="btn-stepper-next button-blue" type="submit" style={{width: 120}}>Simpan</Button>
          {this.state.buttonLoading && <CircularProgress size={24} className="button-progress" />}
        </div>
      </div>
    </form>;

    return (
      <div>
        <div className="bg-lightblue">
          <Navbar />
          <div className="container credential-page">
            <Paper >
              <Stepper activeStep={activeStep} alternativeLabel className="stepper-label">
                {stepButtons}
              </Stepper>
            </Paper>
            <Paper className="cred-area">
              <div className="stepper-content">
                {stepperContent}
              </div>
            </Paper>
          </div>
          <Footer />
        </div>
        <ModalSMS
          visible={this.state.smsVisible} key={this.state.smsKey} {...this.state.smsData}
          onClose={this.onSmsClose}
          />
      </div>
    );
  }
}
